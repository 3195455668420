import {
  GET_BLOCK_LIST,
  SAVE_BLOCK_LIST,
  GET_WELL_LIST,
  SAVE_WELL_LIST,
  GET_LICENSE_LIST,
  SAVE_LICENSE_LIST,
  GET_ASSET_LIST,
  SAVE_ASSET_LIST,
  GET_USERLIST_LIST,
  SAVE_USERLIST_LIST,
  DELETE_ITEM_LIST,
  DELETE_USER_LISTS,
  CLEAR_DELETE_ITEM_LIST,
  EDIT_SINGLE_ITEM,
  SAVE_SINGLE_ITEM_EDIT,
  CLEAR_SINGLE_ITEM_EDIT,
  GET_AREA_LIST,
  SAVE_AREA_LIST,
  SAVE_USERLIST_LIST_EDIT,
  EDIT_USERLIST_LIST,
  GET_MESSAGE_LIST,
  SAVE_MESSAGE_LIST,
  SAVE_MESSAGE,
  SET_MESSAGE_READ,
  GET_MESSAGE_LIST_FOR_USER,
  SAVE_MESSAGE_LIST_FOR_USER,
  SHOW_MODAL,
  CLOSE_MODAL,
  CU_GET_LIST,
  CU_SAVE_LIST,
  CU_DELETE_SINGLE_ITEM,
  CU_DELETED_SINGLE_ITEM,
  CU_UPDATE_ITEM,
  CU_GET_GROUP_LIST,
  CU_SAVE_GROUP_LIST,
  CU_UPDATED_ITEM,
  CU_ERROR,
  SAVE_RIG_LIST,
  GET_RIG_LIST,
  GET_APP_SETTINGS,
  SAVE_APP_SETTINGS,
  GET_COUNTRY_LIST,
  SAVE_COUNTRY_LIST,
} from "../types"

export function getBlockList() {
  return { type: GET_BLOCK_LIST }
}
export function getUserlistList() {
  return { type: GET_USERLIST_LIST }
}
export function saveBlocks(blocks) {
  return { type: SAVE_BLOCK_LIST, blocks }
}
export function saveUserlists(userlists) {
  return { type: SAVE_USERLIST_LIST, userlists }
}
export function getWellList() {
  return { type: GET_WELL_LIST }
}
export function saveWells(wells) {
  return { type: SAVE_WELL_LIST, wells }
}
export function getAppSettings() {
  return { type: GET_APP_SETTINGS }
}
export function saveAppSettings(settings) {
  return { type: SAVE_APP_SETTINGS, settings }
}
export function getRigList() {
  return { type: GET_RIG_LIST }
}
export function saveRigs(rigs) {
  return { type: SAVE_RIG_LIST, rigs }
}
export function getLicenseList() {
  return { type: GET_LICENSE_LIST }
}
export function saveLicenses(licenses) {
  return { type: SAVE_LICENSE_LIST, licenses }
}
export function getAssetList() {
  return { type: GET_ASSET_LIST }
}
export function saveAssets(assets) {
  return { type: SAVE_ASSET_LIST, assets }
}
export function getAreaList() {
  return { type: GET_AREA_LIST }
}
export function saveAreas(assets) {
  return { type: SAVE_AREA_LIST, assets }
}
export function getCountryList() {
  return { type: GET_COUNTRY_LIST }
}
export function saveCountries(countries) {
  return { type: SAVE_COUNTRY_LIST, countries }
}
export function deleteItems(itemType, items) {
  return { type: DELETE_ITEM_LIST, itemType, items }
}
export function deleteUserLists(items) {
  return { type: DELETE_USER_LISTS, items }
}
export function editSingleItem(itemType, item) {
  return { type: EDIT_SINGLE_ITEM, itemType, item }
}
export function saveEditItem(item) {
  return { type: SAVE_SINGLE_ITEM_EDIT, item }
}
export function editUserlistItem(item) {
  return { type: EDIT_USERLIST_LIST, item }
}
export function saveUserlistItem(item) {
  return { type: SAVE_USERLIST_LIST_EDIT, item }
}
export function clearDeleteList() {
  return { type: CLEAR_DELETE_ITEM_LIST }
}
export function clearSingleItemEdit() {
  return { type: CLEAR_SINGLE_ITEM_EDIT }
}
export function getMessagesList() {
  return { type: GET_MESSAGE_LIST }
}
export function getMessagesListForUser() {
  return { type: GET_MESSAGE_LIST_FOR_USER }
}
export function saveMessagesList(messages) {
  return { type: SAVE_MESSAGE_LIST, messages }
}
export function saveMessagesListForUser(messages) {
  return { type: SAVE_MESSAGE_LIST_FOR_USER, messages }
}
export function saveMessage(message) {
  return { type: SAVE_MESSAGE, message }
}
export function setMessageRead(id) {
  return { type: SET_MESSAGE_READ, id }
}
export function showModal(modalType, data) {
  return { type: SHOW_MODAL, modalType, data }
}
export function closeModal(modalType) {
  return { type: CLOSE_MODAL, modalType }
}

// Cognito
export function getCognitoGroups() {
  return { type: CU_GET_GROUP_LIST }
}
export function saveCognitoGroups(groups) {
  return { type: CU_SAVE_GROUP_LIST, payload: groups }
}
export function getCognitoUsers() {
  return { type: CU_GET_LIST }
}
export function saveCognitoUsers(users) {
  return { type: CU_SAVE_LIST, payload: users }
}
export function deleteCognitoUser(userId) {
  return { type: CU_DELETE_SINGLE_ITEM, payload: userId }
}
export function deletedCognitoUser(userId) {
  return { type: CU_DELETED_SINGLE_ITEM, payload: userId }
}

export function updateCognitoUser(user) {
  return { type: CU_UPDATE_ITEM, payload: user }
}
export function updatedCognitoUser(user) {
  return { type: CU_UPDATED_ITEM, payload: user }
}
export function cognitoUserError(err) {
  return { type: CU_ERROR, payload: err }
}
