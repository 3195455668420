import React, { Component } from "react"
import store from "store"
import SchedulerRigList from "./SchedulerRigList"
import SchedulerRigPlotter from "./SchedulerRigPlotter"

export class SchedulerOverview extends Component {
  constructor() {
    super()
    this.state = {
      scrollTop: 0,
    }
  }

  syncScroll = (e, id) => {
    const { scrollTop } = e.target
    this.setState({
      scrollTop,
    })
  }

  render() {
    const { scrollTop } = this.state
    return (
      <div className="Scheduler--Overview">
        <SchedulerRigList scrollTop={scrollTop} syncScroll={(e) => this.syncScroll(e, "list")} />
        <SchedulerRigPlotter scrollTop={scrollTop} syncScroll={(e) => this.syncScroll(e, "plotter")} />
      </div>
    )
  }
}

SchedulerOverview.propTypes = {}

export default SchedulerOverview
