import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

import ContentList from "../Bits/ContentList"

class EditBlocks extends Component {
  componentDidMount() {
    const { getBlockList } = this.props
    getBlockList()
  }

  handleDeleteFormSubmit = (selectedItems) => {
    const { deleteItems } = this.props
    deleteItems("block", selectedItems)
  }

  editItem = (item) => {
    const { editSingleItem } = this.props
    editSingleItem("block", item)
  }

  addItem = () => {
    const { editSingleItem } = this.props
    editSingleItem("block", { name: "" })
  }

  render() {
    const { blocks } = this.props

    return (
      <div className="ManageContent__Content">
        <ContentList
          addItem={this.addItem}
          editItem={this.editItem}
          deleteItemsFunc={this.handleDeleteFormSubmit}
          header={"Edit blocks"}
          items={blocks}
        />
      </div>
    )
  }
}

EditBlocks.propTypes = {
  editSingleItem: PropTypes.func,
  getBlockList: PropTypes.func,
  blocks: PropTypes.array,
  deleteItems: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    blocks: state.managecontent.blocks,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBlocks)
