import React, { PureComponent } from "react"
import PropTypes from "prop-types"

class ContextMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.wrapperRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    const { deselect } = this.props

    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      deselect()
    }
  }

  render() {
    const { options, onSelect, id, active, yPos, styles = {} } = this.props

    if (!active) return null

    const arrowTop = 7 + (yPos - 50)
    const arrowStyle = {
      top: arrowTop || "7px",
    }

    return (
      <div ref={this.wrapperRef} key={id} style={styles} className={`contextmenu ${active ? "contextmenu--item-wrapper-active" : ""}`}>
        <span className="arrow" style={arrowStyle}></span>
        {options.map((val, key) => (
          <div key={key} onClick={(e) => onSelect(e, val)} className={"contextmenu--item-wrapper"}>
            <span className="contextmenu--item">{val.name}</span>
          </div>
        ))}
      </div>
    )
  }
}

ContextMenu.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  deselect: PropTypes.func,
  active: PropTypes.bool,
  styles: PropTypes.object,
  yPos: PropTypes.number,
}

export default ContextMenu
