import { createBrowserHistory } from "history"

import thunkMiddleware from "redux-thunk"
import { createStore, applyMiddleware, compose } from "redux"
import { createEpicMiddleware } from "redux-observable"
import { routerMiddleware } from "connected-react-router"

// import { createLogger } from 'redux-logger'

import Routing from "./middelware/Routing"
import reducer from "./reducers"

import { rootEpic } from "./observers"

const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose // eslint-disable-line no-undef
const epicMiddleware = createEpicMiddleware()

export const history = createBrowserHistory()

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history)

// const logger = createLogger({
//   collapsed: true,
// });

function configureStore(initialState, persistedState = {}) {
  const enhancer = composeEnhancers(
    applyMiddleware(
      middleware,
      thunkMiddleware,
      epicMiddleware,
      Routing
      // logger,
    )
  )

  const store = createStore(reducer(history), initialState, enhancer, persistedState)

  epicMiddleware.run(rootEpic)

  return store
}

export const store = configureStore

export default configureStore
