import React, { useEffect } from "react"
import PropTypes from "prop-types"
import IconContainer from "../icons/IconContainer"

const ChangeMajor = (props) => {
  useEffect(() => {}, [props.data, props])

  const { data, name, Icon, IconWidth, IconHeight } = props

  return (
    <div className="timeline__calendar-change__icons-container--change-desc-icon timeline__change-desc-icon">
      <div className="timeline__change-desc-icon--desc">
        <div className="timeline__change-desc-icon--desc_amount">{data.length}</div>
        <div className="timeline__change-desc-icon--desc_type">{name}</div>
      </div>
      {IconContainer(Icon, IconWidth, IconHeight)}
    </div>
  )
}

ChangeMajor.propTypes = {
  data: PropTypes.array,
  name: PropTypes.string,
  Icon: PropTypes.string,
  IconWidth: PropTypes.number,
  IconHeight: PropTypes.number,
}

export default ChangeMajor
