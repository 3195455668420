import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class DarkModeSwitch extends PureComponent {
  toggle = () => {
    const { toggleCalendarDarkMode } = this.props
    toggleCalendarDarkMode()
  }

  render() {
    const { darkMode } = this.props

    return (
      <div onClick={this.toggle} className="DarkModeSwitch">
        {darkMode ? (
          <svg
            width="25px"
            height="15px"
            viewBox="0 0 25 15"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g id="Main-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="RS_desktop_5year_nightMode" transform="translate(-80.000000, -265.000000)">
                <circle id="Oval" fill="#F5F8F9" cx="97.5" cy="272.5" r="5.5"></circle>
                <rect id="Rectangle-5" stroke="#F5F8F9" x="80.5" y="265.5" width="24" height="14" rx="7"></rect>
              </g>
            </g>
          </svg>
        ) : (
          <svg
            width="25px"
            height="15px"
            viewBox="0 0 25 15"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs></defs>
            <g id="Main-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="RS_desktop_5year" transform="translate(-80.000000, -265.000000)" stroke="#1C2226">
                <circle id="Oval" cx="87.5" cy="272.5" r="5"></circle>
                <rect id="Rectangle-5" x="80.5" y="265.5" width="24" height="14" rx="7"></rect>
              </g>
            </g>
          </svg>
        )}
        <span>{darkMode ? "Normal" : "Night"}</span>
      </div>
    )
  }
}

DarkModeSwitch.propTypes = {
  darkMode: PropTypes.bool,
  toggleCalendarDarkMode: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    darkMode: state.calendar.darkMode,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DarkModeSwitch)
