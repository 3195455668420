import React, { useState } from "react"
import PropTypes from "prop-types"
import { EDIT_WELL_CAMPAIGN } from "../../panelConsts"

const newWellData = {
  booking_type: "wells",
  comment: null,
  estimateData: {
    p10: {
      estimate: 0,
      start_date: "2019-06-03T08:15:07.624Z",
    },
    p50: {
      estimate: 0,
      start_date: "2019-06-03T08:15:07.624Z",
    },
    p90: {
      estimate: 0,
      start_date: "2019-06-03T08:15:07.624Z",
    },
  },
  wells: [
    {
      status: "conceptional",
      rig_booking_id: null,
      well_id: null,
      id: -2,
      sequence_priority: 1,
      start_date_is_hard: null,
      earliest_start_date: null,
      has_contract_with: null,
      well_type: null,
      rig_type: null,
      well: {
        name: "",
        short_name: "",
        block_id: null,
        license_id: null,
      },
      estimateData: {
        p10: {
          estimate: 0,
          start_date: "2019-06-03T08:15:07.624Z",
        },
        p50: {
          estimate: 0,
          start_date: "2019-06-03T08:15:07.624Z",
        },
        p90: {
          estimate: 0,
          start_date: "2019-06-03T08:15:07.624Z",
        },
      },
      is_dirty: true,
    },
  ],
}

const NewWellButton = (props) => {
  const [buttonText] = useState(props.text)

  const onClick = () => {
    props.openPanelWithType(EDIT_WELL_CAMPAIGN, newWellData)
  }

  return (
    <div className="New-Well-Button" onClick={onClick}>
      {buttonText}
    </div>
  )
}

NewWellButton.propTypes = {
  text: PropTypes.string,
  openPanelWithType: PropTypes.func,
}

export default NewWellButton
