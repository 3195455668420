import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class Sidebar extends PureComponent {
  componentDidMount() {
    const { fetchUserRightsAndRoles } = this.props
    fetchUserRightsAndRoles()
  }

  render() {
    const { accessLevel, current, user } = this.props
    return (
      <div className="ManageContent__Sidebar">
        <Link className={`ManageContent__SidebarLink ${current === "wells" ? " active" : ""}`} to={"/managecontent/wells"}>
          Wells
        </Link>
        <Link className={`ManageContent__SidebarLink ${current === "blocks" ? " active" : ""}`} to={"/managecontent/blocks"}>
          Blocks
        </Link>
        <Link className={`ManageContent__SidebarLink ${current === "licenses" ? " active" : ""}`} to={"/managecontent/licenses"}>
          Licenses
        </Link>
        <Link className={`ManageContent__SidebarLink ${current === "assets" ? " active" : ""}`} to={"/managecontent/assets"}>
          Assets
        </Link>
        <Link className={`ManageContent__SidebarLink ${current === "areas" ? " active" : ""}`} to={"/managecontent/areas"}>
          Areas
        </Link>
        {/* <Link className={`ManageContent__SidebarLink ${current === "countries" ? " active" : ""}`} to={"/managecontent/countries"}>
          Countries
        </Link> */}
        <Link className={`ManageContent__SidebarLink ${current === "rigs" ? " active" : ""}`} to={"/managecontent/rigs"}>
          Rigs
        </Link>
        <Link className={`ManageContent__SidebarLink ${current === "userlist" ? " active" : ""}`} to={"/managecontent/userlists"}>
          User lists
        </Link>
        <Link className={`ManageContent__SidebarLink ${current === "messages" ? " active" : ""}`} to={"/managecontent/messages"}>
          Messages
        </Link>
        <Link className={`ManageContent__SidebarLink ${current === "appSettings" ? " active" : ""}`} to={"/managecontent/appSettings"}>
          App settings
        </Link>
        {accessLevel >= 10 && user.isCognito && (
          <Link className={`ManageContent__SidebarLink ${current === "cognitousers" ? " active" : ""}`} to={"/managecontent/cognitousers"}>
            Users
          </Link>
        )}
      </div>
    )
  }
}

Sidebar.propTypes = {
  current: PropTypes.string,
  user: PropTypes.object,
  accessLevel: PropTypes.number,
  fetchUserRightsAndRoles: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}
function mapStateToProps(state) {
  return {
    accessLevel: state.user.accessLevel,
    user: state.application.user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
