import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import PanelMenuButton from "../buttons/PanelMenuButton"

class PanelMenu extends Component {
  shouldComponentUpdate = (nextProps) => {
    const {
      application: { panelOpen },
    } = nextProps

    if (!panelOpen) {
      return false
    }

    return true
  }

  createMenuButton = (val, key, activateOnlyCurrent) => (
    <PanelMenuButton key={key} name={val.name} index={key} clickable={this.props.clickable} activateOnlyCurrent={activateOnlyCurrent} />
  )

  render() {
    const {
      application: { panelOpen },
      classes,
      clickable,
      activateOnlyCurrent,
    } = this.props

    if (panelOpen.options.hideMenu) {
      return null
    }

    const style = {
      display: panelOpen && panelOpen.pages[panelOpen.index].options.hideMenu === true ? "none" : "flex",
      color: classes || "white",
      cursor: clickable ? "pointer" : "",
    }

    return (
      <div className={`Panel__Menu ${classes}`} style={style}>
        {panelOpen.pages.map((val, key) => {
          if (val.options.hideInMenu !== true) {
            return this.createMenuButton(val, key, activateOnlyCurrent)
          }

          return null
        })}
      </div>
    )
  }
}

PanelMenu.propTypes = {
  application: PropTypes.object,
  classes: PropTypes.string,
  clickable: PropTypes.bool,
  activateOnlyCurrent: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    application: state.application,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelMenu)
