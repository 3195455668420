import { REGISTER_NO_CHANGES, INPUT_SELECT_WELL, CANCEL, SUBMIT } from "../../../../panelConsts"

const config = {
  type: REGISTER_NO_CHANGES,
  index: 0,
  options: {
    heading: "Register no changes",
    subHeading: "New Well",
    largeModal: true,
  },
  pages: [
    {
      type: INPUT_SELECT_WELL,
      name: "Select wells",
      options: {
        selectMultiple: true,
        hideMenu: true,
        heading: "Selected wells that don’t require changes",
        hideInMenu: true,
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
