import React, { Component } from "react"
import PropTypes from "prop-types"
import { parseISO, format } from "date-fns"
import Circle from "../../../../assets/images/svg/icons/Circle.svg"
import CircleWithCheck from "../../../../assets/images/svg/icons/CircleWithCheck.svg"
import IconContainer from "../../../components/icons/IconContainer"

class ListItem extends Component {
  onEditClick = (item, event) => {
    const { editItem } = this.props
    event.preventDefault()
    event.stopPropagation()

    editItem(item)
  }

  renderTimeframe = (content) => {
    const { valid_from: validFrom, valid_to: validTo } = content
    const From = <strong>{format(parseISO(validFrom), "dd-MMM-yy")}</strong>
    const to = <strong>{format(parseISO(validTo), "dd-MMM-yy")}</strong>
    return (
      <div className={"ContentList__ListItem__Timeframe"}>
        <span>
          Active from {From} to {to}
        </span>
      </div>
    )
  }

  renderChip = (content) => {
    const { renderChip } = content
    return (
      <div className={"ContentList__ListItem__Chip"}>
        <span>{renderChip}</span>
      </div>
    )
  }

  render() {
    const { content, selected, onSelect, renderItemName, editItem } = this.props

    return (
      <a onClick={() => onSelect(content.id)} className="ContentList__ListItem">
        <span className="ContentList__ItemTitle">
          {selected ? IconContainer(CircleWithCheck, 16, 16) : IconContainer(Circle, 16, 16)}
          {renderItemName ? renderItemName(content) : content.name}
          {content.valid_from ? this.renderTimeframe(content) : null}
          {content.renderChip && this.renderChip(content)}
        </span>

        {editItem ? (
          <button onClick={(e) => this.onEditClick(content, e)} className="ContentList__EditBtn">
            Edit
          </button>
        ) : null}
      </a>
    )
  }
}

ListItem.propTypes = {
  content: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  renderItemName: PropTypes.func,
  editItem: PropTypes.func,
}

export default ListItem
