import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import WellSelection from "../../../components/form/WellSelection"
import { ActionCreators } from "../../../redux/actions"

class SelectWellForCampaignUpdate extends Component {
  constructor(props) {
    super(props)

    const { panelOpen } = props.application
    const isSelectMultiple = panelOpen.pages[panelOpen.index].options.selectMultiple === true

    this.state = {
      heading: panelOpen.pages[panelOpen.index].options.heading,
      selectMultiple: isSelectMultiple,
      selected: isSelectMultiple ? [] : null,
      filter: "",
    }
  }

  componentDidMount() {
    const { fetchWellData } = this.props
    fetchWellData()
  }

  addWell = () => {
    const { addExistingWellToRigBooking } = this.props
    const { selected } = this.state

    addExistingWellToRigBooking(selected.id)
  }

  selectWell = (well) => {
    this.setState({
      selected: well,
    })
  }

  isWellSelected = (well) => {
    const { selectMultiple } = this.state

    if (selectMultiple) {
      return this.state.selected && this.state.selected.includes(well)
    }

    return this.state.selected && this.state.selected.id === well.id
  }

  filterWells = (event) => {
    this.setState({ filter: event.target.value })
  }

  checkIfCompleted = (well) => {
    let returnValue = false

    if (well.request && well.request.well_status === "completed") {
      returnValue = true
    }

    return returnValue
  }

  checkIfAssigned = (well) => {
    const { wellsInCampaign } = this.props

    let returnValue = false

    wellsInCampaign.forEach((v) => {
      if (v.well_id === well.id) {
        returnValue = true
      }
    })

    return returnValue
  }

  render() {
    const { wells } = this.props
    const { heading, filter, selected } = this.state

    let wellsList = wells
      ? wells.filter((v) => {
          if (this.checkIfCompleted(v) || this.checkIfAssigned(v)) {
            return false
          }
          return true
        })
      : []

    let helpText = "Loading wells..."
    if (filter) {
      wellsList = wellsList.filter((v) => {
        const n = v.name ? v.name : v.plno
        let exist = n

        if (n) {
          exist = n.replace(/\//g, "===").toLowerCase().indexOf(filter.replace(/\//g, "===").toLowerCase()) > -1
        }

        return exist
      })
      helpText = wellsList.length ? helpText : `Couldn't find any wells when searching for "${filter}" 🤔`
    }

    return (
      <>
        <div className="well-list-scroll-wrapper">
          <div className="PanelContent__Container PanelForm">
            {wells ? (
              <div className="PanelForm__Header--with-search">
                <h3 className="PanelForm__Header PanelForm__Header--wellupdate PanelForm__Header--bordered">{heading}</h3>
                <input onChange={this.filterWells} placeholder="Filter" type="text" />
              </div>
            ) : null}
            {wellsList && wellsList.length > 0
              ? wellsList.map((well) =>
                  well.name !== "" ? (
                    <WellSelection
                      selected={() => this.isWellSelected(well)}
                      key={`WellSelection-${well.id}`}
                      well={well}
                      onSelect={this.selectWell}
                    />
                  ) : null
                )
              : helpText}
          </div>
        </div>
        <div className="well-info-wrapper">
          {selected && (
            <div>
              <h2 style={{ fontSize: "25px" }}>
                {selected.name ? "Legal well name" : "Common well name"}: {selected.name || selected.short_name}
              </h2>
              <a
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#fff",
                  color: "#1c2226",
                  display: "inline-block",
                  margin: "20px 0 0",
                  fontWeight: "bold",
                  textDecoration: "none",
                  borderRadius: "3px",
                }}
                href="#"
                onClick={this.addWell}
              >
                Add &quot;{selected.short_name || selected.name}&quot; to sequence
              </a>
              <p style={{ fontSize: "13px", color: "#E78680" }}>
                <br />
                <br />
                <strong>
                  NOTE!
                  <br />
                  If this well is present in the schedule, it will be removed when added to this sequence.
                </strong>
              </p>
            </div>
          )}
        </div>
      </>
    )
  }
}

SelectWellForCampaignUpdate.propTypes = {
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  wellsInCampaign: PropTypes.array,
  application: PropTypes.object,
  index: PropTypes.number,
  fetchWellData: PropTypes.func,
  wells: PropTypes.array,
  addExistingWellToRigBooking: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    wells: state.schedulerData.wells.data,
    application: state.application,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectWellForCampaignUpdate)
