import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PartnerIcon from "../../../assets/images/svg/icons/partner.svg"
import { ConditionalFieldBlock } from "../../components/common/ConditionalField"
import Chevron from "../../components/icons/Chevron"
import IconContainer from "../../components/icons/IconContainer"
import RigTypeIcon from "../../components/icons/RigTypeIcon"
import { DATE_FORMATE_NB } from "../../dateConsts"
import { getReadableRigType } from "../../lib/RigUtils"
import { ActionCreators } from "../../redux/actions"
import { END_OF_CONTRACT } from "../../registerConsts"

class RigPopover extends Component {
  constructor(props) {
    super(props)

    this.popoverRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.popoverRef && this.popoverRef.current && !this.popoverRef.current.contains(event.target)) {
      const { closePopOver } = this.props
      closePopOver()
    }
  }

  swapArrayElements = function (arr, indexA, indexB) {
    var temp = arr[indexA]
    arr[indexA] = arr[indexB]
    arr[indexB] = temp
  }

  updateRigPriority = (direction) => {
    const { selectedRig, rigData, setRigPriority, editRig } = this.props

    const originalArray = [...rigData.rigs].sort((a, b) => a.priority > b.priority).map((rig) => ({ id: rig.id, name: rig.rigData.name }))

    if (direction === "up") {
      // swap rig with rig above
      const rigIndex = originalArray.findIndex((rig) => rig.id === selectedRig.rig_instance_id)
      this.swapArrayElements(originalArray, rigIndex, rigIndex - 1)
      setRigPriority(originalArray.map((rig, k) => ({ rigInstanceId: rig.id, priority: k * 10 })))
    }

    if (direction === "down") {
      const rigIndex = originalArray.findIndex((rig) => rig.id === selectedRig.rig_instance_id)
      this.swapArrayElements(originalArray, rigIndex, rigIndex + 1)
      setRigPriority(originalArray.map((rig, k) => ({ rigInstanceId: rig.id, priority: k * 10 })))
    }
  }

  render() {
    const {
      rigData,
      accessLevel,
      PVersion,
      selectedRig,
      showPopOver,
      closePopOver,
      editRig,
      domain: {
        data: { units },
      },
    } = this.props
    const { readonly } = rigData

    let contractPeriodString = ""
    if (selectedRig.end_of_contract && selectedRig.start_of_contract) {
      contractPeriodString = `Contract from ${format(parseISO(selectedRig.start_of_contract), DATE_FORMATE_NB)} to ${format(
        parseISO(selectedRig[END_OF_CONTRACT]),
        DATE_FORMATE_NB
      )}`
    } else if (selectedRig.end_of_contract) {
      contractPeriodString = `Contract ends ${format(parseISO(selectedRig[END_OF_CONTRACT]), DATE_FORMATE_NB)}`
    }

    let contractOptionString = ""
    if (selectedRig.option_from && selectedRig.option_to) {
      contractOptionString = `Option from ${format(parseISO(selectedRig.option_from), DATE_FORMATE_NB)} to ${format(
        parseISO(selectedRig.option_to),
        DATE_FORMATE_NB
      )}`
    }

    return (
      <div
        key={`rig-popover-${selectedRig.id}`}
        ref={this.popoverRef}
        className={`popover Rig__Popover ${showPopOver ? "Rig__Popover--active" : ""}`}
      >
        <div className="popover__wrapper">
          <h2 className="popover__heading">{selectedRig.name}</h2>
          <h3 className="popover__subheading">
            <span className="popover__subheading-icon"></span>{" "}
            <span className="popover__subheading-content">{selectedRig.is_confidential ? "Confidential" : ""}</span>
          </h3>
          <p className="popover__rigtype">
            <span className="popover__rigtype-icon">
              <RigTypeIcon rigtype={selectedRig.type} width={12} height={12} />
            </span>
            <span className="popover__rigtype-content">{getReadableRigType(selectedRig.type)}</span>
            <span className="popover__rigtype-separator"></span>
            <span className="popover__rigtype-pressure">{selectedRig.pressure / 1000}K</span>
          </p>
          {selectedRig.is_partner_rig && (
            <p className="popover__rigtype">
              <span className="popover__rigtype-icon">{IconContainer(PartnerIcon, 12, 12)}</span>
              <span className="popover__rigtype-content">Partner rig</span>
            </p>
          )}
          <p className="popover__enddate">
            {contractPeriodString}
            <br />
            {contractOptionString}
          </p>
          <div className="popover__specs">
            <h4 className="popover__partheading">Specifications</h4>
            <ul className="popover__specs-list">
              <li className="popover__specs-listitem">
                <span>
                  {selectedRig.move_speed} {units === "imperial" ? "mph" : "km/h"}
                </span>
              </li>
              <ConditionalFieldBlock scope="rig" fields={["generation"]}>
                <li className="popover__specs-listitem">
                  <span>{selectedRig.generation}</span>
                </li>
              </ConditionalFieldBlock>
              <ConditionalFieldBlock scope="rig" fields={["ocean_depth_from", "ocean_depth_to"]}>
                {(selectedRig.ocean_depth_from || selectedRig.ocean_depth_to) && (
                  <li className="popover__specs-listitem">
                    <span>
                      {`${selectedRig.ocean_depth_from || ""}${selectedRig.ocean_depth_to ? `-${selectedRig.ocean_depth_to}` : ""}`}
                      {units === "imperial" ? "ft" : "m"} water depth
                    </span>
                  </li>
                )}
              </ConditionalFieldBlock>
            </ul>
          </div>
          <div className="popover__summary">
            <h4 className="popover__partheading">Summary</h4>
            <p className="popover__summary-part">
              Drilling: <span>{selectedRig.drillTimeTotal[PVersion.value]} days</span>
            </p>
            <p className="popover__summary-part">
              Rig move: <span>{selectedRig.rigMoveTotal} days</span>
            </p>
            <p className="popover__summary-part">
              Whitespace: <span>{selectedRig.freeTimeTotal[PVersion.value]} days</span>
            </p>
          </div>
          {selectedRig.comment ? (
            <div className="popover__summary">
              <h4 className="popover__partheading">Comment</h4>
              <p className="popover__summary-part">{selectedRig.comment}</p>
            </div>
          ) : null}
          {accessLevel > 3 && !readonly && (
            <div className="popover__actions">
              <span className="popover__sortingtitle">Move order of rig:</span>
              <div className="popover__sortingbtns">
                <a onClick={() => this.updateRigPriority("up")} className="popover__sortingup" href="#">
                  {Chevron()}
                </a>
                <a onClick={() => this.updateRigPriority("down")} className="popover__sortingdown" href="#">
                  {Chevron()}
                </a>
              </div>
            </div>
          )}
          <div className="popover__buttons">
            <button
              onClick={() => {
                closePopOver()
              }}
              className={`popover__button${accessLevel < 4 ? " popover__button--primary" : ""}`}
            >
              Close
            </button>
            {accessLevel > 3 && (
              <button
                onClick={() => editRig(selectedRig)}
                className={`popover__button popover__button--primary ${readonly ? "readonly" : ""}`}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

RigPopover.propTypes = {
  rigData: PropTypes.object,
  PVersion: PropTypes.object,
  accessLevel: PropTypes.number,
  closePopOver: PropTypes.func,
  showPopOver: PropTypes.bool,
  editRig: PropTypes.func,
  selectedRig: PropTypes.object,
  domain: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    rigData: state.schedulerData.schedulerData.data,
    PVersion: state.calendar.PVersion,
    accessLevel: state.user.accessLevel,
    domain: state.application.domain,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RigPopover)
