import React, { Component } from "react"
import PropTypes from "prop-types"
import { cloneDeep } from "lodash"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class EditAsset extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editItem: null,
    }
  }

  componentDidMount() {
    this.setState({
      editItem: this.props.editItem,
    })
  }

  handleInputChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[event.target.name] = event.target.value
    this.setState({ editItem })
  }

  handleRadioSelect = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    const isTrueSet = event.target.value === "true"
    editItem.visible = isTrueSet
    this.setState({ editItem })
  }

  handleSelectChange = (value, key) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[key] = value ? value.id : null
    this.setState({ editItem })
  }

  closeModal = () => {
    const { clearSingleItemEdit } = this.props
    clearSingleItemEdit()
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleEditFormSubmit = (event) => {
    const { handleEditFormSubmit } = this.props
    const { editItem } = this.state

    event.preventDefault()
    event.stopPropagation()

    handleEditFormSubmit(editItem)
  }

  render() {
    const {
      editItem: { name },
    } = this.props
    const { editItem } = this.state

    if (!editItem) {
      return null
    }

    return (
      <form className="ReactModal__Wrapper" onSubmit={(event) => this.handleEditFormSubmit(event)}>
        <div className="ReactModal__Form ModalForm">
          <h2 className="ReactModal__FormHeader">
            Edit <strong>{name}</strong>
          </h2>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Name</label>
            <input
              type={"text"}
              onKeyDown={this.handleEnterPress}
              value={editItem.name || ""}
              name={"name"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Name"
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Visible to all</label>

            <div className="ModalForm__RadioWrapper">
              <label>
                <input
                  type="radio"
                  checked={editItem.visible}
                  selected={editItem.visible}
                  name={"name"}
                  onChange={this.handleRadioSelect}
                  className="ModalForm__Input"
                  value={true}
                />
                <span>Yes</span>
              </label>
              <label>
                <input
                  type="radio"
                  checked={!editItem.visible}
                  selected={!editItem.visible}
                  name={"name"}
                  onChange={this.handleRadioSelect}
                  className="ModalForm__Input"
                  value={false}
                />
                <span>No</span>
              </label>
            </div>
          </div>
        </div>
        <div className="ReactModal__Buttons">
          <button type="button" onClick={this.closeModal} className="ReactModal__Button">
            Cancel
          </button>
          <button type="submit" className={"ReactModal__Button ReactModal__Button--primary"}>
            {"Save"}
          </button>
        </div>
      </form>
    )
  }
}

EditAsset.propTypes = {
  editItem: PropTypes.object,
  clearSingleItemEdit: PropTypes.func,
  handleEditFormSubmit: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editItem: state.managecontent.editItem,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAsset)
