import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

import ContentList from "../Bits/ContentList"

class EditMessages extends Component {
  componentDidMount() {
    const { getMessagesList } = this.props
    getMessagesList()
  }

  handleDeleteFormSubmit = (selectedItems) => {
    const { deleteItems } = this.props
    deleteItems("flashmessage", selectedItems)
  }

  editItem = (item) => {
    const { editSingleItem } = this.props
    editSingleItem("message", item)
  }

  addItem = () => {
    const { editSingleItem } = this.props
    editSingleItem("message", { name: "" })
  }

  renderItemName = (item) => item.name || item.plno || item.short_name

  render() {
    const { messages } = this.props

    return (
      <div className="ManageContent__Content">
        <span>messages</span>
        <ContentList
          classNames={"HALF"}
          addItem={this.addItem}
          editItem={this.editItem}
          deleteItemsFunc={this.handleDeleteFormSubmit}
          renderItemName={this.renderItemName}
          header={"Scheduled"}
          items={messages.valid}
        />
        <ContentList
          classNames={"HALF"}
          hideAddButton={true}
          addItem={this.addItem}
          editItem={this.editItem}
          deleteItemsFunc={this.handleDeleteFormSubmit}
          renderItemName={this.renderItemName}
          header={"Old"}
          items={messages.unvalid}
        />
      </div>
    )
  }
}

EditMessages.propTypes = {
  editSingleItem: PropTypes.func,
  getMessagesList: PropTypes.func,
  deleteItems: PropTypes.func,
  messages: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    messages: state.managecontent.messages,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMessages)
