import { EDIT_RIG, INPUT_RIG_INFO, INPUT_RIG_SPECS, CANCEL, NEXT, SUBMIT } from "../../../../panelConsts"

const config = {
  type: EDIT_RIG,
  index: 0,
  options: {
    heading: "name",
    dynamicHeader: true,
    subHeading: "Edit rig",
    largeModal: true,
  },
  pages: [
    {
      type: INPUT_RIG_INFO,
      name: "Rig Info",
      options: {
        buttons: {
          left: CANCEL,
          right: NEXT,
        },
      },
    },
    {
      type: INPUT_RIG_SPECS,
      name: "Specification",
      options: {
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
