import React, { PureComponent } from "react"
import PropTypes from "prop-types"

class SelectRigsRemovePVersion extends PureComponent {
  render() {
    const { data } = this.props

    if (!data.rigInstanceId) {
      return null
    }

    return (
      <div className="PanelContent__Container PanelForm">
        <div className="PanelForm__InputPart PanelForm__InputPart--checkbox">
          <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
            <label style={{ textAlign: "center" }} className="radio-input__mainlabel">
              {"Can't remove rig with wells. Remove wells first."}
            </label>
          </div>
        </div>
      </div>
    )
  }
}

SelectRigsRemovePVersion.propTypes = {
  data: PropTypes.object,
}

export default SelectRigsRemovePVersion
