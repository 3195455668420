import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import NewRequestMenu from "../../components/menus/NewRequestMenu"
import RequestPreviewList from "../../components/Scheduler/RequestPreviewList"

class ToolBar extends Component {
  onNewReqesetHandler = (type) => {
    const { openPanelWithType } = this.props

    openPanelWithType(type)
  }

  render() {
    const { requests, toolbarOpen, accessLevel } = this.props

    if (Object.keys(requests).length === 0) {
      return null
    }

    return (
      <div className={"ToolBar"}>
        {toolbarOpen === true ? (
          <NewRequestMenu accessLevel={accessLevel} unprocessed={requests.unprocessed} processed={requests.processed} />
        ) : (
          <>
            <RequestPreviewList key="RequestPreviewList" unprocessed={requests.unprocessed} />
          </>
        )}
      </div>
    )
  }
}

ToolBar.propTypes = {
  PVersion: PropTypes.object,
  text: PropTypes.string,
  requests: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  toggleToolbarOpenState: PropTypes.func,
  openPanelWithType: PropTypes.func,
  toolbarOpen: PropTypes.bool,
  accessLevel: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    requests: state.requestData.requests,
    toolbarOpen: state.application.toolbarOpen,
    PVersion: state.calendar.PVersion,
    accessLevel: state.user.accessLevel,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar)
