import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"

import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"
import PanelEstimate from "../../../components/panel/PanelEstimate"

import { P10_ESTIMATE, P50_ESTIMATE, P90_ESTIMATE } from "../../../registerConsts"

const validationKeys = [P10_ESTIMATE, P50_ESTIMATE, P90_ESTIMATE]

class InputTimeEstimate extends Component {
  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[elem.target.name] = elem.target.value
    onUpdate(obj)
  }

  isNumeric = (n) => Number.isFinite(n) && +n === n /* ignore */

  onWellFieldUpdate = (elem, id) => {
    const { onUpdate, data } = this.props

    const obj = {}

    const wells = data.wells.slice()
    const well = cloneDeep(wells[id])
    const parsed = parseInt(elem.target.value, 0)
    const value = this.isNumeric(parsed) ? parsed : ""

    well[elem.target.name] = value
    wells[id] = well
    obj.wells = wells

    onUpdate(obj)
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props

    if (data !== prevProps.data) {
      const validated = this.checkValidation(data)
      onValidated(index, validated)
    }
  }

  componentDidMount() {
    const { index, onValidated, data } = this.props
    const validated = this.checkValidation(data)
    onValidated(index, validated)
  }

  checkValidation = (data) => {
    let validated = true
    let keys = {}

    validated = data.wells.map((val) => {
      keys = Object.keys(val)
      return validationKeys.every((key) => keys.includes(key))
    })

    return validated.every((value) => value === true)
  }

  render() {
    const {
      data,
      data: { wells },
    } = this.props

    const customInputStyle = { width: "80%" }
    const customIndicatorStyle = { left: "0" }

    return (
      <div className="PanelContent__Container PanelForm">
        {wells.map((val, key) => (
          <Fragment key={key}>
            <div style={{ marginBottom: "10px" }} className="PanelForm__Header">{`Well ${key + 1}`}</div>
            <div key={key} className="PanelForm__Estimates">
              <PanelEstimate
                id={key}
                label={"P10 estimate"}
                customInputStyle={customInputStyle}
                name={P10_ESTIMATE}
                data={data}
                onFieldUpdate={(elem, id) => this.onWellFieldUpdate(elem, id)}
              />
              <PanelEstimate
                id={key}
                label={"P50 estimate"}
                customInputStyle={customInputStyle}
                name={P50_ESTIMATE}
                data={data}
                onFieldUpdate={(elem, id) => this.onWellFieldUpdate(elem, id)}
              />
              <PanelEstimate
                id={key}
                label={"P90 estimate"}
                customInputStyle={customInputStyle}
                name={P90_ESTIMATE}
                data={data}
                onFieldUpdate={(elem, id) => this.onWellFieldUpdate(elem, id)}
              />
            </div>
          </Fragment>
        ))}

        <div className="PanelForm__InputPart PanelForm__InputPart--radio">
          <label className="PanelForm__inputlabel">
            <input
              value="p1_estimator"
              name="estimator"
              type="radio"
              checked={data.estimator === "p1_estimator"}
              onChange={this.onFieldUpdate}
            />
            <span className="check-indicator" style={customIndicatorStyle}></span>
            <span className="PanelForm__inputlabel-text">I have used P1 estimator</span>
          </label>
          <label className="PanelForm__inputlabel">
            <input
              value="custom_estimator"
              name="estimator"
              type="radio"
              checked={data.estimator === "custom_estimator"}
              onChange={this.onFieldUpdate}
            />
            <span className="check-indicator" style={customIndicatorStyle}></span>
            <span className="PanelForm__inputlabel-text">I have used a different estimator</span>
          </label>
        </div>

        <div className={`PanelForm__InputPart ${data.estimator === "custom_estimator" ? "" : "PanelForm__InputPart--hidden"}`}>
          <input
            onChange={this.onFieldUpdate}
            value={data.custom_estimator_name ? data.custom_estimator_name : ""}
            className={`PanelForm__textinput ${data.custom_estimator_name ? "active" : ""}`}
            name="custom_estimator_name"
            type="name"
            placeholder="Input the name of the estimator"
          />
        </div>
      </div>
    )
  }
}

InputTimeEstimate.propTypes = {
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  onValidated: PropTypes.func,
  data: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InputTimeEstimate)
