import { cloneDeep } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import Modal from "react-modal"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Loading from "../components/icons/Loading"
import { ActionCreators } from "../redux/actions"
import { MODAL_TYPE_MASTER_DUPLICATE } from "../redux/types"

class DuplicateMaster extends Component {
  constructor(props) {
    super(props)

    const activeDuplicateDraft = cloneDeep(props.data.data.activeDuplicateDraft)
    activeDuplicateDraft.name = `copy of ${activeDuplicateDraft.name}`
    this.state = {
      activeContextMenu: "",
      activeDuplicateDraft,
      draftDuplicated: false,
      isDuplicating: false,
    }
  }

  closeModal = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const { closeModal } = this.props
    closeModal(MODAL_TYPE_MASTER_DUPLICATE)
  }

  handleDuplicateFormSubmit = (event) => {
    const { createNewDraft } = this.props

    event.preventDefault()
    event.stopPropagation()
    if (!this.state.isSavingRename) {
      this.setState({
        isDuplicating: true,
      })

      createNewDraft(this.state.activeDuplicateDraft)
    }
  }

  handleDuplicateEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleDuplicateFormSubmit(event)
    }
  }

  handleDuplicateInputChange = (event) => {
    const activeDuplicateDraft = cloneDeep(this.state.activeDuplicateDraft)
    activeDuplicateDraft.name = event.target.value
    this.setState({ activeDuplicateDraft })
  }

  render() {
    const {
      data: { data },
    } = this.props

    return (
      <Modal
        key={`duplicate-modal-${data.id}`}
        isOpen={true}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        className={"ReactModal__Content"}
        overlayClassName={"ReactModal__Overlay"}
        contentLabel="Archive"
      >
        <form className="ReactModal__Wrapper" onSubmit={this.handleDuplicateFormSubmit}>
          <div className="ReactModal__Form">
            <h2 className="ReactModal__FormHeader">
              {this.state.draftDuplicated ? "Duplicated!" : `Duplicating "${data.name}". Enter draft name:`}
            </h2>
            <input
              onKeyDown={this.handleDuplicateEnterPress}
              onChange={this.handleDuplicateInputChange}
              value={typeof this.state.activeDuplicateDraft.name !== "undefined" ? this.state.activeDuplicateDraft.name : ""}
              autoFocus
              className="ReactModal__BigInput"
              placeholder="Enter draft name"
            />
            <label>
              <input type="checkbox" />
              <span>Make draft is independent</span>
            </label>
            {this.state.isDuplicating ? (
              Loading({ height: "20px", margin: "5px 0 0 0" }, "loading-draft-duplicate")
            ) : (
              <div style={{ height: "25px" }}></div>
            )}
          </div>
          <div className="ReactModal__Buttons">
            <button type="button" onClick={this.closeModal} className="ReactModal__Button">
              Cancel
            </button>
            <button
              type="submit"
              className={`ReactModal__Button ReactModal__Button--primary ${this.state.isDuplicating ? "ReactModal__Button--inactive" : ""}`}
            >
              {"Duplicate"}
            </button>
          </div>
        </form>
      </Modal>
    )
  }
}

DuplicateMaster.propTypes = {
  createNewDraft: PropTypes.func,
  closeModal: PropTypes.func,
  data: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateMaster)
