/* eslint-disable import/prefer-default-export */
import { from, of } from "rxjs"
import { ofType } from "redux-observable"
import { switchMap, mergeMap, map } from "rxjs/operators"

import * as types from "../types"
import Api from "../../services/ApiService"
import {
  saveBlocks,
  saveUserlists,
  saveWells,
  saveLicenses,
  saveAreas,
  saveAssets,
  saveRigs,
  getWellList,
  getRigList,
  getBlockList,
  getAssetList,
  getLicenseList,
  getUserlistList,
  clearDeleteList,
  clearSingleItemEdit,
  saveMessagesList,
  getMessagesList,
  saveMessagesListForUser,
  getMessagesListForUser,
  saveCognitoUsers,
  deletedCognitoUser,
  saveCognitoGroups,
  updatedCognitoUser,
  cognitoUserError,
  getCognitoUsers,
  saveAppSettings,
  getAppSettings,
  getAreaList,
  saveCountries,
} from "../actions/managecontent"

export const getBlockListObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_BLOCK_LIST),
    switchMap(() => from(Api.getDataFromApi("block/list"))),
    map((result) => saveBlocks(result.data))
  )

export const getRigListObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_RIG_LIST),
    switchMap(() => from(Api.getDataFromApi("rig/getList"))),
    map((result) => saveRigs(result.data))
  )
export const getAppSettingsObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_APP_SETTINGS),
    switchMap(() => from(Api.getDataFromApi("appSettings/raw"))),
    map((result) => saveAppSettings(result.data))
  )

export const getUserlistListObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_USERLIST_LIST),
    switchMap(() => from(Api.getDataFromApi("sharing/getLists"))),
    map((result) => saveUserlists(result.data))
  )

export const getWellListObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_WELL_LIST),
    switchMap(() => from(Api.getDataFromApi("well/list"))),
    map((result) => saveWells(result.data))
  )

export const getLicenseListObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_LICENSE_LIST),
    switchMap(() => from(Api.getDataFromApi("license/list"))),
    map((result) => saveLicenses(result.data))
  )

export const getAssetListObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_ASSET_LIST),
    switchMap(() => from(Api.getDataFromApi("asset/list?onlyVisible=0"))),
    map((result) => saveAssets(result.data))
  )

export const getAreaListObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_AREA_LIST),
    switchMap(() => from(Api.getDataFromApi("area/list"))),
    map((result) => saveAreas(result.data))
  )

export const getCountryListObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_COUNTRY_LIST),
    switchMap(() => from(Api.getDataFromApi("country/list"))),
    map((result) => saveCountries(result.data))
  )

export const deleteItemsObserver = (action$, state$) =>
  action$.pipe(
    ofType(types.DELETE_ITEM_LIST),
    switchMap(() =>
      from(Api.postDataToApi(`${state$.value.managecontent.deleteItemType}/archive`, state$.value.managecontent.deleteItems))
    ),
    map(() => {
      clearDeleteList()
    }),
    map(() => {
      switch (state$.value.managecontent.deleteItemType) {
        case "well":
          return getWellList()
        case "block":
          return getBlockList()
        case "asset":
          return getAssetList()
        case "license":
          return getLicenseList()
        case "flashmessage":
          return getMessagesList()
        case "rig":
          return getRigList()
        case "appSettings":
          return getAppSettings()
        case "area":
          return getAreaList()
        default:
      }

      return () => {}
    })
  )

export const deleteUserListsObserver = (action$, state$) =>
  action$.pipe(
    ofType(types.DELETE_USER_LISTS),
    switchMap(() => from(Api.postDataToApi("sharing/archiveLists", state$.value.managecontent.deleteItems))),
    mergeMap(() => [clearDeleteList(), getUserlistList()])
  )

export const saveEditItemObserver = (action$, state$) =>
  action$.pipe(
    ofType(types.SAVE_SINGLE_ITEM_EDIT),
    switchMap(() => from(Api.postDataToApi(`${state$.value.managecontent.editItemType}`, state$.value.managecontent.updatedEditItem))),
    mergeMap(() => {
      switch (state$.value.managecontent.editItemType) {
        case "well":
          return [clearSingleItemEdit(), getWellList()]
        case "block":
          return [clearSingleItemEdit(), getBlockList()]
        case "asset":
          return [clearSingleItemEdit(), getAssetList()]
        case "appSettings":
          return [clearSingleItemEdit(), getAppSettings()]
        case "area":
          return [clearSingleItemEdit(), getAreaList()]
        default:
          return [clearSingleItemEdit(), getLicenseList()]
      }
    })
  )

export const saveUserslistObserver = (action$, state$) =>
  action$.pipe(
    ofType(types.SAVE_USERLIST_LIST_EDIT),
    switchMap(() => from(Api.postDataToApi("sharing/upsertList", state$.value.managecontent.updatedUserslistItem))),
    mergeMap(() => [clearSingleItemEdit(), getUserlistList()])
  )

// MESSAGES
export const getMessagesListObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_MESSAGE_LIST),
    switchMap(() => from(Api.getDataFromApi("flashmessage/list"))),
    map((result) => saveMessagesList(result.data))
  )

export const getMessagesListForUserObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_MESSAGE_LIST_FOR_USER),
    switchMap(() => from(Api.getDataFromApi("flashmessage/getActiveForCurrentUser"))),
    map((result) => saveMessagesListForUser(result.data))
  )

export const saveMessageObserver = (action$) =>
  action$.pipe(
    ofType(types.SAVE_MESSAGE),
    switchMap((action) => from(Api.postDataToApi("flashmessage", action.message))),
    map(() => getMessagesList())
  )

export const setMessageReadObserver = (action$) =>
  action$.pipe(
    ofType(types.SET_MESSAGE_READ),
    switchMap((action) => from(Api.postDataToApi(`flashmessage/markAsRead/${action.id}`))),
    map(() => getMessagesListForUser())
  )

// Cognito user management
export const getCognitoGroupList$ = (action$) =>
  action$.pipe(
    ofType(types.CU_GET_GROUP_LIST),
    switchMap(() => from(Api.getDataFromApi("cognito/groups"))),
    map((result) => saveCognitoGroups(result.data))
  )
export const getCognitoUserList$ = (action$) =>
  action$.pipe(
    ofType(types.CU_GET_LIST),
    switchMap(() => from(Api.getDataFromApi("cognito"))),
    map((result) => saveCognitoUsers(result.data))
  )
export const deleteCognitoUser$ = (action$) =>
  action$.pipe(
    ofType(types.CU_DELETE_SINGLE_ITEM),
    switchMap(async (action) => {
      await Api.deleteDataFromApi(`cognito/${action.payload}`)
      return action.payload
    }),
    map((result) => deletedCognitoUser(result))
  )
export const updateCognitoUser$ = (action$) =>
  action$.pipe(
    ofType(types.CU_UPDATE_ITEM),
    switchMap((action) => {
      if (action.payload.id) return from(Api.putDataToApi(`cognito/${action.payload.id}`, action.payload))
      return from(Api.postDataToApi("cognito", action.payload))
    }),
    mergeMap((result) => {
      if (result instanceof Error) {
        return cognitoUserError(result)
      }
      return of(updatedCognitoUser(result.data), getCognitoUsers(), clearSingleItemEdit())
    })
  )
