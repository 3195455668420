import React, { Component } from "react"
import PropTypes from "prop-types"
import { cloneDeep, find } from "lodash"

import AsyncSelect from "react-select/async"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { ActionCreators } from "../../../redux/actions"

class EditAsset extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editItem: null,
    }
  }

  componentDidMount() {
    const { getCountryList } = this.props

    getCountryList()

    this.setState({
      editItem: this.props.editItem,
    })
  }

  handleInputChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[event.target.name] = event.target.value
    this.setState({ editItem })
  }

  handleSelectChange = (value) => {
    const editItem = cloneDeep(this.state.editItem)

    if (!value) {
      delete editItem.country
      editItem["country_id"] = null
      this.setState({ editItem })
      return
    }

    const key = value.id ? "country_id" : "country"
    editItem[key] = value.id || value
    this.setState({ editItem })
  }

  closeModal = () => {
    const { clearSingleItemEdit } = this.props
    clearSingleItemEdit()
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleEditFormSubmit = (event) => {
    const { handleEditFormSubmit } = this.props
    const { editItem } = this.state

    event.preventDefault()
    event.stopPropagation()

    handleEditFormSubmit(editItem)
  }

  loadCountries = async (inputValue, callback) => {
    const searchReq = await fetch(`https://restcountries.com/v3.1/name/${inputValue}`)
    const searchRes = await searchReq.json()
    callback(searchRes.map((e) => ({ name: e.name.common, code: e.cca2 })))
  }

  render() {
    const {
      countries,
      editItem: { name },
    } = this.props
    const { editItem } = this.state

    if (!editItem) {
      return null
    }

    return (
      <form className="ReactModal__Wrapper" onSubmit={(event) => this.handleEditFormSubmit(event)}>
        <div className="ReactModal__Form ModalForm">
          <h2 className="ReactModal__FormHeader">
            Edit <strong>{name}</strong>
          </h2>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Name</label>
            <input
              type={"text"}
              onKeyDown={this.handleEnterPress}
              value={editItem.name || ""}
              name={"name"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Name"
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Country</label>
            <AsyncSelect
              defaultOptions={countries}
              closeMenuOnSelect={true}
              isClearable={true}
              placeholder="Search or select country"
              className="White__Dropdown"
              getOptionLabel={(option) => option.name}
              value={editItem.country_id ? find(countries, { id: editItem.country_id }) : editItem.country || null}
              classNamePrefix="Select__Dropdown__Elm"
              loadOptions={this.loadCountries}
              onChange={(value) => this.handleSelectChange(value)}
            />
            <span className="ModalForm__AdditionalInfo">NOTE! If the country is not in the list, you can search to add it.</span>
          </div>
        </div>
        <div className="ReactModal__Buttons">
          <button type="button" onClick={this.closeModal} className="ReactModal__Button">
            Cancel
          </button>
          <button type="submit" className={"ReactModal__Button ReactModal__Button--primary"}>
            {"Save"}
          </button>
        </div>
      </form>
    )
  }
}

EditAsset.propTypes = {
  editItem: PropTypes.object,
  clearSingleItemEdit: PropTypes.func,
  handleEditFormSubmit: PropTypes.func,
  countries: PropTypes.array,
  getCountryList: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editItem: state.managecontent.editItem,
    countries: state.managecontent.countries,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAsset)
