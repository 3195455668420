import React, { Component } from "react"
import PropTypes from "prop-types"
import Month from "./Month"

class Year extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }

    this.ref = React.createRef()
  }

  componentDidMount = () => {
    const { scrollPosition } = this.props
    this.checkVisibility(scrollPosition.value)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { scrollPosition } = this.props

    if (scrollPosition !== prevProps.scrollPosition) {
      this.checkVisibility(scrollPosition.value)
    }

    if (prevState.visible !== this.state.visible) {
      const {
        setCalendarsVisibleYears,
        data: { year },
      } = this.props

      setCalendarsVisibleYears(this.state.visible, year)
    }
  }

  checkVisibility = (scrollPosition) => {
    const nowVisible = this.isInViewport(scrollPosition)

    if (nowVisible !== this.state.visible) {
      this.setState({
        visible: nowVisible,
      })
    }
  }

  isInViewport = () => {
    if (!this.ref.current) return false
    const { left, width } = this.ref.current.getBoundingClientRect()

    const margin = 230
    const inStartView = left + width - margin
    const trueWidth = window.innerWidth

    return inStartView >= 0 && left <= trueWidth
  }

  render() {
    const {
      data: { year, months, firstDayOfFirstMonth },
      currentYear,
      currentMonth,
      zoomLevel,
      dayWidth,
    } = this.props

    return (
      <div className="Year" ref={this.ref}>
        <div className="Year--Title">{year}</div>
        <div className="Year--Months">
          {months.map((i, k) => (
            <Month
              key={`${year}-${k}`}
              currentYear={currentYear}
              currentMonth={currentMonth}
              firstDayOfFirstMonth={firstDayOfFirstMonth}
              isFirstMonth={k === 0}
              year={year}
              zoomLevel={zoomLevel}
              dayWidth={dayWidth}
              data={i}
            >
              {i.short}
            </Month>
          ))}
        </div>
      </div>
    )
  }
}

Year.propTypes = {
  data: PropTypes.object,
  para: PropTypes.object,
  dayWidth: PropTypes.number,
  visibilityChange: PropTypes.func,
  scrollPosition: PropTypes.object,
  currentMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentYear: PropTypes.number,
  zoomLevel: PropTypes.number,
  setCalendarsVisibleYears: PropTypes.func,
  setCalendarsVisibleMonth: PropTypes.func,
}

export default Year
