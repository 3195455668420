import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import addDays from "date-fns/addDays"
import DayPickerElement from "../../../components/form/DayPickerElement"
import Request from "../../../components/Toolbar/Request"
import ExistingWellPreview from "../../../components/Toolbar/ExistingWellPreview"
import { DATE_FORMATE_NB } from "../../../dateConsts"

import { ActionCreators } from "../../../redux/actions"

import { PREVIEW_START_DATE, PREVIEW_END_DATE } from "../../../registerConsts"
import VALUE_HIDDEN from "../../../appSettingConsts"

const validationKeys = [PREVIEW_START_DATE, PREVIEW_END_DATE]

class PreviewRequest extends Component {
  constructor() {
    super()

    this.state = {
      previewSelectionSuggestion: null,
    }
  }

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}
    const value = elem.target.type === "checkbox" ? elem.target.checked : elem.target.value
    obj[elem.target.name] = value

    onUpdate(obj)
  }

  handleStartDayChange = (day) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[PREVIEW_START_DATE] = day

    onUpdate(obj)
  }

  handleEndDayChange = (day) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[PREVIEW_END_DATE] = day

    onUpdate(obj)
  }

  updateOnPreviewSelection(startDate, endDate) {
    const { onUpdate } = this.props

    const obj = {}
    obj[PREVIEW_START_DATE] = startDate
    obj[PREVIEW_END_DATE] = endDate

    onUpdate(obj)
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data, previewSelectionSuggestion } = this.props

    if (data !== prevProps.data) {
      const validated = this.checkValidation(data)
      onValidated(index, validated)
    }

    if (previewSelectionSuggestion !== prevProps.previewSelectionSuggestion) {
      const startDate = previewSelectionSuggestion ? new Date(previewSelectionSuggestion.start_date) : data[PREVIEW_START_DATE] || ""

      const endDate = previewSelectionSuggestion
        ? // TODO: do this in the reducer creating the oject
          addDays(startDate, previewSelectionSuggestion.estimate)
        : data[PREVIEW_END_DATE] || ""

      this.updateOnPreviewSelection(startDate, endDate)
    }
  }

  componentDidMount() {
    const { index, onValidated, data } = this.props
    const validated = this.checkValidation(data)
    onValidated(index, validated)
  }

  checkValidation = (data) => {
    let validated = true
    const keys = Object.keys(data)

    validated = validationKeys.every((key) => keys.includes(key))

    return validated
  }

  render() {
    const { data, requestToEdit, PVersion, previewSelectionSuggestion, domain } = this.props

    const startDate = data[PREVIEW_START_DATE] || ""

    const p10 = domain.data && domain.data.scenarios && domain.data.scenarios.p10 ? domain.data.scenarios.p10 : "P10"
    const p90 = domain.data && domain.data.scenarios && domain.data.scenarios.p90 ? domain.data.scenarios.p90 : "P90"

    return (
      <div className="PanelContent__Container PanelForm">
        {previewSelectionSuggestion && previewSelectionSuggestion.existingRigBooking ? (
          <>
            <h4 className="PanelForm__RequestHeader">In schedule</h4>
            <ExistingWellPreview key={"preview-request-2"} data={previewSelectionSuggestion.existingRigBooking} />
          </>
        ) : null}
        {previewSelectionSuggestion && previewSelectionSuggestion.existingRigBooking ? (
          <h4 className="PanelForm__RequestHeader">Change request</h4>
        ) : null}
        {requestToEdit ? (
          <Request showStatus={false} previewmode={true} status={"unprocessed"} key={"preview-request"} request={requestToEdit} />
        ) : null}
        <DayPickerElement
          label="Selected start date"
          format={DATE_FORMATE_NB}
          onChange={this.handleStartDayChange}
          styles={{ marginBottom: 5 }}
          value={startDate}
        />

        {PVersion.name === "P50" ? (
          <div className="PanelForm__InputPart PanelForm__InputPart--checkbox">
            <label className={`PanelForm__inputlabel ${data.p10 ? "selected" : ""}`}>
              <input onChange={this.onFieldUpdate} name="p10" type="checkbox" />
              <span className="check-indicator"></span>
              <span className="check-text">{p10}</span>
            </label>
            {p90 !== VALUE_HIDDEN ? (
              <label className={`PanelForm__inputlabel ${data.p90 ? "selected" : ""}`}>
                <input onChange={this.onFieldUpdate} name="p90" type="checkbox" />
                <span className="check-indicator"></span>
                <span className="check-text">{p90}</span>
              </label>
            ) : null}
            <span style={{ marginTop: "10px" }} className="PanelForm__inputinfo">
              Place in the same order for other P versions
            </span>
          </div>
        ) : null}
      </div>
    )
  }
}

PreviewRequest.propTypes = {
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  onValidated: PropTypes.func,
  data: PropTypes.object,
  previewSelectionSuggestion: PropTypes.object,
  requestToEdit: PropTypes.object,
  PVersion: PropTypes.object,
  domain: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    previewSelectionSuggestion: state.requestData.previewSelectionSuggestion,
    requestToEdit: state.requestData.requestToEdit,
    PVersion: state.calendar.PVersion,
    domain: state.application.domain,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewRequest)
