import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"
import RU from "../../../lib/RequestUtils"

import { SINGLE_WELL, WELL_SEQUENCE, BOOKING_TYPE } from "../../../registerConsts"

const validationKeys = [BOOKING_TYPE]

class InputWellType extends Component {
  componentDidMount() {
    const { index, onValidated, data } = this.props

    const validated = this.checkValidation(data)
    onValidated(index, validated)
  }

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const { value } = elem.target
    const obj = {}
    obj[elem.target.name] = value

    let wells
    if (value === SINGLE_WELL) {
      wells = [RU.getEmptyWell()]
    } else if (value === WELL_SEQUENCE) {
      wells = [RU.getEmptyWell(0), RU.getEmptyWell(1)]
    }

    obj.wells = wells

    onUpdate(obj)
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props

    if (data !== prevProps.data) {
      let validated = true
      validated = this.checkValidation(data)

      onValidated(index, validated)
    }
  }

  checkValidation = (data) => {
    const keys = Object.keys(data)
    return validationKeys.every((key) => keys.includes(key))
  }

  render() {
    const { data } = this.props

    return (
      <div className="PanelContent__Container PanelForm" style={{ alignItems: "center" }}>
        <div className="PanelForm__InputPart PanelForm__InputPart--radio-container">
          <div className="PanelForm__desc">Select type of addition for the schedule</div>
          <div className="PanelForm__InputPart PanelForm__InputPart--radio">
            <label className="PanelForm__inputlabel">
              <input
                value={SINGLE_WELL}
                name={BOOKING_TYPE}
                type="radio"
                checked={data[BOOKING_TYPE] === SINGLE_WELL}
                onChange={this.onFieldUpdate}
              />
              <span className="check-indicator"></span>
              <span className="PanelForm__inputlabel-text">Single well</span>
            </label>
            <label className="PanelForm__inputlabel">
              <input
                value={WELL_SEQUENCE}
                name={BOOKING_TYPE}
                type="radio"
                checked={data[BOOKING_TYPE] === WELL_SEQUENCE}
                onChange={this.onFieldUpdate}
              />
              <span className="check-indicator"></span>
              <span className="PanelForm__inputlabel-text">Sequence of wells</span>
            </label>
          </div>
        </div>
      </div>
    )
  }
}

InputWellType.propTypes = {
  options: PropTypes.object,
  pages: PropTypes.object,
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  onValidated: PropTypes.func,
  data: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InputWellType)
