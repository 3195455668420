import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

import ContentList from "../Bits/ContentList"

class EditAssets extends Component {
  componentDidMount() {
    const { getUserlistList } = this.props
    getUserlistList()
  }

  handleDeleteFormSubmit = (selectedItems) => {
    const { deleteUserLists } = this.props
    deleteUserLists(selectedItems)
  }

  editItem = (item) => {
    const { editUserlistItem } = this.props
    editUserlistItem(item)
  }

  addItem = () => {
    const { editUserlistItem } = this.props
    editUserlistItem({ name: "", users: [] })
  }

  render() {
    const { userlists } = this.props
    return (
      <div className="ManageContent__Content">
        <ContentList
          addItem={this.addItem}
          editItem={this.editItem}
          deleteItemsFunc={this.handleDeleteFormSubmit}
          header={"Edit userslists"}
          items={userlists}
        />
      </div>
    )
  }
}

EditAssets.propTypes = {
  editUserlistItem: PropTypes.func,
  getUserlistList: PropTypes.func,
  userlists: PropTypes.array,
  deleteUserLists: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    userlists: state.managecontent.userlists,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAssets)
