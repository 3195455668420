/* eslint-disable import/prefer-default-export */
import { from, of } from "rxjs"
import { ofType } from "redux-observable"
import {
  switchMap,
  catchError,
  map,
  // tap,
} from "rxjs/operators"
import * as types from "../types"
import { setTimelineData } from "../actions/timeline"
import { observerEmpty, observerError } from "../actions/observer"
import { openPanelWithType } from "../actions/application"
import Api from "../../services/ApiService"
import { DECISION_SUMMARY } from "../../panelConsts"
/**
  UPDATE DAY WIDTH
*/
export const setTimelineDataObserver = (action$, state$) =>
  action$.pipe(
    ofType(types.SET_TIMELINE_DATA),
    map(() => {
      if (state$.value.timeline.openSummary === true) {
        return openPanelWithType(DECISION_SUMMARY, state$.value.timeline.decisionSummary)
      }

      return observerEmpty()
    })
  )

export const getTimelineDataObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_TIMELINE_DATA),

    switchMap((action) =>
      from(Api.getDataFromApi(action.id ? `timeline/getTimeline?scheduleId=${action.id}` : "timeline/getTimeline")).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    map((result) => setTimelineData(result.data))
  )
