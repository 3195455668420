import PropTypes from "prop-types"
import React, { Component } from "react"

import { cloneDeep, find } from "lodash"
import { connect } from "react-redux"
import Select from "react-select"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class EditWells extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editItem: null,
    }
  }

  componentDidMount() {
    const { getBlockList, getLicenseList } = this.props
    this.setState({
      editItem: this.props.editItem,
    })

    getBlockList()
    getLicenseList()
  }

  handleInputChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[event.target.name] = event.target.value
    this.setState({ editItem })
  }

  handleSelectChange = (value, key) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[key] = value ? value.id : null
    this.setState({ editItem })
  }

  closeModal = () => {
    const { clearSingleItemEdit } = this.props
    clearSingleItemEdit()
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleEditFormSubmit = (event) => {
    const { handleEditFormSubmit } = this.props
    const { editItem } = this.state

    event.preventDefault()
    event.stopPropagation()

    handleEditFormSubmit(editItem)
  }

  render() {
    const {
      editItem: { name, plno, short_name },
      blocks,
      licenses,
    } = this.props
    const { editItem } = this.state

    if (!editItem) {
      return null
    }

    return (
      <form className="ReactModal__Wrapper" onSubmit={(event) => this.handleEditFormSubmit(event)}>
        <div className="ReactModal__Form ModalForm">
          <h2 className="ReactModal__FormHeader">
            Edit <strong>{name || plno || short_name}</strong>
          </h2>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Legal well name</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editItem.name || ""}
              name={"name"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Legal well name"
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Common well name</label>
            <input
              value={editItem.short_name || ""}
              name={"short_name"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Common well name"
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">PLNO</label>
            <input
              value={editItem.plno || ""}
              name={"plno"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder=""
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">UWI</label>
            <input value={editItem.uwi || ""} name={"uwi"} onChange={this.handleInputChange} className="ModalForm__Input" placeholder="" />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Block</label>
            <Select
              options={blocks}
              closeMenuOnSelect={true}
              isClearable={true}
              placeholder="Select block"
              className="White__Dropdown"
              getOptionLabel={(option) => option.name}
              value={find(blocks, { id: editItem.block_id })}
              classNamePrefix="Select__Dropdown__Elm"
              onChange={(value) => this.handleSelectChange(value, "block_id")}
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">License</label>
            <Select
              options={licenses}
              closeMenuOnSelect={true}
              isClearable={true}
              placeholder="Select license"
              className="White__Dropdown"
              getOptionLabel={(option) => option.name}
              value={find(licenses, { id: editItem.license_id })}
              classNamePrefix="Select__Dropdown__Elm"
              onChange={(value) => this.handleSelectChange(value, "license_id")}
            />
          </div>
        </div>
        <div className="ReactModal__Buttons">
          <button type="button" onClick={this.closeModal} className="ReactModal__Button">
            Cancel
          </button>
          <button type="submit" className={"ReactModal__Button ReactModal__Button--primary"}>
            {"Save"}
          </button>
        </div>
      </form>
    )
  }
}

EditWells.propTypes = {
  editItem: PropTypes.object,
  clearSingleItemEdit: PropTypes.func,
  getBlockList: PropTypes.func,
  getLicenseList: PropTypes.func,
  handleEditFormSubmit: PropTypes.func,
  blocks: PropTypes.array,
  licenses: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editItem: state.managecontent.editItem,
    blocks: state.managecontent.blocks,
    licenses: state.managecontent.licenses,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWells)
