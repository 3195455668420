import { SELECT_RIGS_REMOVE_ERROR, REMOVE_RIG_ERROR, CANCEL } from "../../../../panelConsts"

const config = {
  type: REMOVE_RIG_ERROR,
  index: 0,
  options: {
    heading: "",
    dynamicHeader: true,
    dynamicSubHeader: true,
    subHeading: "This rig can't be removed",
  },
  pages: [
    {
      type: SELECT_RIGS_REMOVE_ERROR,
      name: "Remove rig error",
      options: {
        hideMenu: true,
        buttons: {
          center: CANCEL,
        },
      },
    },
  ],
}

export default config
