import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import format from "date-fns/format"

import parseISO from "date-fns/parseISO"
import Modal from "react-modal"
import { ActionCreators } from "../../../redux/actions"
import Draft from "../../icons/Draft"
import DraftMaster from "../../icons/DraftMaster"
import { DATE_FORMATE_NB } from "../../../dateConsts"

Modal.setAppElement("#root")

class NextScheduleModuleTimeline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: "",
      newList: [],
    }
  }

  componentDidMount() {
    const { fetchDrafts } = this.props
    fetchDrafts()
  }

  getTimelineForDraft = (id) => {
    const { getTimelineData } = this.props
    getTimelineData(id)
  }

  render() {
    const { draftsList } = this.props
    const { newList } = this.state

    if (!draftsList) return null

    const drafts = draftsList.length ? draftsList.filter((d) => d.is_main_draft) : []
    const draftsShared = draftsList.length ? draftsList.filter((d) => !d.is_main_draft) : []
    return (
      <>
        <div className="Overview__Module NextScheduleModule">
          <h2 className="Overview__ModuleHeading">Upcoming schedule</h2>
          <div className="NextScheduleModule__list">
            {drafts.map((val, key) => (
              <div
                key={key}
                onClick={() => this.getTimelineForDraft(val.id)}
                className={`NextScheduleModule__listitem ${newList.includes(val) ? "NextScheduleModule__listitem--new" : ""}`}
                to={`/schedule/${val.id}`}
              >
                {val.is_main_draft ? (
                  <h4 className="NextScheduleModule__listitem-subheader">
                    {DraftMaster(
                      {
                        width: "20px",
                        margin: "0px 5px 0 0",
                        position: "relative",
                        top: "3px",
                      },
                      `master-icon-${val.id}`
                    )}{" "}
                    Next months draft
                  </h4>
                ) : null}
                <span className="NextScheduleModule__listitem-header">{val.name}</span>
                <span className="NextScheduleModule__listitem-date">Created {format(parseISO(val.created_at), DATE_FORMATE_NB)}</span>
              </div>
            ))}
          </div>
          <h3 className="Overview__SubHeading">Other drafts</h3>
          <div className="NextScheduleModule__list">
            {draftsShared.map((val, key) => (
              <div
                key={key}
                onClick={() => this.getTimelineForDraft(val.id)}
                className={`NextScheduleModule__listitem ${newList.includes(val) ? "NextScheduleModule__listitem--new" : ""}`}
                to={`/schedule/${val.id}`}
              >
                {val.is_main_draft ? (
                  <h4 className="NextScheduleModule__listitem-subheader">
                    {Draft(
                      {
                        width: "20px",
                        margin: "0px 5px 0 0",
                        position: "relative",
                        top: "3px",
                      },
                      `master-icon-${val.id}`
                    )}{" "}
                    Next months draft
                  </h4>
                ) : null}
                <span className="NextScheduleModule__listitem-header">{val.name}</span>
                <span className="NextScheduleModule__listitem-date">Created {format(parseISO(val.created_at), DATE_FORMATE_NB)}</span>
              </div>
            ))}
            {draftsShared.length === 0 && <p className="Overview__message">No other drafts shared with you.</p>}
          </div>
        </div>
      </>
    )
  }
}

NextScheduleModuleTimeline.propTypes = {
  draftsList: PropTypes.array,
  fetchDrafts: PropTypes.func,
  getTimelineData: PropTypes.func,
  showModal: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    draftsList: state.overviewData.draftsList,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NextScheduleModuleTimeline)
