import { cloneDeep } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class EditBlock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editItem: null,
    }
  }

  componentDidMount() {
    // const { getLicenseList } = this.props;
    this.setState({
      editItem: this.props.editItem,
    })

    // getLicenseList();
  }

  handleInputChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[event.target.name] = event.target.value
    this.setState({ editItem })
  }

  handleCoordinatesChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem.coordinates = editItem.coordinates || {}
    editItem.coordinates[event.target.name] = event.target.value
    this.setState({ editItem })
  }

  handleSelectChange = (value, key) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[key] = value ? value.id : null
    this.setState({ editItem })
  }

  closeModal = () => {
    const { clearSingleItemEdit } = this.props
    clearSingleItemEdit()
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleEditFormSubmit = (event) => {
    const { handleEditFormSubmit } = this.props
    const { editItem } = this.state

    event.preventDefault()
    event.stopPropagation()

    handleEditFormSubmit(editItem)
  }

  render() {
    const {
      editItem: { name },
    } = this.props
    const { editItem } = this.state

    if (!editItem) {
      return null
    }

    return (
      <form className="ReactModal__Wrapper" onSubmit={(event) => this.handleEditFormSubmit(event)}>
        <div className="ReactModal__Form ModalForm">
          <h2 className="ReactModal__FormHeader">
            Edit <strong>{name}</strong>
          </h2>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Name</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editItem.name || ""}
              name={"name"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Name"
            />
          </div>
          {/* <div className="ModalForm__Part">
            <label className="ModalForm__Label">License</label>
            <Select
              options={licenses}
              closeMenuOnSelect={true}
              isClearable={true}
              placeholder='Select license'
              className='White__Dropdown'
              getOptionLabel={option => option.name}
              value={find(licenses, { id: editItem.license_id })}
              classNamePrefix='Select__Dropdown__Elm'
              onChange={ value => this.handleSelectChange(value, 'license_id') }
            />
          </div> */}
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Latitude</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editItem.coordinates ? editItem.coordinates.x : ""}
              name={"x"}
              onChange={this.handleCoordinatesChange}
              className="ModalForm__Input"
              placeholder="Name"
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Longitude</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editItem.coordinates ? editItem.coordinates.y : ""}
              name={"y"}
              onChange={this.handleCoordinatesChange}
              className="ModalForm__Input"
              placeholder="Name"
            />
          </div>
        </div>
        <div className="ReactModal__Buttons">
          <button type="button" onClick={this.closeModal} className="ReactModal__Button">
            Cancel
          </button>
          <button type="submit" className={"ReactModal__Button ReactModal__Button--primary"}>
            {"Save"}
          </button>
        </div>
      </form>
    )
  }
}

EditBlock.propTypes = {
  editItem: PropTypes.object,
  clearSingleItemEdit: PropTypes.func,
  handleEditFormSubmit: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editItem: state.managecontent.editItem,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBlock)
