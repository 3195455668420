/* eslint-disable import/prefer-default-export */
import { from, of } from "rxjs"
import { ofType } from "redux-observable"
import {
  switchMap,
  catchError,
  delay,
  map,
  mergeMap,
  // tap,
} from "rxjs/operators"
import * as types from "../types"
import { hideError, unsetFormValidationFailed, saveDataForDomain } from "../actions/application"
import { setDayWidth } from "../actions/calendar"
import { observerError } from "../actions/observer"
import { fetchUserRightsAndRoles } from "../actions/user"
import { fetchRigData } from "../actions/schedulerData"
import { getMessagesListForUser } from "../actions/managecontent"
import { setViewFilterToStoredVersion } from "../actions/filters"
import { editRequestComplete } from "../actions/requests"
import CalendarUtils from "../../lib/CalendarUtils"

import Api from "../../services/ApiService"

export const getSettingsForDomain = (action$) =>
  action$.pipe(
    ofType(types.GET_DOMAIN_DATA),
    switchMap(() =>
      from(
        Api.getDataFromApi("appSettings")
        // Api.getSettingsDummy(),
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    ),
    // tap(result => console.log('rr::::', result)),
    mergeMap((result) => [
      // mergeMap(result => [
      saveDataForDomain(result),
      fetchUserRightsAndRoles(),
      fetchRigData(),
      getMessagesListForUser(),
      setViewFilterToStoredVersion(),
    ])
  )
/**
  UPDATE DAY WIDTH
*/
export const updateDayWidthAfterResize = (action$, state$) =>
  action$.pipe(
    ofType(types.RESIZE),
    switchMap(() =>
      from(
        CalendarUtils.updateDayWidth(state$.value.calendar.zoomLevel, state$.value.application.size.width, state$.value.calendar.timespan)
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    ),
    map((result) => setDayWidth(result))
  )

export const onClosePanel = (action$) =>
  action$.pipe(
    ofType(types.CLOSE_PANEL),
    map((result) => editRequestComplete(result))
  )

export const hideErrorAfterDelay = (action$) =>
  action$.pipe(
    ofType(types.THROW_ERROR),
    delay(3000),
    map(() => hideError())
  )

export const unsetModalFailedValidation = (action$) =>
  action$.pipe(
    ofType(types.SET_MODAL_FAILED_VALIDATION),
    delay(2000),
    map(() => unsetFormValidationFailed())
  )
