import React, { useState } from "react"
import PropTypes from "prop-types"
import { hot } from "react-hot-loader/root"
import { findIndex } from "lodash"
import isSameDay from "date-fns/isSameDay"
import parseISO from "date-fns/parseISO"

import page from "../../../assets/images/svg/icons/page.svg"
import plus from "../../../assets/images/svg/icons/pluss.svg"

const KeyEvent = (props) => {
  const [hover, setHover] = useState(false)

  const getIcon = (icon) => {
    switch (icon) {
      case "page":
        return page
      case "plus":
        return plus
      default:
        return null
    }
  }
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
  const getPosY = (id) => {
    switch (id) {
      case "nextInputDeadline":
        return !isIE11 ? 0 : 125
      case "coordinationMeetingDate":
        return !isIE11 ? 150 : 275
      case "steeringcomMeetingDate":
        return !isIE11 ? 150 : 275
      case "nextRevision":
        return !isIE11 ? 0 : 125
      default:
        return !isIE11 ? 0 : 125
    }
  }

  const dayOfMonth = findIndex(props.daysInMonth, (o) => isSameDay(o, parseISO(props.data.date)))

  const nod = props.daysInMonth.length
  const pxPrDay = Math.min(props.containerWidth, 2000) / nod
  const pos = dayOfMonth * pxPrDay + pxPrDay / 2 + 2
  const lineHeight = props.containerHeight * 0.1 - 14

  const style = {
    left: `${pos}px`,
    transform: `translate3d(-${50}%, ${-getPosY(props.id)}%, 0) ${hover ? "scale(1.1)" : ""}`,
    zIndex: `${500 - getPosY(props.id)}`,
  }

  const lineStyle = {
    height: !isIE11 ? `calc(${lineHeight}px + ${getPosY(props.id)}%)` : `calc(${lineHeight}px + ${getPosY(props.id) - 125}%)`,
  }

  const icon = getIcon(props.data.icon)

  return (
    <div className={"timeline__key-event"} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} style={style}>
      <div className="timeline__key-event--header">{props.data.text}</div>
      {icon ? (
        <div className="timeline__key-event--icon">
          <img src={icon} />
        </div>
      ) : null}
      <div className="timeline__key-event--line" style={lineStyle}></div>
    </div>
  )
}

KeyEvent.propTypes = {
  data: PropTypes.object,
  header: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  containerWidth: PropTypes.number,
  containerHeight: PropTypes.number,
  daysInMonth: PropTypes.array,
}

export default hot(KeyEvent)
