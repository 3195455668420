import React from "react"

import addDays from "date-fns/addDays"
import addMonths from "date-fns/addMonths"
import differenceInDays from "date-fns/differenceInDays"
import eachDayOfInterval from "date-fns/eachDayOfInterval"
import format from "date-fns/format"
import getDate from "date-fns/getDate"
import getDaysInMonth from "date-fns/getDaysInMonth"
import getMonth from "date-fns/getMonth"
import getYear from "date-fns/getYear"
import isAfter from "date-fns/isAfter"
import isSameDay from "date-fns/isSameDay"
import isWednesday from "date-fns/isWednesday"
import parseISO from "date-fns/parseISO"
import startOfMonth from "date-fns/startOfMonth"

import DNDC from "../components/dragndrop/DNDContainer"
import * as types from "../redux/types"

import ContractOption from "../components/Scheduler/Calendar/Plotter/ContractOption"
import FreeTime from "../components/Scheduler/Calendar/Plotter/FreeTime"
import NoContract from "../components/Scheduler/Calendar/Plotter/NoContract"
import RigMove from "../components/Scheduler/Calendar/Plotter/RigMove"
import Suggestion from "../components/Scheduler/Calendar/Plotter/Suggestion"
import WellBookingSequence from "../components/Scheduler/Calendar/Plotter/WellBookingSequence"

import { DATE_FORMATE_NB } from "../dateConsts"
import { WELL_PLACEMENT_BOTTOM, WELL_PLACEMENT_TOP } from "../wellConsts"

const monthsInYear = 12
const minDayWidth = 1

let moveToTop = true

const CalendarUtils = {
  undo: (schedulerData, regretChange) => {
    const AllHistory = schedulerData.schedulerHistory

    if (AllHistory instanceof Map) {
      const draftHistory = AllHistory.get(schedulerData.schedulerData.data.id)
      if (draftHistory && draftHistory.length > 0) {
        regretChange()
      }
    }
  },

  getZoomLevel: (duration) => {
    let zoom = 0
    switch (duration) {
      case types.DURATION_FIVE_YEAR:
        zoom = 5 * monthsInYear
        break
      case types.DURATION_FOUR_YEAR:
        zoom = 4 * monthsInYear
        break
      case types.DURATION_THREE_YEAR:
        zoom = 3 * monthsInYear
        break
      case types.DURATION_TWO_YEAR:
        zoom = 2 * monthsInYear
        break
      case types.DURATION_18_MONTHS:
        zoom = 1.5 * monthsInYear
        break
      case types.DURATION_ONE_YEAR:
        zoom = monthsInYear
        break
      case types.DURATION_ONE_MONTH:
        zoom = 1
        break
      default:
        zoom = 5 * monthsInYear
        break
    }

    return zoom
  },

  getFirstNameForWellBookings: (well) => {
    if (!well) {
      return ""
    }

    return well.well.name || well.well.short_name || well.well.plno
  },

  getNameForWellBooking: (well, useShortName) => {
    if (!well) return "NO NAME"
    const useShortNameConst = !!useShortName
    const wellTitle = well.name ? well.name : well.plno

    if (useShortNameConst) {
      return well.short_name ? well.short_name : wellTitle
    }

    return wellTitle
  },

  getWellSequence: (props, val, elm, id, readonly) => {
    let position = "middle"

    if (elm.crashes) {
      position = moveToTop ? WELL_PLACEMENT_TOP : WELL_PLACEMENT_BOTTOM
      moveToTop = !moveToTop
    }

    let PVersion = props.PVersion

    const containerClassNames = `Well-Booking__Sequence ${position}`

    const component = (
      <DNDC
        key={`${props.revision}-${props.PVersion.key}-${props.rigId}-${id}-WELL_SEQUENCE`}
        id={id}
        placement={position}
        readonly={readonly}
        hasContractWith={elm.wells[0].has_contract_with}
        pressure={elm.wells[0].well.pressure}
        activePVersion={props.PVersion.value}
        accessLevel={props.accessLevel}
        setScrollObjectPosition={props.setScrollObjectPosition}
        data={elm}
        {...props}
        {...val}
        {...elm}
        type={elm.wells[0].rig_type}
        classNames={containerClassNames}
      >
        <WellBookingSequence
          data={elm}
          dayWidth={props.dayWidth}
          zoomLevel={props.zoomLevel}
          PVersion={PVersion}
          timespan={props.timespan}
          darkMode={props.darkMode}
          filter={props.filter}
          additionalScopeWells={props.additionalScopeWells}
        />
      </DNDC>
    )

    return component
  },

  getFreeTime: (props, val, elm, id) => {
    const component = (
      <FreeTime
        key={`${props.revision}-${props.PVersion.key}-${props.rigId}-${id}`}
        rigId={props.rigId}
        schedule_id={val.scheduleId}
        rigPlacementId={val.rigPlacementId}
        id={id}
        data={val}
        {...elm}
        filter={props.filter}
        timespan={props.timespan}
        PVersion={props.PVersion.value}
        dayWidth={props.dayWidth}
      ></FreeTime>
    )

    return component
  },

  noContract: (props, val, elm, id) => {
    const component = (
      <NoContract
        key={`${props.revision}-${props.PVersion.key}-${props.rigId}-${id}-NO_CONTRACT`}
        // rigId={props.rigId}
        schedule_id={val.scheduleId}
        rigPlacementId={val.rigPlacementId}
        id={id}
        data={val}
        {...props}
        {...val}
        {...elm}
      ></NoContract>
    )

    return component
  },

  contractOption: (props, val, elm, id) => {
    const component = (
      <ContractOption
        key={`${props.revision}-${props.PVersion.key}-${props.rigId}-${id}-CONTRACT_OPTION`}
        // rigId={props.rigId}
        schedule_id={val.scheduleId}
        rigPlacementId={val.rigPlacementId}
        id={id}
        data={val}
        {...props}
        {...val}
        {...elm}
      ></ContractOption>
    )

    return component
  },

  getRigMove: (props, val, elm, id) => {
    const component = (
      <RigMove
        key={`${props.revision}-${id}-${props.PVersion.key}-${
          props.rigId
        }-${Math.random()}`}
        rigId={props.rigId}
        schedule_id={props.scheduleId}
        rigPlacementId={props.rigPlacementId}
        id={id}
        data={val}
      ></RigMove>
    )

    return component
  },

  getSuggestion: (props, val, elm, id, exsistsInScheduleversion) => {
    const component = (
      <Suggestion
        key={`SUGGESTION-${props.revision}-${props.PVersion.key}-${id}`}
        rigId={props.rigId}
        id={id}
        exsistsInScheduleversion={exsistsInScheduleversion}
        data={{
          ...props,
          ...val,
          ...elm,
        }}
      ></Suggestion>
    )

    return component
  },

  updateDayWidth: async (zoomLevel, appWidth, timespan) => {
    const calendarWidth = appWidth - 170
    let daysCount = (365 / 12) * zoomLevel

    if (timespan) {
      daysCount = Math.min(
        differenceInDays(timespan.to, timespan.from),
        daysCount
      )
    }
    const tmpWidth = calendarWidth / daysCount
    const width = Math.max(minDayWidth, tmpWidth)
    return width
  },

  getDaysCount: (zoomLevel) => {
    const daysAYear = 365
    const daysCount = (daysAYear / 12) * zoomLevel
    return daysCount
  },

  getPos: (e) => {
    const type = e.target.getAttribute("class")

    let x = 0

    if (e.nativeEvent) {
      x =
        type && type.includes("Plotter--Row")
          ? e.nativeEvent.offsetX
          : e.nativeEvent.layerX
    } else {
      x = type && type.includes("Plotter--Row") ? e.offsetX : e.layerX
    }

    return x // this is a hack that makes sure position i not 0.
  },

  getMonthsInYear: () => [
    {
      month: "01",
      short: "J",
      longer: "Jan",
      full: "January",
      value: 31,
    },
    {
      month: "02",
      short: "F",
      longer: "Feb",
      full: "February",
      value: 59,
    },
    {
      month: "03",
      short: "M",
      longer: "Mar",
      full: "March",
      value: 90,
    },
    {
      month: "04",
      short: "A",
      longer: "Apr",
      full: "April",
      value: 120,
    },
    {
      month: "05",
      short: "M",
      longer: "May",
      full: "May",
      value: 151,
    },
    {
      month: "06",
      short: "J",
      longer: "Jun",
      full: "June",
      value: 181,
    },
    {
      month: "07",
      short: "J",
      longer: "Jul",
      full: "July",
      value: 212,
    },
    {
      month: "08",
      short: "A",
      longer: "Aug",
      full: "August",
      value: 242,
    },
    {
      month: "09",
      short: "S",
      longer: "Sep",
      full: "September",
      value: 273,
    },
    {
      month: "10",
      short: "O",
      longer: "Oct",
      full: "October",
      value: 303,
    },
    {
      month: "11",
      short: "N",
      longer: "Nov",
      full: "November",
      value: 334,
    },
    {
      month: "12",
      short: "D",
      longer: "Dec",
      full: "December",
      value: 365,
    },
  ],

  getWellBookingStatusColor: (status) => types.WELL_STATUS.get(status),

  getCalendarBasedOnMonth: (date) => {
    const days = getDaysInMonth(date)

    const arr = Array.from({ length: days }, (v, k) => k + 1)

    return arr
  },
  getCalendarBasedOnTimeframe: (timespan) => {
    if (!timespan.from || !timespan.to) return []

    const interval = eachDayOfInterval({
      start: new Date(timespan.from),
      end: new Date(timespan.to),
    })

    return interval
  },

  populateCalendar: async (from, to) => {
    const years = new Map()
    const firstDayOfFirstMonth = getDate(from)
    const startYear = getYear(from)
    const startMonth = getMonth(from)
    const endYear = getYear(to)
    const endMonth = getMonth(to)
    const numberOfYears = endYear - startYear + 1
    let j = 0
    let year
    while (j < numberOfYears) {
      if (j === 0) {
        year = {
          year: startYear + j,
          months: CalendarUtils.getMonthsInYear().filter(
            (m) => m.month > startMonth
          ),
          firstDayOfFirstMonth,
        }
      } else if (startYear + j === endYear) {
        year = {
          year: startYear + j,
          months: CalendarUtils.getMonthsInYear().filter(
            (m) => m.month <= endMonth
          ),
          firstDayOfFirstMonth: 1,
        }
      } else {
        year = {
          year: startYear + j,
          months: CalendarUtils.getMonthsInYear(startMonth),
          firstDayOfFirstMonth: 1,
        }
      }

      years.set(startYear + j, year)

      j += 1
    }

    return years
  },

  shouldWellBeFilteredOut: (
    filter,
    status,
    asset,
    license,
    name,
    shortName = null,
    hasContractWith,
    isHpht,
    area,
    wellType,
    eventType
  ) => {
    let shouldWellBeFilteredOut = false

    if (filter && filter.suggestions) {
      return true
    }

    /*
    If any filter set filterout all wells, and filter them inn if meeting a value in all criterias
    */
    if (Object.keys(filter).length) {
      // if free time then filter out if there is value in filters
      if (status === types.FREE_TIME) {
        shouldWellBeFilteredOut = true
      } else {
        if (filter.license) {
          if (!filter.license.includes(license)) {
            shouldWellBeFilteredOut = true
          }
        }

        if (filter.status) {
          if (!filter.status.includes(status)) {
            shouldWellBeFilteredOut = true
          }
        }

        if (filter.asset) {
          if (!filter.asset.includes(asset)) {
            shouldWellBeFilteredOut = true
          }
        }

        if (filter.area) {
          if (!filter.area.includes(area)) {
            shouldWellBeFilteredOut = true
          }
        }

        if (filter.has_contract) {
          if (!hasContractWith) {
            shouldWellBeFilteredOut = true
          }
        }

        if (filter.hpht) {
          if (!isHpht) {
            shouldWellBeFilteredOut = true
          }
        }

        if (filter.well_type) {
          if (!filter.well_type.includes(wellType)) {
            shouldWellBeFilteredOut = true
          }
        }

        if (filter.event_type) {
          if (!filter.event_type.includes(eventType)) {
            shouldWellBeFilteredOut = true
          }
        }

        if ((filter.search && name) || (filter.search && shortName)) {
          const hitsOnName = name
            ? name.toLowerCase().includes(filter.search.toLowerCase())
            : false
          const hitsOnShortName = shortName
            ? shortName.toLowerCase().includes(filter.search.toLowerCase())
            : false

          shouldWellBeFilteredOut = !hitsOnName && !hitsOnShortName
        }
      }
    }

    return shouldWellBeFilteredOut
  },

  getNewDate: (calendarStartDate, positionInPx, dayWidth) => {
    const days = Math.floor(positionInPx / dayWidth)
    return addDays(calendarStartDate, days)
  },

  getDateBasedOnPotision: (calendarStartDate, positionInPx, dayWidth) => {
    const days = Math.floor(positionInPx / dayWidth)
    const newDate = addDays(calendarStartDate, days)

    return format(newDate, DATE_FORMATE_NB)
  },

  getPositionBasedOnDate: (calendarStartDate, date, dayWidth) => {
    const days = differenceInDays(parseISO(date), calendarStartDate)
    const pos = days * dayWidth
    return pos
  },

  getLastDate: (startDate, estimate) => addDays(startDate, estimate),

  getNextRevisionDate: (now = new Date()) => {
    const firstDayOfThisMonth = startOfMonth(now)

    let firstWednesdayOfThisMonth
    let d = firstDayOfThisMonth
    for (;;) {
      d = addDays(d, 1)
      if (isWednesday(d)) {
        firstWednesdayOfThisMonth = d
        break
      }
    }

    let nextRevisionDate = addDays(firstWednesdayOfThisMonth, 14)
    if (isAfter(now, nextRevisionDate) || isSameDay(now, nextRevisionDate)) {
      nextRevisionDate = CalendarUtils.getNextRevisionDate(
        addMonths(firstDayOfThisMonth, 1)
      )
    }

    return nextRevisionDate
  },

  canCurrentUserEditWell: (usersAsset, accessLevel, well) => {
    // If the current user is NOT a Inputter or a Creator
    if (accessLevel < 3) {
      return false
    }

    // If the user is a Creator
    if (accessLevel >= 5) {
      return true
    }

    // If the user is inputter AND is connected to the exploration asset,
    // give access to edit wells of type = exploration
    if (accessLevel === 3 && usersAsset.includes(4)) {
      return well && well.well_type === "exploration"
    }

    // If the user has a mapping against an asset, he/she can edit those wells
    if (
      well &&
      well.well &&
      (usersAsset.includes(well.well.asset_id) ||
        (well.well.license && usersAsset.includes(well.well.license.asset_id)))
    ) {
      return true
    }
    return false
  },
}
export default CalendarUtils
