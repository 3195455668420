import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { format, getMonth, getYear, isSameDay } from "date-fns"
import { find, isEqual } from "lodash"
import { bindActionCreators } from "redux"

import { BehaviorSubject } from "rxjs"
import { debounceTime } from "rxjs/operators"
import { ActionCreators } from "../../redux/actions"
import GradientBackground from "../../components/backgrounds/GradientBackground"
import TimelineDay from "../../components/timeline/TimelineDay"
import KeyEvent from "../../components/timeline/KeyEvent"
import Change from "../../components/timeline/Change"
import MonthRecap from "../../components/timeline/MonthRecap"
import ChooseMonth from "../../components/timeline/ChooseMonth"
import ListDateChange from "../../components/timeline/ListDateChange"
import ListMinorChange from "../../components/timeline/ListMinorChange"
import ListMajorChange from "../../components/timeline/ListMajorChange"
import EditIcon from "../../../assets/images/svg/icons/bullet.svg"

import CU from "../../lib/CalendarUtils"

export class Timeline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      calendar: [],
      keyEventsObjects: null,
      changes: null,
      gradientOpacity: 0,
      gradientWidth: 0,
      detailsOpen: 0,
      currentData: null,
      selectedDate: null,
      resize: props.resize,
    }

    this.onResize = new BehaviorSubject(this.props.resize)
    this.onResize.pipe(debounceTime(500)).subscribe(this.updateOnResize)

    this.leftContainer = React.createRef()
    this.rightContainer = React.createRef()
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { timeline, resize } = this.props

    if (resize !== nextProps.resize) {
      return true
    }

    if (!isEqual(this.state, nextState)) {
      return true
    }

    if (!isEqual(this.state.changes, nextState.changes)) {
      return true
    }

    if (!isEqual(timeline, nextProps.timeline)) {
      return true
    }

    return false
  }

  componentDidMount = () => {
    const { getTimelineData } = this.props

    getTimelineData()
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      resize,
      timeline: { dateInfo, schedule },
    } = this.props

    const { changes } = this.state

    const { keyEventsObjects } = this.state

    if (resize > prevProps.resize) {
      this.onResize.next(resize)
    }

    if (!changes) {
      this.getData()
    }

    if (!isEqual(schedule.id, prevProps.timeline.schedule.id)) {
      this.getData()
    }

    if (keyEventsObjects !== prevState.keyEventsObjects && dateInfo.from && dateInfo.to) {
      const calendar = this.createDays(dateInfo)
      this.setState({
        calendar,
      })
    }
  }

  getData = () => {
    const {
      timeline: { dateInfo, changes },
    } = this.props

    if (dateInfo.from) {
      const ke = this.createKeyObjects()
      const changesObjects = this.createChangesObjects(changes)

      this.setState({
        keyEventsObjects: ke,
        changes: changesObjects,
      })
    }
  }

  componentWillUnmount = () => {
    this.setState({
      keyEventsObjects: null,
      changes: null,
      calendar: [],
    })
  }

  createDays = (timeframe) => {
    const dates = CU.getCalendarBasedOnTimeframe(timeframe)
    const currentDate = new Date()

    this.setGradientWidth(100)

    const calendar = dates.map((val, key) => (
      <TimelineDay
        key={key}
        date={val}
        setGradientWidth={this.setGradientWidth}
        currentDate={currentDate}
        eventOnDate={this.isEventOnDate(val)}
      />
    ))

    return calendar
  }

  createKeyObjects = () => {
    const {
      timeline: {
        keyEvents,
        dateInfo: { dates },
      },
    } = this.props

    const bounds = this.rightContainer.current ? this.rightContainer.current.getBoundingClientRect() : null

    if (!bounds || !Object.keys(keyEvents).length) return []

    const { width, height } = bounds

    const keyEventsObjects = []

    Object.keys(keyEvents).forEach((key) => {
      keyEventsObjects.push(
        <KeyEvent key={key} id={key} daysInMonth={dates} data={keyEvents[key]} containerWidth={width} containerHeight={height} />
      )
    })

    return keyEventsObjects
  }

  createChangesObjects = (changes) => {
    let key = 0

    const changesObjects = changes.map((val) => {
      key += 1

      return <Change key={key} data={val} handleClick={this.openDetails} />
    })

    return changesObjects
  }

  updateOnResize = () => {
    const keyEventsObjects = this.createKeyObjects()

    this.setState({
      keyEventsObjects,
    })
  }

  isEventOnDate = (date) => {
    const {
      timeline: { keyEvents },
    } = this.props
    const found = find(keyEvents, (val) => isSameDay(new Date(val.date), new Date(date)))
    return found
  }

  setGradientWidth = (gradientWidth) => {
    this.setState({
      gradientWidth,
      gradientOpacity: 1,
    })
  }

  openDetails = (data) => {
    this.setState({
      detailsOpen: true,
      currentData: data,
      selectedDate: null,
    })
  }

  toggleDetails = () => {
    this.setState({
      detailsOpen: !this.state.detailsOpen,
    })
  }

  getMajorChange = (change, key) => {
    const { selectedDate } = this.state
    let message

    const date = new Date(change.created_at)
    const dateString = format(date, "dd.MM")

    if (selectedDate && selectedDate !== dateString) return null

    switch (change.type) {
      case "moved":
        message = <ListMajorChange key={key} change={change} />
        break
      case "added":
        message = <ListMajorChange key={key} change={change} />
        break
      case "removed":
        message = <ListMajorChange key={key} change={change} />
        break
      default:
        message = <ListMajorChange key={key} title={`not supporting major change type ${change.type}`} />
        break
    }

    return message
  }

  getMinorChange = (change, key) => {
    const { selectedDate } = this.state
    const { domain } = this.props

    let message

    const date = new Date(change.created_at)
    const dateString = format(date, "dd.MM")

    if (selectedDate && selectedDate !== dateString) return null

    switch (change.type) {
      case "edited":
        message = <ListMinorChange key={key} change={change} Icon={EditIcon} IconWidth={3} IconHeight={4} domain={domain} />
        break
      default:
        message = <ListMinorChange key={key} title={`not supporting minor change type ${change.type}`} />
        break
    }

    return message
  }

  sortChangesByDate = (data) => {
    const updates = {}

    data.major.concat(data.minor).forEach((val) => {
      const date = new Date(val.created_at)
      const dateString = format(date, "dd.MM")

      if (!updates[dateString]) {
        updates[dateString] = {
          major: 0,
          minor: 0,
        }
      }

      updates[dateString][val.criticality] += 1
    })

    return updates
  }

  setDate = (date) => {
    this.setState({
      selectedDate: date !== this.state.selectedDate ? date : null,
    })
  }

  render() {
    const { calendar, gradientWidth, gradientOpacity, keyEventsObjects, changes, detailsOpen, currentData } = this.state

    const {
      timeline,
      timeline: { schedule, decisionSummary },
      openPanelWithType,
    } = this.props

    const open = detailsOpen ? "timeline--open" : ""

    let selectedMonth = null
    let selectedYear = null

    if (timeline.dateInfo && timeline.dateInfo.to) {
      selectedMonth = getMonth(new Date(timeline.dateInfo.from))
      selectedYear = getYear(new Date(timeline.dateInfo.from))
    }

    return (
      <>
        <ChooseMonth selectedMonth={selectedMonth} selectedYear={selectedYear} getTimelineData={this.props.getTimelineData} />
        <div className={`timeline ${open}`}>
          <div className={`timeline__left-container ${open}`} ref={this.leftContainer}>
            <MonthRecap data={decisionSummary} name={schedule.name} openPanelWithType={openPanelWithType} />
            <div className="timeline__desc-labels">
              <div className="timeline__desc-label timeline__desc-label--events">KEY EVENTS</div>
              <div className="timeline__desc-label timeline__desc-label--drafts">{schedule.name}</div>
              <div className="timeline__desc-label timeline__desc-label--changes">changes</div>
            </div>
          </div>
          <div ref={this.rightContainer} className={`timeline__right-container ${open}`}>
            <div className={"timeline__right-container--top-element"}>{keyEventsObjects}</div>
            <div className={"timeline__right-container--calendar-element"}>{calendar}</div>
            <div className={"timeline__right-container--bottom-element"}>{changes}</div>
          </div>
          <div className={`timeline__information-container ${open}`}>
            <div className="timeline__information-handle" onClick={this.toggleDetails}>
              Show change details
            </div>
            <div className="timeline__info-wrapper">
              <div className="timeline__info-wrapper-part">
                {currentData &&
                  Object.entries(this.sortChangesByDate(currentData)).map((val, key) => (
                    <ListDateChange
                      key={key}
                      title={val[0]}
                      minor={val[1].minor}
                      major={val[1].major}
                      selected={val[0] === this.state.selectedDate}
                      onClick={() => this.setDate(val[0])}
                    />
                  ))}
              </div>
              <div className="timeline__info-wrapper-part">
                <div className="timeline__info-part-header FontStyle--Bold">Major</div>
                <div className="timeline__changes-wrapper">
                  {currentData && currentData.major.map((val, key) => this.getMajorChange(val, key))}
                </div>
              </div>
              <div className="timeline__info-wrapper-part">
                <div className="timeline__info-part-header FontStyle--Bold">Minor</div>
                <div className="timeline__changes-wrapper">
                  {currentData && currentData.minor.map((val, key) => this.getMinorChange(val, key))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <GradientBackground width={"100%"} gradientWidth={gradientWidth} style={{ opacity: gradientOpacity }} />
      </>
    )
  }
}

Timeline.propTypes = {
  keyEvents: PropTypes.object,
  changes: PropTypes.object,
  timeline: PropTypes.object,
  domain: PropTypes.object,
  resize: PropTypes.number,
  getTimelineData: PropTypes.func,
  openPanelWithType: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    timeline: state.timeline,
    resize: state.application.resize,
    domain: state.application.domain,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeline)
