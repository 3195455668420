import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import parseISO from "date-fns/parseISO"

import { ActionCreators } from "../../../../redux/actions"
import CU from "../../../../lib/CalendarUtils"

class Suggestion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.data,
      active: true,
    }
  }

  static getDays(rigStart, calendarStart, duration, dayWidth) {
    const isoFrom = parseISO(rigStart)
    const isoTo = calendarStart
    const diff = differenceInCalendarDays(isoFrom, isoTo)
    const posX = diff * dayWidth
    return posX
  }

  getStyle() {
    const { estimate, start_date: startDate, status } = this.state

    const { dayWidth, timespan, exsistsInScheduleversion } = this.props

    const style = {}

    let postX = 0

    style.width = estimate * dayWidth

    postX = Suggestion.getDays(startDate, timespan.from, estimate, dayWidth)

    style.transform = `translate3d(${postX}px, 0, 0)`

    style.top = exsistsInScheduleversion === true ? "45px" : "0"

    style.backgroundColor = CU.getWellBookingStatusColor(status).color

    return style
  }

  handleClick = () => {
    const { setPreviewSelection, data } = this.props
    setPreviewSelection(data)
  }

  render() {
    const { estimate, name } = this.state
    const { previewSelectionSuggestion } = this.props

    if (parseInt(estimate, 0) === 0) {
      return null
    }

    // TODO: maybe move this out of render function
    const style = this.getStyle()

    const suggestionID = previewSelectionSuggestion ? `${previewSelectionSuggestion.name}` : "suggestion"

    return (
      <div
        id={suggestionID}
        style={style}
        className={`Calendar--Entry Suggestion ${
          previewSelectionSuggestion && previewSelectionSuggestion.name === name ? "Suggestion--selected" : ""
        }`}
        onClick={this.handleClick}
      >
        <div className="Suggestion__Label">{name}</div>
        {estimate}
      </div>
    )
  }
}

Suggestion.propTypes = {
  data: PropTypes.object,
  dayWidth: PropTypes.number,
  estimate: PropTypes.number,
  start_date: PropTypes.string,
  timespan: PropTypes.object,
  setPreviewSelection: PropTypes.func,
  previewSelectionSuggestion: PropTypes.object,
  exsistsInScheduleversion: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    previewSelectionSuggestion: state.requestData.previewSelectionSuggestion,
    dayWidth: state.calendar.dayWidth,
    timespan: state.calendar.timespan,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Suggestion)
