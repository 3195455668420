import { WellType } from "../types/well"

export const getReadableWellType = (welltype: WellType) => {
  switch (welltype) {
    case "plug_abandonment":
      return "Plug & abandonment"
    default:
      return welltype.charAt(0).toUpperCase() + welltype.slice(1)
  }
}
