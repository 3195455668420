import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"

class LogoutPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
    }
  }

  componentDidMount() {
    // Ath this point the user should be loged out (cookie is cleared by back-end)
    // Just remove convenient localstorage-data
    const { setUserData, user } = this.props
    localStorage.removeItem("config")
    localStorage.removeItem("user")
    localStorage.removeItem("RIG_PVersion")
    localStorage.removeItem("RIG_schedulerDataVersion")
    localStorage.removeItem("filter")
    this.setState({
      name: user ? user.firstName : "",
    })

    setUserData(null)
  }

  render() {
    const { name } = this.state
    return (
      <div className="generic-page">
        <h1 className="generic-page__heading">Bye {name} 👋</h1>
        <h2 className="generic-page__subheading">You have been logged out. Hope we'll see you again!</h2>
        <a className="generic-page__link" href="/schedule">
          Log in and go to schedule
        </a>
      </div>
    )
  }
}

LogoutPage.propTypes = {
  setUserData: PropTypes.func,
  user: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    user: state.application.user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage)
