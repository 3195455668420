import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { RootApp } from "./pages/routes"
import { setUserData } from "./redux/actions/application"
import ApiServices, { getConfig } from "./services/ApiService"

const App = () => {
  useEffect(() => {
    ;(async () => {
      const config = await getConfig()
      localStorage.setItem("config", JSON.stringify(config))
      setAppConfig(config.apiHost)
    })()
  }, [])

  const [userSet, setUserSet] = useState(false)
  const dispatch = useDispatch()

  const setAppConfig = (apiUrl) => {
    if (apiUrl) {
      const token = ApiServices.getCookie("jwt")
      if (!token) {
        if (document.location.pathname !== "/logout") {
          const currentUrl = window.location.href
          window.location.href = `//${apiUrl}/v1/auth/initiate?redirectTo=${currentUrl}`
          return null
        }
      }

      ;(async () => {
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
        if (!user) {
          user = await ApiServices.getDataFromApi("user/me")
          localStorage.setItem("user", JSON.stringify(user))
        }
        dispatch(setUserData(user))
        setUserSet(true)
      })()
    }
  }

  return userSet && <RootApp />
}

export default App
