const ActivityIndicatorUtils = {
  getLabelByKey: (key) => {
    let arr = ["Loading 😊"]
    switch (key) {
      case "fetch_rig_data":
        arr = ["Fetching schedule... 🤗", "Getting data... 😊"]
        break
      case "update_rig_data":
        arr = ["Saving... ✌🏼", "Hang on, saving data 🌸", "Writing data to disc 📀"]
        break
      case "save_well_instance":
        arr = ["Saving... 😌", "Just wait a minute, saving data 😋"]
        break
      case "set_new_master":
        arr = ["Setting master 😋", "Hang on, setting new master 😋"]
        break
      case "no_access":
        arr = [
          "🙅 Hm.. You don't have access to view this schedule.",
          "🚫 Seems like you're trying to access something you're not allowed to?",
        ]
        break
      default:
    }
    return arr[Math.floor(Math.random() * arr.length)]
  },
}
export default ActivityIndicatorUtils
