import React from "react"
import * as panels from "../panels"

const PanelHelper = {
  getPanel: (panelInfo) => {
    const Panel = panels.PANELS.get(panelInfo.type).dom
    return <Panel className={"Panel"} {...panelInfo} />
  },

  getPanelConfig: (type) => panels.PANELS.get(type).config,

  getPage: (index, pageInfo, onUpdate, onValidated, data, guiProps = {}) => {
    const Page = panels.INPUT_PAGES.get(pageInfo.type)

    return (
      <Page
        key={index}
        index={index}
        className={"sub page"}
        {...pageInfo}
        onUpdate={onUpdate}
        guiProps={guiProps}
        data={data}
        onValidated={onValidated}
      />
    )
  },
}

export default PanelHelper
