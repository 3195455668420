import createReducer from "../../lib/createReducer"
import * as types from "../types"

export const initialState = {
  accessLevel: 1,
  asset: null,
}

export const user = createReducer(initialState, {
  [types.FETCH_USERS_RIGHTS_AND_ROLES](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SET_USERS_RIGHTS_AND_ROLES](state, actions) {
    const ns = Object.assign({}, state)
    ns.accessLevel = actions.data.accessLevel
    ns.asset = actions.data.asset
    return ns
  },
})
