// buildReduxContainer.js
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import { ActionCreators } from "./redux/actions"

export default function (ComposedComponent, requiredAccessLevel, forceRender = false) {
  class UserAccessComponent extends React.PureComponent {
    checkAccessRights = () => {
      const { accessLevel } = this.props

      if (accessLevel >= requiredAccessLevel) {
        return true
      }

      return false
    }

    render() {
      const access = this.checkAccessRights()

      return <>{access || forceRender ? <ComposedComponent shouldRender={access} {...this.props} {...this.boundActionCreators} /> : null}</>
    }
  }

  UserAccessComponent.propTypes = {
    accessLevel: PropTypes.number,
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch)
  }

  function mapStateToProps(state) {
    return {
      accessLevel: state.user.accessLevel,
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(UserAccessComponent)
}
