import { cloneDeep } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"

import { bindActionCreators } from "redux"
// import { find } from 'lodash';
import RoundedDropDown from "../../../components/dropdown/RoundedDropDown"
import DayPickerElement from "../../../components/form/DayPickerElement"
import { ActionCreators } from "../../../redux/actions"

import { DATE_FORMATE_NB } from "../../../dateConsts"

const rolesArr = [
  { name: "Inputter", value: ["inputter"] },
  { name: "Full read", value: ["fullread"] },
  { name: "Viewer", value: ["viewer"] },
  // { name: "ExtendedViewer", value: ["extendedviewer"] },
]

class EditMessage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editItem: props.editItem || {},
      dateFrom: new Date(),
      dateTo: new Date(),
      applies_to: [],
    }
  }

  handleInputChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[event.target.name] = event.target.value
    this.setState({ editItem })
  }

  handleSelectChange = (value, key) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[key] = value ? value.id : null
    this.setState({ editItem })
  }

  closeModal = () => {
    const { clearSingleItemEdit } = this.props
    clearSingleItemEdit()
  }

  saveMessage = () => {
    const { saveMessage } = this.props
    const {
      editItem,
      editItem: { message, name, valid_from: validFrom, valid_to: validTo, applies_to: appliesTo },
    } = this.state

    if (message && name && validFrom && validTo && appliesTo) {
      saveMessage(editItem)
      this.closeModal()
    }
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleEditFormSubmit = (event) => {
    const { handleEditFormSubmit } = this.props
    const { editItem } = this.state

    event.preventDefault()
    event.stopPropagation()

    handleEditFormSubmit(editItem)
  }

  handleStartDayChange = (elm) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem.valid_from = elm
    this.setState({ editItem })
  }

  handleEndDayChange = (elm) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem.valid_to = elm
    this.setState({ editItem })
  }

  switchRole = (role) => {
    // const selection = find(rolesArr, 'name', role[0]);
    const editItem = cloneDeep(this.state.editItem)

    if (!editItem.applies_to) editItem.applies_to = []

    if (editItem.applies_to.includes(role.value[0])) {
      editItem.applies_to.splice(editItem.applies_to.indexOf(role.value[0]), 1)
    } else {
      editItem.applies_to.push(role.value[0])
    }

    this.setState({
      editItem,
    })
  }

  renderRole = (key, val) => (
    <div onClick={() => this.switchRole(val)} className="RoundedDropDown__Option RoundedDropDown__Option--asset" key={key}>
      {val.selected ? (
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs></defs>
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Button/Dropdown/Active" transform="translate(-10.000000, -79.000000)" fill="#1C2226">
              <g id="Item-2-Copy" transform="translate(10.000000, 76.000000)">
                <g id="Icons/Check/black" transform="translate(0.000000, 3.000000)">
                  <path
                    d="M15.4278468,5.03142357 L14.7948263,5.66486596 C15.0504692,6.39488701 15.1884352,7.18212483 15.1884352,8.00182607 C15.1884352,11.9623627 11.9624651,15.1884146 8.00202891,15.1884146 C4.03753487,15.1884146 0.811564798,11.9623627 0.811564798,8.00182607 C0.811564798,4.03723148 4.03753487,0.811585381 8.00202891,0.811585381 C9.6576211,0.811585381 11.1833629,1.37523143 12.3966523,2.3207284 L12.9769211,1.74450278 C11.6094344,0.652920439 9.88080142,0 8.00202891,0 C3.59117423,0 0,3.59085952 0,8.00182607 C0,12.4127926 3.59117423,16 8.00202891,16 C12.4128836,16 16,12.4127926 16,8.00182607 C16,6.95488093 15.7971088,5.94892085 15.4278468,5.03142357"
                    id="Fill-1"
                  ></path>
                  <path
                    d="M4.72372652,5.78968254 C4.64337151,5.71428571 4.53240507,5.67460317 4.42186379,5.67460317 C4.3155741,5.67460317 4.20503282,5.71428571 4.12467781,5.78968254 C3.95844073,5.94444444 3.95844073,6.1984127 4.12467781,6.3531746 L8.03146175,10 L15.1018521,3.40039683 L15.8756411,2.67857143 C16.041453,2.52380952 16.041453,2.27380952 15.8756411,2.11904762 C15.7906093,2.03968254 15.6843196,2 15.5742035,2 C15.4679138,2 15.3573725,2.03968254 15.2761672,2.11904762 L14.5831584,2.76190476 L13.9798581,3.32936508 L8.03146175,8.87698413 L4.72372652,5.78968254 Z"
                    id="Fill-4"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ) : (
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs></defs>
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Button/Dropdown/Active" transform="translate(-10.000000, -114.000000)" fill="#B2C2CC">
              <g id="Item-3-Copy" transform="translate(10.000000, 111.000000)">
                <g id="Icons/Check/Uncheck-Button-white" transform="translate(0.000000, 3.000000)">
                  <path
                    d="M8,0.811605965 C4.03611647,0.811605965 0.811605965,4.03611647 0.811605965,8 C0.811605965,11.9634777 4.03611647,15.188394 8,15.188394 C11.9638835,15.188394 15.188394,11.9634777 15.188394,8 C15.188394,4.03611647 11.9638835,0.811605965 8,0.811605965 M8,16 C3.58892158,16 0,12.4110784 0,8 C0,3.58892158 3.58892158,0 8,0 C12.4110784,0 16,3.58892158 16,8 C16,12.4110784 12.4110784,16 8,16"
                    id="Fill-1-Copy"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      )}
      <span className="RoundedDropDown__Option-Value">{val.name}</span>
    </div>
  )

  clearFilter = () => {
    const editItem = cloneDeep(this.state.editItem)

    editItem.applies_to = []

    this.setState({
      editItem,
    })
  }

  truncate = (string, length) => {
    if (string.length > length) {
      return `${string.substring(0, length)}...`
    }

    return string
  }

  render() {
    const {
      editItem,
      editItem: { message, name, valid_from: validFrom, valid_to: validTo, applies_to: appliesTo },
    } = this.state

    if (!editItem) {
      return null
    }

    const assetArr = rolesArr.map((val) => ({
      ...val,
      selected: appliesTo && appliesTo.includes(val.value[0]),
    }))

    const apliesToText = appliesTo && appliesTo.length > 0 ? this.truncate(appliesTo.join(", "), 15) : "Select roles"

    return (
      <form className="ReactModal__Wrapper" onSubmit={(event) => this.handleEditFormSubmit(event)}>
        <div className="ReactModal__Form ModalForm">
          <h2 className="ReactModal__FormHeader">
            Edit <strong>{name || ""}</strong>
          </h2>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Headline</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={name || ""}
              name={"name"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Name"
            />
          </div>
          <div className="ModalForm__Part TALL">
            <label className="ModalForm__Label">Message</label>
            <textarea
              type="textarea"
              value={message || ""}
              name={"message"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Message"
            />
          </div>
          <div className="ModalForm__Part">
            <span className="ModalForm__Date--Container">
              <DayPickerElement
                label="Message starts"
                onChange={this.handleStartDayChange}
                styles={{ marginBottom: 5 }}
                format={DATE_FORMATE_NB}
                value={validFrom}
              />
              <DayPickerElement
                label="Message valid until"
                onChange={this.handleEndDayChange}
                styles={{ marginBottom: 5 }}
                format={DATE_FORMATE_NB}
                value={validTo}
              />
            </span>
          </div>
          <span>Who will see this message</span>
          <RoundedDropDown
            classNames={"PUSH_BOTTOM"}
            hasSelectedValue={!!appliesTo}
            clearFunction={() => this.clearFilter("license")}
            label={apliesToText}
            renderoption={(key, val) => this.renderRole(key, val)}
            data={assetArr}
          />
        </div>
        <div className="ReactModal__Buttons">
          <button type="button" onClick={this.closeModal} className="ReactModal__Button">
            Cancel
          </button>
          <button type="submit" onClick={this.saveMessage} className={"ReactModal__Button ReactModal__Button--primary"}>
            {"Save"}
          </button>
        </div>
      </form>
    )
  }
}

EditMessage.propTypes = {
  editItem: PropTypes.object,
  clearSingleItemEdit: PropTypes.func,
  getBlockList: PropTypes.func,
  getAssetList: PropTypes.func,
  handleEditFormSubmit: PropTypes.func,
  saveMessage: PropTypes.func,
  blocks: PropTypes.array,
  assets: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editItem: state.managecontent.editItem,
    blocks: state.managecontent.blocks,
    assets: state.managecontent.assets,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMessage)
