import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PanelInputSelection from "../../../components/panel/PanelInputSelection"

import { ActionCreators } from "../../../redux/actions"
import RU from "../../../lib/RequestUtils"

import { SINGLE_WELL, WELL_SEQUENCE } from "../../../registerConsts"

class SelectExistingRig extends Component {
  constructor(props) {
    super(props)

    const { rigList, fetchRigList } = this.props

    if (!rigList) {
      fetchRigList()
    }
  }

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const { value } = elem.target
    const obj = {}
    obj[elem.target.name] = value

    let wells
    if (value === SINGLE_WELL) {
      wells = [RU.getEmptyWell()]
    } else if (value === WELL_SEQUENCE) {
      wells = [RU.getEmptyWell(), RU.getEmptyWell()]
    }

    obj.wells = wells

    onUpdate(obj)
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props

    if (data !== prevProps.data) {
      onValidated(index, true)
    }
  }

  handleRigChange = (contract) => {
    const { onUpdate, scheduleId } = this.props
    const obj = {}
    obj.rig_id = contract.id
    obj.schedule_id = scheduleId
    onUpdate(obj)
  }

  render() {
    const { rigList, rigData, data } = this.props

    if (!rigList) {
      return null
    }

    const theRigList = rigList.filter((r) => {
      const isIt = rigData.rigs.some((a) => a.rig_id === r.id)
      return !isIt
    })

    let selected = {}
    if (data) {
      selected = theRigList.filter((rl) => rl.id === data.rig_id)[0]
    }

    return (
      <div className="PanelContent__Container PanelForm" style={{ alignItems: "center" }}>
        <div className="PanelForm__InputPart PanelForm__InputPart--radio-container" style={{ width: "80%" }}>
          <div className="PanelForm__InputPart">
            <label style={{ marginBottom: "15px" }} className="PanelForm__inputheading">
              Select rig
            </label>
            <PanelInputSelection value={selected} options={theRigList} placeholder="Select rig" onChange={this.handleRigChange} />
          </div>
        </div>
      </div>
    )
  }
}

SelectExistingRig.propTypes = {
  options: PropTypes.object,
  pages: PropTypes.object,
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  onValidated: PropTypes.func,
  rigList: PropTypes.array,
  fetchRigList: PropTypes.func,
  data: PropTypes.object,
  rigData: PropTypes.object,
  PVersion: PropTypes.object,
  scheduleId: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    rigList: state.schedulerData.rigList,
    rigData: state.schedulerData.schedulerData.data,
    PVersion: state.calendar.PVersion,
    scheduleId: state.schedulerData.schedulerData.data.id,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectExistingRig)
