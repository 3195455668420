import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

import ContentList from "../Bits/ContentList"

class EditWells extends Component {
  componentDidMount() {
    const { getWellList } = this.props
    getWellList()
  }

  handleDeleteFormSubmit = (selectedItems) => {
    const { deleteItems } = this.props
    deleteItems("well", selectedItems)
  }

  editItem = (item) => {
    const { editSingleItem } = this.props
    editSingleItem("well", item)
  }

  addItem = () => {
    const { editSingleItem } = this.props
    editSingleItem("well", { name: "" })
  }

  renderItemName = (item) => item.name || item.plno || item.short_name

  render() {
    const { wells } = this.props
    return (
      <div className="ManageContent__Content">
        <ContentList
          addItem={this.addItem}
          editItem={this.editItem}
          deleteItemsFunc={this.handleDeleteFormSubmit}
          renderItemName={this.renderItemName}
          header={"Edit wells"}
          items={wells}
        />
      </div>
    )
  }
}

EditWells.propTypes = {
  editSingleItem: PropTypes.func,
  getWellList: PropTypes.func,
  deleteItems: PropTypes.func,
  wells: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    wells: state.managecontent.wells,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWells)
