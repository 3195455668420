import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Modal from "react-modal"
import { ActionCreators } from "../../redux/actions"
import PanelFrame from "../../components/panel/PanelFrame"
import PH from "../../lib/PanelHelper"

Modal.setAppElement("#root")

class Panel extends Component {
  render() {
    const {
      application: { panelOpen, errorConsoleOpen },
    } = this.props

    const isOpen = !!panelOpen

    const largeModal = `Modal__Container ${panelOpen && panelOpen.options.tallModal === true ? "ReactModal__Content--tall" : ""} ${
      panelOpen && panelOpen.options.wideModal === true ? "ReactModal__Content--wide" : ""
    } ${panelOpen && panelOpen.options.largeModal === true ? "ReactModal__Content--large" : ""}${
      panelOpen && panelOpen.options.whiteModal === true ? " ReactModal__Content--white" : ""
    }`
    const free = `${panelOpen && panelOpen.options.free === true ? " ReactModal__Content--free" : ""}`
    const overlayFree = `${panelOpen && panelOpen.options.free === true ? " Modal__Overlay--free" : ""}`
    const limitMaxHeight = `${panelOpen && panelOpen.options.limitHeight === true ? " Modal__Overlay--max-height" : ""}`
    const shringForErrorConsole = `${errorConsoleOpen === true ? " errorConsoleOpen" : ""}`

    return (
      <Modal
        isOpen={isOpen}
        className={largeModal + free + limitMaxHeight + shringForErrorConsole}
        overlayClassName={`Modal__Overlay ${overlayFree}`}
        contentLabel="Example Modal"
      >
        <PanelFrame>{panelOpen ? PH.getPanel(panelOpen) : null}</PanelFrame>
      </Modal>
    )
  }
}

Panel.propTypes = {
  application: PropTypes.object,
  children: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    application: state.application,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Panel)
