import React, { Component } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { cloneDeep } from "lodash"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../redux/actions"
import { MODAL_TYPE_DRAFT_ARCHIVE } from "../redux/types"

class ArchiveDraft extends Component {
  constructor(props) {
    super(props)

    const activeDraftArchive = cloneDeep(props.data.data.activeDraftArchive)
    this.state = {
      activeDraftArchive,
      draftArchived: false,
      isSavingArchive: false,
    }
  }

  closeModal = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const { closeModal } = this.props
    closeModal(MODAL_TYPE_DRAFT_ARCHIVE)
  }

  handleArchiveFormSubmit = (event) => {
    const { archiveDraft } = this.props

    event.preventDefault()
    event.stopPropagation()
    //
    if (!this.state.isSavingArchive) {
      this.setState({
        isSavingArchive: true,
      })

      archiveDraft(this.state.activeDraftArchive)
    }
  }

  render() {
    const {
      data: { data },
    } = this.props

    return (
      <Modal
        key={`archive-modal-${data.id}`}
        isOpen={true}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        className={"ReactModal__Content"}
        overlayClassName={"ReactModal__Overlay"}
        contentLabel="Archive"
      >
        <form className="ReactModal__Wrapper" onSubmit={this.handleArchiveFormSubmit}>
          <div className="ReactModal__Form">
            <h2 className="ReactModal__FormHeader ReactModal__FormHeader--big">
              {this.state.draftArchived ? "Archived!" : `Archive draft "${data.name}"?`}
            </h2>
          </div>
          <div className="ReactModal__Buttons">
            <button type="button" onClick={this.closeModal} className="ReactModal__Button">
              Cancel
            </button>
            <button
              type="submit"
              className={`ReactModal__Button ReactModal__Button--primary ${
                this.state.isSavingArchive ? "ReactModal__Button--inactive" : ""
              }`}
            >
              {"Archive"}
            </button>
          </div>
        </form>
      </Modal>
    )
  }
}

ArchiveDraft.propTypes = {
  archiveDraft: PropTypes.func,
  closeModal: PropTypes.func,
  data: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveDraft)
