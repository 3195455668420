import { cloneDeep, isEqual } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import Close from "../../../../assets/images/svg/icons/Close.svg"
import Down from "../../../../assets/images/svg/icons/Down.svg"
import Warning from "../../../../assets/images/svg/icons/Warning.svg"
import ErrorBg from "../../../../assets/images/svg/icons/error_bg.svg"
import ErrorWarning from "../../../../assets/images/svg/icons/error_warning.svg"
import IconContainer from "../../icons/IconContainer"

class ErrorDisplay extends Component {
  constructor() {
    super()

    this.state = {
      messages: {},
      closed: {},
    }
  }

  componentDidMount = () => {
    this.checkForUpdates()
  }

  componentDidUpdate = () => {
    this.checkForUpdates()
  }

  checkForUpdates = () => {
    const { errorMessages } = this.props
    const { messages } = this.state
    const newMessages = {}

    let counter = 0
    errorMessages.forEach((val) => {
      if (val.messages) {
        const key = val.well ? val.well : `Well ${counter + 1}`
        counter += 1
        if (!newMessages[key]) {
          newMessages[key] = []
        }

        val.messages.forEach((message) => {
          newMessages[key].push(
            <div key={newMessages[key].length} className="error">
              {message}
            </div>
          )
        })
      }
    })

    if (!isEqual(newMessages, messages)) {
      this.setState({
        messages: newMessages,
      })
    }
  }

  toggleOpen = () => {
    const { openConsole, errorConsoleOpen } = this.props
    const open = !errorConsoleOpen

    openConsole(open)
  }

  registerClosed = (key) => {
    const closed = cloneDeep(this.state.closed)

    if (!closed[key]) {
      closed[key] = true
    } else {
      delete closed[key]
    }

    this.setState({
      closed,
    })
  }

  render() {
    const { messages } = this.state
    const { errorConsoleOpen } = this.props

    const iStyle = {
      position: "absolute",
      width: 20,
      height: 20,
      left: 7,
      top: 14,
      zIndex: 10,
    }

    if (!errorConsoleOpen) {
      return (
        <div className="ErrorDisplay animatable" onClick={this.toggleOpen}>
          {IconContainer(ErrorBg, 35, 50)}
          <span style={iStyle}>{IconContainer(ErrorWarning, 20, 20)}</span>
        </div>
      )
    }

    let errorLength = 0
    Object.keys(messages).forEach((key) => {
      errorLength += messages[key].length
    })

    const errorHeader = errorLength > 1 ? `${errorLength} requirements missing` : "1 requirement missing"

    return (
      <div className="ErrorDisplay open">
        <div className="ErrorDisplay__Header">
          <div className="ErrorDisplay__Header__Container">
            {IconContainer(Warning, 14, 14)}
            <span>{errorHeader}</span>
          </div>
          <div className="ErrorDisplay__close" onClick={this.toggleOpen}>
            {IconContainer(Close, 12, 12)}
          </div>
        </div>

        {Object.entries(messages).map((errors, key) => (
          <div key={key} className="ErrorContainer">
            <div className="ErrorDisplay_Messages">
              <div className="ErrorDisplay_Header" onClick={() => this.registerClosed(key)}>
                <span className={`ErrorDisplay_Header__Arrow ${this.state.closed[key] ? "rotate" : ""}`}>
                  {IconContainer(Down, 12, 12)}
                </span>
                {errors[0] || "Well needs a legal well name"}
              </div>
              {!this.state.closed[key] ? errors[1] : null}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

ErrorDisplay.propTypes = {
  errorMessages: PropTypes.array,
  data: PropTypes.object,
  blocks: PropTypes.object,
  assets: PropTypes.object,
  rigList: PropTypes.array,
  onFieldUpdate: PropTypes.func,
  openConsole: PropTypes.func,
  failedStatusChange: PropTypes.string,
  errorConsoleOpen: PropTypes.bool,
}

export default ErrorDisplay
