import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Modal from "react-modal"
import { withRouter, Switch, Route } from "react-router-dom"
import { ActionCreators } from "../../redux/actions"
import Sidebar from "./Sidebar"
import EditWells from "./Content/EditWells"
import EditBlocks from "./Content/EditBlocks"
import EditRigs from "./Content/EditRigs"
import EditLicenses from "./Content/EditLicenses"
import EditAssets from "./Content/EditAssets"
import EditAreas from "./Content/EditAreas"
import EditUserList from "./Content/EditUserList"
import EditMessages from "./Content/EditMessages"
import EditCognitoUsers from "./Content/EditCognitoUsers"
import EditAppSettings from "./Content/EditAppSettings"

import EditSingleWell from "./Edit/Well"
import EditSingleBlock from "./Edit/Block"
import EditSingleLicense from "./Edit/License"
import EditSingleRig from "./Edit/Rig"
import EditSingleAsset from "./Edit/Asset"
import EditSingleArea from "./Edit/Area"
import EditSingleUserlist from "./Edit/UserList"
import Editmessage from "./Edit/Message"
import EditCognitoUser from "./Edit/CognitoUser"
import EditSingleAppSetting from "./Edit/AppSettings"

class ManageContentPage extends PureComponent {
  handleEditFormSubmit = (editItem) => {
    const { saveEditItem } = this.props
    saveEditItem(editItem)
  }

  handleUserlistEditFormSubmit = (editUserListItem) => {
    const { saveUserlistItem } = this.props
    saveUserlistItem(editUserListItem)
  }

  render() {
    const { match, editItem, editItemType, editUserListItem } = this.props

    const type = match.params.type || "wells"

    let editContent = null
    switch (editItemType) {
      case "well":
        editContent = <EditSingleWell handleEditFormSubmit={this.handleEditFormSubmit} />
        break
      case "block":
        editContent = <EditSingleBlock handleEditFormSubmit={this.handleEditFormSubmit} />
        break
      case "license":
        editContent = <EditSingleLicense handleEditFormSubmit={this.handleEditFormSubmit} />
        break
      case "asset":
        editContent = <EditSingleAsset handleEditFormSubmit={this.handleEditFormSubmit} />
        break
      case "area":
        editContent = <EditSingleArea handleEditFormSubmit={this.handleEditFormSubmit} />
        break
      case "rig":
        editContent = <EditSingleRig handleEditFormSubmit={this.handleEditFormSubmit} />
        break
      case "userlist":
        editContent = <EditSingleUserlist handleEditFormSubmit={this.handleUserlistEditFormSubmit} />
        break
      case "message":
        editContent = <Editmessage handleEditFormSubmit={() => {}} />
        break
      case "cognitouser":
        editContent = <EditCognitoUser handleEditFormSubmit={this.handleEditFormSubmit} />
      case "appSettings":
        editContent = <EditSingleAppSetting handleEditFormSubmit={this.handleEditFormSubmit} />
        break
      default:
    }

    return (
      <div className="ManageContent">
        <Sidebar current={type} />
        <Switch>
          <Route exact path="/managecontent/blocks" component={EditBlocks} />
          <Route exact path="/managecontent/licenses" component={EditLicenses} />
          <Route exact path="/managecontent/assets" component={EditAssets} />
          <Route exact path="/managecontent/areas" component={EditAreas} />
          <Route exact path="/managecontent/countries" component={EditAssets} />
          <Route exact path="/managecontent/userlists" component={EditUserList} />
          <Route exact path="/managecontent/messages" component={EditMessages} />
          <Route exact path="/managecontent/cognitousers" component={EditCognitoUsers} />
          <Route exact path="/managecontent/rigs" component={EditRigs} />
          <Route exact path="/managecontent/appSettings" component={EditAppSettings} />
          <Route component={EditWells} />
        </Switch>

        <Modal
          key={"delete-modal"}
          isOpen={!!editItem || !!editUserListItem}
          className={"ReactModal__Content ReactModal__Content--scroll"}
          overlayClassName={"ReactModal__Overlay"}
          contentLabel="Rename"
        >
          {editContent}
        </Modal>
      </div>
    )
  }
}

ManageContentPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  editItem: PropTypes.object,
  editUserListItem: PropTypes.object,
  editItemType: PropTypes.string,
  saveEditItem: PropTypes.func,
  saveUserlistItem: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editItem: state.managecontent.editItem,
    editUserListItem: state.managecontent.editUserListItem,
    editItemType: state.managecontent.editItemType,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageContentPage))
