import PropTypes from "prop-types"
import React, { Component } from "react"
import InputCheckboxElement from "../../form/InputCheckboxElement"

import BuilderUtils from "../../../lib/BuilderUtils"
import ConditionalField, { ConditionalFieldBlock } from "../../common/ConditionalField"
import WellStatusName from "../../common/WellStatusName"

class EditDetailsConceptional extends Component {
  onFieldUpdate = (elem) => {
    const { onFieldUpdate, data } = this.props
    let { value } = elem.target
    const { checked } = elem.target
    if (elem.target.type === "checkbox") {
      value = !!checked
    }
    if (elem.target.type === "radio") {
      if (value === "null") {
        value = null
      } else {
        value = value === "true"
      }
    }

    onFieldUpdate(elem.target.name, value, data.id)
  }

  render() {
    const { data, failedStatusChange } = this.props

    return (
      <ConditionalFieldBlock scope="well" fields={BuilderUtils.fieldsPrStatus("conceptional")}>
        <div id="conceptional" className={`Details-edit__section${failedStatusChange === "conceptional" ? " validation-failed" : ""}`}>
          <h3>
            <WellStatusName statusKey={"conceptional"} />
          </h3>
          <div className="Details-edit__wrapper">
            {data.well_type && data.well_type !== "exploration" && (
              <div className="Details-edit__part">
                <div className={"Details-edit__checkboxwrapper"}>
                  <ConditionalField scope="well" field="dg1">
                    <InputCheckboxElement
                      header={"Decision gates passed"}
                      value={data.well.dg1}
                      onUpdate={this.onFieldUpdate}
                      name={"well.dg1"}
                      label="DG 1"
                    />
                    <br />
                  </ConditionalField>
                  <ConditionalField scope="well" field="dg2">
                    <InputCheckboxElement
                      header={null}
                      value={data.well.dg2}
                      onUpdate={this.onFieldUpdate}
                      name={"well.dg2"}
                      label="DG 2"
                    />
                    <br />
                  </ConditionalField>
                  <ConditionalField scope="well" field="dg3">
                    <InputCheckboxElement
                      header={null}
                      value={data.well.dg3}
                      onUpdate={this.onFieldUpdate}
                      classes={"required"}
                      name={"well.dg3"}
                      label="DG 3"
                    />
                  </ConditionalField>
                  <div className="required">
                    <span className="radio-input__sublabel">* Required field</span>
                  </div>
                </div>
              </div>
            )}
            {data.well_type === "exploration" && (
              <ConditionalFieldBlock scope="well" fields={["work_order_issues", "decision_to_drill"]}>
                <div className="Details-edit__part">
                  <ConditionalField scope="well" field="work_order_issues">
                    <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                      <label className="radio-input__mainlabel">Work order issued</label>
                      <div className="radio-input__optionwrapper">
                        <label className={`radio-input__option${data.well.work_order_issues ? " selected" : ""}`}>
                          <span className="radio-input__label">Yes</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.work_order_issues"
                            value={true}
                          />
                        </label>
                        <label className={`radio-input__option${data.well.work_order_issues ? "" : " selected"}`}>
                          <span className="radio-input__label">No</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.work_order_issues"
                            value={false}
                          />
                        </label>
                      </div>
                    </div>
                  </ConditionalField>
                  <ConditionalField scope="well" field="decision_to_drill">
                    <div className={"Details-edit__radiowrapper radio-input required radio-input--horizontal"}>
                      <label className="radio-input__mainlabel">
                        Decision to Drill
                        <span className="radio-input__sublabel">* Required</span>
                      </label>
                      <div className="radio-input__optionwrapper">
                        <label className={`radio-input__option${data.well.decision_to_drill ? " selected" : ""}`}>
                          <span className="radio-input__label">Yes</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.decision_to_drill"
                            value={true}
                          />
                        </label>
                        <label className={`radio-input__option${data.well.decision_to_drill ? "" : " selected"}`}>
                          <span className="radio-input__label">No</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.decision_to_drill"
                            value={false}
                          />
                        </label>
                      </div>
                    </div>
                  </ConditionalField>
                </div>
              </ConditionalFieldBlock>
            )}
            <ConditionalFieldBlock scope="well" fields={["lli_ordered", "cpt_ordered", "cpt_conducted"]}>
              <div className="Details-edit__part">
                <ConditionalField scope="well" field="lli_ordered">
                  <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                    <label className="radio-input__mainlabel">
                      LLI ordered
                      <span className="radio-input__sublabel">(Long Lead Items)</span>
                    </label>
                    <div className="radio-input__optionwrapper">
                      <label className={`radio-input__option${data.well.lli_ordered ? " selected" : ""}`}>
                        <span className="radio-input__label">Yes</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.lli_ordered"
                          value={true}
                        />
                      </label>
                      <label className={`radio-input__option${data.well.lli_ordered ? "" : " selected"}`}>
                        <span className="radio-input__label">No</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.lli_ordered"
                          value={false}
                        />
                      </label>
                    </div>
                  </div>
                </ConditionalField>
                <ConditionalField scope="well" field="cpt_ordered">
                  <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                    <label className="radio-input__mainlabel">
                      CPT ordered
                      <span className="radio-input__sublabel">(Cone Penetration Test)</span>
                    </label>
                    <div className="radio-input__optionwrapper">
                      <label className={`radio-input__option${data.well.cpt_ordered ? " selected" : ""}`}>
                        <span className="radio-input__label">Yes</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.cpt_ordered"
                          value={true}
                        />
                      </label>
                      <label className={`radio-input__option${data.well.cpt_ordered === false ? " selected" : ""}`}>
                        <span className="radio-input__label">No</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.cpt_ordered"
                          value={false}
                        />
                      </label>
                      <label className={`radio-input__option${data.well.cpt_ordered === null ? " selected" : ""}`}>
                        <span className="radio-input__label">N/A</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.cpt_ordered"
                          value={"null"}
                        />
                      </label>
                    </div>
                  </div>
                </ConditionalField>
                <ConditionalField scope="well" field="cpt_conducted">
                  <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                    <label className="radio-input__mainlabel">
                      CPT conducted
                      <span className="radio-input__sublabel">(Cone Penetration Test)</span>
                    </label>
                    <div className="radio-input__optionwrapper">
                      <label className={`radio-input__option${data.well.cpt_conducted ? " selected" : ""}`}>
                        <span className="radio-input__label">Yes</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.cpt_conducted"
                          value={true}
                        />
                      </label>
                      <label className={`radio-input__option${data.well.cpt_conducted === false ? " selected" : ""}`}>
                        <span className="radio-input__label">No</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.cpt_conducted"
                          value={false}
                        />
                      </label>
                      <label className={`radio-input__option${data.well.cpt_conducted === null ? " selected" : ""}`}>
                        <span className="radio-input__label">N/A</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.cpt_conducted"
                          value={"null"}
                        />
                      </label>
                    </div>
                  </div>
                </ConditionalField>
              </div>
            </ConditionalFieldBlock>
            <ConditionalFieldBlock scope="well" fields={["site_survey_ordered", "site_survey_conducted"]}>
              <div className="Details-edit__part">
                <ConditionalField scope="well" field="site_survey_ordered">
                  <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                    <label className="radio-input__mainlabel">Site survey ordered</label>
                    <div className="radio-input__optionwrapper">
                      <label className={`radio-input__option${data.well.site_survey_ordered ? " selected" : ""}`}>
                        <span className="radio-input__label">Yes</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.site_survey_ordered"
                          value={true}
                        />
                      </label>
                      <label className={`radio-input__option${data.well.site_survey_ordered ? "" : " selected"}`}>
                        <span className="radio-input__label">No</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.site_survey_ordered"
                          value={false}
                        />
                      </label>
                    </div>
                  </div>
                </ConditionalField>
                <ConditionalField scope="well" field="site_survey_conducted">
                  <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                    <label className="radio-input__mainlabel">Site survey conducted</label>
                    <div className="radio-input__optionwrapper">
                      <label className={`radio-input__option${data.well.site_survey_conducted ? " selected" : ""}`}>
                        <span className="radio-input__label">Yes</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.site_survey_conducted"
                          value={true}
                        />
                      </label>
                      <label className={`radio-input__option${data.well.site_survey_conducted ? "" : " selected"}`}>
                        <span className="radio-input__label">No</span>
                        <span className="check-indicator"></span>
                        <input
                          onChange={this.onFieldUpdate}
                          className="radio-input__radio"
                          type="radio"
                          name="well.site_survey_conducted"
                          value={false}
                        />
                      </label>
                    </div>
                  </div>
                </ConditionalField>
              </div>
            </ConditionalFieldBlock>
          </div>
        </div>
      </ConditionalFieldBlock>
    )
  }
}

EditDetailsConceptional.propTypes = {
  data: PropTypes.object,
  assets: PropTypes.object,
  rigList: PropTypes.array,
  onFieldUpdate: PropTypes.func,
  failedStatusChange: PropTypes.string,
}

export default EditDetailsConceptional
