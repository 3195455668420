import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import eachDayOfInterval from "date-fns/eachDayOfInterval"
import lastDayOfMonth from "date-fns/lastDayOfMonth"
import startOfMonth from "date-fns/startOfMonth"
import getDate from "date-fns/getDate"
import parseISO from "date-fns/parseISO"

import Day from "./Day"

class Month extends Component {
  constructor(props) {
    super(props)

    const {
      data: { month },
    } = props

    const dates = this.getMonthDates()
    const days = Month.populateDays(dates, month)

    this.state = {
      days,
    }
  }

  // TODO: MOVE THIS TO AN EXTERNAL FILE, CalendarUtils for example
  getMonthName() {
    const {
      dayWidth,
      data: { short, longer, full },
    } = this.props
    let showName = short

    if (dayWidth > 3.5) {
      showName = full
    } else if (dayWidth > 1.5) {
      showName = longer
    }
    return showName
  }

  static populateDays(dates, month) {
    // return dates.map((i, k) => Day(getDate(i), `${month}-${k}`));
    return dates.map((i, k) => <Day key={`${month}-${k}`} date={getDate(i)} />)
  }

  getMonthDates() {
    const {
      year,
      data: { month },
      firstDayOfFirstMonth,
      isFirstMonth,
    } = this.props

    const isoFrom = parseISO(`${year}-${month}`)
    const isoTo = parseISO(`${year}-${month}`)

    let from = startOfMonth(isoFrom)
    const to = lastDayOfMonth(isoTo)
    if (isFirstMonth) {
      from = new Date(`${year}/${month}/${firstDayOfFirstMonth}`)
    }

    const days = eachDayOfInterval({
      start: from,
      end: to,
    })
    return days
  }

  shouldComponentUpdate(nextProps) {
    const { dayWidth } = this.props

    if (dayWidth !== nextProps.dayWidth) {
      return true
    }

    return false
  }

  monthStyle() {
    const { dayWidth } = this.props
    const { days } = this.state

    return { width: `${days.length * dayWidth}px` }
  }

  render() {
    const { zoomLevel, currentYear, currentMonth, year, data, dayWidth } = this.props

    const { days } = this.state
    const monthLength = days.length * dayWidth
    const classes = `Month${currentYear === year && data.month === currentMonth ? " current" : ""}`
    const styles = this.monthStyle()
    const name = this.getMonthName()
    return (
      <div className={classes} style={styles}>
        <div className="Month--Title">{name}</div>
        <div className="Month--Days">
          {zoomLevel <= 2 ? <Fragment>{days}</Fragment> : <div className="Month" style={{ width: `${monthLength}px` }}></div>}
        </div>
      </div>
    )
  }
}

Month.propTypes = {
  firstDayOfFirstMonth: PropTypes.number,
  isFirstMonth: PropTypes.bool,
  dayWidth: PropTypes.number,
  zoomLevel: PropTypes.number,
  year: PropTypes.number,
  currentMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentYear: PropTypes.number,
  data: PropTypes.object,
}

export default Month
