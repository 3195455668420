import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"
import InputCheckboxElement from "../../../components/form/InputCheckboxElement"

const requiredFields = ["schedule_version_id"]

class SelectRigsPVersion extends PureComponent {
  onFieldUpdate = (elem, id) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[elem.target.name] = id
    onUpdate(obj, "array")
  }

  validateForm() {
    const { data } = this.props

    let validated = true
    const keys = Object.keys(data)
    validated = requiredFields.every((key) => keys.includes(key))

    return validated
  }

  componentDidMount() {
    const { index, onValidated, data } = this.props
    if (data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props

    if (data !== prevProps.data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }
  }

  render() {
    const { versions, data } = this.props

    return (
      <div className="PanelContent__Container PanelForm">
        <div className="PanelForm__InputPart PanelForm__InputPart--checkbox">
          <label className="PanelForm__inputheading">Which versions?</label>
          {versions.map((val) => (
            <InputCheckboxElement
              key={`schedule_version_id-${val.id}`}
              label={val.type}
              desc=""
              id={val.id}
              name={"schedule_version_id"}
              value={data.schedule_version_id && data.schedule_version_id.includes(val.id)}
              onUpdate={(elm, id) => this.onFieldUpdate(elm, id)}
            />
          ))}
          <span style={{ marginTop: "5px" }} className="PanelForm__inputinfo">
            Select the versions of this schedule the rig should be visible for
          </span>
        </div>
      </div>
    )
  }
}

SelectRigsPVersion.propTypes = {
  onUpdate: PropTypes.func,
  versions: PropTypes.array,
  data: PropTypes.object,
  index: PropTypes.number,
  onValidated: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    versions: state.schedulerData.schedulerData.data.versions,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectRigsPVersion)
