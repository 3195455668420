import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { format } from "date-fns"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"
import Close from "../../../../assets/images/svg/icons/Close.svg"
import IconContainer from "../../../components/icons/IconContainer"

class DecisionSummarySub extends PureComponent {
  closeModal = (e) => {
    const { closePanel } = this.props
    e.stopPropagation()
    e.preventDefault()

    closePanel()
  }

  render() {
    const { timeline } = this.props

    const data =
      timeline && timeline.decisionSummary
        ? timeline.decisionSummary
        : {
            content: "",
            created_by: "",
            created_at: "",
          }

    return (
      <div className="PanelContent__Container desicion-summary">
        <div className="desicion-summary__header">
          <div className="desicion-summary__header--close" onClick={this.closeModal}>
            {IconContainer(Close, 13, 13)}
          </div>
        </div>
        <div className="desicion-summary__content-wrapper">
          <h1 className="FontStyle--Bold">{`Decision summary for ${timeline.schedule ? timeline.schedule.name : ""}`}</h1>
          <div className="desicion-summary__content-wrapper--sender">
            <div className="desicion-summary__content-wrapper--from">{`By ${data.created_by}`}</div>
            <div className="desicion-summary__content-wrapper--date">{`${format(new Date(data.created_at), "dd.MM.yyyy' 'HH:mm")}`}</div>
          </div>
          <div className="desicion-summary__content">{data.content}</div>
        </div>
      </div>
    )
  }
}

DecisionSummarySub.propTypes = {
  closePanel: PropTypes.func,
  timeline: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    timeline: state.timeline,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DecisionSummarySub)
