import PropTypes from "prop-types"
import React, { PureComponent } from "react"
import BuilderUtils from "../../lib/BuilderUtils"
import { ConditionalFieldBlock } from "../common/ConditionalField"
import WellStatusName from "../common/WellStatusName"

class StatusButton extends PureComponent {
  onClick = () => {
    const { clickHandler } = this.props

    clickHandler()
  }

  render() {
    const { selected, id, type, name, clickHandler, isOccupiedSelected } = this.props

    return isOccupiedSelected && type !== "basic_information" ? (
      <span className={`Details-navigation__item Details-navigation__item--disabled`}>
        <span className={"Details-navigation__title"}>{name}</span>
        <span className={"Details-navigation__info"}>(no info required)</span>
      </span>
    ) : (
      <ConditionalFieldBlock
        emptyState={
          <span className={`Details-navigation__item Details-navigation__item--disabled`}>
            <span className={"Details-navigation__title"}>{name}</span>
            <span className={"Details-navigation__info"}>(no info required)</span>
          </span>
        }
        scope="well"
        fields={BuilderUtils.fieldsPrStatus(type)}
      >
        <button
          key={`details-nav-${id}`}
          data-id={type}
          className={`Details-navigation__item ${(!selected && id === 0) || selected === type ? "active" : ""}`}
          onClick={() => clickHandler(type)}
        >
          <span className={"Details-navigation__title"}>
            {type !== "basic_information" ? <WellStatusName statusKey={type} /> : "Basic information"}
          </span>
        </button>
      </ConditionalFieldBlock>
    )
  }
}

StatusButton.propTypes = {
  clickHandler: PropTypes.func,
  selected: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string,
  isOccupiedSelected: PropTypes.bool,
}

export default StatusButton
