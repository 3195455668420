import { isEqual, cloneDeep } from "lodash"

const DomUtils = {
  getTranslateValues: (elm) => {
    const selectedObjectRect = elm.style.transform.replace("3d", "")

    const numbers = selectedObjectRect ? selectedObjectRect.match(/\d+/g).map(Number) : [0, 0, 0]
    const [translateX, translateY, translateZ] = numbers

    return { translateX, translateY, translateZ }
  },

  getDomXValueOnDocument: (elem) => {
    let offsetLeft = 0
    do {
      if (!Number.isNaN(elem.offsetLeft)) {
        offsetLeft += elem.offsetLeft
      }
      elem = elem.offsetParent
    } while (elem)
    return offsetLeft
  },

  deepCheckEntries: (e1, e2) => {
    // CHECK for entries
    const n1 = cloneDeep(e1)
    const n2 = cloneDeep(e2)

    let i = 0
    for (i = 0; i < n1.length; i += 1) {
      delete n1[i].updated_at
      delete n1[i].wells
      delete n1[i].revision
    }
    for (i = 0; i < n2.length; i += 1) {
      delete n2[i].updated_at
      delete n2[i].wells
      delete n2[i].revision
    }

    const ie = isEqual(n1, n2)
    return ie
  },
  isEqual: (e1, e2) => isEqual(e1, e2),
}

export default DomUtils
