import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import Chevron from "../icons/Chevron"
import IC from "../icons/IconContainer"

class RoundedDropDown extends Component {
  constructor(props) {
    super(props)

    this.wrapperRef = React.createRef()

    this.state = {
      open: false,
      direction: "",
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        open: false,
      })
    }
  }

  close = () => {
    this.setState({
      open: false,
    })
  }

  toggleOpen = () => {
    const { direction } = this.props

    this.setState({
      open: !this.state.open,
      direction,
    })
  }

  render() {
    const { data, label, iconLeft, iconRight, renderoption, hasSelectedValue, clearFunction, classNames, passCloseFunction, renderLabel } =
      this.props

    const { open, direction } = this.state

    if (!data.length) {
      return null
    }
    return (
      <div
        ref={this.wrapperRef}
        className={`RoundedDropDown ${classNames || ""} ${open ? "open" : ""} ${direction} ${hasSelectedValue ? "hasValue" : ""}`}
      >
        {clearFunction ? (
          <a onClick={clearFunction} className={`close${hasSelectedValue ? " visible" : ""}`}>
            <span></span>
          </a>
        ) : null}
        <label className={"RoundedDropDown__label"} onClick={() => this.toggleOpen()}>
          {label}
          {Chevron(open ? "open" : "")}
        </label>
        <div className="RoundedDropDown--Elements--Container">
          {passCloseFunction ? data.map((val, key) => renderoption(key, val, this.close)) : data.map((val, key) => renderoption(key, val))}
        </div>
        {iconLeft ? IC(iconLeft) : null}
        {iconRight ? IC(iconRight) : null}
      </div>
    )
  }
}

RoundedDropDown.propTypes = {
  data: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconRight: PropTypes.object,
  hasSelectedValue: PropTypes.bool,
  renderoption: PropTypes.func,
  clearFunction: PropTypes.func,
  iconLeft: PropTypes.object,
  classNames: PropTypes.string,
  passCloseFunction: PropTypes.bool,
  direction: PropTypes.string,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

// TODO: pass down function instead of using connect
export default connect(mapStateToProps, mapDispatchToProps)(RoundedDropDown)
