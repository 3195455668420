import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import * as schedulerData from "./schedulerData"
import * as application from "./application"
import * as calendar from "./calendar"
import * as filters from "./filters"
import * as overviewdata from "./overviewData"
import * as requestData from "./requestData"
import * as observer from "./observer"
import * as routes from "./routes"
import * as user from "./user"
import * as managecontent from "./managecontent"
import * as timeline from "./timeline"

export default (history) =>
  combineReducers(
    Object.assign(
      {},
      { router: connectRouter(history) },
      schedulerData,
      application,
      calendar,
      filters,
      overviewdata,
      requestData,
      observer,
      routes,
      user,
      timeline,
      managecontent
    )
  )
