import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import parseISO from "date-fns/parseISO"

import { ActionCreators } from "../../../../redux/actions"
import CU from "../../../../lib/CalendarUtils"

class RigMove extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.data,
      active: true,
    }
  }

  static getDays(rigStart, calendarStart, duration, dayWidth) {
    const isoFrom = parseISO(rigStart)
    const isoTo = parseISO(calendarStart)

    const posX = differenceInCalendarDays(isoFrom, isoTo) * dayWidth
    return posX
  }

  getStyle() {
    const { estimate, start_date: startDate } = this.state

    const { dayWidth, timespan, filter } = this.props

    const style = {}

    let postX = 0

    style.width = estimate * dayWidth
    postX = RigMove.getDays(startDate, timespan.from, estimate, dayWidth)

    style.transform = `translate3d(${postX}px, 0, 0)`

    if (CU.shouldWellBeFilteredOut(filter, "rig_move")) {
      style.display = "none"
    }

    return style
  }

  render() {
    // TODO: maybe move this out of render function
    const style = this.getStyle()

    return (
      <div style={style} className="Calendar--Entry RigMove">
        Rig move
      </div>
    )
  }
}

RigMove.propTypes = {
  data: PropTypes.object,
  dayWidth: PropTypes.number,
  estimate: PropTypes.number,
  start_date: PropTypes.string,
  timespan: PropTypes.object,
  filter: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    dayWidth: state.calendar.dayWidth,
    timespan: state.calendar.timespan,
    filter: state.filters.filter,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RigMove)
