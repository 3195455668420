import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { set, cloneDeep, truncate } from "lodash"

import { ActionCreators } from "../../../../redux/actions"
import PanelHeader from "../../../../components/panel/PanelHeader"
import PanelHeaderButton from "../../../../components/buttons/PanelHeaderButton"
import PH from "../../../../lib/PanelHelper"
import ErrorDisplay from "../../../../components/builder/ErrorDisplay"
import VALUE_HIDDEN from "../../../../appSettingConsts"
import iqxLogo from "../../../../../assets/images/png/iqx.png"

class EditWellCampaign extends Component {
  constructor(props) {
    super(props)

    const { panelData } = props

    const formattedData = panelData.wells.length > 0 ? panelData : null

    this.inputFileRef = React.createRef()

    this.state = {
      pages: props.pages,
      options: props.options,
      postData: formattedData,
      validated: {},
      saving: false,
      hasChanges: false,
      p1InputObject: null,
      hasErrors: null,
      errors: [],
      selectedBuilderPVersion: "p50",
    }
  }

  componentDidMount() {
    const {
      panelOpen: { index },
      nextStepInPanel,
    } = this.props
    const { postData } = this.state

    if (postData.booking_type && index === 0) {
      nextStepInPanel()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      panelOpen: { index },
    } = this.props
    const { pages, validated } = this.state

    // TODO: if object has prod that it does not need to be validated
    if (index !== prevProps.panelOpen.index) {
      if (!validated[index]) {
        if (pages[index].options.validated) {
          this.setSubPageValidated(index, pages[index].options.validated)
        }
      }
    }

    if (this.state.p1InputObject !== prevState.p1InputObject) {
      this.setContentFromP1()
    }
  }

  onUpdate = (obj) => {
    const { postData } = this.state

    const newData = cloneDeep(postData)
    const newObj = cloneDeep(obj)

    const editKey = Object.keys(newObj)[0]

    if (editKey) {
      const path = editKey.substring(0, editKey.lastIndexOf(".") + 1)
      const variable = editKey.substring(editKey.lastIndexOf(".") + 1, editKey.length)

      if (variable === "dg3") {
        if (newObj[`${path}dg3`]) {
          newObj[`${path}dg1`] = true
          newObj[`${path}dg2`] = true
        }
      } else if (variable === "dg2") {
        if (newObj[`${path}dg2`]) {
          newObj[`${path}dg1`] = true
        } else {
          newObj[`${path}dg3`] = false
        }
      } else if (variable === "dg1") {
        if (!newObj[`${path}dg1`]) {
          newObj[`${path}dg2`] = false
          newObj[`${path}dg3`] = false
        }
      }

      Object.entries(newObj).forEach(([key, val]) => {
        if (
          val ||
          val === null ||
          val === false ||
          val === "" ||
          (key.includes("estimate") && val === 0) ||
          (key.includes("actual_duration") && val === 0)
        ) {
          if (key.includes(".")) {
            set(newData, key, val)
          } else {
            newData[key] = val
          }
          if (key.includes("wells")) {
            const wellsSelector = key.split(".")[0]
            set(newData, `${wellsSelector}.is_dirty`, true)
          }
        } else {
          delete newData[key]
        }
      })

      this.setState({
        postData: cloneDeep(newData),
        hasChanges: true,
      })
    }

    this.setState({
      hasChanges: true,
    })
  }

  setSubPageValidated = (index, isValidated) => {
    const validated = cloneDeep(this.state.validated)
    validated[index] = !isValidated.some((val) => val.valid === false)

    let hasErrors = null
    if (Object.values(validated).includes(false)) {
      hasErrors = validated
    }

    this.setState({
      validated,
      hasErrors,
      errors: isValidated.filter((val) => val.valid === false),
    })
  }

  saveAndClose = () => {
    const { closePanel, saveWellInstance, shakeBuilder, toggleErrorConsoleOpen } = this.props
    const { validated } = this.state

    this.setState({
      saving: true,
    })

    const data = cloneDeep(this.state.postData)

    if (!Object.values(validated).includes(false)) {
      saveWellInstance(data)
      closePanel()
    } else {
      shakeBuilder(validated)
      toggleErrorConsoleOpen(true)
    }
  }

  closeModal = () => {
    const { closePanel } = this.props
    closePanel()
  }

  getPage = (index) =>
    PH.getPage(
      index,
      this.state.pages[index],
      (val) => this.onUpdate(val),
      (i, val) => this.setSubPageValidated(i, val),
      this.state.postData,
      {
        selectedBuilderPVersion: this.state.selectedBuilderPVersion,
      }
    )

  setSelectedPVersion = (pVersion) => {
    this.setState({
      selectedBuilderPVersion: pVersion,
    })
  }

  triggerP1Import = (event) => {
    event.preventDefault()
    this.inputFileRef.current.click()
  }

  importFromP1 = (event) => {
    const file = event.target.files[0]
    let reader = new FileReader()
    const _this = this
    reader.onloadend = function (e) {
      const obj = JSON.parse(reader.result)
      _this.setState({
        p1InputObject: obj,
      })
    }
    reader.readAsText(file)
  }

  p1RigTypeToRigType = (p1Rigtype) => {
    switch (p1Rigtype) {
      case "semi":
        return "semi-sub"
      case "platform":
        return "platform"
      case "jackup":
        return "jack-up"
      case "landRig":
        return "land_rig"
      case "drillship":
        return "drillship"
      default:
        return null
    }
  }
  p1WellTypeToWellType = (p1welltype) => {
    switch (p1welltype) {
      case "exploration":
        return "exploration"
      case "development":
        return "exploration"
      default:
        return null
    }
  }

  setContentFromP1 = () => {
    const { p1InputObject } = this.state
    const {
      blocks: { data: blocks },
    } = this.props

    if (p1InputObject && p1InputObject.Statistics && p1InputObject.Statistics.PhaseTimeSumDays) {
      const useKey = p1InputObject.Statistics.PhaseTimeSumDays.length - 1
      const stats = p1InputObject.Statistics.PhaseTimeSumDays[useKey]
      const model = p1InputObject.Model
      const wellinfo = p1InputObject.Model.WellInformation

      const [p10, p50, p90] = [1, 2, 3].map((i) =>
        Math.round(
          model.ReportSettings[`Percentile${i}UseMean`] ? stats.Mean : stats.Percentiles[model.ReportSettings[`Percentile${i}Value`]]
        )
      )

      const name = wellinfo.WellName
      const type = this.p1WellTypeToWellType((wellinfo.WellType || "").toLowerCase())
      const rigType = this.p1RigTypeToRigType((wellinfo.RigType || "").toLowerCase())
      const waterDepth = wellinfo.WaterDepth
      const blockName = wellinfo.Block
      let blockId = null

      if (blocks && Array.isArray(blocks) && blocks.length > 0) {
        const selectedBlock = blocks.filter((b) => b.name === blockName)
        if (selectedBlock && selectedBlock.length > 0) {
          blockId = selectedBlock[0].id
        }
      }

      this.onUpdate({
        "wells[0].estimateData.p10.estimate": p10,
        "wells[0].estimateData.p50.estimate": p50,
        "wells[0].estimateData.p90.estimate": p90,
        "wells[0].well.name": name,
        "wells[0].well_type": type,
        "wells[0].rig_type": [rigType],
        "wells[0].well.ocean_depth": waterDepth,
        "wells[0].well.block_id": blockId,
      })
    }
  }

  render() {
    const { hasErrors, hasChanges, selectedBuilderPVersion, errors, postData } = this.state
    const { toggleErrorConsoleOpen, errorConsoleOpen, domain } = this.props
    const {
      panelOpen: { index },
    } = this.props

    const headerButtons = []
    if (hasChanges) {
      headerButtons.push(<PanelHeaderButton key={"cancel-edit-button-modal"} value={"Cancel"} handleClick={this.closeModal} />)
      headerButtons.push(<PanelHeaderButton key={"save-and-close-button-modal"} value={"Save and close"} handleClick={this.saveAndClose} />)
    } else {
      headerButtons.push(
        <form key="import-btn" className={"Panel__Header-Button Panel__Header-Button--p1"} method="post">
          <button onClick={this.triggerP1Import}>
            <img src={iqxLogo} alt="IQX logo" />
            Import from P1
          </button>
          <input
            style={{ visibility: "hidden", height: 0, width: 0 }}
            ref={this.inputFileRef}
            type="file"
            name="file"
            onChange={this.importFromP1}
            accept={".json"}
          />
        </form>
      )
      headerButtons.push(<PanelHeaderButton key={"cancel-edit-button-modal"} value={"Cancel"} handleClick={this.closeModal} />)
    }

    const p10 = domain.data && domain.data.scenarios && domain.data.scenarios.p10 ? domain.data.scenarios.p10 : "P10"
    const p50 = domain.data && domain.data.scenarios && domain.data.scenarios.p50 ? domain.data.scenarios.p50 : "P50"
    const p90 = domain.data && domain.data.scenarios && domain.data.scenarios.p90 ? domain.data.scenarios.p90 : "P90".p10
    const PversionSelector = (
      <div className="Panel__Header-Middle Panel-pselector">
        <div className="Panel-pselector__wrapper">
          <a
            className={`Panel-pselector__link${selectedBuilderPVersion === "p10" ? " active" : ""}`}
            onClick={() => this.setSelectedPVersion("p10")}
          >
            {p10}
          </a>
          <a
            className={`Panel-pselector__link${selectedBuilderPVersion === "p50" ? " active" : ""}`}
            onClick={() => this.setSelectedPVersion("p50")}
          >
            {p50}
          </a>
          {p90 !== VALUE_HIDDEN ? (
            <a
              className={`Panel-pselector__link${selectedBuilderPVersion === "p90" ? " active" : ""}`}
              onClick={() => this.setSelectedPVersion("p90")}
            >
              {p90}
            </a>
          ) : null}
        </div>
      </div>
    )

    let heading = `Edit well${postData.wells.length > 1 ? "s" : ""}: `
    heading += postData.wells.map((w) => (w.well && w.well.short_name) || (w.well && w.well.name) || " ").join(",")

    return (
      <>
        <PanelHeader
          forcedHeading={truncate(heading)}
          middleComponent={PversionSelector}
          key={"PanelHeader"}
          buttons={headerButtons}
          classes={"wide noBorder hasMiddleComponent"}
        />
        <div key={"Panel__Page"} className="Panel__Page Panel-Campaign">
          {hasErrors ? (
            <ErrorDisplay openConsole={toggleErrorConsoleOpen} errorConsoleOpen={errorConsoleOpen} errorMessages={errors} />
          ) : null}
          <div className="Panel__Page-Container PanelContent">{this.getPage(index)}</div>
        </div>
      </>
    )
  }
}

EditWellCampaign.propTypes = {
  panelOpen: PropTypes.object,
  panelData: PropTypes.object,
  options: PropTypes.object,
  requestToEdit: PropTypes.object,
  data: PropTypes.object,
  pages: PropTypes.array,
  index: PropTypes.number,
  shakeBuilder: PropTypes.func,
  saveWellInstance: PropTypes.func,
  nextStepInPanel: PropTypes.func,
  setWellInstanceToRemove: PropTypes.func,
  openPanelWithType: PropTypes.func,
  closePanel: PropTypes.func,
  indicateModalFormValidationFailed: PropTypes.func,
  toggleErrorConsoleOpen: PropTypes.func,
  errorConsoleOpen: PropTypes.bool,
  domain: PropTypes.object,
  blocks: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    errorConsoleOpen: state.application.errorConsoleOpen,
    panelOpen: state.application.panelOpen,
    panelData: state.application.panelData,
    requestToEdit: state.requestData.requestToEdit,
    domain: state.application.domain,
    blocks: state.schedulerData.blocks,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWellCampaign)
