import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import Request from "../Toolbar/Request"

class PushDropDown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: props.defaultOpen || false,
      selectedFilter: null,
    }
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  setFilter = (selectedFilter) => {
    this.setState({
      selectedFilter,
    })
  }

  clearFilter = () => {
    this.setState({
      selectedFilter: null,
    })
  }

  filterRequests = (data) => {
    const { selectedFilter } = this.state
    return data.filter((val) => val.request[selectedFilter.filter.key] === selectedFilter.filter.val)
  }

  render() {
    const { data, label, status, emptyMessage } = this.props

    const { open, selectedFilter } = this.state

    const requests = selectedFilter ? this.filterRequests(data) : data

    return (
      <div className={`PushDropDown ${open ? "PushDropDown--open" : ""}`}>
        <div className={"PushDropDown__label FontStyle--Regular"} onClick={() => this.toggleOpen()}>
          <label>{label}</label>
          <svg width="16px" height="8px" viewBox="0 0 16 8" version="1.1">
            <defs></defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
              <g id="Container/Sidebar/Expanded" transform="translate(-204.000000, -148.000000)" stroke="#2E3A41">
                <g id="Icons/Arrow/Black/Up" transform="translate(204.000000, 144.000000)">
                  <polyline
                    id="Path-2-Copy-2"
                    transform="translate(8.071068, 11.071068) rotate(-315.000000) translate(-8.071068, -11.071068) "
                    points="3.07106781 16.0710678 3.07106781 6.07106781 13.0710678 6.07106781"
                  ></polyline>
                </g>
              </g>
            </g>
          </svg>
        </div>
        {data.length === 0 && emptyMessage ? <div className="PushDropDown--Elements--Container--empty">{emptyMessage}</div> : null}

        <div className="PushDropDown--Elements--Container">
          {requests.map((val, key) => (
            <Request previewmode={false} showStatus={true} status={status} key={`toolbar-request-${key}-${val.id}`} request={val} />
          ))}
        </div>
      </div>
    )
  }
}

PushDropDown.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  status: PropTypes.string,
  emptyMessage: PropTypes.string,
  filter: PropTypes.array,
  defaultOpen: PropTypes.bool,
  showCounter: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PushDropDown)
