import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import CU from "../../lib/CalendarUtils"

import { P10, P50, P90 } from "../../redux/types"

class RequestPreview extends Component {
  getEstimate = () => {
    const { request, PVersion } = this.props

    switch (PVersion) {
      case P10:
        return request.wells.reduce((prev, next) => prev + next.estimateData.p10.estimate, 0)
      case P50:
        return request.wells.reduce((prev, next) => prev + next.estimateData.p50.estimate, 0)
      case P90:
        return request.wells.reduce((prev, next) => prev + next.estimateData.p90.estimate, 0)
      default:
        return request.wells.reduce((prev, next) => prev + next.estimateData.p50.estimate, 0)
    }
  }

  getStyle = (request) => ({
    backgroundColor: CU.getWellBookingStatusColor(request.wells.length > 0 ? request.wells[0].status : "conceptional").color,
  })

  render() {
    const { request, toggleToolbarOpenState } = this.props
    const style = this.getStyle(request)

    return (
      <div onClick={() => toggleToolbarOpenState()} className={"Requestlist-Preview__item"} style={style}>
        <div className="Requestlist-Preview__time">{this.getEstimate()}</div>
        <div className="Requestlist-Preview__label">days</div>
      </div>
    )
  }
}

RequestPreview.propTypes = {
  PVersion: PropTypes.object,
  request: PropTypes.object,
  toggleToolbarOpenState: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    PVersion: state.calendar.PVersion,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestPreview)
