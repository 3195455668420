import React, { Component } from "react"
import PropTypes from "prop-types"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import parseISO from "date-fns/parseISO"

import CU from "../../../../lib/CalendarUtils"
import * as types from "../../../../redux/types"

class ContractOption extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.data,
      active: true,
      estimate: props.estimate,
      startDate: props.start_date,
    }
  }

  static getDays(rigStart, calendarStart, duration, dayWidth) {
    const isoFrom = parseISO(rigStart)
    const posX = differenceInCalendarDays(isoFrom, calendarStart) * dayWidth

    return posX
  }

  getStyle() {
    const { estimate, startDate } = this.state

    const { dayWidth, timespan, filter } = this.props

    const style = {}

    let postX = 0

    // console.log()

    style.width = estimate * dayWidth
    postX = ContractOption.getDays(startDate, timespan.from, estimate, dayWidth)

    style.transform = `translate3d(${postX}px, 0, 0)`

    if (CU.shouldWellBeFilteredOut(filter, types.FREE_TIME)) {
      style.display = "none"
    }

    return style
  }

  render() {
    const { estimate } = this.state

    if (parseInt(estimate, 0) === 0) {
      return null
    }

    // TODO: maybe move this out of render function
    const style = this.getStyle()

    return (
      <div style={style} className="Calendar--Entry ContractOption">
        {estimate}
      </div>
    )
  }
}

ContractOption.propTypes = {
  data: PropTypes.object,
  dayWidth: PropTypes.number,
  estimate: PropTypes.number,
  start_date: PropTypes.string,
  timespan: PropTypes.object,
  filter: PropTypes.object,
}

export default ContractOption
