import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"

class MoveObject extends Component {
  constructor() {
    super()

    this.moveObject = React.createRef()
  }

  componentDidUpdate() {
    const { xPos } = this.props

    this.moveObject.current.style.transform = `translate3d(${xPos}px, 0, 0)`
  }

  render() {
    return (
      <div ref={this.moveObject} className="MoveObject">
        {this.props.children}
      </div>
    )
  }
}

MoveObject.propTypes = {
  children: PropTypes.object,
  xPos: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    xPos: state.schedulerData.xPos,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveObject)
