import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"

class NoMatchPage extends Component {
  render() {
    return (
      <div className="generic-page">
        <h1 className="generic-page__heading">Hmm, nothing found here 🤔</h1>
        <h2 className="generic-page__subheading">Something went wrong here, we're so sorry.</h2>
        <a className="generic-page__link" href="/schedule">
          Go to schedule
        </a>
      </div>
    )
  }
}

NoMatchPage.propTypes = {
  views: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    views: state.views,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoMatchPage)
