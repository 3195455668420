import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"

class PanelMenuButton extends PureComponent {
  handleClick = () => {
    const { indexedStepInPanel, panelOpen, index, clickable } = this.props
    const active = index <= panelOpen.index

    if (active || clickable) {
      indexedStepInPanel(index)
    }
  }

  render() {
    const { name, panelOpen, index, activateOnlyCurrent } = this.props

    const activeCheck = activateOnlyCurrent === true ? index === panelOpen.index : index <= panelOpen.index
    const active = activeCheck ? " active" : ""
    const current = index === panelOpen.index ? " current" : ""

    return (
      <div className={`Panel__Menu--Button${active}${current}`} onClick={() => this.handleClick()}>
        {name}
      </div>
    )
  }
}

PanelMenuButton.propTypes = {
  indexedStepInPanel: PropTypes.func,
  panelOpen: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  clickable: PropTypes.bool,
  activateOnlyCurrent: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    panelOpen: state.application.panelOpen,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelMenuButton)
