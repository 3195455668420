/* eslint-disable import/prefer-default-export */
import { ofType } from "redux-observable"
import { map, delay } from "rxjs/operators"
import * as types from "../types"
import { hideObserverError, observerEmpty } from "../actions/observer"

/**
  ATTEMPT LOGIN
*/

export const hideErrorMessagefterDelay = (action$) =>
  action$.pipe(
    ofType(types.OBSERVER_ERROR),
    delay(5000),
    map(() => hideObserverError())
  )

/**
  LOGIN ATTEMPT COMPLETE
*/
export const catchSomeError = (action$) =>
  action$.pipe(
    ofType(types.LOGIN),
    map(() => observerEmpty())
  )
