// PANELS
export const REGISTER_NEW_REQUEST = "panels.REGISTER_NEW_REQUEST"
export const REGISTER_NEW_RIG = "panels.REGISTER_NEW_RIG"
export const EDIT_RIG = "panels.EDIT_RIG"
export const REMOVE_RIG = "panels.REMOVE_RIG"
export const REMOVE_RIG_ERROR = "panels.REMOVE_RIG_ERROR"
export const CONNECT_EXISTING_RIG = "panels.CONNECT_EXISTING_RIG"
export const ADD_BUFFER_TIME_PANEL = "panels.ADD_BUFFER_TIME_PANEL"
export const EDIT_BUFFER_TIME_PANEL = "panels.EDIT_BUFFER_TIME_PANEL"
export const REGISTER_UPDATE_REQUEST = "panels.REGISTER_UPDATE_REQUEST"
export const PANEL_PREVIEW_REQUEST = "panels.PANEL_PREVIEW_REQUEST"
export const SHOULD_YOU_REMOVE_REQUEST = "panels.SHOULD_YOU_REMOVE_REQUEST"
export const REGISTER_NO_CHANGES = "panels.REGISTER_NO_CHANGES"
export const EDIT_WELL_INSTANCE = "panels.EDIT_WELL_INSTANCE"
export const EDIT_WELL_CAMPAIGN = "panels.EDIT_WELL_CAMPAIGN"
export const CONFIRM_WELL_INSTANCE_REMOVE = "panels.CONFIRM_WELL_INSTANCE_REMOVE"
export const CONFIRM_SCHEDULER_SHARE = "panels.CONFIRM_SCHEDULER_SHARE"
export const CONFIRM_SET_AS_MASTER = "panels.CONFIRM_SET_AS_MASTER"
export const CONFIRM_REMOVE_REQUEST = "panels.CONFIRM_REMOVE_REQUEST"
export const CONFIRM_BUFFER_TIME_INSTANCE_REMOVE = "panels.CONFIRM_BUFFER_TIME_INSTANCE_REMOVE"
export const CONFIRM_BUFFER_TIME_INSTANCE_REMOVE_SUBPAGE = "panels.CONFIRM_BUFFER_TIME_INSTANCE_REMOVE_SUBPAGE"
export const DECISION_SUMMARY = "panels.DECISION_SUMMARY"

// PANELS SUB-PAGES
export const INPUT_WELL_TYPE_SELECION = "panels.subpage.INPUT_WELL_TYPE_SELECION"
export const INPUT_WELL_INFO = "panels.subpage.INPUT_WELL_INFO"
export const INPUT_WELL_RIG_INFO = "panels.subpage.INPUT_WELL_RIG_INFO"
export const INPUT_RIG_INFO = "panels.subpage.INPUT_RIG_INFO"
export const INPUT_REQUEST_RIG_INFO = "panels.subpage.INPUT_REQUEST_RIG_INFO"
export const INPUT_RIG_SPECS = "panels.subpage.INPUT_RIG_SPECS"
export const INPUT_TIMEFRAME = "panels.subpage.INPUT_TIMEFRAME"
export const INPUT_TIME_ESTIMATE = "panels.subpage.INPUT_TIME_ESTIMATE"
export const INPUT_COMMENT = "panels.subpage.INPUT_COMMENT"
export const SELECT_RIGS_P_VERSION = "panels.subpage.SELECT_RIGS_P_VERSION"
export const SELECT_RIGS_REMOVE_P_VERSION = "panels.subpage.SELECT_RIGS_REMOVE_P_VERSION"
export const SELECT_RIGS_REMOVE_ERROR = "panels.subpage.SELECT_RIGS_REMOVE_ERROR"
export const ADD_BUFFER_TIME = "panels.subpage.ADD_BUFFER_TIME"
export const INPUT_REQUEST_LIST = "panels.subpage.INPUT_REQUEST_LIST"
export const INPUT_REMOVE_REQUEST = "panels.subpage.INPUT_REMOVE_REQUEST"
export const INPUT_SELECT_WELL = "panels.subpage.INPUT_SELECT_WELL"
export const CONFIRM_SCHEDULER_SHARE_SUBPAGE = "panels.subpage.CONFIRM_SCHEDULER_SHARE_SUBPAGE"
export const CONFIRM_SCHEDULER_SHARE_UPDATE_SUBPAGE = "panels.subpage.CONFIRM_SCHEDULER_SHARE_UPDATE_SUBPAGE"
export const INPUT_WELL_INSTANCE_INFO = "panels.subpage.INPUT_WELL_INSTANCE_INFO"
export const EDIT_WELL_CAMPAIGN_INFO = "panels.subpage.EDIT_WELL_CAMPAIGN_INFO"
export const CONFIRM_WELL_INSTANCE_REMOVE_SUBPAGE = "panels.subpage.CONFIRM_WELL_INSTANCE_REMOVE_SUBPAGE"
export const CONFIRM_SET_AS_MASTER_SUBPAGE = "panels.subpage.CONFIRM_SET_AS_MASTER_SUBPAGE"
export const CONFIRM_REMOVE_REQUEST_SUBPAGE = "panels.subpage.CONFIRM_REMOVE_REQUEST_SUBPAGE"
export const SELECT_EXISTING_RIG = "panels.subpage.SELECT_EXISTING_RIG"
export const WRITE_DESICION_SUMMARY = "panels.subpage.WRITE_DESICION_SUMMARY"
export const DECISION_SUMMARY_SUB = "panels.subpage.DECISION_SUMMARY_SUB"

export const INPUT_PREVIEW_REQUEST = "panels.INPUT_PREVIEW_REQUEST"

// PANELS buttons
export const CANCEL = "Cancel"
export const NEXT = "Next"
export const SUBMIT = "Submit"
export const SHARE = "Share"
export const UPDATE = "Update"
export const ACCEPT_AND_ADD = "Accept & add"
export const CLOSE = "Close"
export const NO = "No"
export const YES = "Yes"
