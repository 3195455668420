/* eslint-disable import/prefer-default-export */
import * as Observer from "./observer"
import * as Data from "./schedulerData"
import * as Application from "./application"
import * as Calendar from "./calendar"
import * as Filters from "./filters"
import * as OverviewData from "./overviewData"
import * as RequestData from "./requests"
import * as User from "./user"
import * as ManageContent from "./managecontent"
import * as Timeline from "./timeline"

export const ActionCreators = Object.assign(
  {},
  Observer,
  Data,
  Application,
  Calendar,
  Filters,
  OverviewData,
  RequestData,
  User,
  ManageContent,
  Timeline
)
