import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { find } from "lodash"
import { ActionCreators } from "../../../redux/actions"
import InputCheckboxElement from "../../../components/form/InputCheckboxElement"
import PanelInputSelection from "../../../components/panel/PanelInputSelection"

import { CONTRACT, RIG_TYPE, JACK_UP, SEMI_SUBMERSIBLE, PLATFORM } from "../../../registerConsts"

const rigs = [
  {
    id: 0,
    name: "Jack-up",
    rig_name: JACK_UP,
    type: "checkbox",
  },
  {
    id: 1,
    name: "Semi-submersible",
    rig_name: SEMI_SUBMERSIBLE,
    type: "checkbox",
  },
  {
    id: 2,
    name: "Platform",
    rig_name: PLATFORM,
    type: "checkbox",
  },
]

class InputRequestRigInfo extends Component {
  constructor(props) {
    super(props)

    const { rigList, fetchRigList } = this.props

    if (!rigList) {
      fetchRigList()
    }
  }

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[elem.target.name] = elem.target.value

    onUpdate(obj)
  }

  updateRigType = (elem) => {
    const { onUpdate, data } = this.props
    const obj = {}

    let rigTypes = data.rig_type ? Array.from(data.rig_type) : []

    if (rigTypes.includes(elem.target.name)) {
      rigTypes = rigTypes.filter((e) => e !== elem.target.name)
    } else {
      rigTypes.push(elem.target.name)
    }

    obj[RIG_TYPE] = rigTypes

    onUpdate(obj)
  }

  handleContractChange = (contract) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[CONTRACT] = contract === null ? null : contract.id

    onUpdate(obj)
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props

    if (data !== prevProps.data) {
      const validated = this.checkValidation(data)
      onValidated(index, validated)
    }
  }

  componentDidMount() {
    const { index, onValidated, data } = this.props
    const validated = this.checkValidation(data)
    onValidated(index, validated)
  }

  checkValidation = () => true

  // TODO: WHY IS THIS TURNED OFF?
  // let validated = true;
  // const keys = Object.keys(data);
  //
  // validated = (
  //   validationKeys.match.every(key => keys.includes(key))
  //   && validationKeys.checkLength.every(key => (data[key] && data[key].length))
  // );
  //
  // return validated;

  render() {
    const {
      rigList,
      data: { rig_type: rigType, has_contract_with: hasContractWidth },
    } = this.props

    const check = rigType || []

    if (!rigList) {
      return null
    }

    return (
      <div className="PanelContent__Container PanelForm">
        <div className="PanelForm__Header">Type of rig</div>
        <div className="PanelForm__InputPart PanelForm__InputPart--checkbox">
          <label className="PanelForm__inputheading">Select all requirements of the well</label>
          {rigs.map((val) => (
            <InputCheckboxElement
              key={`req-${val.id}`}
              label={val.rig_name}
              desc=""
              name={val.rig_name}
              value={check.includes(val.rig_name)}
              onUpdate={this.updateRigType}
              styles={{ marginBottom: 5 }}
            />
          ))}
        </div>

        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputheading">Well has a signed contract with</label>
          <PanelInputSelection
            value={find(rigList, { id: hasContractWidth })}
            options={rigList}
            isClearable={true}
            placeholder="Select rig contracts"
            onChange={this.handleContractChange}
          />

          {/* <Select
            options={rigList}
            closeMenuOnSelect={true}
            isClearable={true}
            placeholder='Select rig contracts'
            className='Select__Dropdown'
            getOptionLabel={option => option.name}
            value={find(rigList, { id: hasContractWidth })}
            classNamePrefix='Select__Dropdown__Elm'
            onChange={ this.handleContractChange }
          /> */}
        </div>
      </div>
    )
  }
}

InputRequestRigInfo.propTypes = {
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  onValidated: PropTypes.func,
  rigList: PropTypes.array,
  fetchRigList: PropTypes.func,
  index: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    rigList: state.schedulerData.rigList,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputRequestRigInfo)
