import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { differenceInDays } from "date-fns"
import { ActionCreators } from "../../redux/actions"
import CU from "../../lib/CalendarUtils"

class PlacementIndicator extends Component {
  constructor() {
    super()

    this.pi = React.createRef()

    this.state = {
      active: false,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.xPos !== this.props.xPos) {
      return true
    }

    if (nextState.acitve !== this.state.acitve) {
      return true
    }

    return false
  }

  componentDidUpdate() {
    // this.pi.current.style.transform = `translate3d(0, ${-10}px, 0)`;
    this.checkPlacement()
  }

  checkPlacement = () => {
    const { timespan, dayFromStartOfCalendar, endDayFromStartOfCalendar, xPos, dayWidth } = this.props

    let active = false

    const mouseDate = CU.getNewDate(timespan.from, xPos, dayWidth)
    const mouseDays = differenceInDays(mouseDate, timespan.from)

    if (mouseDays > dayFromStartOfCalendar && mouseDays < endDayFromStartOfCalendar) {
      active = true
    }

    if (this.state.active !== active) {
      this.setState({
        active,
      })
    }
  }

  render() {
    const { sStyle } = this.props
    const { active } = this.state

    const activeClass = active ? "active" : null

    return <span ref={this.pi} className={`Placement__Indicator InBetweenSpace ${activeClass}`} style={sStyle} />
  }
}

PlacementIndicator.propTypes = {
  timespan: PropTypes.object,
  sStyle: PropTypes.object,
  dayFromStartOfCalendar: PropTypes.number,
  endDayFromStartOfCalendar: PropTypes.number,
  dayWidth: PropTypes.number,
  xPos: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    timespan: state.calendar.timespan,
    xPos: state.schedulerData.xPos,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlacementIndicator)
