import React from "react"

const Master = (style, key) => (
  <svg
    key={key}
    style={style}
    width="19px"
    height="22px"
    viewBox="0 0 19 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs></defs>
    <g id="Modules-+-Flows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Overview-draft-and-master-schedule-module" transform="translate(-1516.000000, -919.000000)" fill="#1C2226">
        <g id="Icons/Sidebar/Master" transform="translate(1513.000000, 918.000000)">
          <path
            d="M4.1875,18.7893446 L20.8125,18.7893446 L20.8125,7.00451725 L17.3147187,9.74718779 C17.1799375,9.85251215 17.0047812,9.89609465 16.8409062,9.86159184 C16.6734687,9.82890497 16.5285937,9.72418592 16.4419062,9.57527907 L12.5,2.77459411 L8.55809375,9.57527907 C8.47140625,9.72418592 8.32653125,9.82890497 8.15909375,9.86159184 C7.994625,9.89609465 7.81946875,9.85251215 7.68528125,9.74718779 L4.1875,7.00451725 L4.1875,18.7893446 Z M21.40625,19.9999694 L3.59375,19.9999694 C3.26540625,19.9999694 3,19.7293948 3,19.394657 L3,5.77512774 C3,5.54450371 3.12884375,5.33385499 3.33190625,5.23216251 C3.534375,5.13107533 3.77721875,5.15468252 3.95534375,5.295115 L7.8770625,8.37070737 L11.9887813,1.27644589 C12.203125,0.90781063 12.796875,0.90781063 13.0112188,1.27644589 L17.1229375,8.37070737 L21.0446563,5.295115 C21.223375,5.15528783 21.4668125,5.13168065 21.6680938,5.23216251 C21.8711563,5.33385499 22,5.54450371 22,5.77512774 L22,19.394657 C22,19.7293948 21.7345938,19.9999694 21.40625,19.9999694 Z"
            id="Fill-1"
          ></path>
          <path
            d="M21.40625,22.6264278 L3.59375,22.6264278 C3.26540625,22.6264278 3,22.3532611 3,22.0153167 C3,21.6773722 3.26540625,21.4042056 3.59375,21.4042056 L21.40625,21.4042056 C21.7345938,21.4042056 22,21.6773722 22,22.0153167 C22,22.3532611 21.7345938,22.6264278 21.40625,22.6264278"
            id="Fill-3"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default Master
