import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import getDate from "date-fns/getDate"
import differenceInDays from "date-fns/differenceInDays"
import isSameDay from "date-fns/isSameDay"
import DH from "../../lib/DomUtils"

const TimelineDay = (props) => {
  const ref = React.createRef()
  const [active, setClass] = useState("")

  useEffect(() => {
    const itd = differenceInDays(props.date, props.currentDate)
    const isd = isSameDay(props.date, props.currentDate)

    setClass(() => (props.eventOnDate ? "active" : ""))

    if (itd === 0 && isd === true) {
      const xPos = DH.getDomXValueOnDocument(ref.current) + ref.current.offsetWidth / 2
      const percent = (xPos / window.innerWidth) * 100
      props.setGradientWidth(percent)
    }
  }, [ref, props.date, props.currentDate, props.setGradientWidth, props.eventOnDate, props])

  return (
    <div ref={ref} className={`timeline__calendar-date ${active}`}>
      {getDate(props.date)}
    </div>
  )
}

TimelineDay.propTypes = {
  date: PropTypes.object,
  currentDate: PropTypes.object,
  setGradientWidth: PropTypes.func,
  eventOnDate: PropTypes.object,
}

export default TimelineDay
