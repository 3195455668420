import React, { useEffect } from "react"
import PropTypes from "prop-types"

const ChangeMinor = (props) => {
  useEffect(() => {}, [props.data, props])

  return (
    <div className={`timeline__calendar-change--minor calendar-change   ${props.data.length ? "hasEvents" : ""}`}>
      <div className="calendar-change__number">{props.data.length}</div>
      <div className="calendar-change__title">{"Minor"}</div>
    </div>
  )
}

ChangeMinor.propTypes = {
  data: PropTypes.array,
}

export default ChangeMinor
