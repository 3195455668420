import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { groupBy } from "lodash"
import ChangeDescIcon from "./ChangeDescIcon"
import Plus from "../../../assets/images/svg/icons/pluss.svg"
import Move from "../../../assets/images/svg/icons/Move.svg"
import Remove from "../../../assets/images/svg/icons/Remove.svg"

const ChangeMajor = (props) => {
  useEffect(() => {}, [props.data, props])

  const { moved, added, removed } = groupBy(props.data, "type")

  return (
    <div className={`timeline__calendar-change--major calendar-change  ${props.data.length ? "hasEvents" : ""}`}>
      <div className="timeline__calendar-change__header-container">
        <div className="timeline__calendar-change--number">{props.data.length}</div>
        <div className="timeline__calendar-change--title">{"Major"}</div>
      </div>
      <div className="timeline__calendar-change__icons-container">
        {moved ? <ChangeDescIcon name="moved" data={moved} Icon={Move} IconWidth={12} IconHeight={13} /> : null}
        {added ? <ChangeDescIcon name="added" data={added} Icon={Plus} IconWidth={13} IconHeight={13} /> : null}
        {removed ? <ChangeDescIcon name="removed" data={removed} Icon={Remove} IconWidth={13} IconHeight={14} /> : null}
      </div>
    </div>
  )
}

ChangeMajor.propTypes = {
  data: PropTypes.array,
}

export default ChangeMajor
