import PropTypes from "prop-types"
import React, { PureComponent } from "react"
import CU from "../../../../lib/CalendarUtils"
import WellBooking from "./WellBooking"

class WellBookingSequence extends PureComponent {
  getRigmoveStyle = (rigMove) => {
    const { dayWidth, data, darkMode } = this.props
    const style = {}
    const bookingStatusColor = CU.getWellBookingStatusColor(data.wells[0].status)

    style.backgroundColor = !darkMode ? bookingStatusColor.color : bookingStatusColor.darkmodeColor
    style.borderColor = bookingStatusColor.borderColor

    style.width = rigMove.estimate * dayWidth + 6
    style.left = 0 - rigMove.estimate * dayWidth - 3
    return style
  }

  render() {
    const { data, dayWidth, zoomLevel, timespan, filter, darkMode, PVersion, additionalScopeWells } = this.props

    let rigMoveDom = null
    if (data.rig_move) {
      const rigMovestyle = this.getRigmoveStyle(data.rig_move)
      rigMoveDom = (
        <div key={`rigmove-${data.id}`} style={rigMovestyle} className="rigmove">
          {zoomLevel <= 12 ? data.rig_move.estimate : "\u00a0"}
        </div>
      )
    }

    return (
      <>
        {data.wells.map((well, k) => (
          <WellBooking
            key={`${well.id}-single-well`}
            id={well.id}
            hasRigMove={!!data.rig_move}
            rigMove={data.rig_move}
            isSequence={data.wells.length > 1}
            islastSequence={data.wells.length === k + 1}
            well={well}
            darkMode={darkMode}
            dayWidth={dayWidth}
            zoomLevel={zoomLevel}
            timespan={timespan}
            filter={filter}
            PVersion={PVersion}
            additionalScopeWells={additionalScopeWells}
          />
        ))}
        {rigMoveDom}
      </>
    )
  }
}

WellBookingSequence.propTypes = {
  data: PropTypes.object,
  setScrollObjectPosition: PropTypes.func,
  openWellPopover: PropTypes.func,
  dayWidth: PropTypes.number,
  zoomLevel: PropTypes.number,
  filter: PropTypes.object,
  PVersion: PropTypes.object,
  timespan: PropTypes.object,
  darkMode: PropTypes.bool,
  overridenPValueWells: PropTypes.object,
}

export default WellBookingSequence
