import PropTypes from "prop-types"
import React, { Component } from "react"

class EditAdditionalScope extends Component {
  onFieldUpdate = (elem) => {
    const { onFieldUpdate, data } = this.props
    let { value } = elem.target
    onFieldUpdate(elem.target.name, Number(value), data.id)
  }

  render() {
    const { data, failedStatusChange } = this.props
    return (
      <div
        id="additional_scope"
        className={`Details-edit__section${failedStatusChange === "plan_for_execution" ? " validation-failed" : ""}`}
      >
        <h3>Additional scope</h3>
        <div className="Details-edit__wrapper">
          <div className="Details-edit__part">
            <div className={`Details-edit__inputwrapper${data.additional_scope_p10 ? " has-content" : ""}`}>
              <label className="Details-edit__inputlabel">Additional scope P10</label>
              <input
                onChange={this.onFieldUpdate}
                className={"Details-edit__input"}
                value={data.additional_scope_p10 || ""}
                type="number"
                name="additional_scope_p10"
                placeholder=""
              />
            </div>
            <div className={`Details-edit__inputwrapper${data.additional_scope_p50 ? " has-content" : ""}`}>
              <label className="Details-edit__inputlabel">Additional scope P50</label>
              <input
                onChange={this.onFieldUpdate}
                className={"Details-edit__input"}
                value={data.additional_scope_p50 || ""}
                type="number"
                name="additional_scope_p50"
                placeholder=""
              />
            </div>
            <div className={`Details-edit__inputwrapper${data.additional_scope_p90 ? " has-content" : ""}`}>
              <label className="Details-edit__inputlabel">Additional scope P90</label>
              <input
                onChange={this.onFieldUpdate}
                className={"Details-edit__input"}
                value={data.additional_scope_p90 || ""}
                type="number"
                name="additional_scope_p90"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditAdditionalScope.propTypes = {
  data: PropTypes.object,
  onFieldUpdate: PropTypes.func,
  failedStatusChange: PropTypes.string,
}

export default EditAdditionalScope
