import React, { Component } from "react"
import PropTypes from "prop-types"
import StatusButton from "../../buttons/StatusButton"

class StatusSelector extends Component {
  render() {
    const { navArr, selected, showExpanded, clickHandler, isOccupiedSelected } = this.props

    return (
      <div className={`Details__navigation Details-navigation${showExpanded ? "" : " hidden"}`}>
        {navArr.map((e, key) => (
          <StatusButton
            id={key}
            key={key}
            type={e.type}
            isOccupiedSelected={isOccupiedSelected}
            selected={selected}
            name={e.name}
            clickHandler={(type) => clickHandler(type)}
          />
        ))}
      </div>
    )
  }
}

StatusSelector.propTypes = {
  selected: PropTypes.string,
  navArr: PropTypes.array,
  showExpanded: PropTypes.bool,
  isOccupiedSelected: PropTypes.bool,
  clickHandler: PropTypes.func,
}

export default StatusSelector
