import React, { Component } from "react"
import PropTypes from "prop-types"
import NumberFormat from "react-number-format"

class EditAfeAndCo2Cost extends Component {
  onFieldUpdate = (elem) => {
    const { onFieldUpdate, data } = this.props
    let { value } = elem.target
    const { checked } = elem.target
    if (elem.target.type === "checkbox") {
      value = !!checked
    }
    if (elem.target.type === "radio") {
      value = value === "true"
    }
    onFieldUpdate(elem.target.name, value, data.id)
  }

  onNumberFieldUpdate = (values, sourceInfo) => {
    const { onFieldUpdate, data } = this.props
    onFieldUpdate(sourceInfo.event.target.name, values.value, data.id)
  }

  render() {
    const { data, failedStatusChange, scenarios, units, currency } = this.props

    const p10Label = scenarios && scenarios.p10 ? scenarios.p10 : "P10"
    const p50Label = scenarios && scenarios.p50 ? scenarios.p50 : "P50"
    const p90Label = scenarios && scenarios.p90 ? scenarios.p90 : "P90"

    // const numOfDays = data.actual_duration && data.actual_duration > 0 ? data.actual_duration : data.estimateData.p50.estimate

    return (
      <div
        id="afe_and_co2_cost"
        className={`Details-edit__section${failedStatusChange === "plan_for_execution" ? " validation-failed" : ""}`}
      >
        <h3>Planned cost and emissions</h3>
        <div className="Details-edit__wrapper">
          <div style={{ maxWidth: "none" }} className="Details-edit__part">
            <div className="Details-edit__horiz">
              <h4>Planned total cost</h4>
              <div className="Details-edit__horiz-inner">
                <div
                  className={`Details-edit__inputwrapper Details-edit__inputwrapper--no-bottom-margin${
                    data.planned_cost_p10 ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">{p10Label}</label>
                  <NumberFormat
                    name="planned_cost_p10"
                    value={data.planned_cost_p10 || ""}
                    className={"Details-edit__input"}
                    onValueChange={this.onNumberFieldUpdate}
                    thousandSeparator={true}
                  />
                </div>
                <div
                  className={`Details-edit__inputwrapper Details-edit__inputwrapper--no-bottom-margin${
                    data.planned_cost_p50 ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">{p50Label}</label>
                  <NumberFormat
                    name="planned_cost_p50"
                    value={data.planned_cost_p50 || ""}
                    className={"Details-edit__input"}
                    onValueChange={this.onNumberFieldUpdate}
                    thousandSeparator={true}
                  />
                </div>
                <div
                  className={`Details-edit__inputwrapper Details-edit__inputwrapper--no-bottom-margin${
                    data.planned_cost_p90 ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">{p90Label}</label>
                  <NumberFormat
                    name="planned_cost_p90"
                    value={data.planned_cost_p90 || ""}
                    className={"Details-edit__input"}
                    onValueChange={this.onNumberFieldUpdate}
                    thousandSeparator={true}
                  />
                </div>
              </div>
              <div className="Details-edit__horiz-inner">
                <div className={`Details-edit__info-section`}>
                  <span>
                    {data.planned_cost_p10 && data.estimateData.p10.estimate ? (
                      <NumberFormat
                        value={(data.planned_cost_p10 / data.estimateData.p10.estimate).toFixed(2) || ""}
                        thousandSeparator={true}
                        displayType="text"
                      />
                    ) : (
                      " — "
                    )}{" "}
                    {currency}
                  </span>
                  <span>Pr. day</span>
                </div>
                <div className={`Details-edit__info-section`}>
                  <span>
                    {data.planned_cost_p50 && data.estimateData.p50.estimate ? (
                      <NumberFormat
                        value={(data.planned_cost_p50 / data.estimateData.p50.estimate).toFixed(2) || ""}
                        thousandSeparator={true}
                        displayType="text"
                      />
                    ) : (
                      " — "
                    )}{" "}
                    {currency}
                  </span>
                  <span>Pr. day</span>
                </div>
                <div className={`Details-edit__info-section`}>
                  <span>
                    {data.planned_cost_p90 && data.estimateData.p90.estimate ? (
                      <NumberFormat
                        value={(data.planned_cost_p90 / data.estimateData.p90.estimate).toFixed(2) || ""}
                        thousandSeparator={true}
                        displayType="text"
                      />
                    ) : (
                      " — "
                    )}{" "}
                    {currency}
                  </span>
                  <span>Pr. day</span>
                </div>
              </div>
            </div>
            <div className="Details-edit__horiz">
              <h4>Planned total Co2 emissions</h4>
              <div className="Details-edit__horiz-inner">
                <div
                  className={`Details-edit__inputwrapper Details-edit__inputwrapper--no-bottom-margin${
                    data.planned_co2_p10 ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">{p10Label}</label>
                  <NumberFormat
                    name="planned_co2_p10"
                    value={data.planned_co2_p10 || ""}
                    className={"Details-edit__input"}
                    onValueChange={this.onNumberFieldUpdate}
                    thousandSeparator={true}
                  />
                </div>
                <div
                  className={`Details-edit__inputwrapper Details-edit__inputwrapper--no-bottom-margin${
                    data.planned_co2_p50 ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">{p50Label}</label>
                  <NumberFormat
                    name="planned_co2_p50"
                    value={data.planned_co2_p50 || ""}
                    className={"Details-edit__input"}
                    onValueChange={this.onNumberFieldUpdate}
                    thousandSeparator={true}
                  />
                </div>
                <div
                  className={`Details-edit__inputwrapper Details-edit__inputwrapper--no-bottom-margin${
                    data.planned_co2_p90 ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">{p90Label}</label>
                  <NumberFormat
                    name="planned_co2_p90"
                    value={data.planned_co2_p90 || ""}
                    className={"Details-edit__input"}
                    onValueChange={this.onNumberFieldUpdate}
                    thousandSeparator={true}
                  />
                </div>
              </div>
              <div className="Details-edit__horiz-inner">
                <div className={`Details-edit__info-section`}>
                  <span>
                    {data.planned_co2_p10 && data.estimateData.p10.estimate ? (
                      <NumberFormat
                        value={(data.planned_co2_p10 / data.estimateData.p10.estimate).toFixed(2) || ""}
                        thousandSeparator={true}
                        displayType="text"
                      />
                    ) : (
                      " — "
                    )}{" "}
                    {units === "imperial" ? "lbs" : "kg"}
                  </span>
                  <span>Pr. day</span>
                </div>
                <div className={`Details-edit__info-section`}>
                  <span>
                    {data.planned_co2_p50 && data.estimateData.p50.estimate ? (
                      <NumberFormat
                        value={(data.planned_co2_p50 / data.estimateData.p50.estimate).toFixed(2) || ""}
                        thousandSeparator={true}
                        displayType="text"
                      />
                    ) : (
                      " — "
                    )}{" "}
                    {units === "imperial" ? "lbs" : "kg"}
                  </span>
                  <span>Pr. day</span>
                </div>
                <div className={`Details-edit__info-section`}>
                  <span>
                    {data.planned_co2_p90 && data.estimateData.p90.estimate ? (
                      <NumberFormat
                        value={(data.planned_co2_p90 / data.estimateData.p90.estimate).toFixed(2) || ""}
                        thousandSeparator={true}
                        displayType="text"
                      />
                    ) : (
                      " — "
                    )}{" "}
                    {units === "imperial" ? "lbs" : "kg"}
                  </span>
                  <span>Pr. day</span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: "none" }} className="Details-edit__part">
            <div className="Details-edit__horiz">
              <h4>Actual total cost</h4>
              <div className="Details-edit__horiz-inner">
                <div
                  className={`Details-edit__inputwrapper Details-edit__inputwrapper--no-bottom-margin${
                    data.actual_cost ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">Total actual cost</label>
                  <NumberFormat
                    name="actual_cost"
                    value={data.actual_cost || ""}
                    className={"Details-edit__input"}
                    onValueChange={this.onNumberFieldUpdate}
                    thousandSeparator={true}
                  />
                </div>
              </div>
              <div className="Details-edit__horiz-inner">
                <div className={`Details-edit__info-section`}>
                  <span>
                    {data.actual_cost && data.actual_duration ? (
                      <NumberFormat
                        value={(data.actual_cost / data.actual_duration).toFixed(2) || ""}
                        thousandSeparator={true}
                        displayType="text"
                        suffix={` ${currency}`}
                      />
                    ) : (
                      " — "
                    )}
                  </span>
                  {data.actual_cost && data.actual_duration && <span>Pr. day</span>}
                  {!data.actual_duration && <span>Actual duration missing</span>}
                </div>
              </div>
            </div>
            <div className="Details-edit__horiz">
              <h4>Actual total Co2 emissions</h4>
              <div className="Details-edit__horiz-inner">
                <div
                  className={`Details-edit__inputwrapper Details-edit__inputwrapper--no-bottom-margin${
                    data.actual_co2 ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">Total actual Co2</label>
                  <NumberFormat
                    name="actual_co2"
                    value={data.actual_co2 || ""}
                    className={"Details-edit__input"}
                    onValueChange={this.onNumberFieldUpdate}
                    thousandSeparator={true}
                  />
                </div>
              </div>
              <div className="Details-edit__horiz-inner">
                <div className={`Details-edit__info-section`}>
                  <span>
                    {data.actual_co2 && data.actual_duration ? (
                      <NumberFormat
                        value={(data.actual_co2 / data.actual_duration).toFixed(2) || ""}
                        thousandSeparator={true}
                        displayType="text"
                        suffix={units === "imperial" ? " lbs" : " kg"}
                      />
                    ) : (
                      " — "
                    )}{" "}
                  </span>
                  {data.actual_co2 && data.actual_duration && <span>Pr. day</span>}
                  {!data.actual_duration && <span>Actual duration missing</span>}
                </div>
              </div>
            </div>
            {/* {data.actual_duration && (
              <div className="Details-edit__horiz">
                <h4>Total actual cost pr. day: {data.actual_cost ? (data.actual_cost / data.actual_duration).toFixed(3) : " — "}</h4>
                <h4>Total actual co2 emission pr. day: {data.actual_co2 ? (data.actual_co2 / data.actual_duration).toFixed(3) : " — "}</h4>
              </div>
            )} */}
          </div>
        </div>
      </div>
    )
  }
}

EditAfeAndCo2Cost.propTypes = {
  data: PropTypes.object,
  domain: PropTypes.object,
  onFieldUpdate: PropTypes.func,
  failedStatusChange: PropTypes.string,
  scenarios: PropTypes.object,
  units: PropTypes.string,
  currency: PropTypes.string,
}

export default EditAfeAndCo2Cost
