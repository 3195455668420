import React from "react"
import PropTypes from "prop-types"
import CU from "../../lib/CalendarUtils"

const getChange = (change, key, domain) => {
  let string

  const from = typeof change.from !== "undefined" || typeof change.from !== "boolean" ? String(change.from).replace(/_/g, " ") : change.from
  const CFrom = from && typeof from === "string" ? from.charAt(0).toUpperCase() + from.slice(1) : from
  const to = typeof change.to !== "undefined" ? String(change.to).replace(/_/g, " ") : change.to
  const CTo = to && typeof to === "string" ? to.charAt(0).toUpperCase() + to.slice(1) : to
  const status = String(change.key).replace(/_/g, " ")
  let CStatus = status && typeof status === "string" ? status.charAt(0).toUpperCase() + status.slice(1) : status

  const p10 = domain.data && domain.data.scenarios && domain.data.scenarios.p10 ? `${domain.data.scenarios.p10}-estimate` : "P10-estimate"
  const p50 = domain.data && domain.data.scenarios && domain.data.scenarios.p50 ? `${domain.data.scenarios.p50}-estimate` : "P50-estimate"
  const p90 = domain.data && domain.data.scenarios && domain.data.scenarios.p90 ? `${domain.data.scenarios.p90}-estimate` : "P90-estimate"

  domain.data.scenarios

  switch (change.key) {
    case "start_date_is_hard":
      if (change.from && change.to) {
        string = (
          <span className="timeline__changes-wrapper--list-change-item">
            Changed<span className={"FontStyle--Bold"}>{" hard date"}</span>
            {` from ${CFrom} to ${to}`}
          </span>
        )
      } else if (change.from) {
        string = (
          <span className="timeline__changes-wrapper--list-change-item">
            Removed<span className={"FontStyle--Bold"}>{" hard date"}</span>
            {` from ${CFrom}`}
          </span>
        )
      } else if (change.to) {
        string = (
          <span className="timeline__changes-wrapper--list-change-item">
            Set<span className={"FontStyle--Bold"}>{" hard date"}</span>
            {` to ${CTo}`}
          </span>
        )
      }
      break
    case "dg1":
      CStatus = "DG1"
      break
    case "dg2":
      CStatus = "DG2"
      break
    case "dg3":
      CStatus = "DG3"
      break
    case "afc":
      CStatus = "AFC"
      break
    case "afd":
      CStatus = "AFD"
      break
    case "p10-estimate":
      CStatus = p10.charAt(0).toUpperCase() + p10.slice(1)
      break
    case "p50-estimate":
      CStatus = p50.charAt(0).toUpperCase() + p50.slice(1)
      break
    case "p90-estimate":
      CStatus = p90.charAt(0).toUpperCase() + p90.slice(1)
      break
    case "lwi_candidate":
      CStatus = "LWI"
      break
    case "block_id":
      CStatus = "Block"
      break
    default:
      break
  }

  if (!string) {
    if ((change.from || change.from === 0) && (change.to || change.to === 0)) {
      string = (
        <span className="timeline__changes-wrapper--list-change-item">
          <span className={"FontStyle--Bold"}>{CStatus}</span>
          {` changed from ${CFrom} to ${CTo}`}
        </span>
      )
    } else if (change.from || change.from === 0) {
      string = (
        <span className="timeline__changes-wrapper--list-change-item">
          <span className={"FontStyle--Bold"}>{CStatus}</span>
          {` removed ${CFrom}`}
        </span>
      )
    } else if (change.to || change.to === 0) {
      string = (
        <span className="timeline__changes-wrapper--list-change-item">
          <span className={"FontStyle--Bold"}>{CStatus}</span>
          {` set to ${CTo}`}
        </span>
      )
    }
  }

  return string
}

const getString = (props) => {
  const { change, domain } = props

  const well = change.well ? CU.getNameForWellBooking(change.well, true) : "Unknown"

  let string = ""

  const changesArray = change.changes.map((val, key) => getChange(val, key, domain))

  const arrLength = changesArray.length
  string = arrLength > 1 ? `${well} has ${arrLength} changes` : `${well} has one change`

  const changes = (
    <>
      <header>
        <span className={"FontStyle--Bold timeline__changes-wrapper--list-change-header"}>{string}</span>
      </header>
      {changesArray.map((val, key) => (
        <div key={key}>{val}</div>
      ))}
    </>
  )

  return changes
}

const ListMinorChange = (props) => {
  const changeString = getString(props)

  return <div className="timeline__changes-wrapper--list-minor-change">{changeString}</div>
}

ListMinorChange.propTypes = {
  change: PropTypes.object,
  title: PropTypes.string,
  Icon: PropTypes.string,
  IconWidth: PropTypes.number,
  IconHeight: PropTypes.number,
}

export default ListMinorChange
