import { EDIT_BUFFER_TIME_PANEL, ADD_BUFFER_TIME, CANCEL, SUBMIT } from "../../../../panelConsts"

const config = {
  type: EDIT_BUFFER_TIME_PANEL,
  index: 0,
  options: {
    heading: "name",
    dynamicHeader: true,
    subHeading: "Edit buffer time",
    hideMenu: true,
  },
  pages: [
    {
      type: ADD_BUFFER_TIME,
      name: null,
      options: {
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
