import { CONNECT_EXISTING_RIG, SELECT_EXISTING_RIG, CANCEL, SUBMIT } from "../../../../panelConsts"

const config = {
  type: CONNECT_EXISTING_RIG,
  index: 0,
  options: {
    heading: "Connect rig",
    dynamicHeader: false,
    dynamicSubHeader: false,
    subHeading: "",
  },
  pages: [
    {
      type: SELECT_EXISTING_RIG,
      name: "Select rig",
      options: {
        hideMenu: true,
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
