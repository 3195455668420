import { getDayOfYear } from "date-fns"
import differenceInDays from "date-fns/differenceInDays"
import parseISO from "date-fns/parseISO"
import { find } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { BehaviorSubject } from "rxjs"
import { debounceTime } from "rxjs/operators"
import Calendar from "../../../components/Scheduler/Calendar"
import DropDate from "../../../components/dragndrop/DropDate"
import CU from "../../../lib/CalendarUtils"
import DH from "../../../lib/DomUtils"
import { ActionCreators } from "../../../redux/actions"

import { OBJECT_SCROLL_POS } from "../../../scrollConsts"

const months = CU.getMonthsInYear()

export class SchedulerRigPlotter extends Component {
  constructor() {
    super()

    this.state = {
      left: 0,
    }

    this.scrollElm = React.createRef()
    this.scrollLeftPrev = 0
    this.scrollTopPrev = 0
  }

  onUpdateScroll = (e) => {
    e.preventDefault()

    if (e.target.scrollLeft !== this.scrollLeftPrev) {
      this.scrollLeftPrev = e.target.scrollLeft
      this.scrollLeftListener.next(e.target.scrollLeft)
    }
  }

  onScroll = () => {
    const {
      setScrollPosition,
      setCalendarsVisibleMonth,
      dayWidth,
      calendar: { timespan },
    } = this.props

    const pos = {
      top: this.scrollElm.current.scrollTop,
      left: this.scrollElm.current.scrollLeft,
    }

    this.setState(pos)
    setScrollPosition(pos.left)

    const firstDayCalendar = getDayOfYear(new Date(timespan.from))
    const day = (firstDayCalendar + parseInt(pos.left / dayWidth, 0)) % 365
    const month = find(months, (o) => o.value >= day)

    setCalendarsVisibleMonth(month)
  }

  componentDidMount = () => {
    this.contentWidth = this.scrollElm.current.scrollWidth

    this.scrollLeftListener = new BehaviorSubject(this.scrollElm.current.scrollLeft)
    this.scrollLeftListener.pipe(debounceTime(100)).subscribe(this.onScroll)

    this.scrollToFirstActiveDate()
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (snapshot !== null) {
      setTimeout(() => {
        if (snapshot.type === "messure_new_pos") {
          this.updateScrollPos(snapshot)
        } else if (snapshot.type === "set_new_pos") {
          const element = this.scrollElm.current
          element.scrollLeft = snapshot.position.value
        }
      }, 1)
    }

    if (
      (this.props.scheduleId && this.props.scheduleId !== prevProps.scheduleId) ||
      (this.props.PVersion && this.props.PVersion.key !== prevProps.PVersion.key)
    ) {
      this.scrollToFirstActiveDate()
    }
  }

  scrollToFirstActiveDate = () => {
    const isoFrom = parseISO(this.props.firstActiveDate)
    const isoTo = parseISO(this.props.startDate)

    const posX = differenceInDays(isoFrom, isoTo) * this.props.dayWidth

    setTimeout(() => {
      const element = this.scrollElm.current
      element.scrollLeft = posX
    }, 50)
  }

  getSnapshotBeforeUpdate = (prevProps) => {
    const {
      calendar: { zoomLevel, scrollPosition, scrollToLastPosition },
    } = this.props
    // Capture the scroll position so we can adjust scroll later.
    if (prevProps.calendar.zoomLevel !== zoomLevel) {
      return {
        type: "messure_new_pos",
        position: scrollPosition,
        contentWidth: this.scrollElm.current.scrollWidth,
      }
    }

    if (prevProps.calendar.scrollPosition !== scrollPosition && scrollPosition.type === OBJECT_SCROLL_POS) {
      return {
        type: "messure_new_pos",
        position: scrollPosition,
        contentWidth: this.scrollElm.current.scrollWidth,
      }
    }

    if (scrollToLastPosition !== prevProps.calendar.scrollToLastPosition) {
      return {
        type: "set_new_pos",
        position: scrollPosition,
        contentWidth: this.scrollElm.current.scrollWidth,
      }
    }

    return null
  }

  updateScrollPos = (snapshot) => {
    const element = this.scrollElm.current
    const contentWidth = element.scrollWidth
    const containerWidth = element.offsetWidth
    const prevContentWidth = snapshot.contentWidth

    const selectedObject = document.getElementById(snapshot.position.object)

    let newPos = 0

    if (selectedObject) {
      newPos = this.getSelectedObjectPosition(selectedObject, containerWidth)
    } else {
      newPos = element.scrollLeft * (contentWidth / prevContentWidth)
    }

    element.scrollLeft = newPos
  }

  getSelectedObjectPosition = (selectedSequence, containerWidth) => {
    const { wells, wellClickedID } = this.props.calendar.popOverData
    const selectedWell = document.getElementById(wells[wellClickedID].id)

    const sequencePosition = DH.getTranslateValues(selectedSequence).translateX
    const wellPosition = DH.getDomXValueOnDocument(selectedWell)

    const selectionPosition = sequencePosition + wellPosition - containerWidth / 2 + selectedWell.offsetWidth / 2

    return selectionPosition
  }

  shouldComponentUpdate = (prevProps, prevState) => {
    const { left } = this.state
    const { scrollTop } = this.props
    const {
      calendar: { zoomLevel, scrollPosition, scrollToLastPosition },
      scheduleId,
      PVersion,
    } = this.props

    if (left !== prevState.left) {
      return true
    }

    if (scrollPosition !== prevProps.calendar.scrollPosition) {
      return true
    }

    if (zoomLevel !== prevProps.calendar.zoomLevel) {
      return true
    }
    if (scheduleId !== prevProps.scheduleId) {
      return true
    }

    if (scrollToLastPosition !== prevProps.calendar.scrollToLastPosition) {
      return true
    }

    if (scheduleId !== prevProps.scheduleId) {
      return true
    }
    if (PVersion !== prevProps.PVersion) {
      return true
    }
    if (scrollTop !== prevProps.scrollTop) {
      return true
    }

    return false
  }

  render() {
    const { left } = this.state
    const { scrollTop, syncScroll } = this.props

    return (
      <>
        <DropDate left={left} />
        <div
          ref={this.scrollElm}
          className="Scheduler--Overview__Rig-Plotter-Container"
          id="Rig-Plotter-Container"
          onScroll={(e) => this.onUpdateScroll(e)}
        >
          <Calendar scrollTop={scrollTop} syncScroll={syncScroll} />
        </div>
      </>
    )
  }
}

SchedulerRigPlotter.propTypes = {
  calendar: PropTypes.object,
  setScrollPosition: PropTypes.func,
  setCalendarsVisibleMonth: PropTypes.func,
  scheduleId: PropTypes.number,
  firstActiveDate: PropTypes.string,
  startDate: PropTypes.string,
  PVersion: PropTypes.object,
  dayWidth: PropTypes.number,
  scrollTop: PropTypes.number,
  syncScroll: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    calendar: state.calendar,
    scheduleId: state.schedulerData.schedulerData.data ? state.schedulerData.schedulerData.data.id : null,
    startDate: state.schedulerData.schedulerData.data ? state.schedulerData.schedulerData.data.start_date : null,
    firstActiveDate: state.schedulerData.schedulerData.data ? state.schedulerData.schedulerData.data.firstActiveDate : null,
    PVersion: state.calendar.PVersion,
    dayWidth: state.calendar.dayWidth,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerRigPlotter)
