import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import CU from "../../lib/CalendarUtils"

class DropDate extends Component {
  constructor() {
    super()

    this.drop = React.createRef()
  }

  componentDidUpdate() {
    const {
      moveObject: { dom },
      xPos,
      left,
    } = this.props

    if (this.drop && this.drop.current) {
      this.drop.current.style.transform = `translate3d(${145 + xPos - left}px, 0, 0)`
      this.drop.current.style.opacity = dom ? 1 : 0
    }
  }

  getDate = () => {
    const {
      xPos,
      timespan: { from },
      dayWidth,
    } = this.props
    return CU.getDateBasedOnPotision(from, xPos, dayWidth)
  }

  render() {
    const { timespan } = this.props

    if (!timespan) {
      return null
    }

    return (
      <div ref={this.drop} className="DropDate">
        <div className="DateInText">{this.getDate()}</div>
      </div>
    )
  }
}

DropDate.propTypes = {
  moveObject: PropTypes.object,
  xPos: PropTypes.number,
  timespan: PropTypes.object,
  dayWidth: PropTypes.number,
  left: PropTypes.number.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    timespan: state.calendar.timespan,
    dayWidth: state.calendar.dayWidth,
    moveObject: state.schedulerData.moveObject,
    xPos: state.schedulerData.xPos,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropDate)
