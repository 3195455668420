import {
  REGISTER_UPDATE_REQUEST,
  INPUT_SELECT_WELL,
  INPUT_WELL_INFO,
  INPUT_REQUEST_RIG_INFO,
  INPUT_TIMEFRAME,
  INPUT_TIME_ESTIMATE,
  INPUT_COMMENT,
  CANCEL,
  NEXT,
  SUBMIT,
} from "../../../../panelConsts"

const config = {
  type: REGISTER_UPDATE_REQUEST,
  index: 0,
  options: {
    heading: "Well update",
    subHeading: false,
    largeModal: true,
  },
  pages: [
    {
      type: INPUT_SELECT_WELL,
      name: "Select well",
      options: {
        hideMenu: true,
        hideInMenu: true,
        heading: "Select well",
        buttons: {
          left: CANCEL,
          right: NEXT,
        },
      },
    },
    {
      type: INPUT_WELL_INFO,
      name: "Well Info",
      options: {
        buttons: {
          left: CANCEL,
          right: NEXT,
        },
      },
    },
    {
      type: INPUT_REQUEST_RIG_INFO,
      name: "Rig Info",
      options: {
        buttons: {
          left: CANCEL,
          right: NEXT,
        },
      },
    },
    {
      type: INPUT_TIMEFRAME,
      name: "Timeframe",
      options: {
        buttons: {
          left: CANCEL,
          right: NEXT,
        },
      },
    },
    {
      type: INPUT_TIME_ESTIMATE,
      name: "Time Estimate",
      options: {
        buttons: {
          left: CANCEL,
          right: NEXT,
        },
      },
    },
    {
      type: INPUT_COMMENT,
      name: "Comment",
      options: {
        validated: true,
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
