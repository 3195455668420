import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import VALUE_HIDDEN from "../../appSettingConsts"

class PSelectorButton extends PureComponent {
  updateP() {
    const { PValue, setPVersion } = this.props

    setPVersion(PValue)
  }

  render() {
    const {
      PValue,
      PVersion,
      domain: { data },
    } = this.props

    const active = PValue.name === PVersion.name ? "active" : ""

    const name = data.scenarios[PValue.value] || PValue.name

    // If this button is set to '!hidden' in app_settings, it will not show in the UI
    if (name === VALUE_HIDDEN) return null

    return (
      <div className={`PSelector--Button ${active}`} onClick={() => this.updateP()}>
        {name}
      </div>
    )
  }
}

PSelectorButton.propTypes = {
  PValue: PropTypes.object,
  PVersion: PropTypes.object,
  domain: PropTypes.object,
  setPVersion: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    PVersion: state.calendar.PVersion,
    domain: state.application.domain,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PSelectorButton)
