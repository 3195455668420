import {
  CONFIRM_SCHEDULER_SHARE_UPDATE_SUBPAGE,
  CONFIRM_SCHEDULER_SHARE,
  CONFIRM_SCHEDULER_SHARE_SUBPAGE,
  CLOSE,
  UPDATE,
  SHARE,
} from "../../../../panelConsts"

const config = {
  type: CONFIRM_SCHEDULER_SHARE,
  index: 0,
  options: {
    heading: "#schedule_name#",
    dynamicHeader: false,
    subHeading: "Access to draft",
    hideMenu: false,
    whiteModal: true,
  },
  pages: [
    {
      type: CONFIRM_SCHEDULER_SHARE_SUBPAGE,
      name: "Add people",
      options: {
        buttons: {
          left: CLOSE,
          right: SHARE,
        },
      },
    },
    {
      type: CONFIRM_SCHEDULER_SHARE_UPDATE_SUBPAGE,
      name: "Remove Access",
      options: {
        buttons: {
          left: CLOSE,
          right: UPDATE,
        },
      },
    },
  ],
}

export default config
