import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { cloneDeep } from "lodash"

import { ActionCreators } from "../../../../redux/actions"
import PanelFooter from "../../../../components/panel/PanelFooter"
import PanelHeader from "../../../../components/panel/PanelHeader"
import PanelHeaderButton from "../../../../components/buttons/PanelHeaderButton"
import PanelMenu from "../../../../components/panel/PanelMenu"
import PH from "../../../../lib/PanelHelper"
import Preview from "../../../../components/icons/Preview"

class RegisterNewRequest extends Component {
  constructor(props) {
    super(props)

    const { requestToEdit } = props

    this.state = {
      pages: props.pages,
      options: props.options,
      postData: requestToEdit || {},
      validated: {},
    }
  }

  componentDidMount() {
    const {
      panelOpen: { index },
      nextStepInPanel,
    } = this.props
    const { postData } = this.state

    if (postData.booking_type && index === 0) {
      nextStepInPanel()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      panelOpen: { index },
    } = this.props
    const { pages, validated } = this.state

    // TODO: if object has prod that it does not need to be validated
    if (index !== prevProps.panelOpen.index) {
      if (!validated[index]) {
        if (pages[index].options.validated) {
          this.setSubPageValidated(index, pages[index].options.validated)
        }
      }
    }
  }

  onUpdate = (obj) => {
    const { postData } = this.state

    const nPostData = cloneDeep(postData)
    Object.entries(obj).forEach(([key, val]) => {
      if (val || val === null) {
        nPostData[key] = val
      } else {
        delete nPostData[key]
      }
    })

    this.setState({
      postData: nPostData,
    })
  }

  onSubmit = () => {
    const { createNewRequest } = this.props

    const data = cloneDeep(this.state.postData)
    data.request_type = data.request_type || "new_well"

    createNewRequest(data)
  }

  setSubPageValidated = (index, isValidated) => {
    const validated = cloneDeep(this.state.validated)

    validated[index] = isValidated

    this.setState({
      validated,
    })
  }

  previewRequest = () => {
    const { previewRequest, requestToEdit } = this.props
    previewRequest(requestToEdit)
  }

  getPage = (index, pages, postData) =>
    PH.getPage(
      index,
      pages[index],
      (val) => this.onUpdate(val),
      (i, val) => this.setSubPageValidated(i, val),
      postData
    )

  render() {
    const { pages, postData, validated } = this.state
    const {
      panelOpen: { index },
    } = this.props

    const previewIcon = <span>{Preview()} Preview</span>
    const headerButtons = postData.id
      ? [<PanelHeaderButton key={"Preview-request-button-modal"} value={previewIcon} handleClick={this.previewRequest} />]
      : null

    return [
      <PanelHeader key={"PanelHeader"} buttons={headerButtons} />,
      <PanelMenu key={"PanelMenu"} />,
      <div key={"Panel__Page"} className="Panel__Page Panel-Request">
        <div className="Panel__Page-Container PanelContent">{this.getPage(index, pages, postData)}</div>
        <PanelFooter index={index} data={pages[index]} validated={validated[index]} onSubmit={(val) => this.onSubmit(val)} />
      </div>,
    ]
  }
}

RegisterNewRequest.propTypes = {
  panelOpen: PropTypes.object,
  options: PropTypes.object,
  requestToEdit: PropTypes.object,
  pages: PropTypes.array,
  index: PropTypes.number,
  createNewRequest: PropTypes.func,
  nextStepInPanel: PropTypes.func,
  previewRequest: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    panelOpen: state.application.panelOpen,
    requestToEdit: state.requestData.requestToEdit,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterNewRequest)
