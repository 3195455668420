import React, { Component } from "react"
import PropTypes from "prop-types"
import RequestPreview from "../Toolbar/RequestPreview"
import CU from "../../lib/CalendarUtils"
// import RU from '../../lib/RequestUtils';

class RequestPreviewList extends Component {
  getStyle = (request) => ({
    backgroundColor: CU.getWellBookingStatusColor(request.wells.length > 0 ? request.wells[0].well_status : "conceptional").color,
  })

  render() {
    const { unprocessed } = this.props

    const reqs = unprocessed.filter((val, key) => key < 30)
    // TODO: get this sorted from backend instead of sorting here

    return (
      <div className="Requestlist-Preview">
        {reqs.map((val, key) => (
          <RequestPreview key={`RequestPreview-${key}`} request={val} />
        ))}
      </div>
    )
  }
}

RequestPreviewList.propTypes = {
  unprocessed: PropTypes.array,
}

export default RequestPreviewList
