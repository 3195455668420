import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import format from "date-fns/format"
import getYear from "date-fns/getYear"
import { ActionCreators } from "../../../redux/actions"
import Year from "./duration/Year"

import { DATE_FORMATE_MONTH_NB } from "../../../dateConsts"

class Dates extends Component {
  constructor() {
    super()

    this.state = {
      elements: null,
    }
  }

  componentDidMount = () => {
    this.setState({
      elements: this.getElements(),
      para: document.getElementById("Rig-Plotter-Container"),
    })
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (
      prevProps.dayWidth !== this.props.dayWidth ||
      prevProps.calendar.calendar !== this.props.calendar.calendar ||
      prevProps.calendar.scrollPosition.value !== this.props.calendar.scrollPosition.value
    ) {
      return true
    }

    return null
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const {
      calendar: { calendar },
      dayWidth,
      scrollPosition,
    } = nextProps
    const { elements } = nextState

    if (calendar !== this.props.calendar.calendar) {
      return true
    }

    if (dayWidth !== this.props.dayWidth) {
      return true
    }

    if (elements !== this.state.elements) {
      return true
    }

    if (scrollPosition.value !== this.props.scrollPosition.value) {
      return true
    }

    return false
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (snapshot !== null) {
      this.setState({
        elements: this.getElements(),
      })
    }
  }

  getElements = () => {
    if (!this.props.calendar) return null

    const {
      calendar: { calendar },
      zoomLevel,
      dayWidth,
      setCalendarsVisibleYears,
      setCalendarsVisibleMonth,
      scrollPosition,
    } = this.props

    if (calendar.values) {
      const currentMonth = format(new Date(), DATE_FORMATE_MONTH_NB)
      const currentYear = getYear(new Date())
      const { para } = this.state

      return Array.from(calendar.values()).map((i, k) => (
        <Year
          currentMonth={currentMonth}
          currentYear={currentYear}
          zoomLevel={zoomLevel}
          dayWidth={dayWidth}
          scrollPosition={scrollPosition}
          para={para}
          setCalendarsVisibleYears={setCalendarsVisibleYears}
          setCalendarsVisibleMonth={setCalendarsVisibleMonth}
          key={`calendar-year-${k}`}
          data={i}
        >
          {i.year}
        </Year>
      ))
    }

    return null
  }

  render() {
    const { elements } = this.state

    return <div className="Dates">{elements}</div>
  }
}

Dates.propTypes = {
  calendar: PropTypes.object,
  setCalendarsVisibleYears: PropTypes.func,
  setCalendarsVisibleMonth: PropTypes.func,
  currentYear: PropTypes.number,
  dayWidth: PropTypes.number,
  zoomLevel: PropTypes.number,
  scrollPosition: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    calendar: state.calendar,
    zoomLevel: state.calendar.zoomLevel,
    dayWidth: state.calendar.dayWidth,
    scrollPosition: state.calendar.scrollPosition,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dates)
