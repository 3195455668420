// WELLS
export const WELLS = "wells"
export const SINGLE_WELL = "single_well"
export const WELL_SEQUENCE = "well_sequence"

// TYPES
export const BOOKING_TYPE = "booking_type"

// DATES
export const EARLIEST_START_DATE = "earliest_start_date"
export const LAST_POSSIBLE_END_DATE = "last_possible_end_date"
export const START_DATE_IS_HARD = "start_date_is_hard"
export const END_DATE_IS_HARD = "end_date_is_hard"
export const END_OF_CONTRACT = "end_of_contract"

// P_VERSION
export const P10 = "P10"
export const P50 = "P50"
export const P90 = "P90"
export const P10_ESTIMATE = "p10_estimate"
export const P50_ESTIMATE = "p50_estimate"
export const P90_ESTIMATE = "p90_estimate"

// WELL
export const BLOCK_ID = "block_id"
export const ASSET_ID = "asset_id"
export const ASSET_NUMBER = "plno"
export const ASSET_NAME = "name"
export const WELL_STATUS = "well_status"
export const IS_CONFIDENTIAL = "confidential"
export const HAS_PRE_WORK = "pre_work"
export const ASSET_DEPTH = "ocean_depth"
export const ASSET_UNIQUE_WELL_IDENTIFIER = "uwi"

// RIG
export const PRESSURE = "pressure"
export const RIG_TYPE = "rig_type"
export const CONTRACT = "has_contract_with"

// RIG TYPES
export const JACK_UP = "jack-up"
export const SEMI_SUBMERSIBLE = "semi-sub"
export const PLATFORM = "platform"
export const LANDRIG = "land_rig"
export const DRILLSHIP = "drillship"

// PRESSURES
export const HPHT = "hpht"

// PREVIEW REQUEST'
export const PREVIEW_START_DATE = "preview_start_date"
export const PREVIEW_END_DATE = "preview_end_date"
