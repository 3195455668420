import React from "react"
import Warning from "../../../assets/images/svg/icons/Warning.svg"
import IconContainer from "../icons/IconContainer"

const ErrorMessage = (message) => (
  <div className="ErrorMessage">
    {IconContainer(Warning, 14, 14)}
    {message}
  </div>
)

export default ErrorMessage
