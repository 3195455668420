import React, { memo } from "react"
import PropTypes from "prop-types"

const MyComponent = (props) => <div className="Day">{props.date}</div>

const areEqual = (prevProps, nextProps) => {
  if (prevProps.date !== nextProps.date) {
    return true
  }

  return false
}

MyComponent.propTypes = {
  date: PropTypes.number,
}

const Day = memo(MyComponent, areEqual)
export default Day
