import React, { Component } from "react"
import PropTypes from "prop-types"
import Chevron from "../icons/Chevron"

class RoundedDropList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: props.defaultOpen || false,
    }
  }

  toggleOpen = () => {
    const { open } = this.state

    this.setState({
      open: !open,
    })
  }

  render() {
    const { data, selectedYear, startYear, onChange } = this.props

    const { open } = this.state

    return (
      <div className={"RoundedDropList"}>
        <form onClick={this.toggleOpen}>
          <select className={"noselect"} name="year" onChange={onChange} value={selectedYear}>
            {data.map((val) => (
              <option key={val} value={`${startYear + val}`}>
                {`${startYear + val}`}
              </option>
            ))}
          </select>
        </form>
        {Chevron(open ? "open" : "", "ignore")}
      </div>
    )
  }
}

RoundedDropList.propTypes = {
  data: PropTypes.array,
  selectedYear: PropTypes.number,
  thisYear: PropTypes.number,
  startYear: PropTypes.number,
  defaultOpen: PropTypes.bool,
  onChange: PropTypes.func,
}

// TODO: pass down function instead of using connect
export default RoundedDropList
