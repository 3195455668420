import { CONFIRM_BUFFER_TIME_INSTANCE_REMOVE, CONFIRM_BUFFER_TIME_INSTANCE_REMOVE_SUBPAGE, CANCEL, SUBMIT } from "../../../../panelConsts"

const config = {
  type: CONFIRM_BUFFER_TIME_INSTANCE_REMOVE,
  index: 0,
  options: {
    heading: "name",
    dynamicHeader: true,
    subHeading: "Remove buffer time",
    hideMenu: true,
    whiteModal: true,
  },
  pages: [
    {
      type: CONFIRM_BUFFER_TIME_INSTANCE_REMOVE_SUBPAGE,
      name: null,
      options: {
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
