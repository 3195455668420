import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class ConfirmWellInputRemovePanel extends Component {
  render() {
    const { wellInstanceToRemove } = this.props
    return (
      <div className="PanelContent__Container ConfirmMessage">
        <svg
          width="76px"
          height="76px"
          viewBox="0 0 76 76"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs></defs>
          <g id="Modules-+-Flows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Overview-draft-and-master-schedule-module" transform="translate(-1885.000000, -2885.000000)">
              <g id="fail" transform="translate(1885.000000, 2885.000000)">
                <circle id="Oval-5" stroke="#1C2226" cx="38" cy="38" r="37.5"></circle>
                <path
                  d="M36.6142322,42.3361642 L36.1947566,27 L39.8052434,27 L39.3857678,42.3361642 L36.6142322,42.3361642 Z M36,49 L36,44.8895966 L40,44.8895966 L40,49 L36,49 Z"
                  id="!"
                  fill="#1C2226"
                ></path>
              </g>
            </g>
          </g>
        </svg>
        <div className="ConfirmMessage__SubHeader">Permanently remove</div>
        <div className="ConfirmMessage__Header">{wellInstanceToRemove.well.name}</div>
        <div className="ConfirmMessage__SubHeader">from this schedule?</div>
      </div>
    )
  }
}

ConfirmWellInputRemovePanel.propTypes = {
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  onValidated: PropTypes.func,
  data: PropTypes.object,
  wellInstanceToRemove: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    wellInstanceToRemove: state.schedulerData.wellInstanceToRemove,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmWellInputRemovePanel)
