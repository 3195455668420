import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { matchPath } from "react-router"
import { bindActionCreators } from "redux"

import { Route, withRouter, Switch, Redirect } from "react-router-dom"
import DnD from "../components/dragndrop/DnD"
import CU from "../lib/CalendarUtils"
import MU from "../lib/ModalUtils"

import { ActionCreators } from "../redux/actions"
import { Scheduler, Overview, Timeline, ManageContent, NoMatch, Error, Logout } from "./routes"
import PageHeader from "../containers/PageHeader"
import Panel from "../containers/panel"

class RootApp extends Component {
  constructor() {
    super()

    this.state = {
      modalOpen: null,
    }
  }

  componentDidMount() {
    const { resizeView, getDataForDomain } = this.props

    window.onresize = (e) => {
      resizeView({
        width: e.currentTarget.innerWidth,
        height: e.currentTarget.innerHeight,
      })
    }

    document.addEventListener("keypress", (e) => {
      const ev = e || window.event
      const { schedulerData, regretChange } = this.props

      if (ev.ctrlKey === true && (ev.which === 26 || ev.keyCode === 26)) {
        CU.undo(schedulerData, regretChange)
      }
    })

    // SET CORRECT SIZE AT START
    resizeView({
      width: window.innerWidth,
      height: window.innerHeight,
    })

    getDataForDomain()
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { location, modal, darkMode } = this.props
    const { modelOpen } = this.state

    if (location !== nextProps.location) {
      return true
    }

    if (modal !== nextProps.modal) {
      return true
    }

    if (darkMode !== nextProps.darkMode) {
      return true
    }

    if (modelOpen !== nextState.modelOpen) {
      return true
    }

    return false
  }

  componentDidUpdate(prevProps) {
    const { location, fetchRigData, schedulerData } = this.props

    if (location !== prevProps.location) {
      const newID = matchPath(location.pathname, {
        path: "/schedule/:id",
        exact: true,
        strict: false,
      })

      if (newID) {
        const fromID = parseInt(schedulerData.schedulerData.data.id, 0)
        const toID = parseInt(newID.params.id, 0)

        if (toID !== fromID) {
          fetchRigData()
        }
      }
    }
  }

  render = () => {
    const { location, modal, darkMode } = this.props
    const isError = !!(
      (location.state && location.state.error && this.previousLocation !== location) // not initial render
    )

    if (isError) {
      return <Route component={Error} />
    }

    const ModalPoiPoi = modal ? MU.getModal(modal) : null

    return (
      <>
        <div className={`HeaderContainer${darkMode ? " darkmode" : ""}`}>
          <PageHeader />
        </div>
        {ModalPoiPoi}

        <Switch>
          <Redirect exact from="/" to="/schedule" />
          <Route path="/schedule" component={Scheduler} />
          <Route path="/schedule/:id?" component={Scheduler} />
          <Route exact path="/overview" component={Overview} />
          <Route exact path="/timeline" component={Timeline} />
          <Route exact path="/managecontent/:type?" component={ManageContent} />
          <Route exact path="/logout" component={Logout} />
          <Route component={NoMatch} />
        </Switch>

        <DnD></DnD>
        <Panel></Panel>
      </>
    )
  }
}

RootApp.propTypes = {
  location: PropTypes.object,
  schedulerData: PropTypes.object,
  modal: PropTypes.object,
  fetchRigData: PropTypes.func,
  resizeView: PropTypes.func,
  regretChange: PropTypes.func,
  // fetchUserRightsAndRoles: PropTypes.func,
  // getMessagesListForUser: PropTypes.func,
  // setViewFilterToStoredVersion: PropTypes.func,
  getDataForDomain: PropTypes.func,
  darkMode: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    schedulerData: state.schedulerData,
    modal: state.managecontent.modal,
    darkMode: state.calendar.darkMode,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RootApp))
