import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import DayPickerElement from "../../../components/form/DayPickerElement"
import { ActionCreators } from "../../../redux/actions"
import { DATE_FORMATE_NB } from "../../../dateConsts"

const requiredFields = ["estimate", "start_date"]

class AddBufferTime extends Component {
  isNumeric = (n) => Number.isFinite(n) && +n === n /* ignore */

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}
    let { value } = elem.target

    if (elem.target.name === "estimate") {
      const parsed = parseInt(elem.target.value, 0)
      value = this.isNumeric(parsed) ? parsed : ""
    }

    obj[elem.target.name] = value

    onUpdate(obj)
  }

  handleDayChange = (day) => {
    const { onUpdate } = this.props

    const obj = {}
    obj.start_date = day

    onUpdate(obj)
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props

    if (data !== prevProps.data) {
      let validated = true
      const keys = Object.keys(data)
      validated = requiredFields.every((key) => keys.includes(key))
      onValidated(index, validated)
    }
  }

  // TODO: Add form validation
  render() {
    const { data } = this.props

    return (
      <div className="PanelContent__Container PanelForm">
        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputlabel">Estimate in days *</label>
          <input
            onChange={this.onFieldUpdate}
            value={data.estimate ? data.estimate : ""}
            className={`PanelForm__textinput ${data.estimate ? "active" : ""}`}
            name="estimate"
            placeholder="E.g. 5"
          />
        </div>
        <div className="PanelForm__InputPart">
          <DayPickerElement label="Start date" onChange={this.handleDayChange} format={DATE_FORMATE_NB} value={data.start_date || ""} />
        </div>
        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputlabel">Comment</label>
          <textarea
            onChange={this.onFieldUpdate}
            className="PanelForm__textinput PanelForm__textinput--textarea"
            name="comment"
            value={data.comment ? data.comment : ""}
            placeholder="Enter your comment here"
          ></textarea>
        </div>
      </div>
    )
  }
}

AddBufferTime.propTypes = {
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
  onValidated: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBufferTime)
