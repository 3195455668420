import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Select from "react-select"

import { ActionCreators } from "../../../redux/actions"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

class ConfirmSetAsMasterSubpage extends PureComponent {
  constructor() {
    super()
    this.state = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }
  }

  componentDidMount() {
    this.setInitialData()
  }

  setInitialData = () => {
    const { month, year } = this.state
    const { onUpdate } = this.props

    onUpdate({
      month,
      year,
    })
  }

  onMonthSelect = (month) => {
    this.setState({
      month: month.value,
    })

    const { onUpdate } = this.props

    onUpdate({
      month: month.value,
    })
  }

  onYearSelect = (year) => {
    this.setState({
      year: year.value,
    })

    const { onUpdate } = this.props

    onUpdate({
      year: year.value,
    })
  }

  range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

  render() {
    const { schedulerData } = this.props
    const { month, year } = this.state

    const currentSchedule = schedulerData ? schedulerData.data : null
    return (
      <div className="PanelContent__Container ConfirmMessage">
        <div className="ConfirmMessage__SubHeader">Set</div>
        <div className="ConfirmMessage__Header">{currentSchedule.name}</div>
        <div className="ConfirmMessage__SubHeader">as next master schedule?</div>
        <div className="ConfirmMessage__SelectWrapper">
          <div className="ConfirmMessage__Select">
            <label className={"ConfirmMessage__Label"}>Month</label>
            <Select
              key={"month-selector"}
              options={this.range(0, 11, 1).map((e) => ({ value: e, label: monthNames[e] }))}
              closeMenuOnSelect={true}
              isClearable={false}
              placeholder={"Select month"}
              isDisabled={false}
              isMulti={false}
              value={[{ value: month, label: monthNames[month] }]}
              getOptionLabel={(option) => option.label}
              classNamePrefix="Select__Dropdown__Elm Select__Dropdown__Elm--dark-text"
              onChange={this.onMonthSelect}
              maxMenuHeight={150}
            />
          </div>
          <div className="ConfirmMessage__Select">
            <label className={"ConfirmMessage__Label"}>Year</label>
            <Select
              key={"month-selector"}
              options={this.range(year - 1, year + 3, 1).map((e) => ({ value: e, label: e }))}
              closeMenuOnSelect={true}
              isClearable={false}
              placeholder={"Select year"}
              isDisabled={false}
              isMulti={false}
              value={[{ value: year, label: year }]}
              getOptionLabel={(option) => option.label}
              classNamePrefix="Select__Dropdown__Elm Select__Dropdown__Elm--dark-text"
              onChange={this.onYearSelect}
              maxMenuHeight={150}
            />
          </div>
        </div>
        <div className="ConfirmMessage__SelectExplanation">
          <p>The month/year determines the naming of the new master.</p>
        </div>
      </div>
    )
  }
}

ConfirmSetAsMasterSubpage.propTypes = {
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  onValidated: PropTypes.func,
  data: PropTypes.object,
  schedulerData: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    schedulerData: state.schedulerData.schedulerData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSetAsMasterSubpage)
