import { DECISION_SUMMARY, DECISION_SUMMARY_SUB } from "../../../../panelConsts"

const config = {
  type: DECISION_SUMMARY,
  index: 0,
  options: {
    heading: "name",
    dynamicHeader: true,
    subHeading: "Add occupied time",
    hideMenu: true,
    whiteModal: true,
    // wideModal: true,
    tallModal: true,
  },
  pages: [
    {
      type: DECISION_SUMMARY_SUB,
      name: null,
      options: {},
    },
  ],
}

export default config
