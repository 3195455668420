import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ConditionalField from "../../../components/common/ConditionalField"
import DayPickerElement from "../../../components/form/DayPickerElement"
import { DATE_FORMATE_NB } from "../../../dateConsts"
import { ActionCreators } from "../../../redux/actions"
import { END_OF_CONTRACT } from "../../../registerConsts"

const requiredFields = ["name"]

class InputRigInfo extends Component {
  isNumeric = (n) => Number.isFinite(n) && +n === n /* ignore */

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    let value = elem.target.type === "checkbox" ? elem.target.checked : elem.target.value
    const obj = {}

    if (elem.target.name === "day_rate") {
      const parsed = parseInt(elem.target.value, 0)
      value = this.isNumeric(parsed) ? parsed : ""
    }

    obj[elem.target.name] = value

    onUpdate(obj)
  }

  handleDayChange = (day, key) => {
    const { onUpdate } = this.props
    const obj = {}
    obj[key] = typeof day === "undefined" ? null : day

    if (key === END_OF_CONTRACT) {
      obj.option_from = day
    }
    onUpdate(obj)
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props
    if (data !== prevProps.data) {
      let validated = true
      const keys = Object.keys(data)
      validated = requiredFields.every((key) => keys.includes(key))
      onValidated(index, validated)
    }
  }

  render() {
    const { data } = this.props
    const activeName = data.name ? "active" : ""

    return (
      <div className="PanelContent__Container PanelForm">
        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputlabel">Name of rig *</label>
          <input
            onChange={this.onFieldUpdate}
            value={data.name ? data.name : ""}
            className={`PanelForm__textinput ${activeName}`}
            name="name"
            placeholder="E.g. JU #3"
          />
        </div>
        <ConditionalField scope="rig" field="npdid">
          <div className="PanelForm__InputPart">
            <label className="PanelForm__inputlabel">NPDID</label>
            <input
              onChange={this.onFieldUpdate}
              value={data.npdid ? data.npdid : ""}
              className={`PanelForm__textinput ${activeName}`}
              name="npdid"
              placeholder="NIDID of Rig"
            />
          </div>
        </ConditionalField>

        <DayPickerElement
          label="Start of contract"
          onChange={(day) => this.handleDayChange(day, "start_of_contract")}
          format={DATE_FORMATE_NB}
          value={data.start_of_contract ? new Date(data.start_of_contract) : ""}
        />
        <DayPickerElement
          label="End of contract"
          onChange={(day) => this.handleDayChange(day, END_OF_CONTRACT)}
          format={DATE_FORMATE_NB}
          value={data[END_OF_CONTRACT] ? new Date(data[END_OF_CONTRACT]) : ""}
        />

        <DayPickerElement
          label="Option start"
          onChange={(day) => this.handleDayChange(day, "option_from")}
          format={DATE_FORMATE_NB}
          value={data.option_from ? new Date(data.option_from) : ""}
        />

        <DayPickerElement
          label="Option End"
          onChange={(day) => this.handleDayChange(day, "option_to")}
          format={DATE_FORMATE_NB}
          value={data.option_to ? new Date(data.option_to) : ""}
        />
        <div className="PanelForm__InputPart PanelForm__InputPart--checkbox">
          <label className={`PanelForm__inputlabel ${data.is_confidential ? "PanelForm__inputlabel--checked" : ""}`}>
            <input
              onChange={this.onFieldUpdate}
              checked={data.is_confidential ? data.is_confidential : false}
              name="is_confidential"
              type="checkbox"
            />
            <span className="check-indicator"></span>
            <span>Rig is confidential</span>
          </label>
          <span className="PanelForm__inputinfo">Shows rig&apos;s placement but hides all other information</span>
        </div>
        <div className="PanelForm__InputPart">
          <label className={"PanelForm__inputlabel"}>Comment</label>
          <textarea
            value={data.comment || ""}
            onChange={this.onFieldUpdate}
            className="PanelForm__textinput PanelForm__textinput--textarea"
            name="comment"
          ></textarea>
        </div>
        <div className="PanelForm__InputPart PanelForm__InputPart--checkbox">
          <label className={`PanelForm__inputlabel ${data.is_partner_rig ? "PanelForm__inputlabel--checked" : ""}`}>
            <input
              onChange={this.onFieldUpdate}
              checked={data.is_partner_rig ? data.is_partner_rig : false}
              name="is_partner_rig"
              type="checkbox"
            />
            <span className="check-indicator"></span>
            <span>This is a partner rig</span>
          </label>
        </div>
        <div className="PanelForm__InputPart PanelForm__InputPart--checkbox">
          <label className={`PanelForm__inputlabel ${data.is_draft_rig ? "PanelForm__inputlabel--checked" : ""}`}>
            <input
              onChange={this.onFieldUpdate}
              checked={data.is_draft_rig ? data.is_draft_rig : false}
              name="is_draft_rig"
              type="checkbox"
            />
            <span className="check-indicator"></span>
            <span>This is a draft rig</span>
          </label>
        </div>
      </div>
    )
  }
}

InputRigInfo.propTypes = {
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
  onValidated: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InputRigInfo)
