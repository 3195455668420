import React, { Component } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../redux/actions"

class FlashMessage extends Component {
  closeModal = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const {
      data: { id },
      setMessageRead,
    } = this.props
    setMessageRead(id)
  }

  render() {
    const { data } = this.props

    return (
      <Modal
        key={"flash-modal"}
        isOpen={!!data}
        className={"ReactModal__Content"}
        overlayClassName={"ReactModal__Overlay"}
        contentLabel="Rename"
      >
        <form className="ReactModal__Wrapper">
          <div className="ReactModal__Form ModalForm">
            <h1 className="ReactModal__FormHeader ReactModal__FormHeader--big ">
              <strong>{data.name}</strong>
            </h1>
            <p className="ModalForm__Part PADDING_TOP CENTER_TEXT">{data.message}</p>
          </div>
          <div className="ReactModal__Buttons">
            <button onClick={this.closeModal} className={"ReactModal__Button ReactModal__Button--primary"}>
              {"Got it!"}
            </button>
          </div>
        </form>
      </Modal>
    )
  }
}

FlashMessage.propTypes = {
  setMessageRead: PropTypes.func,
  data: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage)
