import { cloneDeep, findIndex } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import BU from "../../../lib/BuilderUtils"
import { CONCEPTIONAL } from "../../../redux/types"

import CampaignInfoContainer from "../../../components/CampaignInfoContainer"
import DragnDropContainer from "../../../components/dragndrop/DragnDropContainer"
import { ActionCreators } from "../../../redux/actions"

class InputWellCampaignInfo extends Component {
  constructor() {
    super()

    this.state = {
      mode: {
        type: "campaign",
      },
      failedStatusChange: "",
    }
  }

  componentDidMount() {
    const { index, onValidated, data } = this.props
    if (data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data, singleWellToAddToRigBooking, shakeIncrement } = this.props
    const { mode } = this.state
    if (data !== prevProps.data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }
    if (singleWellToAddToRigBooking && singleWellToAddToRigBooking !== prevProps.singleWellToAddToRigBooking) {
      this.pushNewWellIntoRigBooking()
    }

    if (shakeIncrement > prevProps.shakeIncrement) {
      this.shake(0, mode)
    }
  }

  isNumeric = (n) => Number.isFinite(n) && +n === n /* ignore */

  onFieldUpdate = async (key, value, wellInstanceId) => {
    const { onUpdate, data } = this.props
    const obj = {}
    const selectedWellIndex = cloneDeep(data.wells.findIndex((well) => well.id === wellInstanceId))

    const thisIsABlobalValue = BU.isPropertyGlobal(key)

    if (thisIsABlobalValue) {
      for (let i = 0; i < data.wells.length; i += 1) {
        obj[`wells[${i}].${key}`] = value
      }
    } else {
      obj[`wells[${selectedWellIndex}].${key}`] = value
    }

    console.log("obj::")
    console.log(obj)

    onUpdate(obj)
  }

  handleStatusChange = (status, wellInstanceId) => {
    const { onUpdate, data, domain } = this.props

    const updatedObject = {}
    const changeToStatus = status.value
    const currentWellIndex = data.wells.findIndex((well) => well.id === wellInstanceId)
    const currentObject = data.wells[currentWellIndex]
    const checkkArray = BU.requiredFieldsPrStatusCheck()

    const statusArrayIndex = findIndex(checkkArray, (val) => val[changeToStatus])

    const statusChangeIsValid = BU.checkUntilIndex(currentObject, statusArrayIndex, domain)
    updatedObject[`wells[${currentWellIndex}].status`] = status.value

    if (statusChangeIsValid.valid || changeToStatus === "occupied") {
      this.setState({ failedStatusChange: "" })
      onUpdate(updatedObject)
    } else {
      onUpdate(updatedObject)
    }
  }

  validateForm() {
    const { data, domain } = this.props
    const checkkArray = BU.requiredFieldsPrStatusCheck()
    const validated = data.wells.map((val) => {
      if (val.status === "occupied") {
        if (
          val.well.name &&
          val.has_contract_with &&
          val.estimateData.p10.estimate &&
          val.estimateData.p50.estimate &&
          val.estimateData.p90.estimate
        ) {
          return { valid: true, messages: [] }
        }

        const errorMessages = []

        if (!val.well.name) {
          errorMessages.push("Well needs a legal well name in basic information.")
        }

        if (!val.has_contract_with) {
          errorMessages.push("Select the rig the occupied times applies for.")
        }
        if (!val.estimateData.p10.estimate || !val.estimateData.p50.estimate || !val.estimateData.p90.estimate) {
          errorMessages.push("Occupied time needs estimates.")
        }

        return { well: val.well.name, valid: false, messages: errorMessages }
      }
      const statusArrayIndex = findIndex(checkkArray, (status) => status[val.status])
      const statusChangeIsValid = BU.checkUntilIndex(val, statusArrayIndex, domain)
      return statusChangeIsValid
    })

    return validated
  }

  shake = (currentWellIndex, mode) => {
    const { data, indicateModalFormValidationFailed } = this.props

    let delay1 = 800
    let delay2 = 1500
    if (mode.expanded) {
      delay1 = 0
      delay2 = 0
    }

    // this.setMode({ ...mode, expanded: true });
    setTimeout(() => {
      this.setState({
        failedStatusChange: data.wells[currentWellIndex].status,
      })
    }, delay1)
    setTimeout(() => {
      indicateModalFormValidationFailed()
    }, delay2)
  }

  addExistingWellToRigBooking = (wellId) => {
    const { fetchSingleWellInstance } = this.props
    fetchSingleWellInstance(wellId)
  }

  pushNewWellIntoRigBooking = () => {
    const { singleWellToAddToRigBooking, data, onUpdate, clearSingleWellToAddToRigBooking } = this.props

    const obj = {}
    obj.wells = data.wells

    const newWell = cloneDeep(singleWellToAddToRigBooking)
    newWell.sequence_priority = obj.wells.length + 1
    newWell.rig_booking_id = data.id
    newWell.id = (2 + data.wells.length) * -1

    // TODO

    obj.wells.push(newWell)
    onUpdate(obj)
    this.setMode({
      type: "edit",
      id: newWell.id,
      data: newWell,
      expanded: true,
    })

    clearSingleWellToAddToRigBooking()
  }

  deleteWell = (well) => {
    const { onUpdate, data } = this.props

    // If the well is not saved
    if (well.id < 0) {
      const obj = {}
      obj.wells = data.wells
      const wellIndex = data.wells.findIndex((w) => w.id === well.id)
      obj.wells.splice(wellIndex, 1)
      onUpdate(obj)
      this.setMode({
        type: "campaign",
        expanded: false,
      })
    } else {
      const obj = {}
      obj.wells = cloneDeep(data.wells)
      const wellIndex = data.wells.findIndex((w) => w.id === well.id)

      obj.wells[wellIndex].to_be_removed = true
      onUpdate(obj)
    }
  }

  extractWellFromThisSequence = (well) => {
    const { onUpdate, data } = this.props
    const obj = {}
    obj.wells = cloneDeep(data.wells)
    const wellIndex = data.wells.findIndex((w) => w.id === well.id)

    obj.wells[wellIndex].to_be_extracted = true
    onUpdate(obj)
  }

  duplicateWell = (well) => {
    const { onUpdate, data } = this.props

    const obj = {}
    obj.wells = data.wells

    const newWell = cloneDeep(well)
    newWell.id = (1 + data.wells.length) * -1

    delete newWell.well.id
    newWell.well_id = null
    newWell.rig_booking_id = null

    newWell.sequence_priority = obj.wells.length + 1

    obj.wells.push(newWell)

    onUpdate(obj)

    this.setMode({
      type: "edit",
      id: newWell.id,
      data: newWell,
      expanded: true,
    })
  }

  addWell = () => {
    const { onUpdate, data } = this.props

    const obj = {}
    obj.wells = data.wells

    const newWell = cloneDeep(obj.wells[0])

    Object.keys(newWell).forEach((key) => {
      newWell[key] = null
    })

    newWell.estimate = 1
    newWell.estimateData = {
      p10: {
        estimate: 1,
        start_date: new Date(),
      },
      p50: {
        estimate: 1,
        start_date: new Date(),
      },
      p90: {
        estimate: 1,
        start_date: new Date(),
      },
    }
    newWell.well_type = ""
    newWell.id = (2 + data.wells.length) * -1
    newWell.status = CONCEPTIONAL.type
    newWell.start_date = obj.wells[0].start_date
    newWell.end_date = obj.wells[0].end_date
    newWell.well = {
      name: "",
      block_id: null,
      license_id: null,
    }

    newWell.sequence_priority = obj.wells.length + 1
    obj.wells.push(newWell)

    // Add global variables if there is a well
    if (obj.wells[0]) {
      const globalVariables = BU.globalVariables()

      globalVariables.forEach((val) => {
        const variable = val.replace("well.", "")
        newWell.well[variable] = obj.wells[0].well[variable]
      })
    }

    onUpdate(obj)

    // TODO:  Why was this called twice?
    // onUpdate(obj);

    this.setMode({
      type: "edit",
      id: newWell.id,
      data: newWell,
      expanded: true,
    })
  }

  handleCommentChange = (comment, wellInstanceId) => {
    const { onUpdate, data } = this.props

    const obj = {}
    // obj.status = status.value;
    const selectedWellIndex = data.wells.findIndex((well) => well.id === wellInstanceId)
    obj[`wells[${selectedWellIndex}].well.comment`] = comment
    onUpdate(obj)
  }

  handleInlineEstimateChange = (estimate, wellInstanceId, pversion) => {
    const { onUpdate, data } = this.props

    const obj = {}
    const selectedWellIndex = data.wells.findIndex((well) => well.id === wellInstanceId)

    if (pversion === "actual_duration") {
      obj[`wells[${selectedWellIndex}].actual_duration`] = estimate
    } else {
      obj[`wells[${selectedWellIndex}].estimateData.${pversion}.estimate`] = estimate
    }

    onUpdate(obj)
  }

  updateWellsAfterDragAndDrop = () => {
    const { onUpdate } = this.props

    const obj = {}
    onUpdate(obj)
  }

  setMode = (mode) => {
    this.setState({
      mode,
    })
  }

  render() {
    const {
      data,
      guiProps: { selectedBuilderPVersion },
    } = this.props
    const { mode, failedStatusChange } = this.state

    if (!data) {
      return <div className="PanelContent__Container PanelCampaign__Container PanelForm">Loading...</div>
    }

    return (
      <div className="PanelContent__Container PanelCampaign__Container PanelForm">
        <DragnDropContainer
          key={"DragnDropContainer"}
          data={data}
          wellLength={data.wells.length}
          selectedBuilderPVersion={selectedBuilderPVersion}
          setMode={this.setMode}
          addWell={this.addWell}
          duplicateWell={this.duplicateWell}
          deleteWell={this.deleteWell}
          extractWell={this.extractWellFromThisSequence}
          addExistingWellToRigBooking={this.addExistingWellToRigBooking}
          updateWellsAfterDragAndDrop={this.updateWellsAfterDragAndDrop}
          handleInlineEstimateChange={this.handleInlineEstimateChange}
          mode={mode}
        />
        <CampaignInfoContainer
          data={data}
          onFieldUpdate={this.onFieldUpdate}
          handleStatusChange={this.handleStatusChange}
          handleCommentChange={this.handleCommentChange}
          setMode={this.setMode}
          failedStatusChange={failedStatusChange}
          mode={mode}
        />
      </div>
    )
  }
}

InputWellCampaignInfo.propTypes = {
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
  shakeIncrement: PropTypes.number,
  onValidated: PropTypes.func,
  indicateModalFormValidationFailed: PropTypes.func,
  fetchSingleWellInstance: PropTypes.func,
  singleWellToAddToRigBooking: PropTypes.object,
  guiProps: PropTypes.object,
  clearSingleWellToAddToRigBooking: PropTypes.func,
  domain: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    shakeIncrement: state.application.shakeIncrement,
    singleWellToAddToRigBooking: state.schedulerData.singleWellToAddToRigBooking,
    domain: state.application.domain,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputWellCampaignInfo)
