import React, { Component } from "react"
import PropTypes from "prop-types"

class PanelEstimate extends Component {
  render() {
    const { id, customInputStyle, data, name, onFieldUpdate, label } = this.props

    const value = data.wells[id][name] ? data.wells[id][name] : ""
    const active = value !== "" ? "active" : ""

    return (
      <div className="PanelForm__Estimate">
        <div style={customInputStyle} className="PanelForm__Estimate__Version">
          {label}
        </div>
        <div className={"PanelForm__InputPart"}>
          <input
            onChange={(elem) => onFieldUpdate(elem, id)}
            value={value}
            className={`PanelForm__textinput ${active}`}
            style={customInputStyle}
            name={name}
            keyboardtype="numeric"
            placeholder="Days"
          />
        </div>
      </div>
    )
  }
}

PanelEstimate.propTypes = {
  id: PropTypes.number,
  customInputStyle: PropTypes.object,
  data: PropTypes.object,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onFieldUpdate: PropTypes.func,
}

export default PanelEstimate
