import React from "react"

const DraftMaster = (style, key) => (
  <svg key={key} style={style} width="21px" height="21px" viewBox="0 0 21 21" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons/Sidebar/Master-Draft" transform="translate(-2.000000, -2.000000)" fill="#000000">
        <g id="Group-29" transform="translate(2.000000, 2.000000)">
          <path
            d="M0.984375,20.5981781 L0.65625,20.5981781 C0.29334375,20.5981781 0,20.3048344 0,19.9419281 L0,19.6138031 C0,19.2508969 0.29334375,18.9575531 0.65625,18.9575531 C0.92203125,18.9575531 1.15171875,19.1157094 1.25475,19.3434281 C1.48246875,19.4464594 1.640625,19.6761469 1.640625,19.9419281 C1.640625,20.3048344 1.34728125,20.5981781 0.984375,20.5981781"
            id="Fill-1"
          ></path>
          <path
            d="M0.65625,9.84119062 C0.29334375,9.84119062 0,9.54784687 0,9.18494062 L0,8.57134687 C0,8.20909688 0.29334375,7.91509687 0.65625,7.91509687 C1.01915625,7.91509687 1.3125,8.20909688 1.3125,8.57134687 L1.3125,9.18494062 C1.3125,9.54784687 1.01915625,9.84119062 0.65625,9.84119062 M0.65625,13.5220969 C0.29334375,13.5220969 0,13.2287531 0,12.8658469 L0,12.2522531 C0,11.8893469 0.29334375,11.5960031 0.65625,11.5960031 C1.01915625,11.5960031 1.3125,11.8893469 1.3125,12.2522531 L1.3125,12.8658469 C1.3125,13.2287531 1.01915625,13.5220969 0.65625,13.5220969 M0.65625,17.2030031 C0.29334375,17.2030031 0,16.9096594 0,16.5467531 L0,15.9331594 C0,15.5702531 0.29334375,15.2769094 0.65625,15.2769094 C1.01915625,15.2769094 1.3125,15.5702531 1.3125,15.9331594 L1.3125,16.5467531 C1.3125,16.9096594 1.01915625,17.2030031 0.65625,17.2030031"
            id="Fill-3"
          ></path>
          <path
            d="M0.65625,6.16067812 C0.29334375,6.16067812 0,5.86733438 0,5.50442812 L0,5.17630312 C0,4.92627187 0.14240625,4.69789688 0.3661875,4.58764688 C0.59128125,4.47805312 0.85903125,4.50430312 1.05590625,4.65589688 L1.3164375,4.85605312 C1.60321875,5.07720937 1.6576875,5.48933438 1.43653125,5.77611563 C1.341375,5.90080312 1.21078125,5.98086562 1.06903125,6.01433437 C0.95615625,6.10620938 0.8124375,6.16067812 0.65625,6.16067812"
            id="Fill-5"
          ></path>
          <path
            d="M3.31649063,7.8794625 C3.17670938,7.8794625 3.03627188,7.83549375 2.91749063,7.74361875 L2.51717813,7.4358375 C2.22974062,7.21468125 2.17658437,6.80255625 2.39708437,6.515775 C2.61758437,6.227025 3.02970937,6.17386875 3.31714688,6.395025 L3.71680313,6.70280625 C4.00489688,6.9239625 4.05805313,7.3360875 3.83755313,7.62286875 C3.70827188,7.791525 3.51336563,7.8794625 3.31649063,7.8794625"
            id="Fill-7"
          ></path>
          <path
            d="M5.578125,9.6187875 C5.43440625,9.6187875 5.2933125,9.57219375 5.17846875,9.48294375 L4.9179375,9.2827875 C4.63115625,9.06163125 4.5766875,8.64950625 4.79784375,8.362725 C4.948125,8.168475 5.187,8.08185 5.41340625,8.11335 C5.61225,7.9965375 5.8655625,7.989975 6.0781875,8.1146625 C6.38990625,8.2984125 6.49425,8.70135 6.30984375,9.01306875 L6.14315625,9.2959125 C6.048,9.45800625 5.887875,9.57088125 5.7028125,9.60631875 C5.66146875,9.61485 5.61946875,9.6187875 5.578125,9.6187875"
            id="Fill-9"
          ></path>
          <path
            d="M8.71322812,4.31241562 C8.59969687,4.31241562 8.48485312,4.28288437 8.37985313,4.22119687 C8.06813437,4.03679062 7.96444687,3.63450937 8.14885312,3.32213437 L8.41857187,2.86538437 C8.60297812,2.55432187 9.00525937,2.45129062 9.31763437,2.63372812 C9.62935312,2.81879062 9.73304062,3.22107187 9.54863437,3.53344687 L9.27891562,3.99019687 C9.15685312,4.19691562 8.93766562,4.31241562 8.71322812,4.31241562 M7.09360312,7.05291562 C6.98007188,7.05291562 6.86588438,7.02338437 6.76022813,6.96169687 C6.44850937,6.77729062 6.34547812,6.37500937 6.52922813,6.06263437 L6.79894688,5.60588437 C6.98335313,5.29416562 7.38563438,5.19179062 7.69800937,5.37422812 C8.01038437,5.55863437 8.11341563,5.96157187 7.92966563,6.27329062 L7.65994687,6.73069687 C7.53722813,6.93741562 7.31804063,7.05291562 7.09360312,7.05291562"
            id="Fill-11"
          ></path>
          <path
            d="M10.6672781,1.57204687 C10.6108406,1.57204687 10.5544031,1.56482812 10.4999344,1.55039062 C10.3358719,1.59501562 10.1567156,1.57335937 9.99987188,1.48082812 C9.68815312,1.29707812 9.58446562,0.894796875 9.76821562,0.582421875 L9.93490312,0.300234375 C10.1704969,-0.100078125 10.8293719,-0.100078125 11.0649656,0.300234375 L11.2316531,0.582421875 C11.4160594,0.894796875 11.3117156,1.29707812 10.9999969,1.48082812 C10.8956531,1.54251562 10.7808094,1.57204687 10.6672781,1.57204687"
            id="Fill-13"
          ></path>
          <path
            d="M12.2867719,4.31241562 C12.0623344,4.31241562 11.8431469,4.19691562 11.7210844,3.99019687 L11.4513656,3.53344687 C11.2669594,3.22107187 11.3706469,2.81879062 11.6823656,2.63372812 C11.9960531,2.45129062 12.3976781,2.55432187 12.5814281,2.86538437 L12.8511469,3.32213437 C13.0355531,3.63450937 12.9318656,4.03679062 12.6201469,4.22119687 C12.5151469,4.28288437 12.4003031,4.31241562 12.2867719,4.31241562 M13.9063969,7.05225937 C13.6819594,7.05225937 13.4634281,6.93675937 13.3407094,6.73004062 L13.0709906,6.27329062 C12.8865844,5.96157187 12.9896156,5.55863437 13.3019906,5.37488437 C13.6137094,5.19113437 14.0166469,5.29416562 14.2003969,5.60588437 L14.4701156,6.06197812 C14.6545219,6.37435312 14.5514906,6.77663437 14.2397719,6.96038437 C14.1347719,7.02272812 14.0199281,7.05225937 13.9063969,7.05225937"
            id="Fill-15"
          ></path>
          <path
            d="M15.421875,9.6187875 C15.3805313,9.6187875 15.3385313,9.61485 15.2971875,9.60631875 C15.112125,9.57088125 14.952,9.45800625 14.8568437,9.2959125 L14.6901563,9.01306875 C14.50575,8.70135 14.6100937,8.2984125 14.9218125,8.1146625 C15.1337813,7.989975 15.38775,7.9965375 15.5865938,8.11335 C15.81825,8.08250625 16.0525313,8.168475 16.2021563,8.362725 C16.4233125,8.64950625 16.3688438,9.06163125 16.0820625,9.2827875 L15.8215313,9.48294375 C15.7066875,9.57219375 15.5655938,9.6187875 15.421875,9.6187875"
            id="Fill-17"
          ></path>
          <path
            d="M17.6835094,7.8794625 C17.4866344,7.8794625 17.2917281,7.791525 17.1624469,7.62286875 C16.9419469,7.3360875 16.9951031,6.9239625 17.2831969,6.70280625 L17.6828531,6.395025 C17.9702906,6.17386875 18.3811031,6.227025 18.6029156,6.515775 C18.8234156,6.80255625 18.7702594,7.21468125 18.4828219,7.4358375 L18.0825094,7.74361875 C17.9637281,7.83549375 17.8232906,7.8794625 17.6835094,7.8794625"
            id="Fill-19"
          ></path>
          <path
            d="M20.34375,6.16067812 C20.1875625,6.16067812 20.0438437,6.10620938 19.9309687,6.01433437 C19.7892187,5.98086562 19.658625,5.90080312 19.5634687,5.77611563 C19.3423125,5.48933438 19.3967812,5.07720937 19.6835625,4.85605312 L19.9440937,4.65589688 C20.141625,4.50430312 20.409375,4.47805312 20.6338125,4.58764688 C20.8575937,4.69789688 21,4.92627187 21,5.17630312 L21,5.50442812 C21,5.86733438 20.7066562,6.16067812 20.34375,6.16067812"
            id="Fill-21"
          ></path>
          <path
            d="M20.34375,9.84119062 C19.9808437,9.84119062 19.6875,9.54784687 19.6875,9.18494062 L19.6875,8.57134687 C19.6875,8.20909688 19.9808437,7.91509687 20.34375,7.91509687 C20.7066563,7.91509687 21,8.20909688 21,8.57134687 L21,9.18494062 C21,9.54784687 20.7066563,9.84119062 20.34375,9.84119062 M20.34375,13.5220969 C19.9808437,13.5220969 19.6875,13.2287531 19.6875,12.8658469 L19.6875,12.2522531 C19.6875,11.8893469 19.9808437,11.5960031 20.34375,11.5960031 C20.7066563,11.5960031 21,11.8893469 21,12.2522531 L21,12.8658469 C21,13.2287531 20.7066563,13.5220969 20.34375,13.5220969 M20.34375,17.2030031 C19.9808437,17.2030031 19.6875,16.9096594 19.6875,16.5467531 L19.6875,15.9331594 C19.6875,15.5702531 19.9808437,15.2769094 20.34375,15.2769094 C20.7066563,15.2769094 21,15.5702531 21,15.9331594 L21,16.5467531 C21,16.9096594 20.7066563,17.2030031 20.34375,17.2030031"
            id="Fill-23"
          ></path>
          <path
            d="M20.34375,20.5981781 L20.015625,20.5981781 C19.6527187,20.5981781 19.359375,20.3048344 19.359375,19.9419281 C19.359375,19.6761469 19.5175313,19.4464594 19.74525,19.3434281 C19.8482812,19.1157094 20.0779688,18.9575531 20.34375,18.9575531 C20.7066563,18.9575531 21,19.2508969 21,19.6138031 L21,19.9419281 C21,20.3048344 20.7066563,20.5981781 20.34375,20.5981781"
            id="Fill-25"
          ></path>
          <path
            d="M4.921875,20.5981781 L4.265625,20.5981781 C3.90271875,20.5981781 3.609375,20.3048344 3.609375,19.9419281 C3.609375,19.5790219 3.90271875,19.2856781 4.265625,19.2856781 L4.921875,19.2856781 C5.28478125,19.2856781 5.578125,19.5790219 5.578125,19.9419281 C5.578125,20.3048344 5.28478125,20.5981781 4.921875,20.5981781 M8.859375,20.5981781 L8.203125,20.5981781 C7.84021875,20.5981781 7.546875,20.3048344 7.546875,19.9419281 C7.546875,19.5790219 7.84021875,19.2856781 8.203125,19.2856781 L8.859375,19.2856781 C9.22228125,19.2856781 9.515625,19.5790219 9.515625,19.9419281 C9.515625,20.3048344 9.22228125,20.5981781 8.859375,20.5981781 M12.796875,20.5981781 L12.140625,20.5981781 C11.7777188,20.5981781 11.484375,20.3048344 11.484375,19.9419281 C11.484375,19.5790219 11.7777188,19.2856781 12.140625,19.2856781 L12.796875,19.2856781 C13.1597812,19.2856781 13.453125,19.5790219 13.453125,19.9419281 C13.453125,20.3048344 13.1597812,20.5981781 12.796875,20.5981781 M16.734375,20.5981781 L16.078125,20.5981781 C15.7152188,20.5981781 15.421875,20.3048344 15.421875,19.9419281 C15.421875,19.5790219 15.7152188,19.2856781 16.078125,19.2856781 L16.734375,19.2856781 C17.0972813,19.2856781 17.390625,19.5790219 17.390625,19.9419281 C17.390625,20.3048344 17.0972813,20.5981781 16.734375,20.5981781"
            id="Fill-27"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default DraftMaster
