import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

const requiredFields = []

class SelectRigsRemovePVersion extends PureComponent {
  onFieldUpdate = () => {
    const { onUpdate, data } = this.props

    const obj = {}
    obj.rig_instance_id = data.rigInstanceId

    onUpdate(obj, "array")
  }

  validateForm() {
    const { data } = this.props

    let validated = true
    const keys = Object.keys(data)
    validated = requiredFields.every((key) => keys.includes(key))

    return validated
  }

  componentDidMount() {
    const { index, onValidated, data } = this.props
    if (data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data, onUpdate } = this.props

    if (data !== prevProps.data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }

    if (data.rig_instance_id !== prevProps.data.rig_instance_id) {
      const obj = {}
      obj.rig_instance_id = data.rigInstanceId
      onUpdate(obj, "array")
    }
  }

  render() {
    const { data } = this.props

    if (!data.rigInstanceId) {
      return null
    }

    return (
      <div className="PanelContent__Container PanelForm">
        <div className="PanelForm__InputPart PanelForm__InputPart--checkbox">
          <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
            <label style={{ textAlign: "center" }} className="radio-input__mainlabel">
              Are you sure you want to remove this Rig?
            </label>
          </div>
          <span className="PanelForm__inputinfo">The rig will only be removed for this draft.</span>
        </div>
      </div>
    )
  }
}

SelectRigsRemovePVersion.propTypes = {
  onUpdate: PropTypes.func,
  versions: PropTypes.array,
  data: PropTypes.object,
  index: PropTypes.number,
  onValidated: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    versions: state.schedulerData.schedulerData.data.versions,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectRigsRemovePVersion)
