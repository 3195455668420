import React, { Component } from "react"
import PropTypes from "prop-types"
import isAfter from "date-fns/isAfter"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import DayPickerElement from "../../../components/form/DayPickerElement"
import InputCheckboxElement from "../../../components/form/InputCheckboxElement"
import ErrorMessage from "../../../components/error/ErrorMessage"
import { ActionCreators } from "../../../redux/actions"
import { DATE_FORMATE_NB } from "../../../dateConsts"

import { EARLIEST_START_DATE, LAST_POSSIBLE_END_DATE, START_DATE_IS_HARD, END_DATE_IS_HARD } from "../../../registerConsts"

const validationKeys = [EARLIEST_START_DATE, LAST_POSSIBLE_END_DATE]

class InputTimeframe extends Component {
  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[elem.target.name] = elem.target.checked

    onUpdate(obj)
  }

  handleStartDayChange = (day) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[EARLIEST_START_DATE] = day

    onUpdate(obj)
  }

  handleEndDayChange = (day) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[LAST_POSSIBLE_END_DATE] = day

    onUpdate(obj)
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props

    if (data !== prevProps.data) {
      const validated = this.checkValidation(data)
      onValidated(index, validated)
    }
  }

  componentDidMount() {
    const { index, onValidated, data } = this.props
    const validated = this.checkValidation(data)
    onValidated(index, validated)
  }

  checkValidation = (data) => {
    let validated = true
    const keys = Object.keys(data)

    validated = validationKeys.every((key) => keys.includes(key))

    // IF LAST HARD DATE IS BEFORE FIRST DATE SET INVALID
    if (
      data[EARLIEST_START_DATE] &&
      data[LAST_POSSIBLE_END_DATE] &&
      isAfter(new Date(data[EARLIEST_START_DATE]), new Date(data[LAST_POSSIBLE_END_DATE]))
    ) {
      validated = false
    }

    return validated
  }

  render() {
    const { data } = this.props

    const earliestDate = data[EARLIEST_START_DATE] ? new Date(data[EARLIEST_START_DATE]) : null
    const latestDate = data[LAST_POSSIBLE_END_DATE] ? new Date(data[LAST_POSSIBLE_END_DATE]) : null

    let error = null

    if (isAfter(earliestDate, latestDate)) {
      error = "Start date needs to be before end date"
    }

    return (
      <div className="PanelContent__Container PanelForm">
        <DayPickerElement
          label="Earliest start date"
          onChange={this.handleStartDayChange}
          styles={{ marginBottom: 5 }}
          format={DATE_FORMATE_NB}
          value={earliestDate}
        />
        <InputCheckboxElement
          label="Hard date"
          desc=""
          name={START_DATE_IS_HARD}
          value={data[START_DATE_IS_HARD]}
          onUpdate={this.onFieldUpdate}
          styles={{ marginBottom: 5 }}
        />
        <DayPickerElement
          label="Latest possible end date"
          onChange={this.handleEndDayChange}
          styles={{ marginBottom: 5 }}
          format={DATE_FORMATE_NB}
          value={latestDate}
        />
        <InputCheckboxElement
          label="Hard date"
          desc=""
          name={END_DATE_IS_HARD}
          value={data[END_DATE_IS_HARD]}
          onUpdate={this.onFieldUpdate}
          styles={{ marginTop: 5 }}
        />
        {error ? ErrorMessage(error) : null}
      </div>
    )
  }
}

InputTimeframe.propTypes = {
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
  onValidated: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InputTimeframe)
