import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"

import { bindActionCreators } from "redux"
import ShareToElm from "../../../components/form/ShareToElm"
import ShareToObj from "../../../components/form/ShareToObj"
import RoundedDropDown from "../../../components/dropdown/RoundedDropDown"
import { ActionCreators } from "../../../redux/actions"
import VU from "../../../lib/ValidateUtils"

class ConfirmShareScheduleSubpage extends Component {
  constructor() {
    super()
    this.state = {
      currentText: "",
      // currentComment: '',
      shareSuccess: null,
      notEmail: false,
    }

    this.userScroll = React.createRef()
  }

  componentDidUpdate = (prevProps) => {
    const { wasNotificationASuccess, onUpdate, data } = this.props

    if (wasNotificationASuccess !== prevProps.wasNotificationASuccess) {
      if (wasNotificationASuccess === true) {
        this.setState({
          shareSuccess: true,
        })
        onUpdate("add", {
          users: {},
          comment: "",
        })
      } else if (wasNotificationASuccess === false) {
        this.setState({
          shareSuccess: false,
        })
      }
    }

    if (data !== prevProps.data) {
      this.scrollToBottom()
    }
  }

  updateCurrentShare = (e) => {
    const { value } = e.target

    if (value !== "" || value !== null) {
      this.setState({
        currentText: value,
      })
    }
  }

  updateCurrentComment = (e) => {
    const { value } = e.target

    if (value !== "" || value !== null) {
      const { onUpdate, data } = this.props

      const newData = cloneDeep(data)
      newData.comment = value

      onUpdate("add", newData)
    }
  }

  addShares = (values) => {
    if (values.length > 0) {
      // const { currentComment } = this.state;
      const { data, onUpdate } = this.props
      let share = {}
      let shares = {}
      let allOk = true

      values.forEach((val) => {
        if (val !== "" || val !== null) {
          const lowercaseValue = val.toLowerCase()
          const email = VU.isEmail(lowercaseValue)

          if (email) {
            share = ShareToObj(lowercaseValue, "added")
            shares = Object.assign(share, shares)
          } else {
            allOk = false
          }

          this.setState({
            currentText: "",
          })
        } else {
          allOk = false
        }
      })

      if (allOk === true) {
        const oldShares = cloneDeep(data.users)
        const newShares = Object.assign(shares, oldShares)
        const newData = {}
        newData.users = newShares
        newData.comment = data.comment

        onUpdate("add", newData)
      }

      this.setState({
        notEmail: !allOk,
      })
    }
  }

  handleEnterPress = (e) => {
    if (e.keyCode === 13) {
      const { value } = e.target
      this.addShares([value])
      e.preventDefault()
    }
  }

  removeShare = (id) => {
    const { data, onUpdate } = this.props

    const sharedTo = cloneDeep(data)
    delete sharedTo.users[id]

    onUpdate("add", sharedTo)
  }

  addGroup = (group) => {
    const shares = group.users.map((val) => val.user_id)
    this.addShares(shares)
  }

  renderGroup = (key, val) => (
    <div onClick={() => this.addGroup(val)} className="RoundedDropDown__Option RoundedDropDown__Option--asset" key={key}>
      <span className="RoundedDropDown__Option-Value">{val.name}</span>
    </div>
  )

  scrollToBottom = () => {
    this.userScroll.current.scrollTop = this.userScroll.current.scrollHeight
  }

  render() {
    const { data, shareGroups } = this.props
    const { currentText, shareSuccess, notEmail } = this.state

    const header = <div className="UpdateShareOfDraft__Header">Add more people to access the draft.</div>

    let successMessage = null

    if (shareSuccess === true && data.comment === "" && Object.keys(data.users).length === 0) {
      successMessage = <span>Success, schedule have been shared!</span>
    } else if (shareSuccess === false) {
      successMessage = <span>Success, schedule have been shared!</span>
    }

    return (
      <div className="PanelContent__Container UpdateShareOfDraft">
        {header}
        <div className="ShareTo"></div>
        <div className="sharegroup">
          <div ref={this.userScroll} className="Shares">
            {Object.entries(data.users).map((entry) => ShareToElm(entry[0], entry[1].classes, this.removeShare))}
            <input
              key="input"
              value={currentText}
              type="textbox"
              placeholder={"Add user"}
              onChange={(e) => this.updateCurrentShare(e)}
              onKeyDown={(e) => this.handleEnterPress(e)}
            />
          </div>
          <div className="addGroup">
            <RoundedDropDown
              clearFunction={() => {}}
              label={"Add group"}
              hasSelectedValue={false}
              renderoption={this.renderGroup}
              data={shareGroups || []}
            />
          </div>
        </div>
        <div className="addComment">
          <textarea
            key="input"
            value={data.comment}
            type="textbox"
            placeholder={"Add a comment to this draft"}
            onChange={(e) => this.updateCurrentComment(e)}
          />
        </div>
        {successMessage}
        {notEmail ? <span>This is not a valid email address</span> : null}
      </div>
    )
  }
}

ConfirmShareScheduleSubpage.propTypes = {
  data: PropTypes.object,
  shareGroups: PropTypes.array,
  onUpdate: PropTypes.func,
  notifySharesSuccess: PropTypes.func,
  wasNotificationASuccess: PropTypes.any,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    wasNotificationASuccess: state.schedulerData.wasNotificationASuccess,
    shareGroups: state.schedulerData.shareGroups,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmShareScheduleSubpage)
