import PropTypes from "prop-types"
import React, { Component } from "react"

import BuilderUtils from "../../../lib/BuilderUtils"
import ConditionalField, { ConditionalFieldBlock } from "../../common/ConditionalField"
import WellStatusName from "../../common/WellStatusName"

class EditDetailsPlanForExecution extends Component {
  onFieldUpdate = (elem) => {
    const { onFieldUpdate, data } = this.props
    let { value } = elem.target
    const { checked } = elem.target
    if (elem.target.type === "checkbox") {
      value = !!checked
    }
    if (elem.target.type === "radio") {
      value = value === "true"
    }
    onFieldUpdate(elem.target.name, value, data.id)
  }

  render() {
    const { data, failedStatusChange } = this.props
    return (
      <ConditionalFieldBlock scope="well" fields={BuilderUtils.fieldsPrStatus("plan_for_execution")}>
        <div
          id="plan_for_execution"
          className={`Details-edit__section${failedStatusChange === "plan_for_execution" ? " validation-failed" : ""}`}
        >
          <h3>
            <WellStatusName statusKey="plan_for_execution" />
          </h3>
          <div className="Details-edit__wrapper">
            <div className="Details-edit__part">
              <ConditionalField scope="well" field="afc">
                <div className={"Details-edit__radiowrapper radio-input required radio-input--horizontal"}>
                  <label className="radio-input__mainlabel">
                    AfC
                    <span className="radio-input__sublabel">(Application For Concent)</span>
                    <span className="radio-input__sublabel">* Required</span>
                  </label>
                  <div className="radio-input__optionwrapper">
                    <label className={`radio-input__option${data.well.afc ? " selected" : ""}`}>
                      <span className="radio-input__label">Yes</span>
                      <span className="check-indicator"></span>
                      <input onChange={this.onFieldUpdate} className="radio-input__radio" type="radio" name="well.afc" value={true} />
                    </label>
                    <label className={`radio-input__option${data.well.afc ? "" : " selected"}`}>
                      <span className="radio-input__label">No</span>
                      <span className="check-indicator"></span>
                      <input onChange={this.onFieldUpdate} className="radio-input__radio" type="radio" name="well.afc" value={false} />
                    </label>
                  </div>
                </div>
              </ConditionalField>
              <ConditionalField scope="well" field="afd">
                <div className={"Details-edit__radiowrapper radio-input required radio-input--horizontal"}>
                  <label className="radio-input__mainlabel">
                    AfD
                    <span className="radio-input__sublabel">(Application For Discharge)</span>
                    <span className="radio-input__sublabel">* Required</span>
                  </label>
                  <div className="radio-input__optionwrapper">
                    <label className={`radio-input__option${data.well.afd ? " selected" : ""}`}>
                      <span className="radio-input__label">Yes</span>
                      <span className="check-indicator"></span>
                      <input onChange={this.onFieldUpdate} className="radio-input__radio" type="radio" name="well.afd" value={true} />
                    </label>
                    <label className={`radio-input__option${data.well.afd ? "" : " selected"}`}>
                      <span className="radio-input__label">No</span>
                      <span className="check-indicator"></span>
                      <input onChange={this.onFieldUpdate} className="radio-input__radio" type="radio" name="well.afd" value={false} />
                    </label>
                  </div>
                </div>
              </ConditionalField>
            </div>
          </div>
        </div>
      </ConditionalFieldBlock>
    )
  }
}

EditDetailsPlanForExecution.propTypes = {
  data: PropTypes.object,
  onFieldUpdate: PropTypes.func,
  failedStatusChange: PropTypes.string,
}

export default EditDetailsPlanForExecution
