import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

import ContentList from "../Bits/ContentList"

class EditCognitoUsers extends Component {
  componentDidMount() {
    const { getCognitoUsers, getCognitoGroups } = this.props
    getCognitoUsers()
    getCognitoGroups()
  }

  handleDeleteFormSubmit = (selectedItems) => {
    const { deleteCognitoUser } = this.props
    selectedItems.forEach(deleteCognitoUser)
  }

  editItem = (item) => {
    const { editSingleItem } = this.props
    editSingleItem("cognitouser", item)
  }

  addItem = () => {
    const { editSingleItem } = this.props
    editSingleItem("cognitouser", { groups: [] })
  }

  render() {
    const { users: rawUsers } = this.props
    const users = rawUsers.map((u) => Object.assign(u, { renderChip: (u.groups || [])[0] }))
    return (
      <div className="ManageContent__Content">
        <ContentList
          addItem={this.addItem}
          editItem={this.editItem}
          deleteItemsFunc={this.handleDeleteFormSubmit}
          header={"Edit users"}
          items={users}
        />
      </div>
    )
  }
}

EditCognitoUsers.propTypes = {
  getCognitoGroups: PropTypes.func,
  getCognitoUsers: PropTypes.func,
  addCognitoUsers: PropTypes.func,
  deleteCognitoUser: PropTypes.func,
  editSingleItem: PropTypes.func,
  users: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    users: state.managecontent.cognitousers.users.map((u) => ({
      ...u,
      name: u.email,
    })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCognitoUsers)
