import React, { Component } from "react"
import PropTypes from "prop-types"
import { cloneDeep } from "lodash"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../../redux/actions"
import PanelFooter from "../../../../components/panel/PanelFooter"
import PanelHeader from "../../../../components/panel/PanelHeader"
import PanelMenu from "../../../../components/panel/PanelMenu"
import PH from "../../../../lib/PanelHelper"

class ConnectExistingRig extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pages: props.pages,
      options: props.options,
      validated: {},
      postData: {},
    }
  }

  onSubmit = () => {
    const { addExistingRigToSchedule } = this.props
    addExistingRigToSchedule(this.state.postData)
  }

  setSubPageValidated = (index, isValidated) => {
    const validated = cloneDeep(this.state.validated)
    validated[index] = isValidated
    this.setState({
      validated,
    })
  }

  componentDidMount() {
    const {
      panelOpen: { index },
    } = this.props
    const { pages, validated } = this.state

    // TODO: if object has prod that it does not need to be validated
    if (!validated[index]) {
      if (pages[index].options.validated) {
        this.setSubPageValidated(index, pages[index].options.validated)
      } else {
        this.setSubPageValidated(index, false)
      }
    }
  }

  onUpdate = (obj, type) => {
    const { postData } = this.state

    const nPostData = cloneDeep(postData)

    Object.entries(obj).forEach(([key, val]) => {
      if (type === "array") {
        if (typeof nPostData[key] === "undefined") {
          nPostData[key] = []
        }
        if (nPostData[key].includes(val)) {
          const index = nPostData[key].indexOf(val)
          if (index !== -1) nPostData[key].splice(index, 1)
        } else {
          nPostData[key].push(val)
        }
      } else {
        nPostData[key] = val
      }
    })

    this.setState({
      postData: nPostData,
    })
  }

  getPage = (index, pages, postData) =>
    PH.getPage(
      index,
      pages[index],
      (val, type) => this.onUpdate(val, type),
      (i, val) => this.setSubPageValidated(i, val),
      postData
    )

  render() {
    const { pages, validated, postData } = this.state
    const {
      panelOpen: { index },
    } = this.props
    return [
      <PanelHeader key={"PanelHeader"} />,
      <PanelMenu key={"PanelMenu"} />,
      <div key={"Panel__Page"} className="Panel__Page ">
        <div className="Panel__Page-Container PanelContent">{this.getPage(index, pages, postData)}</div>
        <PanelFooter index={index} data={pages[index]} validated={validated[index]} onSubmit={(val) => this.onSubmit(val)} />
      </div>,
    ]
  }
}

ConnectExistingRig.propTypes = {
  panelOpen: PropTypes.object,
  panelData: PropTypes.object,
  options: PropTypes.object,
  addExistingRigToSchedule: PropTypes.func,
  pages: PropTypes.array,
  index: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    panelOpen: state.application.panelOpen,
    panelData: state.application.panelData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectExistingRig)
