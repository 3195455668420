import { find } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"

import { DATE_FORMATE_NB } from "../../../dateConsts"
import { getReadableRigType } from "../../../lib/RigUtils"
import { getReadableWellType } from "../../../lib/WellUtils"
import ConditionalField, {
  ConditionalFieldBlock,
} from "../../common/ConditionalField"
import DayPickerElement from "../../form/DayPickerElement"
import PanelInputSelection from "../../panel/PanelInputSelection"

class EditDetailsBasic extends Component {
  handleStartDayChange = (day) => {
    const { onFieldUpdate, data } = this.props

    const value = day || null
    onFieldUpdate("earliest_start_date", value, data.id)
  }

  onFieldUpdate = (elem) => {
    const { onFieldUpdate, data } = this.props
    let { value } = elem.target
    const { checked } = elem.target
    if (elem.target.type === "checkbox") {
      switch (elem.target.name) {
        case "well.pressure":
          value = checked ? "hpht" : ""
          break
        default:
      }
    }
    if (elem.target.type === "radio") {
      switch (elem.target.name) {
        case "well.well_test":
        case "well.lwi_candidate":
        case "start_date_is_hard":
          value = value === "true"
          break
        default:
      }
    }

    onFieldUpdate(elem.target.name, value, data.id)
  }

  handleEventTypeChange = (eventType) => {
    const { data, onFieldUpdate } = this.props
    onFieldUpdate("event_type", eventType.value, data.id)
  }
  handleWellTypeChange = (wellType) => {
    const { data, onFieldUpdate } = this.props
    onFieldUpdate("well_type", wellType.value, data.id)
  }

  handleRigTypeChange = (rigType) => {
    const { data, onFieldUpdate } = this.props
    const rigTypeArray = rigType.map((r) => r.value)
    onFieldUpdate("rig_type", rigTypeArray, data.id)
  }

  handleAssetChange = (asset) => {
    const { data, onFieldUpdate } = this.props
    onFieldUpdate("well.asset_id", asset.id, data.id)
  }

  handleBlockChange = (block) => {
    const { data, onFieldUpdate } = this.props
    onFieldUpdate("well.block_id", block ? block.id : null, data.id)
  }

  handleLicenseChange = (license) => {
    const { data, onFieldUpdate } = this.props
    onFieldUpdate("well.license_id", license ? license.id : null, data.id)
  }

  handleContractChange = (contract) => {
    const { data, onFieldUpdate } = this.props
    onFieldUpdate("has_contract_with", contract ? contract.id : null, data.id)
  }

  render() {
    const {
      data,
      blocks,
      licenses,
      assets,
      rigList,
      activeRigTypes,
      activeWellTypes,
      activeEventTypes,
      units,
      user,
    } = this.props

    const selectedBlock = data.well.block_id
      ? find(blocks.data, { id: data.well.block_id })
      : null
    const selectedLicense = data.well.license_id
      ? find(licenses.data, { id: data.well.license_id })
      : null

    let blockName = null
    let licenseName = null

    if (selectedBlock) {
      blockName = { name: selectedBlock.name }
    }

    if (selectedLicense) {
      licenseName = { name: selectedLicense.name }
    }

    let assetId = null

    if (selectedLicense) {
      assetId = selectedLicense.asset_id
    }

    const selectedAsset = find(assets.data, { id: assetId })

    let assetName = null
    if (selectedAsset) {
      assetName = { name: selectedAsset.name }
    }
    if (data.well_type === "exploration") {
      assetName = { name: "Exploration" }
    }

    const earliestDate = data.earliest_start_date
      ? new Date(data.earliest_start_date)
      : null

    const filteredRigTypes = data.rig_type
      ? data.rig_type.filter((el) => el && el !== null)
      : []

    const rigTypesArray = filteredRigTypes
      ? filteredRigTypes.map((r) => ({ value: r, name: getReadableRigType(r) }))
      : null

    return (
      <>
        <div id="basic_information" className="Details-edit__section">
          <div className="Details-edit__wrapper">
            <div className="Details-edit__part">
              {user.role === "Creator" && (
                <div
                  className={`Details-edit__inputwrapper has-content readonly`}
                >
                  <label className="Details-edit__inputlabel">Created by</label>
                  <input
                    onChange={this.onFieldUpdate}
                    className={"Details-edit__input"}
                    value={data.well.created_by}
                    type="text"
                    name="well.created_by"
                    placeholder="Created by not set initially"
                    readOnly
                  />
                </div>
              )}

              <div
                className={`Details-edit__inputwrapper${
                  data.well.contact_person ? " has-content" : ""
                }`}
              >
                <label className="Details-edit__inputlabel">
                  Contact person
                </label>
                <input
                  onChange={this.onFieldUpdate}
                  className={"Details-edit__input"}
                  value={data.well.contact_person || ""}
                  type="text"
                  name="well.contact_person"
                  placeholder=""
                  readOnly={data.well.id && user.role !== "Creator"}
                />
              </div>

              <div
                className={`Details-edit__inputwrapper${
                  data.well.name ? " has-content" : ""
                }`}
              >
                <label className="Details-edit__inputlabel">
                  Legal well name
                </label>
                <input
                  onChange={this.onFieldUpdate}
                  className={"Details-edit__input"}
                  value={data.well.name || ""}
                  type="text"
                  name="well.name"
                  placeholder=""
                />
              </div>
              <div
                className={`Details-edit__inputwrapper${
                  data.well.short_name ? " has-content" : ""
                }`}
              >
                <label className="Details-edit__inputlabel">
                  Common well name *
                </label>
                <input
                  onChange={this.onFieldUpdate}
                  className={"Details-edit__input"}
                  value={data.well.short_name || ""}
                  type="text"
                  name="well.short_name"
                  placeholder=""
                />
              </div>
              {data && data.status && data.status !== "occupied" && (
                <div
                  className={`Details-edit__selectwrapper${
                    data.well_type ? " has-content" : ""
                  }`}
                >
                  {data.well_type ? (
                    <span className="Details-edit__selectlabel">
                      Well type *
                    </span>
                  ) : null}
                  <PanelInputSelection
                    value={
                      data.well_type
                        ? {
                            value: data.well_type,
                            name: getReadableWellType(data.well_type),
                          }
                        : null
                    }
                    options={activeWellTypes.map((a) => ({
                      value: a,
                      name: getReadableWellType(a),
                    }))}
                    placeholder="Well type"
                    classes="Select__Dropdown--small"
                    onChange={(welltype) => this.handleWellTypeChange(welltype)}
                  />
                </div>
              )}

              <div
                className={`Details-edit__selectwrapper${
                  data.event_type ? " has-content" : ""
                }`}
              >
                {data.event_type ? (
                  <span className="Details-edit__selectlabel">Event type</span>
                ) : null}
                <PanelInputSelection
                  value={
                    data.event_type
                      ? {
                          value: data.event_type,
                          name: data.event_type,
                        }
                      : null
                  }
                  options={activeEventTypes.map((a) => ({
                    value: a,
                    name: a,
                  }))}
                  placeholder="Well type"
                  classes="Select__Dropdown--small"
                  onChange={(eventType) =>
                    this.handleEventTypeChange(eventType)
                  }
                />
              </div>

              {data && data.status && data.status !== "occupied" && (
                <div
                  className={`Details-edit__selectwrapper${
                    data.well.block_id ? " has-content" : ""
                  }`}
                >
                  {blockName ? (
                    <span className="Details-edit__selectlabel">Block *</span>
                  ) : null}
                  <PanelInputSelection
                    value={blockName}
                    options={blocks.data}
                    isClearable={data.well_type === "exploration"}
                    placeholder="Select block *"
                    classes="Select__Dropdown--small"
                    onChange={(block) => this.handleBlockChange(block)}
                  />
                </div>
              )}
              {data && data.status && data.status !== "occupied" && (
                <div
                  className={`Details-edit__selectwrapper${
                    data.well.license_id ? " has-content" : ""
                  }`}
                >
                  {licenseName ? (
                    <span className="Details-edit__selectlabel">License *</span>
                  ) : null}
                  <PanelInputSelection
                    value={licenseName}
                    options={licenses.data}
                    isClearable={false}
                    placeholder="Select license *"
                    classes="Select__Dropdown--small"
                    onChange={(license) => this.handleLicenseChange(license)}
                  />
                  {assets.data.length > 1 && assetName && (
                    <div className="Details-edit__sublabels">
                      <span className="Details-edit__sublabel">
                        <strong>Asset:</strong>
                        <br />
                        {assetName.name}
                      </span>
                      <span className="Details-edit__sublabel"></span>
                    </div>
                  )}
                </div>
              )}
            </div>
            {data && data.status && data.status !== "occupied" && (
              <ConditionalFieldBlock
                scope="well"
                fields={[
                  "well_test",
                  "lwi_candidate",
                  "pressure",
                  "ocean_depth",
                ]}
              >
                <div className="Details-edit__part">
                  {data.well_type && data.well_type === "exploration" ? (
                    <ConditionalField scope="well" field="well_test">
                      <div
                        className={
                          "Details-edit__radiowrapper radio-input radio-input--horizontal"
                        }
                      >
                        <label className="radio-input__mainlabel">
                          Well test
                        </label>
                        <div className="radio-input__optionwrapper">
                          <label
                            className={`radio-input__option${
                              data.well.well_test ? " selected" : ""
                            }`}
                          >
                            <span className="radio-input__label">Yes</span>
                            <span className="check-indicator"></span>
                            <input
                              onChange={this.onFieldUpdate}
                              className="radio-input__radio"
                              type="radio"
                              name="well.well_test"
                              value={true}
                            />
                          </label>
                          <label
                            className={`radio-input__option${
                              data.well.well_test ? "" : " selected"
                            }`}
                          >
                            <span className="radio-input__label">No</span>
                            <span className="check-indicator"></span>
                            <input
                              onChange={this.onFieldUpdate}
                              className="radio-input__radio"
                              type="radio"
                              name="well.well_test"
                              value={false}
                            />
                          </label>
                        </div>
                      </div>
                    </ConditionalField>
                  ) : null}
                  <ConditionalField scope="well" field="lwi_candidate">
                    <div
                      className={
                        "Details-edit__radiowrapper radio-input radio-input--horizontal"
                      }
                    >
                      <label
                        className="radio-input__mainlabel"
                        style={{ paddingRight: "10px" }}
                      >
                        {"Candidate for vessel drilling"}
                      </label>
                      <div className="radio-input__optionwrapper">
                        <label
                          className={`radio-input__option${
                            data.well.lwi_candidate ? " selected" : ""
                          }`}
                        >
                          <span className="radio-input__label">Yes</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.lwi_candidate"
                            value={true}
                          />
                        </label>
                        <label
                          className={`radio-input__option${
                            data.well.lwi_candidate ? "" : " selected"
                          }`}
                        >
                          <span className="radio-input__label">No</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.lwi_candidate"
                            value={false}
                          />
                        </label>
                      </div>
                    </div>
                  </ConditionalField>
                  <ConditionalField scope="well" field="pressure">
                    <div
                      className={
                        "Details-edit__radiowrapper radio-input radio-input--horizontal"
                      }
                    >
                      <label className="radio-input__mainlabel">
                        Requires HPHT
                      </label>
                      <div className="radio-input__optionwrapper">
                        <label
                          className={`radio-input__option${
                            data.well.pressure === "hpht" ? " selected" : ""
                          }`}
                        >
                          <span className="radio-input__label">Yes</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.pressure"
                            value={"hpht"}
                          />
                        </label>
                        <label
                          className={`radio-input__option${
                            data.well.pressure !== "hpht" ? " selected" : ""
                          }`}
                        >
                          <span className="radio-input__label">No</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.pressure"
                            value={""}
                          />
                        </label>
                      </div>
                    </div>
                  </ConditionalField>
                  <ConditionalField scope="well" field="subsea_well">
                    <div
                      className={
                        "Details-edit__radiowrapper radio-input radio-input--horizontal"
                      }
                    >
                      <label className="radio-input__mainlabel">
                        Subsea well
                      </label>
                      <div className="radio-input__optionwrapper">
                        <label
                          className={`radio-input__option${
                            data.well.subsea_well ? " selected" : ""
                          }`}
                        >
                          <span className="radio-input__label">Yes</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.subsea_well"
                            value={true}
                          />
                        </label>
                        <label
                          className={`radio-input__option${
                            data.well.subsea_well ? "" : " selected"
                          }`}
                        >
                          <span className="radio-input__label">No</span>
                          <span className="check-indicator"></span>
                          <input
                            onChange={this.onFieldUpdate}
                            className="radio-input__radio"
                            type="radio"
                            name="well.subsea_well"
                            value={""}
                          />
                        </label>
                      </div>
                    </div>
                  </ConditionalField>
                  <ConditionalField scope="well" field="ocean_depth">
                    <div
                      className={`Details-edit__inputwrapper ${
                        data.well.ocean_depth ? " has-content" : ""
                      }`}
                    >
                      <label className="Details-edit__inputlabel">
                        Water depth ({units === "imperial" ? "feet" : "meters"})
                      </label>
                      <input
                        onChange={this.onFieldUpdate}
                        className={"Details-edit__input"}
                        value={data.well.ocean_depth || ""}
                        type="number"
                        name="well.ocean_depth"
                        placeholder=""
                      />
                    </div>
                  </ConditionalField>
                  <ConditionalField scope="well" field="expected_bpd">
                    <div
                      className={`Details-edit__inputwrapper${
                        data.well.expected_bpd ? " has-content" : ""
                      }`}
                    >
                      <label className="Details-edit__inputlabel">
                        Expected BPD
                      </label>
                      <input
                        onChange={this.onFieldUpdate}
                        className={"Details-edit__input"}
                        value={data.well.expected_bpd || ""}
                        type="number"
                        name="well.expected_bpd"
                        placeholder=""
                      />
                    </div>
                  </ConditionalField>
                  <ConditionalField
                    scope="well"
                    field="days_to_first_production"
                  >
                    <div
                      className={`Details-edit__inputwrapper${
                        data.well.days_to_first_production ? " has-content" : ""
                      }`}
                    >
                      <label className="Details-edit__inputlabel">
                        Days to first production
                      </label>
                      <input
                        onChange={this.onFieldUpdate}
                        className={"Details-edit__input"}
                        value={data.well.days_to_first_production || ""}
                        type="number"
                        name="well.days_to_first_production"
                        placeholder=""
                      />
                      <span className="Details-edit__inputinfo">
                        Days to first production after well completion
                      </span>
                    </div>
                  </ConditionalField>
                </div>
              </ConditionalFieldBlock>
            )}
            <div className="Details-edit__part right">
              <div
                className={
                  "Details-edit__radiowrapper radio-input radio-input--horizontal"
                }
              >
                <label className="radio-input__mainlabel">
                  Hard-date {data.start_date_is_hard}
                </label>
                <div className="radio-input__optionwrapper">
                  <label
                    className={`radio-input__option${
                      data.start_date_is_hard ? " selected" : ""
                    }`}
                  >
                    <span className="radio-input__label">Yes</span>
                    <span className="check-indicator"></span>
                    <input
                      onChange={this.onFieldUpdate}
                      className="radio-input__radio"
                      type="radio"
                      name="start_date_is_hard"
                      value={true}
                    />
                  </label>
                  <label
                    className={`radio-input__option${
                      data.start_date_is_hard ? "" : " selected"
                    }`}
                  >
                    <span className="radio-input__label">No</span>
                    <span className="check-indicator"></span>
                    <input
                      onChange={this.onFieldUpdate}
                      className="radio-input__radio"
                      type="radio"
                      name="start_date_is_hard"
                      value={false}
                    />
                  </label>
                </div>
              </div>
              {(data.start_date_is_hard || data.id < 0) && (
                <div
                  style={{ marginTop: "-15px" }}
                  className={`Details-edit__inputwrapper date-input ${
                    data.earliest_start_date ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">
                    Earliest startdate
                  </label>
                  <DayPickerElement
                    label={null}
                    onChange={this.handleStartDayChange}
                    format={DATE_FORMATE_NB}
                    hidePlaceholder={true}
                    value={earliestDate}
                  />
                </div>
              )}
              {data && data.status && data.status !== "occupied" && (
                <div
                  className={`Details-edit__selectwrapper${
                    data.rig_type && data.rig_type.length > 0
                      ? " has-content"
                      : ""
                  }`}
                >
                  <PanelInputSelection
                    value={rigTypesArray.length > 0 ? rigTypesArray : null}
                    options={activeRigTypes.map((a) => ({
                      value: a,
                      name: getReadableRigType(a),
                    }))}
                    placeholder="Rig type"
                    isMulti={true}
                    optionLabel={"name"}
                    onChange={(rigtype) => this.handleRigTypeChange(rigtype)}
                  />
                </div>
              )}
              <div
                className={`Details-edit__selectwrapper${
                  data.has_contract_with ? " has-content" : ""
                }`}
              >
                {data.has_contract_with ? (
                  <span className="Details-edit__selectlabel">
                    {data && data.status && data.status === "occupied"
                      ? "Occupied time applies to Rig:"
                      : "Well has contract with:"}
                  </span>
                ) : null}
                <PanelInputSelection
                  value={find(rigList, { id: data.has_contract_with })}
                  options={rigList}
                  isClearable={true}
                  placeholder={
                    data && data.status && data.status === "occupied"
                      ? "Occupied time applies to Rig"
                      : "Well has contract with"
                  }
                  onChange={this.handleContractChange}
                />
              </div>
              <ConditionalField scope="well" field="environmental_constraints">
                <div
                  className={`Details-edit__inputwrapper${
                    data.well.environmental_constraints ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">
                    Environmental constraints
                  </label>
                  <textarea
                    onChange={this.onFieldUpdate}
                    value={data.well.environmental_constraints || ""}
                    className={"Details-edit__input"}
                    name="well.environmental_constraints"
                    placeholder=""
                  />
                </div>
              </ConditionalField>
              <ConditionalField scope="well" field="start_up_constraints">
                <div
                  className={`Details-edit__inputwrapper${
                    data.well.start_up_constraints ? " has-content" : ""
                  }`}
                >
                  <label className="Details-edit__inputlabel">
                    Start-up constraints
                  </label>
                  <textarea
                    onChange={this.onFieldUpdate}
                    value={data.well.start_up_constraints || ""}
                    className={"Details-edit__input"}
                    name="well.start_up_constraints"
                    placeholder=""
                  />
                </div>
              </ConditionalField>
            </div>
          </div>
        </div>
      </>
    )
  }
}

EditDetailsBasic.propTypes = {
  data: PropTypes.object,
  blocks: PropTypes.object,
  licenses: PropTypes.object,
  assets: PropTypes.object,
  user: PropTypes.object,
  rigList: PropTypes.array,
  activeRigTypes: PropTypes.array,
  onFieldUpdate: PropTypes.func,
  units: PropTypes.string,
}

export default EditDetailsBasic
