import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import * as types from "../../panelConsts"

class PanelFooter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showErrorMessage: false,
    }
  }

  handleClick = (type) => {
    const { nextStepInPanel, closePanel, onSubmit, validated, onClose } = this.props

    if (validated || type === types.CANCEL || type === types.NO) {
      switch (type) {
        case types.CANCEL:
          if (onClose) {
            onClose()
          } else {
            closePanel()
          }
          break
        case types.CLOSE:
          if (onClose) {
            onClose()
          } else {
            closePanel()
          }
          break
        case types.NO:
          if (onClose) {
            onClose()
          } else {
            closePanel()
          }
          break
        case types.NEXT:
          nextStepInPanel()
          break
        case types.SUBMIT:
          onSubmit()
          break
        case types.SHARE:
          onSubmit()
          break
        case types.UPDATE:
          onSubmit()
          break
        case types.YES:
          onSubmit()
          break
        case types.ACCEPT_AND_ADD:
          onSubmit()
          break
        default:
          break
      }
      this.setState({
        showErrorMessage: false,
      })
    } else {
      this.setState({
        showErrorMessage: true,
      })
    }
  }

  getButton = (key, val, validated, saving) => {
    let inValidatedClass = ""
    let classes = ""

    if (validated !== true && val !== types.CANCEL && val !== types.NO) {
      inValidatedClass = "invalid"
    }

    switch (val) {
      case types.CANCEL:
        classes = "Panel__Button--cancel"
        break
      case types.CLOSE:
        classes = "Panel__Button--cancel"
        break
      case types.NO:
        classes = "Panel__Button--cancel"
        break
      case types.NEXT:
        classes = "Panel__Button--next"
        break
      case types.SUBMIT:
        classes = `Panel__Button--submit${saving ? " Panel__Button--submit--in-progress" : ""}`
        break
      case types.SHARE:
        classes = "Panel__Button--submit"
        break
      case types.UPDATE:
        classes = "Panel__Button--submit"
        break
      case types.ACCEPT_AND_ADD:
        classes = "Panel__Button--submit"
        break
      default:
        break
    }

    return (
      <div key={key} className={`Panel__Button ${classes} ${inValidatedClass}`} onClick={() => this.handleClick(val)}>
        {saving && val === types.SUBMIT ? "Saving..." : val}
      </div>
    )
  }

  render() {
    const {
      data: { options },
      validated,
      saving,
    } = this.props
    return (
      <div className="Panel__Footer">
        <div className={`Panel__Footer-Error ${this.state.showErrorMessage ? "Panel__Footer-Error--visible" : ""}`}>
          All fields with * must be filled out
        </div>
        {Object.keys(options.buttons).map((key) => this.getButton(key, options.buttons[key], validated, saving))}
      </div>
    )
  }
}

PanelFooter.propTypes = {
  nextStepInPanel: PropTypes.func,
  closePanel: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
  validated: PropTypes.bool,
  saving: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelFooter)
