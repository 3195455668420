import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"

class ErrorPage extends Component {
  render() {
    return (
      <div className="About">
        <h1>Error 1</h1>
      </div>
    )
  }
}

ErrorPage.propTypes = {
  views: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    views: state.views,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage)
