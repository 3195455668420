import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"

class WellStatusName extends Component {
  render() {
    const { wellStatusMapping, statusKey } = this.props

    return <span>{wellStatusMapping && wellStatusMapping[statusKey] ? wellStatusMapping[statusKey] : statusKey}</span>
  }
}

WellStatusName.propTypes = {
  wellStatusMapping: PropTypes.object,
  statusKey: PropTypes.string,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    wellStatusMapping: state.application.domain.data.wellStatusMapping,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WellStatusName)
