// import '@babel/polyfill';
import React from "react"

import wellPopovers from "../wellPopovers"
// import * as wellPopovers from '../wellPopovers';

const WellPopoverHelper = {
  empty: () => {},
  getPopover: (type, data) => {
    const Page = wellPopovers.get(type)
    const PageDom = <Page className={"sub page"} data={data} />
    return PageDom
  },
}

export default WellPopoverHelper
