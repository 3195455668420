import PropTypes from "prop-types"
import React, { Component } from "react"
import EditAdditionalScope from "../EditAdditionalScope"
import EditAfeAndCo2Cost from "../EditAfeAndCo2Cost"
import EditDetailsBasic from "../EditDetailsBasic"
import EditDetailsConceptional from "../EditDetailsConceptional"
import EditDetailsEngeneringAndDesign from "../EditDetailsEngeneringAndDesign"
import EditDetailsPlanAndSelect from "../EditDetailsPlanAndSelect"
import EditDetailsPlanForExecution from "../EditDetailsPlanForExecution"

class EditDetails extends Component {
  render() {
    const {
      data,
      blocks,
      licenses,
      rigList,
      assets,
      onFieldUpdate,
      failedStatusChange,
      activeRigTypes,
      activeWellTypes,
      activeEventTypes,
      includeAfeCo2,
      scenarios,
      units,
      currency,
      user,
    } = this.props

    return (
      <>
        <EditDetailsBasic
          activeRigTypes={activeRigTypes}
          activeWellTypes={activeWellTypes}
          activeEventTypes={activeEventTypes}
          data={data}
          blocks={blocks}
          licenses={licenses}
          rigList={rigList}
          assets={assets}
          onFieldUpdate={onFieldUpdate}
          units={units}
          user={user}
        />
        {data.status !== "occupied" ? (
          <>
            <EditDetailsConceptional
              onFieldUpdate={onFieldUpdate}
              data={data}
              rigList={rigList}
              failedStatusChange={failedStatusChange}
              assets={assets}
            />
            <EditDetailsPlanAndSelect
              onFieldUpdate={onFieldUpdate}
              data={data}
            />
            <EditDetailsEngeneringAndDesign
              onFieldUpdate={onFieldUpdate}
              data={data}
            />
            <EditDetailsPlanForExecution
              failedStatusChange={failedStatusChange}
              onFieldUpdate={onFieldUpdate}
              data={data}
            />
            <EditAdditionalScope
              failedStatusChange={failedStatusChange}
              onFieldUpdate={onFieldUpdate}
              data={data}
            />
          </>
        ) : null}
        {includeAfeCo2 && (
          <EditAfeAndCo2Cost
            units={units}
            currency={currency}
            scenarios={scenarios}
            failedStatusChange={failedStatusChange}
            onFieldUpdate={onFieldUpdate}
            data={data}
          />
        )}
      </>
    )
  }
}

EditDetails.propTypes = {
  data: PropTypes.object,
  blocks: PropTypes.object,
  licenses: PropTypes.object,
  assets: PropTypes.object,
  rigList: PropTypes.array,
  onFieldUpdate: PropTypes.func,
  failedStatusChange: PropTypes.string,
  activeRigTypes: PropTypes.array,
  activeWellTypes: PropTypes.array,
  includeAfeCo2: PropTypes.bool,
  scenarios: PropTypes.object,
  user: PropTypes.object,
  units: PropTypes.string,
  currency: PropTypes.string,
}

export default EditDetails
