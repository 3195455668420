import React from "react"
import PropTypes from "prop-types"

const ListDateChange = (props) => {
  const { title, minor, major, onClick, selected } = props

  return (
    <div className={"date-changes"} onClick={onClick}>
      <div className={`date-changes__date ${selected ? "FontStyle--Bold" : ""}`}>{title}</div>
      <div>
        <div>{`${minor} MINOR`}</div>
        <div>{`${major} MAJOR`}</div>
      </div>
    </div>
  )
}

ListDateChange.propTypes = {
  title: PropTypes.string,
  minor: PropTypes.number,
  major: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}

export default ListDateChange
