import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"

class RoundCloseButton extends PureComponent {
  close = () => {
    const { toggleToolbarOpenState } = this.props
    toggleToolbarOpenState()
  }

  render() {
    return (
      <div className={"Round__Close__button"} onClick={() => this.close()}>
        <svg
          style={{ margin: "-2px 5px 0 0" }}
          width="7px"
          height="11px"
          viewBox="0 0 7 11"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs></defs>
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
            <g id="Container/Sidebar/Expanded" transform="translate(-171.000000, -82.000000)" stroke="#2E3A41">
              <g id="Icons/Arrow/Black/Left" transform="translate(170.000000, 82.000000)">
                <polyline
                  id="Path-2-Copy"
                  transform="translate(6.923859, 5.548859) rotate(-45.000000) translate(-6.923859, -5.548859) "
                  points="3.48635912 8.98635912 3.48635912 2.11135912 10.3613591 2.11135912"
                ></polyline>
              </g>
            </g>
          </g>
        </svg>
        Close
      </div>
    )
  }
}

RoundCloseButton.propTypes = {
  toggleToolbarOpenState: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

// TODO: pass down function instead of using connect
export default connect(mapStateToProps, mapDispatchToProps)(RoundCloseButton)
