import React, { Component } from "react"
// import PropTypes from 'prop-types';
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class InputWellRigInfo extends Component {
  render() {
    return <div className="PanelContent__Container">InputWellRigInfo</div>
  }
}

InputWellRigInfo.propTypes = {}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InputWellRigInfo)
