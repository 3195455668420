import {
  CLOSE_WELL_POPOVER,
  DELETE_SCROLL_OBJECT_POSITION,
  OPEN_WELL_POPOVER,
  POPULATE_CALENDAR,
  SCROLL_OBJECT_POSITION,
  SCROLL_POSITION,
  SCROLL_TO_LAST_POSITION,
  SET_CALENDARS_VISIBLE_MONTH,
  SET_CALENDARS_VISIBLE_YEARS,
  SET_CALENDAR_TIMESPAN,
  SET_DAY_WIDTH,
  SET_HIDDEN_RIGS,
  SET_P_LEVEL,
  SET_ZOOM_LEVEL,
  TOGGLE_DARKMODE,
  UPDATE_CALENDAR,
} from "../types"

export function setCalendarTimespan(timespan) {
  return { type: SET_CALENDAR_TIMESPAN, timespan }
}

export function toggleCalendarDarkMode() {
  return { type: TOGGLE_DARKMODE }
}

export function setCalendar(calendar) {
  return { type: POPULATE_CALENDAR, calendar }
}

export function updateCalendar(calendar) {
  return { type: UPDATE_CALENDAR, calendar }
}

export function openWellPopover(popover, data) {
  return { type: OPEN_WELL_POPOVER, popover, data }
}
export function closeWellPopover() {
  return { type: CLOSE_WELL_POPOVER }
}

export function setZoomLevel(zoomLevel) {
  return { type: SET_ZOOM_LEVEL, zoomLevel }
}

export function setPVersion(p) {
  return { type: SET_P_LEVEL, p }
}

export function setDayWidth(dayWidth) {
  return { type: SET_DAY_WIDTH, dayWidth }
}
export function setHiddenRigs(hiddenRigs) {
  return { type: SET_HIDDEN_RIGS, hiddenRigs }
}
export function setCalendarsVisibleYears(isVisible, year) {
  return { type: SET_CALENDARS_VISIBLE_YEARS, isVisible, year }
}
export function setCalendarsVisibleMonth(month) {
  return { type: SET_CALENDARS_VISIBLE_MONTH, month }
}

export function updateScrollToLastScrollPos() {
  return { type: SCROLL_TO_LAST_POSITION }
}

export function setScrollPosition(value) {
  return { type: SCROLL_POSITION, value }
}

export function setScrollObjectPosition(value, object) {
  return { type: SCROLL_OBJECT_POSITION, value, object }
}

export function deleteScrollObjectPosition() {
  return { type: DELETE_SCROLL_OBJECT_POSITION }
}
