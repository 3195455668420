// import { cloneDeep } from 'lodash';

import createReducer from "../../lib/createReducer"
import * as types from "../types"

export const initialState = {
  previousDrafts: [],
  draftsList: [],
  mastersList: [],
  updatedDraftMeta: {},
  archivedDraft: {},
  newDraft: {},
  missingInputters: [],
}

export const overviewData = createReducer(initialState, {
  [types.FETCH_DRAFTS](state) {
    return state
  },
  [types.FETCH_PREVIOUS_DRAFTS](state) {
    return state
  },
  [types.UPDATE_PREVIOUS_DRAFTS_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.previousDrafts = actions.data
    return ns
  },
  [types.UPDATE_DRAFTS_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.draftsList = actions.data
    return ns
  },
  [types.FETCH_MISSING_INPUTTERS](state) {
    return state
  },
  [types.SAVE_MISSING_INPUTTERS](state, actions) {
    const ns = Object.assign({}, state)

    ns.missingInputters = actions.data
    return ns
  },
  [types.SAVE_DRAFT_META](state, actions) {
    const ns = Object.assign({}, state)
    ns.updatedDraftMeta = actions.data
    return ns
  },
  [types.ARCHIVE_DRAFT](state, actions) {
    const ns = Object.assign({}, state)
    ns.archivedDraft = actions.data
    return ns
  },
  [types.CREATE_NEW_DRAFT](state, actions) {
    const ns = Object.assign({}, state)
    ns.newDraft = actions.data
    return ns
  },
  [types.FETCH_MASTERS](state) {
    return state
  },
  [types.UPDATE_MASTERS_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.mastersList = actions.data
    return ns
  },
})
