import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class RequestList extends PureComponent {
  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[elem.target.name] = elem.target.value

    onUpdate(obj)
  }

  render() {
    const { data } = this.props

    return (
      <div className="PanelContent__Container PanelForm">
        <div className="PanelForm__Header">Comment</div>
        <div className="PanelForm__InputPart">
          <textarea
            onChange={this.onFieldUpdate}
            value={data.comment ? data.comment : ""}
            className="PanelForm__textarea"
            name="comment"
            type="text"
            placeholder="Enter your comment here"
          />
        </div>
      </div>
    )
  }
}

RequestList.propTypes = {
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  onValidated: PropTypes.func,
  data: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestList)
