import { truncate } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import NotVisible from "../../../../assets/images/svg/icons/NotVisible.svg"
import Visible from "../../../../assets/images/svg/icons/Visible.svg"
import PartnerIcon from "../../../../assets/images/svg/icons/partner.svg"
import { ActionCreators } from "../../../redux/actions"
import IconContainer from "../../icons/IconContainer"
import RigTypeIcon from "../../icons/RigTypeIcon"

class Rig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hovered: false,
      rig_instance_id: props.id,
      ...props.rigData,
      rigMoveTotal: props.rigMoveTotal,
      freeTimeTotal: props.freeTimeTotal,
      drillTimeTotal: props.drillTimeTotal,
    }

    this.rigRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rigData !== this.props.rigData) {
      this.setState({
        ...this.props.rigData,
      })
    }
  }

  toggleRigLineVisibility = (event) => {
    const {
      setHiddenRigs,
      hiddenRigs,
      rigData: { id },
    } = this.props

    event.preventDefault()
    event.stopPropagation()

    if (hiddenRigs.includes(id)) {
      setHiddenRigs([...hiddenRigs.filter((e) => e !== id)])
    } else {
      setHiddenRigs([...hiddenRigs, id])
    }
  }

  render() {
    const { name, pressure, type, hovered } = this.state

    const {
      onSelectRig,
      isRigSelected,
      hiddenRigs,
      rigData: { is_draft_rig: isDraftRig, is_partner_rig: isPartnerRig, id },
    } = this.props

    let heightWidth = 12
    if (isPartnerRig) {
      heightWidth = 16
    }

    return hiddenRigs.includes(id) ? (
      <div
        ref={this.rigRef}
        className={`Row Rig ${isRigSelected ? "Rig--selected" : ""}${isDraftRig ? " Rig--draft-rig" : ""}${
          isPartnerRig ? " Rig--partner-rig" : ""
        }`}
        style={{ height: 20 }}
      >
        <div style={{ height: 20 }} className="Rig__Wrapper">
          <span className="Rig__Name">{truncate(name, { length: 15 })}</span>
          <a onClick={this.toggleRigLineVisibility} className="Rig__Visibility">
            {IconContainer(Visible, heightWidth, heightWidth)}
          </a>
        </div>
      </div>
    ) : (
      <div
        ref={this.rigRef}
        className={`Row Rig ${isRigSelected ? "Rig--selected" : ""}${isDraftRig ? " Rig--draft-rig" : ""}${
          isPartnerRig ? " Rig--partner-rig" : ""
        }`}
      >
        <div
          className="Rig__Wrapper"
          onClick={() => {
            onSelectRig(this.state, this.rigRef)
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <span className="Rig__Icon">
            {isPartnerRig ? (
              IconContainer(PartnerIcon, heightWidth, heightWidth)
            ) : (
              <RigTypeIcon rigtype={type} width={heightWidth} height={heightWidth} />
            )}
          </span>
          <span className="Rig__Name">{name}</span>
          <a onClick={this.toggleRigLineVisibility} className="Rig__Visibility">
            {IconContainer(NotVisible, heightWidth, heightWidth)}
          </a>
          <span className="Rig__Pressure">{pressure / 1000}K</span>
        </div>
      </div>
    )
  }
}

Rig.propTypes = {
  id: PropTypes.number.isRequired,
  rig_id: PropTypes.number.isRequired,
  rigData: PropTypes.object.isRequired,
  rigScheduleId: PropTypes.object.isRequired,
  onSelectRig: PropTypes.func.isRequired,
  rigMoveTotal: PropTypes.number.isRequired,
  isRigSelected: PropTypes.bool,
  freeTimeTotal: PropTypes.object.isRequired,
  drillTimeTotal: PropTypes.object.isRequired,
  darkMode: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    rigScheduleId: state.schedulerData.schedulerData.data,
    hiddenRigs: state.calendar.hiddenRigs,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rig)
