import React, { Component } from "react"
import PropTypes from "prop-types"
import getYear from "date-fns/getYear"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class SchedulerTopDate extends Component {
  render() {
    const {
      calendar: { visibleYears, timespan, visibleMonth, zoomLevel },
    } = this.props

    if (!timespan) {
      return <div className="SchedulerTop__Date"></div>
    }

    let from = timespan ? getYear(timespan.from).toString() : null
    let to = timespan ? getYear(timespan.to).toString() : null

    if (visibleYears.length > 0) {
      from = visibleYears.reduce((a, b) => Math.min(a, b))
      to = visibleYears.reduce((a, b) => Math.max(a, b))
    }

    let range = from === to ? from : `${from} - ${to}`
    if (visibleMonth && zoomLevel === 1) {
      range = `${visibleMonth.longer}, ${from}`
    }

    return (
      <div className="SchedulerTop__Date">
        <span className="SchedulerTop__DateLabel">Viewing</span>
        <span className="SchedulerTop__DateRange">{range}</span>
      </div>
    )
  }
}

SchedulerTopDate.propTypes = {
  calendar: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    calendar: state.calendar,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerTopDate)
