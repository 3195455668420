import React, { Component } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { cloneDeep } from "lodash"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../redux/actions"
import { MODAL_TYPE_DRAFT_RENAME } from "../redux/types"

class RenameDraft extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: "",
      activeDraftNameChange: props.data.data.activeDraftNameChange,
      draftNameSaved: false,
      isSavingRename: false,
    }
  }

  closeModal = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const { closeModal } = this.props
    closeModal(MODAL_TYPE_DRAFT_RENAME)
  }

  handleRenameFormSubmit = (event) => {
    const { saveSingleDraftMeta } = this.props

    event.preventDefault()
    event.stopPropagation()

    if (!this.state.isSavingRename) {
      this.setState({
        isSavingRename: true,
      })

      saveSingleDraftMeta(this.state.activeDraftNameChange)
    }
  }

  handleRenameEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleRenameFormSubmit(event)
    }
  }

  handleRenameInputChange = (event) => {
    const activeDraftNameChange = cloneDeep(this.state.activeDraftNameChange)
    activeDraftNameChange.name = event.target.value
    this.setState({ activeDraftNameChange })
  }

  render() {
    const {
      data: { data },
    } = this.props

    return (
      <Modal
        key={`rename-modal-${data.id}`}
        isOpen={true}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        className={"ReactModal__Content"}
        overlayClassName={"ReactModal__Overlay"}
        contentLabel="Rename"
      >
        <form className="ReactModal__Wrapper" onSubmit={this.handleRenameFormSubmit}>
          <div className="ReactModal__Form">
            <h2 className="ReactModal__FormHeader">{this.state.draftNameSaved ? "Saved!" : `Rename "${data.name}" to:`}</h2>
            <input
              onKeyDown={this.handleRenameEnterPress}
              onChange={this.handleRenameInputChange}
              autoFocus
              className="ReactModal__BigInput"
              placeholder="New draft name"
            />
          </div>
          <div className="ReactModal__Buttons">
            <button type="button" onClick={this.closeModal} className="ReactModal__Button">
              Cancel
            </button>
            <button
              type="submit"
              className={`ReactModal__Button ReactModal__Button--primary ${
                this.state.isSavingRename ? "ReactModal__Button--inactive" : ""
              }`}
            >
              {"Save name"}
            </button>
          </div>
        </form>
      </Modal>
    )
  }
}

RenameDraft.propTypes = {
  saveSingleDraftMeta: PropTypes.func,
  closeModal: PropTypes.func,
  data: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RenameDraft)
