import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"

import addDays from "date-fns/addDays"
import format from "date-fns/format"
import { bindActionCreators } from "redux"

import DeletedWell from "../../../assets/images/svg/icons/DeletedWell.svg"
import IsDirty from "../../../assets/images/svg/icons/IsDirty.svg"
import { ActionCreators } from "../../redux/actions"
import ContextMenu from "../ContextMenu"

import VALUE_HIDDEN from "../../appSettingConsts"
import { DATE_FORMATE_NB_SHORT } from "../../dateConsts"
import CU from "../../lib/CalendarUtils"

class DNDCSingleWellContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      style: this.getStyles(),
      focused: false,
      showContextMenu: false,
      contextOptions: props.extractEnabled
        ? [
            { name: "Delete", action: "delete" },
            { name: "Duplicate", action: "duplicate" },
            { name: "Edit details", action: "edit" },
            { name: "Extract from sequence", action: "extract" },
          ]
        : [
            { name: "Delete", action: "delete" },
            { name: "Duplicate", action: "duplicate" },
            { name: "Edit details", action: "edit" },
          ],
    }

    this.myRef = React.createRef()
  }

  getSnapshotBeforeUpdate(nextProps) {
    const { id, index } = this.props

    if (index !== nextProps.index) {
      if (nextProps.selectedElm.id !== id) {
        return {
          style: this.getStyles(),
        }
      }
    }

    if (nextProps.selectedElm !== this.props.selectedElm) {
      return {
        style: this.getStyles(),
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, ns) {
    if (prevProps.status !== this.props.status) {
      this.setState({
        style: this.getStyles(),
      })
    }

    if (prevProps.data.to_be_removed !== this.props.data.to_be_removed) {
      if (this.props.data.to_be_removed) {
        this.setState({
          contextOptions: [{ name: "Undo delete", action: "undo_delete" }],
        })
      } else if (this.props.data.to_be_extracted) {
        this.setState({
          contextOptions: [{ name: "Undo extract", action: "undo_extract" }],
        })
      } else {
        this.setState({
          contextOptions: this.props.data.id
            ? [
                { name: "Delete", action: "delete" },
                { name: "Duplicate", action: "duplicate" },
                { name: "Edit details", action: "edit" },
                { name: "Extract from sequence", action: "extract" },
              ]
            : [
                { name: "Delete", action: "delete" },
                { name: "Duplicate", action: "duplicate" },
                { name: "Edit details", action: "edit" },
              ],
        })
      }
    }

    if (ns) {
      this.setState(ns)
    }
  }

  componentDidMount() {
    const { data } = this.props

    const p10input = document.getElementById(`dndc-p10estimate-${data.id}`)
    const p50input = document.getElementById(`dndc-p50estimate-${data.id}`)
    const p90input = document.getElementById(`dndc-p90estimate-${data.id}`)
    p10input.addEventListener("keypress", (e) => {
      if (e.which === 13) {
        e.preventDefault()
        p10input.blur()
      }
      if (Number.isNaN(String.fromCharCode(e.which))) e.preventDefault()
    })
    p50input.addEventListener("keypress", (e) => {
      if (e.which === 13) {
        e.preventDefault()
        p50input.blur()
      }
      if (Number.isNaN(String.fromCharCode(e.which))) e.preventDefault()
    })

    if (p90input) {
      p90input.addEventListener("keypress", (e) => {
        if (e.which === 13) {
          e.preventDefault()
          p90input.blur()
        }
        if (Number.isNaN(String.fromCharCode(e.which))) e.preventDefault()
      })
    }
  }

  componentWillUnmount() {
    const { data } = this.props
    const p10input = document.getElementById(`dndc-p10estimate-${data.id}`)
    const p50input = document.getElementById(`dndc-p50estimate-${data.id}`)
    const p90input = document.getElementById(`dndc-p90estimate-${data.id}`)

    if (p10input) {
      p10input.removeEventListener("keypress", () => {})
    }

    if (p50input) {
      p50input.removeEventListener("keypress", () => {})
    }

    if (p90input) {
      p90input.removeEventListener("keypress", () => {})
    }
  }

  onDragStart = (e) => {
    const { dragEnabled } = this.props
    e.stopPropagation()

    if (!dragEnabled) {
      e.preventDefault()
      return
    }

    const { id, index, setDragElm } = this.props
    const { offsetX } = e.nativeEvent

    e.dataTransfer.effectAllowed = "move"
    e.dataTransfer.setData("text/plain", e.target.id)

    let dragContainerImage = document.getElementById("dragContainerImage")
    const drag = document.getElementById(e.target.id)

    if (!dragContainerImage) {
      dragContainerImage = document.createElement("div")
      dragContainerImage.setAttribute("id", "dragContainerImage")
      dragContainerImage.setAttribute(
        "style",
        "position: absolute; left: 0px; top: 0px; width: 1px; height: 1px; background: transparent; z-index: -1"
      )
      document.body.appendChild(dragContainerImage)
    }
    e.dataTransfer.setDragImage(dragContainerImage, 10, 10)

    setDragElm(id, index, drag, offsetX)
  }

  getStyles = () => {
    const { width, status } = this.props

    const height = 105
    const style = {}

    const bookingStatusColor = CU.getWellBookingStatusColor(status)
    style.width = `${width}px`
    style.height = `${height}px`
    style.fontSize = "12px"
    style.borderLeft = bookingStatusColor && `2px solid ${bookingStatusColor.borderColor}`

    return style
  }

  onSelect = (e, data) => {
    const { onClick } = this.props

    e.preventDefault()
    e.stopPropagation()

    if (e.target.classList.contains("DNDC") || e.target.classList.contains("DNDC__Selector")) {
      onClick(data)
    }
  }

  handleContentEditable = (value, pversion) => {
    const strippedValue = value.replace(/\D/g, "")

    const {
      handleInlineEstimateChange,
      data: { id },
    } = this.props
    handleInlineEstimateChange(Number(strippedValue), id, pversion)
  }

  onBlur = () => {}

  onFocus = (elementId) => {
    setTimeout(() => {
      const el = document.getElementById(elementId)
      el.select()
    }, 0)
  }

  preventDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleContextDeSelect = () => {
    this.setState({
      showContextMenu: false,
    })
  }

  handleContextSelect = (e, selectedContextItem) => {
    e.preventDefault()
    e.stopPropagation()

    const { onClick, data, duplicateWell, deleteWell, extractWell } = this.props
    this.setState({
      showContextMenu: false,
    })
    switch (selectedContextItem.action) {
      case "delete":
        deleteWell(data)
        break
      case "duplicate":
        duplicateWell(data)
        break
      case "edit":
        onClick(data, true)
        break
      case "extract":
        extractWell(data)
        break
      default:
    }
  }

  toggleMenu = (e) => {
    e.stopPropagation()

    this.setState({
      showContextMenu: !this.state.showContextMenu,
    })
  }

  render() {
    const {
      classNames,
      styles,
      selected,
      hasSelection,
      selectedElm,
      data: { id, estimateData, well, to_be_removed: toBeRemoved, to_be_extracted: toBeExtracted },
      calculatedStartDate,
      rigMove,
      wellEstimateAggregated,
      data,
      status,
      p10estimate,
      p50estimate,
      p90estimate,
      p10OriginalEstimate,
      p50OriginalEstimate,
      p90OriginalEstimate,
      selectedBuilderPVersion,
      domain,
      actualDuration,
    } = this.props

    const { style, focused, showContextMenu, contextOptions } = this.state

    let dragging = true
    if (selectedElm && selectedElm.id && selectedElm.id !== id) {
      dragging = false
    }
    // If drag and drop is activated
    let pe = {}
    if (Object.keys(selectedElm).length > 0) {
      pe = {
        pointerEvents: dragging ? "all" : "none",
        opacity: dragging ? 1 : 0.3,
        cursor: "grabbing",
      }
    }
    const elmSelected = selected && selected.id === id ? " DNDC__Singlewell--is-selected" : ""
    const name = CU.getNameForWellBooking(well, true)
    const entryStartDateObj = calculatedStartDate
    const aggregatedStartDate = addDays(entryStartDateObj, wellEstimateAggregated)
    const wellStartDate = rigMove ? addDays(aggregatedStartDate, rigMove.estimate + wellEstimateAggregated) : aggregatedStartDate
    const wellEndDate = addDays(wellStartDate, actualDuration || estimateData[selectedBuilderPVersion].estimate)

    let dateRange

    try {
      dateRange = `${format(wellStartDate, DATE_FORMATE_NB_SHORT)}—${format(wellEndDate, DATE_FORMATE_NB_SHORT)}`
    } catch (err) {
      dateRange = ""
    }

    const p10 = domain.data && domain.data.scenarios && domain.data.scenarios.p10 ? domain.data.scenarios.p10 : "P10"
    const p50 = domain.data && domain.data.scenarios && domain.data.scenarios.p50 ? domain.data.scenarios.p50 : "P50"
    const p90 = domain.data && domain.data.scenarios && domain.data.scenarios.p90 ? domain.data.scenarios.p90 : "P90"

    const disablePInput = (status === "execution" || status === "completed") && actualDuration && Number(actualDuration) > 0

    return (
      <div
        id={`dragme-${id}`}
        key={id}
        draggable={selectedBuilderPVersion === "p50"}
        onDragStart={(e) => this.onDragStart(e)}
        ref={this.myRef}
        className={`DNDC DNDC__Singlewell DNDC__Singlewell--${status} ${!!classNames || ""}${
          data.id < 0 ? " DNDC__Singlewell--unsaved" : ""
        }${toBeRemoved ? " DNDC__Singlewell--removed" : ""}${toBeExtracted ? " DNDC__Singlewell--extracted" : ""}${
          showContextMenu ? " hasActiveContextMenu" : ""
        }${hasSelection ? " DNDC__Singlewell--has-one-selected" : ""}${elmSelected}`}
        style={Object.assign({}, style, styles, pe)}
        onClick={(e) => this.onSelect(e, data)}
      >
        <div className="DNDC__Selector">
          {selected && selected.id === id ? (
            <svg
              width="12px"
              height="12px"
              viewBox="0 0 12 12"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g id="Modules-+-Flows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="well-behaviour" transform="translate(-549.000000, -279.000000)">
                  <g id="Icons/Check/black" transform="translate(549.000000, 279.000000)" fill="#1C2226">
                    <path
                      d="M11.5708851,3.77356768 L11.0961197,4.24864947 C11.2878519,4.79616526 11.3913264,5.38659362 11.3913264,6.00136955 C11.3913264,8.97177205 8.97184885,11.391311 6.00152168,11.391311 C3.02815115,11.391311 0.608673599,8.97177205 0.608673599,6.00136955 C0.608673599,3.02792361 3.02815115,0.608689036 6.00152168,0.608689036 C7.24321583,0.608689036 8.38752219,1.03142357 9.29748922,1.7405463 L9.73269084,1.30837708 C8.70707583,0.489690329 7.41060107,0 6.00152168,0 C2.69338067,0 0,2.69314464 0,6.00136955 C0,9.30959446 2.69338067,12 6.00152168,12 C9.30966269,12 12,9.30959446 12,6.00136955 C12,5.21616069 11.8478316,4.46169063 11.5708851,3.77356768"
                      id="Fill-1"
                    ></path>
                    <path
                      d="M3.54279489,4.3422619 C3.48252863,4.28571429 3.3993038,4.25595238 3.31639784,4.25595238 C3.23668057,4.25595238 3.15377461,4.28571429 3.09350836,4.3422619 C2.96883055,4.45833333 2.96883055,4.64880952 3.09350836,4.76488095 L6.02359631,7.5 L11.3263891,2.55029762 L11.9067308,2.00892857 C12.0310897,1.89285714 12.0310897,1.70535714 11.9067308,1.58928571 C11.842957,1.5297619 11.7632397,1.5 11.6806526,1.5 C11.6009353,1.5 11.5180294,1.5297619 11.4571254,1.58928571 L10.9373688,2.07142857 L10.4848936,2.49702381 L6.02359631,6.6577381 L3.54279489,4.3422619 Z"
                      id="Fill-4"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          ) : (
            <svg
              width="12px"
              height="12px"
              viewBox="0 0 12 12"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g id="Modules-+-Flows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="well-behaviour" transform="translate(-384.000000, -279.000000)" stroke="#1C2226" strokeWidth="0.5">
                  <circle id="Oval-Copy-9" cx="390" cy="285" r="5.75"></circle>
                </g>
              </g>
            </svg>
          )}
        </div>
        <div className="DNDC__EstimateLabel">
          {selectedBuilderPVersion === "p10" && p10estimate}
          {selectedBuilderPVersion === "p50" && p50estimate}
          {selectedBuilderPVersion === "p90" && p90estimate}
          {" days"}
        </div>
        {(status === "execution" || status === "completed") && (
          <div className="DNDC__actual">
            <label>Actual duration</label>
            <input
              value={actualDuration}
              id={`dndc-actualDuration-${id}`}
              className={`DNDC__Estimate ${focused ? " focus" : ""}`}
              onChange={(event) => this.handleContentEditable(event.target.value, "actual_duration")}
              onFocus={() => this.onFocus(`dndc-actualDuration-${id}`)}
              type="number"
            />
          </div>
        )}
        <div className="DNDC__Estimates" onDragStart={this.preventDrag}>
          <div className="DNDC__Estimatewrapper DNDC__Estimate--p10">
            {p10estimate < 1 ? <span className="DNDC__Estimates-error">!</span> : null}
            <label>{p10}</label>
            <input
              value={!!disablePInput ? p10OriginalEstimate || p10estimate : p10OriginalEstimate || p10estimate}
              disabled={!!disablePInput}
              id={`dndc-p10estimate-${id}`}
              className={`DNDC__Estimate ${focused ? " focus" : ""}`}
              onChange={(event) => this.handleContentEditable(event.target.value, "p10")}
              onFocus={() => this.onFocus(`dndc-p10estimate-${id}`)}
            />
          </div>
          <div className="DNDC__Estimatewrapper DNDC__Estimate--p50">
            {p50estimate < 1 ? <span className="DNDC__Estimates-error">!</span> : null}
            <label>{p50}</label>
            <input
              value={!!disablePInput ? p50OriginalEstimate || p50estimate : p50OriginalEstimate || p50estimate}
              disabled={!!disablePInput}
              id={`dndc-p50estimate-${id}`}
              className={`DNDC__Estimate${focused ? " focus" : ""}`}
              onChange={(event) => this.handleContentEditable(event.target.value, "p50")}
              onFocus={() => this.onFocus(`dndc-p50estimate-${id}`)}
            />
          </div>
          {p90 !== VALUE_HIDDEN ? (
            <div className="DNDC__Estimatewrapper DNDC__Estimate--p90">
              {p90estimate < 1 ? <span className="DNDC__Estimates-error">!</span> : null}
              <label>{p90}</label>
              <input
                value={!!disablePInput ? p90OriginalEstimate || p90estimate : p90OriginalEstimate || p90estimate}
                disabled={!!disablePInput}
                id={`dndc-p90estimate-${id}`}
                className={`DNDC__Estimate DNDC__Estimate--p90${focused ? " focus" : ""}`}
                onChange={(event) => this.handleContentEditable(event.target.value, "p90")}
                onFocus={() => this.onFocus(`dndc-p90estimate-${id}`)}
              />
            </div>
          ) : null}
        </div>

        <a onClick={this.toggleMenu} href="#" className={"DNDC__menu-trigger"}>
          <svg
            width="10px"
            height="5px"
            viewBox="0 0 10 5"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g id="Modules-+-Flows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="well-behaviour" transform="translate(-503.000000, -283.000000)">
                <rect fill="#F5F8F9" x="0" y="0" width="1442" height="999"></rect>
                <g id="Icons/Arrow/Black/Down" transform="translate(503.000000, 280.000000)" stroke="#2E3A41" strokeLinecap="round">
                  <polyline
                    id="Path-2-Copy"
                    transform="translate(5.044417, 3.169417) rotate(-135.000000) translate(-5.044417, -3.169417) "
                    points="1.91941738 6.29441738 1.91941738 0.0444173824 8.16941738 0.0444173824"
                  ></polyline>
                </g>
              </g>
            </g>
          </svg>
        </a>

        <ContextMenu
          id={`context-menu-masterslist-${id}`}
          options={contextOptions}
          active={this.state.showContextMenu}
          yPos={35}
          deselect={this.handleContextDeSelect}
          onSelect={(e, selectedContextItem) => this.handleContextSelect(e, selectedContextItem)}
        />

        <div style={data.start_date_is_hard ? { textDecoration: "underline" } : {}} className="DNDC__Title">
          {name}
          {toBeExtracted && (
            <>
              <br />
              (Will be extracted →)
            </>
          )}
        </div>
        <div className="DNDC__Timespan">{dateRange}</div>

        {data.is_dirty && <img src={IsDirty} className="DNDC__DirtyWell" />}
        {toBeRemoved && <img src={DeletedWell} className="DNDC__RemovedWell" />}
      </div>
    )
  }
}

DNDCSingleWellContainer.propTypes = {
  id: PropTypes.number,
  classNames: PropTypes.string,
  styles: PropTypes.object,
  moveObject: PropTypes.object,
  rigMove: PropTypes.object,
  data: PropTypes.any,
  status: PropTypes.string,
  handleInlineEstimateChange: PropTypes.func,
  dragEnabled: PropTypes.bool,
  extractEnabled: PropTypes.bool,
  duplicateWell: PropTypes.func,
  deleteWell: PropTypes.func,
  extractWell: PropTypes.func,
  selectedBuilderPVersion: PropTypes.string,
  onClick: PropTypes.func,
  setDragElm: PropTypes.func,
  selected: PropTypes.object,
  estimates: PropTypes.object,
  selectedElm: PropTypes.object,
  activeElm: PropTypes.object,
  calculatedStartDate: PropTypes.object,
  domain: PropTypes.object,
  wellEstimateAggregated: PropTypes.number,
  index: PropTypes.number,
  width: PropTypes.number,
  inactive: PropTypes.bool,
  hasSelection: PropTypes.bool,
  p10estimate: PropTypes.string,
  p50estimate: PropTypes.string,
  p90estimate: PropTypes.string,
  actualDuration: PropTypes.string,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    domain: state.application.domain,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DNDCSingleWellContainer)
