import React, { Component } from "react"
import PropTypes from "prop-types"
import { cloneDeep } from "lodash"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"
import ShareToElm from "../../../components/form/ShareToElm"

class ConfirmShareScheduleUpdateSubpage extends Component {
  constructor() {
    super()
    this.state = {
      page: "share",
      shares: {},
    }
  }

  componentDidMount = () => {
    const {
      currentShareList: { data },
    } = this.props
    this.updateSharesList(data.sharings)
  }

  componentDidUpdate = (prevProps) => {
    const {
      currentShareList: { data },
    } = this.props

    if (data.sharings !== prevProps.currentShareList.data.sharings) {
      this.updateSharesList(data.sharings)
    }
  }

  updateSharesList = (sharings) => {
    const shares = {}

    if (!sharings) return

    sharings.forEach((val) => {
      shares[val.user_id] = { classes: "old" }
    })

    this.setState({
      shares,
    })
  }

  setShareForRemoval = (id) => {
    const { onUpdate } = this.props
    const { shares } = this.state

    const updatedShares = cloneDeep(shares)

    if (updatedShares[id].classes.indexOf("delete") === -1) {
      updatedShares[id].classes += " delete"
    } else {
      updatedShares[id].classes = updatedShares[id].classes.split(" delete").pop()
    }

    const removeTheseOnUpdate = []

    Object.keys(updatedShares).forEach((key) => {
      if (updatedShares[key].classes.indexOf("delete") !== -1) {
        removeTheseOnUpdate.push(key)
      }
    })

    this.setState({
      shares: updatedShares,
    })

    onUpdate("remove", removeTheseOnUpdate)
  }

  changePage = (page) => {
    this.setState({
      page,
    })
  }

  render() {
    const { shares } = this.state

    return (
      <div className="PanelContent__Container UpdateShareOfDraft">
        <div className="UpdateShareOfDraft__Header">
          <div className="title">These have access to the draft.</div>
          {Object.entries(shares).map((entry) => ShareToElm(entry[0], entry[1].classes, this.setShareForRemoval))}
        </div>
      </div>
    )
  }
}

ConfirmShareScheduleUpdateSubpage.propTypes = {
  data: PropTypes.object,
  currentShareList: PropTypes.object,
  onUpdate: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    currentShareList: state.schedulerData.currentShareList,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmShareScheduleUpdateSubpage)
