import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { get } from "lodash"
import { ActionCreators } from "../../redux/actions"

class PanelHeader extends Component {
  getHeading = (val) => {
    const { schedulerData } = this.props

    if (val.indexOf("#") !== -1) {
      return schedulerData.name
    }

    return val
  }

  render() {
    const {
      application: { panelOpen, panelData },
      buttons,
      classes,
      middleComponent,
      forcedHeading,
    } = this.props

    const options = panelOpen ? panelOpen.options : null
    let heading = options && options.heading ? this.getHeading(options.heading) : "MISSING HEADER"

    if (options && options.dynamicHeader) {
      heading = get(panelData, options.heading)
    }

    if (forcedHeading) {
      heading = forcedHeading
    }

    let subHeading = options && options.subHeading ? options.subHeading : null
    if (options && options.dynamicSubHeader) {
      const test = options.subHeading.match(/{(.*?)}/)

      if (test) {
        const value = get(panelData, test[1])
        subHeading = options.subHeading.replace(/{(.*?)}/, `"${value}"`)
      }
    }

    return (
      <div className={`Panel__Header ${classes}`}>
        <div className="Panel__Header-Info">
          {subHeading ? <div className="Panel__Header-Sub-Heading">{subHeading}</div> : ""}
          <div className="Panel__Header-Heading">{heading}</div>
        </div>
        {middleComponent && middleComponent}
        <div className="Panel__Header-Buttons">{buttons || ""}</div>
      </div>
    )
  }
}

PanelHeader.propTypes = {
  schedulerData: PropTypes.object,
  application: PropTypes.object,
  middleComponent: PropTypes.object,
  buttons: PropTypes.array,
  classes: PropTypes.string,
  forcedHeading: PropTypes.string,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    application: state.application,
    schedulerData: state.schedulerData.schedulerData.data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelHeader)
