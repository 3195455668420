/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React from "react"
import ReactDOM from "react-dom"

import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"

import App from "../shared/App"
import configureStore, { history } from "../shared/redux/store"

require("./styles/root.scss")

const store = configureStore({})

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
)

export { store }
