import React, { Component } from "react"
import PropTypes from "prop-types"

class InputCheckboxElement extends Component {
  render() {
    const { classes, header, label, desc, name, id, value, onUpdate, styles } = this.props

    const active = value ? "active" : ""

    return (
      <div className={`PanelForm__InputPart PanelForm__InputPart--checkbox ${classes || ""} ${active}`}>
        {header ? <label className="PanelForm__inputheading">{header}</label> : null}
        <label style={styles} className={`PanelForm__inputlabel ${value ? "selected" : ""}`}>
          <input onChange={(elm) => onUpdate(elm, id)} name={name} checked={!!value} type="checkbox" />
          <span className="check-indicator"></span>
          <span className="check-text">{label}</span>
        </label>
        {desc ? <span className="PanelForm__inputinfo">{desc}</span> : null}
      </div>
    )
  }
}

InputCheckboxElement.propTypes = {
  header: PropTypes.string,
  label: PropTypes.string,
  desc: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.string,
  id: PropTypes.any,
  value: PropTypes.any,
  onUpdate: PropTypes.func.isRequired,
  styles: PropTypes.object,
}

export default InputCheckboxElement
