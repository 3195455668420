import { EDIT_WELL_INSTANCE, INPUT_WELL_INSTANCE_INFO, CANCEL, SUBMIT } from "../../../../panelConsts"

const config = {
  type: EDIT_WELL_INSTANCE,
  index: 0,
  options: {
    heading: "wells[0].well.name",
    dynamicHeader: true,
    subHeading: "Edit well",
    largeModal: true,
    hideMenu: true,
  },
  pages: [
    {
      type: INPUT_WELL_INSTANCE_INFO,
      name: "Well Info",
      options: {
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
