import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { bindActionCreators } from "redux"
import Select from "react-select"
import { find, cloneDeep } from "lodash"
import { ActionCreators } from "../../../redux/actions"

class EditLicense extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editItem: null,
      ourInterest: null,
    }
  }

  componentDidMount() {
    const { getAssetList, getAreaList, editItem } = this.props
    this.setState({
      editItem: this.props.editItem,
    })

    const ourInterestRow =
      editItem.ownerInterests && editItem.ownerInterests.length > 0 ? editItem.ownerInterests.find((e) => e.is_ours === true) : null
    if (ourInterestRow) {
      this.setState({
        ourInterest: ourInterestRow.interest,
      })
    }

    getAssetList()
    getAreaList()
  }

  handleOurInterestChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    // editItem = event.target.value

    const ourInterestIndex =
      editItem.ownerInterests && editItem.ownerInterests.length > 0 ? editItem.ownerInterests.findIndex((e) => e.is_ours === true) : null

    if (ourInterestIndex !== null && ourInterestIndex > -1) {
      editItem.ownerInterests[ourInterestIndex].interest = Number(event.target.value)
    } else {
      editItem.ownerInterests.push({
        is_ours: true,
        company: "Ours",
        interest: Number(event.target.value),
      })
    }

    this.setState({ editItem, ourInterest: event.target.value })
  }

  handleInterestNameChange = (key, name) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem.ownerInterests[key].company = name
    this.setState({ editItem })
  }

  handleInterestChange = (key, name) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem.ownerInterests[key].interest = name
    this.setState({ editItem })
  }

  addInterest = (event) => {
    event.preventDefault()
    const editItem = cloneDeep(this.state.editItem)
    editItem.ownerInterests.push({
      is_ours: false,
      company: "",
      interest: "",
    })
    this.setState({ editItem })
  }

  removeInterest = (event, key) => {
    event.preventDefault()
    const editItem = cloneDeep(this.state.editItem)
    editItem.ownerInterests.splice(key, 1)
    this.setState({ editItem })
  }

  handleInputChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[event.target.name] = event.target.value
    this.setState({ editItem })
  }

  handleSelectChange = (value, key) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[key] = value ? value.id : null
    this.setState({ editItem })
  }

  closeModal = () => {
    const { clearSingleItemEdit } = this.props
    clearSingleItemEdit()
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleEditFormSubmit = (event) => {
    const { handleEditFormSubmit } = this.props
    const { editItem } = this.state

    event.preventDefault()
    event.stopPropagation()

    handleEditFormSubmit(editItem)
  }

  render() {
    const {
      editItem: { name },
      assets,
      areas,
    } = this.props
    const { editItem, ourInterest } = this.state

    if (!editItem) {
      return null
    }

    return (
      <form className="ReactModal__Wrapper" onSubmit={(event) => this.handleEditFormSubmit(event)}>
        <div className="ReactModal__Form ModalForm">
          <h2 className="ReactModal__FormHeader">
            Edit <strong>{name}</strong>
          </h2>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Name</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editItem.name || ""}
              name={"name"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Name"
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">NPDID</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editItem.npdid || ""}
              name={"npdid"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Input the NPDID"
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Asset</label>
            <Select
              options={assets}
              closeMenuOnSelect={true}
              isClearable={true}
              placeholder="Select asset"
              className="White__Dropdown"
              getOptionLabel={(option) => option.name}
              value={find(assets, { id: editItem.asset_id })}
              classNamePrefix="Select__Dropdown__Elm"
              onChange={(value) => this.handleSelectChange(value, "asset_id")}
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Area</label>
            <Select
              options={areas}
              closeMenuOnSelect={true}
              isClearable={true}
              placeholder="Select area"
              className="White__Dropdown"
              getOptionLabel={(option) => option.name}
              value={find(areas, { id: editItem.area_id })}
              classNamePrefix="Select__Dropdown__Elm"
              onChange={(value) => this.handleSelectChange(value, "area_id")}
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Owner interest</label>
            <div className="OwnerInterest">
              <div className="OwnerInterest__row OwnerInterest__row--head">
                <div className="OwnerInterest__cell">
                  <span className="OwnerInterest__text">Company</span>
                  <button type="button" onClick={this.addInterest} className="OwnerInterest__add">
                    + Add new
                  </button>
                </div>
                <div className="OwnerInterest__cell">
                  <span className="OwnerInterest__text">Interest %</span>
                </div>
              </div>
              <div className="OwnerInterest__row OwnerInterest__row--ours">
                <div className="OwnerInterest__cell">
                  <input
                    value={"Our interest"}
                    name={"0"}
                    className="OwnerInterest__input OwnerInterest__input--ours-label"
                    placeholder="Company name"
                    type={"text"}
                    disabled
                  />
                </div>
                <div className="OwnerInterest__cell">
                  <input
                    onKeyDown={this.handleEnterPress}
                    value={ourInterest || ""}
                    name={"ourInterest"}
                    onChange={this.handleOurInterestChange}
                    className="OwnerInterest__input OwnerInterest__input--interest"
                    type={"number"}
                    placeholder="%"
                    max={100}
                  />
                </div>
              </div>
              {editItem.ownerInterests &&
                editItem.ownerInterests.length > 0 &&
                editItem.ownerInterests
                  // .filter((e) => e.is_ours !== true)
                  .map((e, k) =>
                    e.is_ours === true ? null : (
                      <div key={`interest-${k}`} className="OwnerInterest__row">
                        <div className="OwnerInterest__cell">
                          <input
                            onKeyDown={this.handleEnterPress}
                            value={e.company || ""}
                            name={""}
                            onChange={(event) => this.handleInterestNameChange(k, event.target.value)}
                            className="OwnerInterest__input"
                            placeholder="Company name"
                            type={"text"}
                          />
                          <button type="button" onClick={(event) => this.removeInterest(event, k)} className="OwnerInterest__remove">
                            Remove
                          </button>
                        </div>
                        <div className="OwnerInterest__cell">
                          <input
                            onKeyDown={this.handleEnterPress}
                            value={e.interest || ""}
                            name={"interest"}
                            onChange={(event) => this.handleInterestChange(k, event.target.value)}
                            className="OwnerInterest__input OwnerInterest__input--interest"
                            type={"number"}
                            placeholder="%"
                          />
                        </div>
                      </div>
                    )
                  )}
              <div className="OwnerInterest__row">
                <div className="OwnerInterest__cell">
                  <span style={{ backgroundColor: "#fff", fontStyle: "italic", lineHeight: "0.8rem" }} className="OwnerInterest__input">
                    Sum
                  </span>
                </div>
                <div className="OwnerInterest__cell">
                  <span style={{ backgroundColor: "#fff" }} className="OwnerInterest__input OwnerInterest__input--interest">
                    {editItem.ownerInterests &&
                      editItem.ownerInterests.length > 0 &&
                      editItem.ownerInterests.reduce((prev, c) => prev + Number(c.interest), 0)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ReactModal__Buttons">
          <button type="button" onClick={this.closeModal} className="ReactModal__Button">
            Cancel
          </button>
          <button type="submit" className={"ReactModal__Button ReactModal__Button--primary"}>
            {"Save"}
          </button>
        </div>
      </form>
    )
  }
}

EditLicense.propTypes = {
  editItem: PropTypes.object,
  clearSingleItemEdit: PropTypes.func,
  getAssetList: PropTypes.func,
  getAreaList: PropTypes.func,
  handleEditFormSubmit: PropTypes.func,
  assets: PropTypes.array,
  areas: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editItem: state.managecontent.editItem,
    assets: state.managecontent.assets,
    areas: state.managecontent.areas,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLicense)
