import PropTypes from "prop-types"
import React, { Component } from "react"
import ConditionalField, { ConditionalFieldBlock } from "../common/ConditionalField"

class WellPopoverMetaInfo extends Component {
  render() {
    const { well, hasRigMove, isSingleWell } = this.props
    let info = []

    // Well test & Candidate for vessel drilling
    const wellTest = well.well.well_test ? (
      <ConditionalField scope="well" field="well_test">
        <p key={"wellTest"}>{"Well test: Yes"}</p>
      </ConditionalField>
    ) : null
    info.push(wellTest)
    const subseaWell = well.well.subsea_well ? (
      <ConditionalField scope="well" field="subsea_well">
        <p key={"subseaWell"}>{"Subsea well: Yes"}</p>
      </ConditionalField>
    ) : null
    info.push(subseaWell)
    const lwi = well.well.lwi_candidate ? (
      <ConditionalField scope="well" field="lwi_candidate">
        <p key={"lwiCandidate"}>{"Candidate for vessel drilling: Yes"}</p>
      </ConditionalField>
    ) : null
    info.push(lwi)

    switch (well.status) {
      case "conceptional":
        if (well.well_type !== "exploration") {
          let dgString = "DG passed:"
          if (well.well.dg3) {
            dgString += " DG3"
          } else if (well.well.dg2) {
            dgString += " DG2"
          } else if (well.well.dg1) {
            dgString += " DG1"
          } else {
            dgString += " -"
          }
          const dg = (
            <ConditionalFieldBlock scope="well" fields={["dg1", "dg2", "dg3"]}>
              <p key={"dgString"}>{dgString}</p>
            </ConditionalFieldBlock>
          )
          const lliString = (
            <ConditionalField scope="well" field="lli_ordered">
              <p key={"lliString"}>{`LLI ordered: ${well.well.lli_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )

          info.push(dg)
          info.push(lliString)

          const cpto = (
            <ConditionalField scope="well" field="cpt_ordered">
              <p key={"cpto"}>{`CPT ordered: ${well.well.cpt_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const cptc = (
            <ConditionalField scope="well" field="cpt_conducted">
              <p key={"cptc"}>{`CPT conducted: ${well.well.cpt_conducted ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )

          const siteSurveyO = (
            <ConditionalField scope="well" field="site_survey_ordered">
              <p key={"siteSurveyO"}>{`Site survey ordered: ${well.well.site_survey_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const siteSurveyC = (
            <ConditionalField scope="well" field="site_survey_conducted">
              <p key={"siteSurveyC"}>{`Site survey conducted: ${well.well.site_survey_conducted ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )

          info.push(cpto)
          info.push(cptc)
          info.push(siteSurveyO)
          info.push(siteSurveyC)
        } else if (well.well_type === "exploration") {
          const dtd = (
            <ConditionalField scope="well" field="decision_to_drill">
              <p key={"dtdString"}>{`Decision to drill: ${well.well.decision_to_drill ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const woi = (
            <ConditionalField scope="well" field="work_order_issues">
              <p key={"woi"}>{`Work order issued: ${well.well.work_order_issues ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const lli = (
            <ConditionalField scope="well" field="lli_ordered">
              <p key={"lli"}>{`LLI ordered: ${well.well.lli_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const cpto = (
            <ConditionalField scope="well" field="cpt_ordered">
              <p key={"cpto"}>{`CPT ordered: ${well.well.cpt_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const cptc = (
            <ConditionalField scope="well" field="cpt_conducted">
              <p key={"cptc"}>{`CPT conducted: ${well.well.cpt_conducted ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const siteSurveyO = (
            <ConditionalField scope="well" field="site_survey_ordered">
              <p key={"siteSurveyO"}>{`Site survey ordered: ${well.well.site_survey_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const siteSurveyC = (
            <ConditionalField scope="well" field="site_survey_conducted">
              <p key={"siteSurveyC"}>{`Site survey conducted: ${well.well.site_survey_conducted ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )

          info.push(dtd)
          info.push(woi)
          info.push(lli)
          info.push(cpto)
          info.push(cptc)
          info.push(siteSurveyO)
          info.push(siteSurveyC)
        }
        break
      case "plan_and_select":
        if (well.well_type !== "exploration") {
          const lli = (
            <ConditionalField scope="well" field="lli_ordered">
              <p key={"dtdString"}>{`LLI ordered: ${well.well.lli_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          info.push(lli)
        } else if (well.well_type === "exploration") {
          const lli = (
            <ConditionalField scope="well" field="lli_ordered">
              <p key={"lli"}>{`LLI ordered: ${well.well.lli_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const cpto = (
            <ConditionalField scope="well" field="cpt_ordered">
              <p key={"cpto"}>{`CPT ordered: ${well.well.cpt_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const cptc = (
            <ConditionalField scope="well" field="cpt_conducted">
              <p key={"cptc"}>{`CPT conducted: ${well.well.cpt_conducted ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const siteSurveyO = (
            <ConditionalField scope="well" field="site_survey_ordered">
              <p key={"siteSurveyO"}>{`Site survey ordered: ${well.well.site_survey_ordered ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          const siteSurveyC = (
            <ConditionalField scope="well" field="site_survey_conducted">
              <p key={"siteSurveyC"}>{`Site survey conducted: ${well.well.site_survey_conducted ? " Yes" : " No"}`}</p>
            </ConditionalField>
          )
          info.push(lli)
          info.push(cpto)
          info.push(cptc)
          info.push(siteSurveyO)
          info.push(siteSurveyC)
        }
        break
      case "engineering_and_design":
      case "plan_for_execution": {
        const afc = (
          <ConditionalField scope="well" field="afc">
            <p key={"afc"}>{`Application of consent: ${well.well.afc ? " Yes" : " No"}`}</p>
          </ConditionalField>
        )
        const afd = (
          <ConditionalField scope="well" field="afd">
            <p key={"afd"}>{`Application for discharge: ${well.well.afd ? " Yes" : " No"}`}</p>
          </ConditionalField>
        )
        info.push(afc)
        info.push(afd)
        break
      }
      default:
    }

    const environmental = well.well.environmental_constraints ? (
      <ConditionalField scope="well" field="environmental_constraints">
        <p key={"environmental"}>
          <br />
          <strong>Environmental constraints:</strong>
          <br />
          {well.well.environmental_constraints.split("\n").map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
        </p>
      </ConditionalField>
    ) : null
    info.push(environmental)

    const startUpConstraints = well.well.start_up_constraints ? (
      <ConditionalField scope="well" field="start_up_constraints">
        <p key={"startUpConstraints"}>
          <br />
          <strong>Starts up constraints:</strong>
          <br />
          {well.well.start_up_constraints.split("\n").map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
        </p>
      </ConditionalField>
    ) : null
    info.push(startUpConstraints)

    const comment = well.well.comment ? (
      <p style={{ marginTop: 25 }} key={"comment"}>
        <strong>Comment:</strong>
        <br />
        {well.well.comment.split("\n").map((item, key) => (
          <span key={key}>
            {item}
            <br />
          </span>
        ))}
      </p>
    ) : null

    // info.push(comment)

    info = info.filter((r) => r !== null)

    return (
      <div className="WellPopover__ContentPart WellPopover__ContentPart--wellinfo" style={hasRigMove ? { paddingLeft: "15px" } : {}}>
        <ConditionalFieldBlock
          scope="well"
          fields={[
            "dg1",
            "dg2",
            "dg3",
            "start_up_constraints",
            "environmental_constraints",
            "afd",
            "afc",
            "lli_ordered",
            "cpt_conducted",
            "cpt_ordered",
            "decision_to_drill",
            "lwi_candidate",
            "site_survey_conducted",
            "site_survey_ordered",
            "well_test",
            "subsea_well",
            "work_order_issues",
          ]}
        >
          {info && info.length > 0 && (
            <>
              <h3 className="WellPopover__ContentHeadline">Info</h3>
              <div className="WellPopover__ContentText WellPopover__ContentText--info">{info}</div>
            </>
          )}
        </ConditionalFieldBlock>
        {comment !== null && <div className="WellPopover__ContentText WellPopover__ContentText--info">{comment}</div>}
      </div>
    )
  }
}

WellPopoverMetaInfo.propTypes = {
  well: PropTypes.object,
  hasRigMove: PropTypes.bool,
  isSingleWell: PropTypes.bool,
}

export default WellPopoverMetaInfo
