import PropTypes from "prop-types"
import React, { PureComponent } from "react"

import Dates from "./Dates"
import NowTo1YearIndicator from "./NowTo1YearIndicator"
import Plotter from "./Plotter"

class Calendar extends PureComponent {
  render() {
    const { scrollTop, syncScroll } = this.props
    return (
      <div className="Calendar">
        <NowTo1YearIndicator />
        <Dates />
        <Plotter scrollTop={scrollTop} syncScroll={syncScroll} />
      </div>
    )
  }
}

Calendar.propTypes = {
  scrollTop: PropTypes.number,
  syncScroll: PropTypes.func,
}

export default Calendar
