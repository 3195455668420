import React, { PureComponent } from "react"
import PropTypes from "prop-types"

class AddWellButton extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showAddBtns: false,
    }
  }

  onClick = () => {
    this.setState({
      showAddBtns: !this.state.showAddBtns,
    })
  }

  render() {
    const { showAddBtns } = this.state
    const { btnText, addWell, addExistingWell } = this.props

    return showAddBtns ? (
      <div className="addwell-btns">
        <button
          className={"btn"}
          onClick={() => {
            this.onClick()
            addWell()
          }}
        >
          {"Create new well"}
        </button>
        <button
          className={"btn"}
          onClick={() => {
            this.onClick()
            addExistingWell()
          }}
        >
          {"Link an existing well"}
        </button>
        <a onClick={this.onClick} className="cancel">
          Cancel
        </a>
      </div>
    ) : (
      <button className={"addwell-btn"} onClick={() => this.onClick()}>
        {btnText}
      </button>
    )
  }
}

AddWellButton.propTypes = {
  clickHandler: PropTypes.func,
  options: PropTypes.object,
  addWell: PropTypes.func,
  addExistingWell: PropTypes.func,
  classes: PropTypes.string,
  children: PropTypes.any,
  btnText: PropTypes.string,
}

export default AddWellButton
