import { REMOVE_RIG, SELECT_RIGS_REMOVE_P_VERSION, CANCEL, SUBMIT } from "../../../../panelConsts"

const config = {
  type: REMOVE_RIG,
  index: 0,
  options: {
    heading: "",
    dynamicHeader: true,
    dynamicSubHeader: true,
    subHeading: "Remove {rigName} from this schedule?",
  },
  pages: [
    {
      type: SELECT_RIGS_REMOVE_P_VERSION,
      name: "Select versions",
      options: {
        hideMenu: true,
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
