import React from "react"

const Preview = (style, key) => (
  <svg
    key={key}
    width="20px"
    height="12px"
    viewBox="0 0 20 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs></defs>
    <g id="Modules-+-Flows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="creator-open-request-2" transform="translate(-701.000000, -327.000000)">
        <g id="Icons/Eye/Show/White" transform="translate(701.000000, 327.000000)">
          <path
            d="M19.3618584,6 C19.211071,5.81622425 19.0374967,5.61373694 18.8424303,5.39690114 C18.2253068,4.71090694 17.5236353,4.02456452 16.7519857,3.38495009 C14.571355,1.57744216 12.279739,0.5 10,0.5 C7.72026101,0.5 5.42864502,1.57744216 3.24801435,3.38495009 C2.47636471,4.02456452 1.77469321,4.71090694 1.15756973,5.39690114 C0.96250327,5.61373694 0.788928979,5.81622425 0.638141646,6 C0.788928979,6.18377575 0.96250327,6.38626306 1.15756973,6.60309886 C1.77469321,7.28909306 2.47636471,7.97543548 3.24801435,8.61504991 C5.42864502,10.4225578 7.72026101,11.5 10,11.5 C12.279739,11.5 14.571355,10.4225578 16.7519857,8.61504991 C17.5236353,7.97543548 18.2253068,7.28909306 18.8424303,6.60309886 C19.0374967,6.38626306 19.211071,6.18377575 19.3618584,6 Z"
            id="Oval-Copy-2"
            stroke="#F5F8F9"
            strokeWidth="1"
          ></path>
          <circle id="Oval-2-Copy-2" fill="#F5F8F9" fillRule="evenodd" cx="10" cy="6" r="3"></circle>
        </g>
      </g>
    </g>
  </svg>
)

export default Preview
