/* eslint-disable import/prefer-default-export */
import { from, of } from "rxjs"
import { ofType } from "redux-observable"
import { map, switchMap, catchError } from "rxjs/operators"
import * as types from "../types"
import { setCalendar, setDayWidth } from "../actions/calendar"
import { observerError } from "../actions/observer"
import CalendarUtils from "../../lib/CalendarUtils"

/**
  POPULATE CALENDAR CALENDAR DAYS
*/

export const updateCalendarTimespan = (action$, state$) =>
  action$.pipe(
    ofType(types.SET_CALENDAR_TIMESPAN),
    switchMap(() =>
      from(CalendarUtils.populateCalendar(state$.value.calendar.timespan.from, state$.value.calendar.timespan.to)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    map((result) => setCalendar(result))
  )

/**
  UPDATE DAY WIDTH
*/
export const updateDayWidthAfterZoomLevelChange = (action$, state$) =>
  action$.pipe(
    ofType(types.SET_ZOOM_LEVEL),
    switchMap(() =>
      from(
        CalendarUtils.updateDayWidth(state$.value.calendar.zoomLevel, state$.value.application.size.width, state$.value.calendar.timespan)
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    ),
    map((result) => setDayWidth(result))
  )
