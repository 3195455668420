import React from "react"
import PropTypes from "prop-types"
import SchedulerTopVersionTimeline from "../../containers/Timeline/SchedulerTopVersionTimeline"

const ChooseMonth = (props) => {
  const { selectedMonth } = props

  if (Number.isNaN(selectedMonth) || selectedMonth === null) return <div className={"choose-month"}></div>

  return (
    <>
      <SchedulerTopVersionTimeline />
    </>
  )
}

ChooseMonth.propTypes = {
  open: PropTypes.string,
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  getTimelineData: PropTypes.func,
}

export default ChooseMonth
