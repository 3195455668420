import React from "react"

const ShareToElm = (value, classnames = "", onClose) => (
  <div key={value} className={`Share__Element ${classnames}`}>
    <div className="Share__Element__Header">{value}</div>
    <div className="Share__Element__Delete__Header">{`Removing ${value}`}</div>
    <div className="Share__Element__Remove" onClick={() => onClose(value)}>
      X
    </div>
    <div className="Share__Element__Undo__Remove" onClick={() => onClose(value)}>
      Undo?
    </div>
  </div>
)

export default ShareToElm
