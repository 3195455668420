import React from "react"

const Arrow = (style, key) => (
  <svg key={key} viewBox="0 0 23 12" version="1.1">
    <filter id="dropshadow" height="130%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
      <feOffset dx="1" dy="1" result="offsetblur" />
      <feComponentTransfer>
        <feFuncA type="linear" slope="0.5" />
      </feComponentTransfer>
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
    <defs>
      <path d="M23,11.5 C23,5.14872538 17.8512746,0 11.5,0 C5.14872538,0 0,5.14872538 0,11.5" id="path-1"></path>
      <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="23" height="11.5" fill="white">
        <use xlinkHref="#path-1"></use>
      </mask>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="4">
      <g className="colorChange" id="Well/Extra/Back-to-back/Execute" stroke={style.borderLeftColor} strokeWidth="2">
        <use id="Oval-9" mask="url(#mask-2)" xlinkHref="#path-1"></use>
      </g>
    </g>
  </svg>
)

export default Arrow
