import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { set, cloneDeep } from "lodash"

import { ActionCreators } from "../../../../redux/actions"
import PanelFooter from "../../../../components/panel/PanelFooter"
import PH from "../../../../lib/PanelHelper"

class ConfirmSetMaster extends Component {
  constructor(props) {
    super(props)
    this.state = {
      postData: {
        summary: "",
      },
      pages: props.pages,
      options: props.options,
    }
  }

  onSubmit = () => {
    const { setCurrentScheduleAsMaster } = this.props
    setCurrentScheduleAsMaster(this.state.postData)
  }

  setSubPageValidated = (index, isValidated) => {
    const validated = cloneDeep(this.state.validated)

    validated[index] = isValidated

    this.setState({
      validated,
    })
  }

  onUpdate = (obj) => {
    const { postData } = this.state

    const nPostData = cloneDeep(postData)
    Object.entries(obj).forEach(([key, val]) => {
      if (val || val === "") {
        if (key.includes(".")) {
          set(nPostData, key, val)
        } else {
          nPostData[key] = val
        }
      } else {
        delete nPostData[key]
      }
    })

    this.setState({
      postData: nPostData,
    })
  }

  getPage = (index, pages, postData) =>
    PH.getPage(
      index,
      pages[index],
      (val, type) => this.onUpdate(val, type),
      (i, val) => this.setSubPageValidated(i, val),
      postData
    )

  render() {
    const { pages, postData } = this.state
    const {
      panelOpen: { index },
    } = this.props
    return [
      <div key={"Panel__Page"} className="Panel__Page ">
        <div className="Panel__Page-Container PanelContent">{this.getPage(index, pages, postData)}</div>
        <PanelFooter index={index} data={pages[index]} validated={true} onSubmit={(val) => this.onSubmit(val)} />
      </div>,
    ]
  }
}

ConfirmSetMaster.propTypes = {
  panelOpen: PropTypes.object,
  panelData: PropTypes.object,
  options: PropTypes.object,
  pages: PropTypes.array,
  index: PropTypes.number,
  setCurrentScheduleAsMaster: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    panelOpen: state.application.panelOpen,
    panelData: state.application.panelData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSetMaster)
