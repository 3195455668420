import React from "react"
import PropTypes from "prop-types"
import IconContainer from "../icons/IconContainer"
import page from "../../../assets/images/svg/icons/page.svg"
import { DECISION_SUMMARY } from "../../panelConsts"

const MonthRecap = (props) => {
  const { data, openPanelWithType, name } = props

  const openDecisionSummary = () => {
    openPanelWithType(DECISION_SUMMARY, data)
  }

  if (data && !data.content) {
    return null
  }

  return (
    <div className="timeline__month-recap" onClick={openDecisionSummary}>
      <div className="timeline__month-recap--image-container">{IconContainer(page, 22, 26)}</div>
      <div className="timeline__month-recap--text-container">
        <div className="timeline__month-recap--month FontStyle--Bold">{name}</div>
        <div className="timeline__month-recap--desc">{"Decision summary"}</div>
      </div>
    </div>
  )
}

MonthRecap.propTypes = {
  openPanelWithType: PropTypes.func,
  data: PropTypes.object,
  name: PropTypes.string,
}

export default MonthRecap
