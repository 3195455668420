/* eslint-disable max-len */

import * as panelConsts from "./panelConsts"

/*

  IMPORT PANELS

*/
import RegisterNewRequest from "./containers/panel/panelPages/RegisterNewRequest"
import RegisterNewRequestConfig from "./containers/panel/panelPages/RegisterNewRequest/config"
import RegisterNewRig from "./containers/panel/panelPages/RegisterNewRig"
import RegisterNewRigConfig from "./containers/panel/panelPages/RegisterNewRig/config"
import RemoveRig from "./containers/panel/panelPages/RemoveRig"
import RemoveRigConfig from "./containers/panel/panelPages/RemoveRig/config"
import RemoveRigError from "./containers/panel/panelPages/RemoveRigError"
import RemoveRigErrorConfig from "./containers/panel/panelPages/RemoveRigError/config"
import EditRig from "./containers/panel/panelPages/EditRig"
import RegisterUpdateRequestConfig from "./containers/panel/panelPages/RegisterUpdateRequest/config"
import RegisterUpdateRequest from "./containers/panel/panelPages/RegisterUpdateRequest"
import EditRigConfig from "./containers/panel/panelPages/EditRig/config"
import AddBufferTimePanel from "./containers/panel/panelPages/AddBufferTime"
import AddBufferTimePanelConfig from "./containers/panel/panelPages/AddBufferTime/config"
import EditBufferTimePanel from "./containers/panel/panelPages/EditBufferTime"
import EditBufferTimePanelConfig from "./containers/panel/panelPages/EditBufferTime/config"
import PreviewRequest from "./containers/panel/panelPages/PreviewRequest"
import PreviewRequestConfig from "./containers/panel/panelPages/PreviewRequest/config"
import RemoveRequest from "./containers/panel/panelPages/RemoveRequest"
import RemoveRequestConfig from "./containers/panel/panelPages/RemoveRequest/config"
import RegisterNoChanges from "./containers/panel/panelPages/RegisterNoChanges"
import RegisterNoChangesConfig from "./containers/panel/panelPages/RegisterNoChanges/config"
import EditWellInstance from "./containers/panel/panelPages/EditWellInstance"
import EditWellInstanceConfig from "./containers/panel/panelPages/EditWellInstance/config"
import EditWellCampaign from "./containers/panel/panelPages/EditWellCampaign"
import EditWellCampaignConfig from "./containers/panel/panelPages/EditWellCampaign/config"
import ConfirmWellInstanceRemove from "./containers/panel/panelPages/ConfirmWellInstanceRemove"
import ConfirmWellInstanceRemoveConfig from "./containers/panel/panelPages/ConfirmWellInstanceRemove/config"
import ConfirmBufferInstanceRemove from "./containers/panel/panelPages/ConfirmBufferInstanceRemove"
import ConfirmBufferInstanceRemoveConfig from "./containers/panel/panelPages/ConfirmBufferInstanceRemove/config"
import ConfirmSetMaster from "./containers/panel/panelPages/ConfirmSetMaster"
import ConfirmSetMasterConfig from "./containers/panel/panelPages/ConfirmSetMaster/config"
import ConfirmShareSchedule from "./containers/panel/panelPages/ConfirmShareSchedule"
import ConfirmShareScheduleConfig from "./containers/panel/panelPages/ConfirmShareSchedule/config"
import ConnectExistingRig from "./containers/panel/panelPages/ConnectExistingRig"
import ConnectExistingRigConfig from "./containers/panel/panelPages/ConnectExistingRig/config"
import DecisionSummary from "./containers/panel/panelPages/DecisionSummary"
import DecisionSummaryConfig from "./containers/panel/panelPages/DecisionSummary/config"

/*

  IMPORT PANELS SUB PAGES

*/
import InputWellType from "./containers/panel/subPages/InputWellType"
// import InputWellInfo from "./containers/panel/subPages/DEPRECATED__InputWellInfo"
import InputWellRigInfo from "./containers/panel/subPages/InputWellRigInfo"
import InputRequestRigInfo from "./containers/panel/subPages/InputRequestRigInfo"
import InputRigInfo from "./containers/panel/subPages/InputRigInfo"
import InputRigSpecs from "./containers/panel/subPages/InputRigSpecs"
import SelectRigsPVersion from "./containers/panel/subPages/SelectRigsPVersion"
import SelectRigsRemovePVersion from "./containers/panel/subPages/SelectRigsRemovePVersion"
import InputTimeframe from "./containers/panel/subPages/InputTimeframe"
import InputTimeEstimate from "./containers/panel/subPages/InputTimeEstimate"
import InputComment from "./containers/panel/subPages/InputComment"
import AddBufferTime from "./containers/panel/subPages/AddBufferTime"
import RequestList from "./containers/panel/subPages/RequestList"
import InputPreviewRequest from "./containers/panel/subPages/InputPreviewRequest"
import InputRemoveRequest from "./containers/panel/subPages/InputRemoveRequest"
import SelectWellForUpdate from "./containers/panel/subPages/SelectWellForUpdate"
import InputWellInstanceInfo from "./containers/panel/subPages/InputWellInstanceInfo"
import InputWellCampaignInfo from "./containers/panel/subPages/InputWellCampaignInfo"
import ConfirmWellInstanceRemoveSubPage from "./containers/panel/subPages/ConfirmWellInstanceRemove"
import ConfirmBufferInstanceRemoveSubPage from "./containers/panel/subPages/ConfirmBufferInstanceRemoveSubPage"
import ConfirmSetAsMasterSubpage from "./containers/panel/subPages/ConfirmSetAsMasterSubpage"
import ConfirmShareScheduleSubpage from "./containers/panel/subPages/ConfirmShareScheduleSubpage"
import ConfirmShareScheduleUpdateSubpage from "./containers/panel/subPages/ConfirmShareScheduleUpdateSubpage"
import SelectExistingRig from "./containers/panel/subPages/SelectExistingRig"
import DecisionSummarySub from "./containers/panel/subPages/DecisionSummarySub"
import WriteSummarySub from "./containers/panel/subPages/WriteSummarySub"
import SelectRigsRemoveError from "./containers/panel/subPages/SelectRigsRemoveError"

/*

  START PANELS

*/
export const PANELS = new Map()
PANELS.set(panelConsts.REGISTER_NEW_REQUEST, {
  dom: RegisterNewRequest,
  config: RegisterNewRequestConfig,
})
PANELS.set(panelConsts.REGISTER_NEW_RIG, {
  dom: RegisterNewRig,
  config: RegisterNewRigConfig,
})
PANELS.set(panelConsts.EDIT_RIG, { dom: EditRig, config: EditRigConfig })
PANELS.set(panelConsts.REGISTER_UPDATE_REQUEST, {
  dom: RegisterUpdateRequest,
  config: RegisterUpdateRequestConfig,
})
PANELS.set(panelConsts.REMOVE_RIG, { dom: RemoveRig, config: RemoveRigConfig })
PANELS.set(panelConsts.REMOVE_RIG_ERROR, {
  dom: RemoveRigError,
  config: RemoveRigErrorConfig,
})
PANELS.set(panelConsts.ADD_BUFFER_TIME_PANEL, {
  dom: AddBufferTimePanel,
  config: AddBufferTimePanelConfig,
})
PANELS.set(panelConsts.EDIT_BUFFER_TIME_PANEL, {
  dom: EditBufferTimePanel,
  config: EditBufferTimePanelConfig,
})
PANELS.set(panelConsts.PANEL_PREVIEW_REQUEST, {
  dom: PreviewRequest,
  config: PreviewRequestConfig,
})
PANELS.set(panelConsts.SHOULD_YOU_REMOVE_REQUEST, {
  dom: RemoveRequest,
  config: RemoveRequestConfig,
})
PANELS.set(panelConsts.REGISTER_NO_CHANGES, {
  dom: RegisterNoChanges,
  config: RegisterNoChangesConfig,
})

PANELS.set(panelConsts.EDIT_WELL_INSTANCE, {
  dom: EditWellInstance,
  config: EditWellInstanceConfig,
})
PANELS.set(panelConsts.EDIT_WELL_CAMPAIGN, {
  dom: EditWellCampaign,
  config: EditWellCampaignConfig,
})

PANELS.set(panelConsts.CONFIRM_WELL_INSTANCE_REMOVE, {
  dom: ConfirmWellInstanceRemove,
  config: ConfirmWellInstanceRemoveConfig,
})
PANELS.set(panelConsts.CONFIRM_BUFFER_TIME_INSTANCE_REMOVE, {
  dom: ConfirmBufferInstanceRemove,
  config: ConfirmBufferInstanceRemoveConfig,
})
PANELS.set(panelConsts.CONFIRM_SET_AS_MASTER, {
  dom: ConfirmSetMaster,
  config: ConfirmSetMasterConfig,
})
PANELS.set(panelConsts.CONFIRM_SCHEDULER_SHARE, {
  dom: ConfirmShareSchedule,
  config: ConfirmShareScheduleConfig,
})
PANELS.set(panelConsts.CONNECT_EXISTING_RIG, {
  dom: ConnectExistingRig,
  config: ConnectExistingRigConfig,
})
PANELS.set(panelConsts.DECISION_SUMMARY, {
  dom: DecisionSummary,
  config: DecisionSummaryConfig,
})
/* END PANELS */

/*

  START PANELS SUB PAGES

*/
export const INPUT_PAGES = new Map()
INPUT_PAGES.set(panelConsts.INPUT_WELL_TYPE_SELECION, InputWellType)
// INPUT_PAGES.set(panelConsts.INPUT_WELL_INFO, InputWellInfo)
INPUT_PAGES.set(panelConsts.INPUT_WELL_RIG_INFO, InputWellRigInfo)
INPUT_PAGES.set(panelConsts.INPUT_REQUEST_RIG_INFO, InputRequestRigInfo)
INPUT_PAGES.set(panelConsts.INPUT_RIG_INFO, InputRigInfo)
INPUT_PAGES.set(panelConsts.INPUT_RIG_SPECS, InputRigSpecs)
INPUT_PAGES.set(panelConsts.SELECT_RIGS_P_VERSION, SelectRigsPVersion)
INPUT_PAGES.set(panelConsts.SELECT_RIGS_REMOVE_P_VERSION, SelectRigsRemovePVersion)
INPUT_PAGES.set(panelConsts.SELECT_RIGS_REMOVE_ERROR, SelectRigsRemoveError)
INPUT_PAGES.set(panelConsts.INPUT_TIMEFRAME, InputTimeframe)
INPUT_PAGES.set(panelConsts.INPUT_TIME_ESTIMATE, InputTimeEstimate)
INPUT_PAGES.set(panelConsts.INPUT_COMMENT, InputComment)
INPUT_PAGES.set(panelConsts.ADD_BUFFER_TIME, AddBufferTime)
INPUT_PAGES.set(panelConsts.INPUT_REQUEST_LIST, RequestList)
INPUT_PAGES.set(panelConsts.INPUT_PREVIEW_REQUEST, InputPreviewRequest)
INPUT_PAGES.set(panelConsts.INPUT_REMOVE_REQUEST, InputRemoveRequest)
INPUT_PAGES.set(panelConsts.INPUT_SELECT_WELL, SelectWellForUpdate)
INPUT_PAGES.set(panelConsts.INPUT_WELL_INSTANCE_INFO, InputWellInstanceInfo)
INPUT_PAGES.set(panelConsts.EDIT_WELL_CAMPAIGN_INFO, InputWellCampaignInfo)
INPUT_PAGES.set(panelConsts.CONFIRM_WELL_INSTANCE_REMOVE_SUBPAGE, ConfirmWellInstanceRemoveSubPage)
INPUT_PAGES.set(panelConsts.CONFIRM_BUFFER_TIME_INSTANCE_REMOVE_SUBPAGE, ConfirmBufferInstanceRemoveSubPage)
INPUT_PAGES.set(panelConsts.CONFIRM_SET_AS_MASTER_SUBPAGE, ConfirmSetAsMasterSubpage)
INPUT_PAGES.set(panelConsts.CONFIRM_SCHEDULER_SHARE_SUBPAGE, ConfirmShareScheduleSubpage)
INPUT_PAGES.set(panelConsts.CONFIRM_SCHEDULER_SHARE_UPDATE_SUBPAGE, ConfirmShareScheduleUpdateSubpage)
INPUT_PAGES.set(panelConsts.SELECT_EXISTING_RIG, SelectExistingRig)
INPUT_PAGES.set(panelConsts.DECISION_SUMMARY_SUB, DecisionSummarySub)
INPUT_PAGES.set(panelConsts.WRITE_DESICION_SUMMARY, WriteSummarySub)
/*  END PANELS SUB PAGES */
