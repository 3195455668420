import {
  GET_REQUESTS,
  SAVE_REQUESTS,
  SAVE_NEW_REQUEST,
  ACCEPT_REQUEST_PLACEMENT,
  SET_REQUEST_BLOCKED,
  CONFIRM_REMOVE_REQUEST,
  REMOVE_REQUEST,
  EDIT_REQUEST,
  EDIT_REQUEST_COMPLETE,
  PREVIEW_REQUEST,
  GET_REQUEST_SUGGESTONS,
  SAVE_REQUEST_SUGGESTONS,
  SET_REQUEST_STATUS,
  SET_PREVIEW_SELECTION,
  CLEAR_PREVIEW_SELECTION,
  ADD_REQUEST_TO_SCHEDULE,
  CLEAR_REQUEST_EDIT_DATA,
  CLEAR_REQUEST_TO_REMOVE,
} from "../types"

export function getRequests() {
  return { type: GET_REQUESTS }
}

export function saveRequests(data) {
  return { type: SAVE_REQUESTS, data }
}

export function saveNewRequest(request) {
  return { type: SAVE_NEW_REQUEST, request }
}

export function editRequest(request) {
  return { type: EDIT_REQUEST, request }
}

export function previewRequest(request) {
  return { type: PREVIEW_REQUEST, request }
}

export function editRequestComplete() {
  return { type: EDIT_REQUEST_COMPLETE }
}

export function acceptRequestPlacement() {
  return { type: ACCEPT_REQUEST_PLACEMENT }
}

export function setRequestStatus(request, status) {
  return { type: SET_REQUEST_STATUS, request, status }
}

export function setRequestBlocked() {
  return { type: SET_REQUEST_BLOCKED }
}

export function confirmRemoveRequest(request) {
  return { type: CONFIRM_REMOVE_REQUEST, request }
}

export function removeRequest(request) {
  return { type: REMOVE_REQUEST, request }
}

export function getSuggestions() {
  return { type: GET_REQUEST_SUGGESTONS }
}

export function saveSuggestions(data) {
  return { type: SAVE_REQUEST_SUGGESTONS, data }
}

export function setPreviewSelection(data) {
  return { type: SET_PREVIEW_SELECTION, data }
}

export function clearPreviewSelection() {
  return { type: CLEAR_PREVIEW_SELECTION }
}

export function addRequestToSchedule(data) {
  return { type: ADD_REQUEST_TO_SCHEDULE, data }
}

export function clearRequestToRemove() {
  return { type: CLEAR_REQUEST_TO_REMOVE }
}

export function clearRequestEditData() {
  return { type: CLEAR_REQUEST_EDIT_DATA }
}
