import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import WellSelection from "../../../components/form/WellSelection"
import { ActionCreators } from "../../../redux/actions"

class SelectWellForUpdate extends Component {
  constructor(props) {
    super(props)

    const { panelOpen } = props.application
    const isSelectMultiple = panelOpen.pages[panelOpen.index].options.selectMultiple === true

    this.state = {
      heading: panelOpen.pages[panelOpen.index].options.heading,
      selectMultiple: isSelectMultiple,
      selected: isSelectMultiple ? [] : null,
      filter: "",
    }
  }

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[elem.target.name] = elem.target.value

    onUpdate(obj)
  }

  componentDidMount() {
    const { fetchWellData } = this.props
    fetchWellData()
  }

  prepWellForNochanges = (well) => {
    // TODO - this should not be done here
    const obj = {}
    obj.p10_estimate = 0
    obj.p50_estimate = 0
    obj.p90_estimate = 0
    obj.well_id = well.id
    obj.well_status = well.request.wells[0].well_status

    return obj
  }

  prepWellForUpdate = (well) => {
    // TODO - this should not be done here
    const obj = well.request ? well.request : {}
    obj.booking_type = "single_well"

    delete obj.wells[0].request
    delete obj.wells[0].id
    delete obj.wells[0].well_request_id
    delete obj.id
    delete obj.created_at
    delete obj.updated_at
    delete obj.status

    return obj
  }

  selectWell = (well) => {
    const { index, onValidated, onUpdate } = this.props
    const { selectMultiple } = this.state
    if (selectMultiple) {
      if (this.isWellSelected(well)) {
        const wells = [...this.state.selected]
        const wellsindex = wells.indexOf(well)
        wells.splice(wellsindex, 1)
        this.setState(
          {
            selected: wells,
          },
          () => {
            const arr = this.state.selected.map((w) => this.prepWellForNochanges(w))
            onUpdate(arr)
          }
        )
      } else {
        this.setState(
          (prevState) => ({
            selected: [...prevState.selected, well],
          }),
          () => {
            const arr = this.state.selected.map((w) => this.prepWellForNochanges(w))
            onUpdate(arr)
            onValidated(index, true)
          }
        )
      }
    } else {
      this.setState({
        selected: well,
      })

      const obj = this.prepWellForUpdate(well)
      onUpdate(obj)
      onValidated(index, true)
    }
  }

  isWellSelected = (well) => {
    const { selectMultiple } = this.state

    if (selectMultiple) {
      return this.state.selected && this.state.selected.includes(well)
    }

    return this.state.selected && this.state.selected.id === well.id
  }

  filterWells = (event) => {
    this.setState({ filter: event.target.value })
  }
  render() {
    const { wells } = this.props
    const { heading, filter } = this.state

    let wellsList = wells
      ? wells.filter((v) => {
          if (v.request && v.request.well_status === "completed") {
            return false
          }
          return true
        })
      : []

    let helpText = "Loading wells..."
    if (filter) {
      wellsList = wellsList.filter((v) => {
        const n = v.name ? v.name : v.plno
        const exist = n.replace(/\//g, "===").toLowerCase().indexOf(filter.replace(/\//g, "===").toLowerCase()) > -1
        return exist
      })
      helpText = wellsList.length ? helpText : `Couldn't find any wells when searching for "${filter}" 🤔`
    }

    return (
      <div className="PanelContent__Container PanelForm">
        {wells ? (
          <div className="PanelForm__Header--with-search">
            <h3 className="PanelForm__Header PanelForm__Header--wellupdate PanelForm__Header--bordered">{heading}</h3>
            <input onChange={this.filterWells} placeholder="Filter" type="text" />
          </div>
        ) : null}
        {wellsList && wellsList.length > 0
          ? wellsList.map((well) => (
              <WellSelection
                selected={() => this.isWellSelected(well)}
                key={`WellSelection-${well.id}`}
                well={well}
                onSelect={this.selectWell}
              />
            ))
          : helpText}
      </div>
    )
  }
}

SelectWellForUpdate.propTypes = {
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  application: PropTypes.object,
  index: PropTypes.number,
  onValidated: PropTypes.func,
  fetchWellData: PropTypes.func,
  wells: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    wells: state.schedulerData.wells.data,
    application: state.application,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectWellForUpdate)
