import {
  GET_DOMAIN_DATA,
  SET_DOMAIN_DATA,
  RESIZE,
  SET_TOOLBAR_OPEN_STATE,
  TOGGLE_TOOLBAR_OPEN_STATE,
  GOTO_URL,
  OPEN_PANEL_WITH_TYPE,
  NEXT_STEP_IN_PANEL,
  INDEXED_STEP_IN_PANEL,
  CLOSE_PANEL,
  THROW_ERROR,
  HIDE_ERROR,
  SET_MODAL_FAILED_VALIDATION,
  UNSET_MODAL_FAILED_VALIDATION,
  SET_ACTIVITY_INDICATOR,
  UNSET_ACTIVITY_INDICATOR,
  SHAKE_BUILDER,
  TOGGLE_ERROR_CONSOLE_OPEN,
  FETCH_USER_DATA,
  SET_USER_DATA,
} from "../types"

export function getDataForDomain() {
  return { type: GET_DOMAIN_DATA }
}
export function saveDataForDomain(data) {
  return { type: SET_DOMAIN_DATA, data }
}
export function getUserData() {
  return { type: FETCH_USER_DATA }
}
export function setUserData(user) {
  return { type: SET_USER_DATA, user }
}

export function resizeView(size) {
  return { type: RESIZE, size }
}

export function setToolBarOpenState(open) {
  return { type: SET_TOOLBAR_OPEN_STATE, open }
}

export function toggleToolbarOpenState(open) {
  return { type: TOGGLE_TOOLBAR_OPEN_STATE, open }
}

export function gotoURL(url) {
  return { type: GOTO_URL, url }
}

export function openPanelWithType(panel, data) {
  return { type: OPEN_PANEL_WITH_TYPE, panel, data }
}

export function nextStepInPanel() {
  return { type: NEXT_STEP_IN_PANEL }
}

export function indexedStepInPanel(index) {
  return { type: INDEXED_STEP_IN_PANEL, index }
}

export function closePanel() {
  return { type: CLOSE_PANEL }
}

export function throwError(error) {
  return { type: THROW_ERROR, error }
}

export function showAcitivityIndicator(activity) {
  return { type: SET_ACTIVITY_INDICATOR, activity }
}

export function hideAcitivityIndicator(activity) {
  return { type: UNSET_ACTIVITY_INDICATOR, activity }
}

export function hideError() {
  return { type: HIDE_ERROR }
}

export function indicateModalFormValidationFailed() {
  return { type: SET_MODAL_FAILED_VALIDATION }
}

export function unsetFormValidationFailed() {
  return { type: UNSET_MODAL_FAILED_VALIDATION }
}

export function shakeBuilder(validated) {
  return { type: SHAKE_BUILDER, validated }
}

export function toggleErrorConsoleOpen(open) {
  return { type: TOGGLE_ERROR_CONSOLE_OPEN, open }
}
