import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"

import Chevron from "../../components/icons/Chevron"
import Master from "../../../assets/images/svg/icons/master.svg"
import MasterDraft from "../../../assets/images/svg/icons/master_draft.svg"
import Draft from "../../../assets/images/svg/icons/draft.svg"
import IconContainer from "../../components/icons/IconContainer"
import { ActionCreators } from "../../redux/actions"
import UserAccessComponent from "../../UserAccessComponent"

import MastersModuleTimeline from "../../components/Overview/MastersModuleTimeline"
import NextScheduleModuleTimeline from "../../components/Overview/NextScheduleModuleTimeline"

class SchedulerTopVersionTimeline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      activityIndicatorFinished: false,
    }

    this.toggleSelectList = this.toggleSelectList.bind(this)
  }

  componentDidUpdate = (prevProps) => {
    const {
      timeline: { schedule },
    } = this.props

    if (prevProps.timeline.schedule.id !== schedule.id) {
      this.setState({
        isOpen: false,
      })
    }
  }

  toggleSelectList() {
    const { fetchDrafts, fetchMasters, draftsList, mastersList, accessLevel } = this.props

    if (!this.state.isOpen && !draftsList.length && accessLevel > 1) {
      fetchDrafts()
    }

    if (!this.state.isOpen && !mastersList.length) {
      fetchMasters()
    }

    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const { isOpen, activityIndicatorFinished } = this.state
    const {
      accessLevel,
      timeline: { schedule },
    } = this.props

    const dropDownText = "Loading drafts..."

    let dropDownIcon = IconContainer(Draft, 18, 22)

    if (schedule.is_master) {
      dropDownIcon = IconContainer(Master, 18, 18)
    } else if (schedule.is_main_draft) {
      dropDownIcon = IconContainer(MasterDraft, 18, 18)
    } else if (schedule.master_from_date) {
      dropDownIcon = IconContainer(Master, 18, 18)
    }

    const shouldItRender = true

    return (
      <div className={`SchedulerTopVersionTimeline ${isOpen ? "open" : ""}`}>
        {
          <div className={`SchedulerTopVersionTimeline__activity-done-indicator${activityIndicatorFinished ? " active" : ""}`}>
            Done! 🙌🏼
          </div>
        }
        {shouldItRender ? (
          <a onClick={this.toggleSelectList} className="SchedulerTopVersionTimeline__selector">
            {dropDownIcon} {schedule ? schedule.name : dropDownText}
            {Chevron(isOpen ? "open" : "")}
          </a>
        ) : null}

        <div className={"SchedulerTopVersionTimeline__list DraftSelectionList"}>
          <div className="SchedulerTopVersionTimeline__list__Content__Container">
            {accessLevel > 1 ? <NextScheduleModuleTimeline /> : null}
            <MastersModuleTimeline />
          </div>
        </div>
      </div>
    )
  }
}

SchedulerTopVersionTimeline.propTypes = {
  fetchDrafts: PropTypes.func,
  fetchMasters: PropTypes.func,
  openPanelWithType: PropTypes.func,
  draftsList: PropTypes.array,
  mastersList: PropTypes.array,
  location: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  shouldRender: PropTypes.bool,
  darkMode: PropTypes.bool,
  accessLevel: PropTypes.number,
  activityIndicator: PropTypes.object,
  timeline: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    draftsList: state.overviewData.draftsList,
    mastersList: state.overviewData.mastersList,
    user: state.user,
    accessLevel: state.user.accessLevel,
    activityIndicator: state.application.activityIndicator,
    darkMode: state.calendar.darkMode,
    timeline: state.timeline,
  }
}

export default UserAccessComponent(withRouter(connect(mapStateToProps, mapDispatchToProps)(SchedulerTopVersionTimeline)), 2, true)
