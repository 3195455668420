/* eslint-disable import/prefer-default-export */
import { from, of } from "rxjs"
import { mergeMap, switchMap, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import * as types from "../types"
import { setUserRightsAndRoles } from "../actions/user"
import { observerError } from "../actions/observer"
import Api from "../../services/ApiService"
import { setZoomLevel } from "../actions/calendar"

/**
  UPDATE DAY WIDTH
*/

export const fetchUsersRightsAndRoles = (action$) =>
  action$.pipe(
    ofType(types.FETCH_USERS_RIGHTS_AND_ROLES),
    switchMap(() => from(Api.getDataFromApi("user/accessRules")).pipe(catchError((err) => of(observerError(err.xhr.response))))),
    mergeMap((result) => [
      setUserRightsAndRoles(result.data),
      setZoomLevel(result.data.accessLevel < 2 ? types.DURATION_18_MONTHS : types.DURATION_FIVE_YEAR),
    ])
  )
