import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import SchedulerTop from "../../containers/Scheduler/SchedulerTop"
import SchedulerOverview from "../../containers/Scheduler/SchedulerOverview"
import ToolBar from "../../containers/ToolBar"
import Legend from "../../components/Scheduler/Calendar/Legend"
import AIU from "../../lib/ActivityIndicatorUtils"

class Scheduler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activityIndicatorFinished: false,
    }
  }

  render() {
    const { dayWidth, application, darkMode, accessLevel, timespan, activityIndicator } = this.props

    let toolbarClassNames = "ToolBarContainer"
    toolbarClassNames += application.toolbarOpen === true ? " open" : ""

    if (dayWidth && timespan) {
      return (
        <div className={`Scheduler--Container${darkMode ? " darkmode" : ""}`}>
          {accessLevel > 3 ? (
            <div className={toolbarClassNames}>
              <ToolBar></ToolBar>
            </div>
          ) : null}

          <div className={`activity-indicator ${Object.keys(activityIndicator).length ? " active" : ""}`}>
            <div className="Preloader--Container">
              <div className="activityLoader">
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
                <div className="loader--text">{Object.keys(activityIndicator).map((i) => AIU.getLabelByKey(i))}</div>
              </div>
            </div>
          </div>

          <div className={`Scheduler${application.toolbarOpen ? " toolbarOpen" : ""}`}>
            <SchedulerTop />
            <SchedulerOverview />
          </div>

          <Legend />
        </div>
      )
    }

    return (
      <div className="activity-indicator active">
        <div className="Preloader--Container">
          <div className="activityLoader">
            {!Object.keys(activityIndicator).includes("no_access") && (
              <>
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
                <div className="loader--dot"></div>
              </>
            )}
            <div className="loader--text">{Object.keys(activityIndicator).map((i) => AIU.getLabelByKey(i))}</div>
          </div>
        </div>
      </div>
    )
  }
}

Scheduler.propTypes = {
  dayWidth: PropTypes.number,
  timespan: PropTypes.object,
  zoomLevel: PropTypes.number,
  fetchRigData: PropTypes.func,
  setScrollPosition: PropTypes.func,
  setCalendarTimespan: PropTypes.func,
  match: PropTypes.object,
  application: PropTypes.object,
  darkMode: PropTypes.bool,
  readonly: PropTypes.bool,
  visibleYears: PropTypes.array,
  end_date: PropTypes.string,
  start_date: PropTypes.string,
  accessLevel: PropTypes.number,
  activityIndicator: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    dayWidth: state.calendar.dayWidth,
    timespan: state.calendar.timespan,
    zoomLevel: state.calendar.zoomLevel,
    application: state.application,
    darkMode: state.calendar.darkMode,
    visibleYears: state.calendar.visibleYears,
    accessLevel: state.user.accessLevel,
    activityIndicator: state.application.activityIndicator,
    readonly: state.schedulerData.schedulerData.data ? state.schedulerData.schedulerData.data.readonly : false,
    start_date: state.schedulerData.schedulerData.data ? state.schedulerData.schedulerData.data.start_date : null,
    end_date: state.schedulerData.schedulerData.data ? state.schedulerData.schedulerData.data.end_date : null,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler)
