import { findIndex } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { hot } from "react-hot-loader/root"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import Chevron from "../icons/Chevron"
import WellDetails from "./WellDetails"

import { WELL_STATUS } from "../../redux/types"

const statusArr = Array.from(WELL_STATUS).map((val) => ({
  label: val[1].name,
  name: val[1].name,
  value: val[0],
  color: val[1].color,
}))

class CampaignInfoContainer extends Component {
  constructor(props) {
    super(props)

    const {
      data,
      blocks,
      assets,
      licenses,
      fetchBlockData,
      fetchAssetsData,
      fetchLicenseData,
      rigList,
      fetchRigList,
    } = props

    if (!rigList) {
      fetchRigList()
    }
    const currentStatus = statusArr.find((a) => a.value === data.status)

    this.state = {
      status: currentStatus || null,
      editDetails: false,
    }

    if (!blocks.data || !assets.data) {
      fetchBlockData()
      fetchAssetsData()
    }
    if (!licenses) {
      fetchLicenseData()
    }
    if (!rigList) {
      fetchRigList()
    }
  }

  clearFilter = () => {
    this.setState({
      status: null,
    })
  }

  setStatusFilter = (val) => {
    this.setState({
      status: val,
    })
  }

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}

    let { value } = elem.target
    if (elem.target.name === "estimate") {
      const parsed = parseInt(elem.target.value, 0)
      value = this.isNumeric(parsed) ? parsed : ""
    }

    obj[elem.target.name] = value

    onUpdate(obj)
  }

  renderStatus = (key, val) => (
    <div
      onClick={() => this.setStatusFilter(val)}
      className={`RoundedDropDown__Option RoundedDropDown__Option--status${
        val.selected ? " RoundedDropDown__Option--selected" : ""
      }`}
      key={`${key}-statusfilter-option`}
    >
      <span
        style={{ background: val.color }}
        className="RoundedDropDown__Option--status-color"
      ></span>
      <span className="RoundedDropDown__Option-Value">{val.name}</span>
    </div>
  )

  render() {
    const {
      // data,
      data: { wells },
      mode,
      onFieldUpdate,
      handleStatusChange,
      handleCommentChange,
      setMode,
      blocks,
      assets,
      rigList,
      licenses,
      failedStatusChange,
      user,
      domain: {
        data: {
          activeRigTypes,
          activeWellTypes,
          eventTypes,
          includeAfeCo2,
          scenarios,
          units,
          currency,
        },
      },
    } = this.props

    let element = {}

    if (mode.id || mode.id === 0) {
      element = wells[findIndex(wells, (w) => w.id === mode.id)]
    }

    return (
      <div className={`Details__Container${mode.expanded ? " expanded" : ""}`}>
        <a
          onClick={() => {
            setMode({ ...mode, expanded: !mode.expanded })
          }}
          className={`Details__edit-btn${
            mode.type !== "campaign" ? " active" : ""
          }`}
        >
          {Chevron(`white${mode.expanded ? "" : " open"}`)}{" "}
          {mode.expanded ? "Hide details" : "Edit details"}
        </a>

        <WellDetails
          onFieldUpdate={onFieldUpdate}
          handleStatusChange={handleStatusChange}
          handleCommentChange={handleCommentChange}
          data={element}
          wells={wells}
          showExpanded={mode.expanded}
          blocks={blocks}
          licenses={licenses}
          assets={assets}
          direction={mode.expanded ? "down" : "up"}
          rigList={rigList}
          failedStatusChange={failedStatusChange}
          activeRigTypes={activeRigTypes}
          activeWellTypes={activeWellTypes}
          activeEventTypes={eventTypes}
          includeAfeCo2={includeAfeCo2}
          scenarios={scenarios}
          units={units}
          currency={currency}
          user={user}
        />
      </div>
    )
  }
}

CampaignInfoContainer.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.object,
  failedStatusChange: PropTypes.string,
  onUpdate: PropTypes.func,
  onFieldUpdate: PropTypes.func,
  setMode: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleCommentChange: PropTypes.func,
  blocks: PropTypes.object,
  assets: PropTypes.object,
  licenses: PropTypes.object,
  fetchBlockData: PropTypes.func,
  fetchAssetsData: PropTypes.func,
  fetchLicenseData: PropTypes.func,
  rigList: PropTypes.array,
  fetchRigList: PropTypes.func,
  domain: PropTypes.object,
  user: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    blocks: state.schedulerData.blocks,
    licenses: state.schedulerData.licenses,
    assets: state.schedulerData.assets,
    rigList: state.schedulerData.rigList,
    domain: state.application.domain,
    user: state.application.user,
  }
}

export default hot(
  connect(mapStateToProps, mapDispatchToProps)(CampaignInfoContainer)
)
