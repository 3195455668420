import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"
import { readableAppSetting } from "../../../lib/AppSettingsUtils"

import ContentList from "../Bits/ContentList"

class EditAppSettings extends Component {
  componentDidMount() {
    const { getAppSettings } = this.props
    getAppSettings()
  }

  handleDeleteFormSubmit = (selectedItems) => {
    const { deleteItems } = this.props
    deleteItems("appSettings", selectedItems)
  }

  editItem = (item) => {
    const { editSingleItem } = this.props
    editSingleItem("appSettings", item)
  }

  addItem = () => {
    const { editSingleItem } = this.props
    editSingleItem("appSettings", { key: "", value: "" })
  }

  render() {
    const { settings } = this.props
    return (
      <div className="ManageContent__Content">
        <ContentList
          hideAddButton={false}
          addItem={this.addItem}
          editItem={this.editItem}
          deleteItemsFunc={this.handleDeleteFormSubmit}
          header={"Edit app settings"}
          items={settings}
          renderItemName={(s) => readableAppSetting(s.key)}
          showFilter={false}
        />
      </div>
    )
  }
}

EditAppSettings.propTypes = {
  editSingleItem: PropTypes.func,
  getAppSettings: PropTypes.func,
  deleteItems: PropTypes.func,
  settings: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    settings: state.managecontent.settings,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAppSettings)
