import React, { Component } from "react"
import PropTypes from "prop-types"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import parseISO from "date-fns/parseISO"

import CU from "../../../../lib/CalendarUtils"
import * as types from "../../../../redux/types"

class FreeTime extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.data,
      active: true,
      estimate: props.estimate,
      startDate: props.start_date,
      PVersion: props.PVersion,
    }
  }

  static getDays(rigStart, calendarStart, duration, dayWidth) {
    const isoFrom = parseISO(rigStart)
    const posX = differenceInCalendarDays(isoFrom, calendarStart) * dayWidth

    return posX
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextState.estimate !== this.state.estimate) {
      return true
    }
    if (nextProps.PVersion !== this.props.PVersion) {
      return true
    }
    if (nextProps.estimate !== this.props.estimate) {
      return true
    }
    if (nextProps.estimateData !== this.props.estimateData) {
      return true
    }

    if (nextProps.dayWidth !== this.props.dayWidth) {
      return true
    }

    return false
  }

  componentDidUpdate = () => {
    const { estimate: stateEstimate, startDate: stateStartDate } = this.state

    const { estimate, start_date: startDate } = this.props

    if (stateEstimate !== estimate || stateStartDate !== startDate) {
      this.setState({
        estimate,
        startDate,
      })
    }
  }

  getStyle() {
    const { estimateData } = this.props

    const { dayWidth, timespan, filter, PVersion } = this.props

    const style = {}

    let postX = 0

    style.width = estimateData[PVersion].estimate * dayWidth

    postX = FreeTime.getDays(estimateData[PVersion].start_date, timespan.from, estimateData[PVersion].estimate, dayWidth)

    style.transform = `translate3d(${postX}px, 0, 0)`

    if (CU.shouldWellBeFilteredOut(filter, types.FREE_TIME)) {
      style.display = "none"
    }

    return style
  }

  render() {
    const { estimateData, PVersion } = this.props

    if (parseInt(estimateData[PVersion].estimate, 0) === 0) {
      return null
    }

    const style = this.getStyle()

    return (
      <div style={style} className="Calendar--Entry FreeTime">
        {estimateData[PVersion].estimate}
      </div>
    )
  }
}

FreeTime.propTypes = {
  data: PropTypes.object,
  dayWidth: PropTypes.number,
  estimate: PropTypes.number,
  start_date: PropTypes.string,
  PVersion: PropTypes.string,
  timespan: PropTypes.object,
  filter: PropTypes.object,
  estimateData: PropTypes.object,
}

export default FreeTime
