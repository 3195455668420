import React, { Component } from "react"
import PropTypes from "prop-types"
import { cloneDeep } from "lodash"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class EditAsset extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editUserListItem: null,
    }
  }

  componentDidMount() {
    this.setState({
      editUserListItem: this.props.editUserListItem,
    })
  }

  handleInputChange = (event) => {
    const editUserListItem = cloneDeep(this.state.editUserListItem)
    editUserListItem[event.target.name] = event.target.value
    this.setState({ editUserListItem })
  }

  handleUserInputChange = (event) => {
    const editUserListItemUsers = this.state.editUserListItem.users.slice()
    editUserListItemUsers[event.target.name] = {
      user_id: event.target.value,
    }

    this.setState({
      editUserListItem: {
        ...this.state.editUserListItem,
        users: editUserListItemUsers,
      },
    })
  }

  removeUser = (key) => {
    const editUserListItemUsers = this.state.editUserListItem.users.slice()
    editUserListItemUsers.splice(key, 1)

    this.setState({
      editUserListItem: {
        ...this.state.editUserListItem,
        users: editUserListItemUsers,
      },
    })
  }

  addUser = () => {
    const editUserListItemUsers = this.state.editUserListItem.users.slice()
    editUserListItemUsers.push({
      user_id: "",
    })

    this.setState({
      editUserListItem: {
        ...this.state.editUserListItem,
        users: editUserListItemUsers,
      },
    })
  }

  closeModal = () => {
    const { clearSingleItemEdit } = this.props
    clearSingleItemEdit()
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleAddEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleEditFormSubmit = (event) => {
    const { handleEditFormSubmit } = this.props
    const { editUserListItem } = this.state

    event.preventDefault()
    event.stopPropagation()

    editUserListItem.users = editUserListItem.users.filter((u) => u.user_id !== "" && this.isEmail(u.user_id))
    handleEditFormSubmit(editUserListItem)
  }

  isEmail = (email = null) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
  }

  render() {
    const {
      editUserListItem: { name },
    } = this.props
    const { editUserListItem } = this.state

    if (!editUserListItem) {
      return null
    }

    return (
      <form className="ReactModal__Wrapper" onSubmit={(event) => this.handleEditFormSubmit(event)}>
        <div className="ReactModal__Form ModalForm">
          <h2 className="ReactModal__FormHeader">
            Edit <strong>{name}</strong>
          </h2>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Name</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editUserListItem.name || ""}
              name={"name"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Name"
            />
          </div>
          <div className="ModalForm__Part ModalForm__Part--userlist">
            <label className="ModalForm__Label">Users | must be valid emails</label>
            {editUserListItem.users.map((e, k) => (
              <div key={`users-${k}`} className="ReactModal__User">
                <a onClick={() => this.removeUser(k)} className="ReactModal__RemoveUser">
                  Remove
                </a>
                <input
                  onKeyDown={this.handleAddEnterPress}
                  value={e.user_id || ""}
                  name={k}
                  onChange={this.handleUserInputChange}
                  className={`ModalForm__Input${this.isEmail(e.user_id) ? "" : " invalid"}`}
                  placeholder="Users email"
                />
              </div>
            ))}
            <a className="ModalForm__AddBtn" onClick={this.addUser}>
              + Add
            </a>
          </div>
        </div>
        <div className="ReactModal__Buttons">
          <button type="button" onClick={this.closeModal} className="ReactModal__Button">
            Cancel
          </button>
          <button type="submit" className={"ReactModal__Button ReactModal__Button--primary"}>
            {"Save"}
          </button>
        </div>
      </form>
    )
  }
}

EditAsset.propTypes = {
  editUserListItem: PropTypes.object,
  clearSingleItemEdit: PropTypes.func,
  handleEditFormSubmit: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editUserListItem: state.managecontent.editUserListItem,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAsset)
