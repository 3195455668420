import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { truncate } from "lodash"
import { ActionCreators } from "../../redux/actions"
import IconContainer from "../icons/IconContainer"
import HPHT from "../../../assets/images/svg/icons/HPHT.svg"
import JackUpOnly from "../../../assets/images/svg/icons/Jack-up-only.svg"
import Semisub from "../../../assets/images/svg/icons/Semisub.svg"
import CU from "../../lib/CalendarUtils"
import ContextMenu from "../ContextMenu"
import { EDIT_WELL_CAMPAIGN } from "../../panelConsts"
import { P10, P50, P90 } from "../../redux/types"

class Request extends Component {
  constructor(props) {
    super(props)

    this.obj = React.createRef()

    this.contextOptions = []

    this.state = {
      menuActive: false,
      yPos: 0,
      arrowYPos: 0,
    }
  }

  componentDidMount = () => {
    this.setOptions(this.props)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { request, readonly, accessLevel } = nextProps
    const { menuActive } = nextState

    if (request.id !== this.props.request.id) {
      return true
    }

    if (request !== this.props.request) {
      return true
    }

    if (readonly !== this.props.readonly) {
      return true
    }

    if (menuActive !== this.state.menuActive) {
      return true
    }
    if (accessLevel !== this.props.accessLevel) {
      return true
    }

    return false
  }

  componentDidUpdate = () => {
    this.setOptions(this.props)
  }

  setOptions = (props) => {
    this.contextOptions = [
      { name: "Preview in schedule", action: "preview" },
      { name: "Open Request", action: "open" },
      { name: "Remove", action: "remove" },
    ]

    if (props.accessLevel < 5 || props.readonly) {
      this.contextOptions = [{ name: "Open Request", action: "open" }]
      if (props.request.request_type === "no_changes") {
        this.contextOptions = []
      }
    }

    this.setState({
      request: props.request,
    })
  }

  lookAtRequest = () => {
    const { request } = this.state
    const { onClick } = this.props

    if (onClick) {
      onClick(request)
    } else {
      this.toggleMenuActive()
    }
  }

  openPanelWithType = (type) => {
    const { openPanelWithType } = this.props

    openPanelWithType(type)
  }

  getEstimate = () => {
    const { request, PVersion } = this.props

    switch (PVersion) {
      case P10:
        return request.wells.reduce((prev, next) => prev + next.estimateData.p10.estimate, 0)
      case P50:
        return request.wells.reduce((prev, next) => prev + next.estimateData.p50.estimate, 0)
      case P90:
        return request.wells.reduce((prev, next) => prev + next.estimateData.p90.estimate, 0)
      default:
        return request.wells.reduce((prev, next) => prev + next.estimateData.p50.estimate, 0)
    }
  }

  getYPosForContextMenu = (elm) => {
    const elmRect = elm.getBoundingClientRect()
    const posWidthContextHeight = elmRect.y + 160 + 60
    const maxYpos = window.innerHeight - 10
    const arrowYPos = Math.max(50, posWidthContextHeight - maxYpos)
    const yPos = elmRect.y - arrowYPos + 10

    return { yPos, arrowYPos }
  }

  toggleMenuActive = () => {
    if (this.contextOptions.length > 0) {
      const positions = this.getYPosForContextMenu(this.obj.current)
      this.setState({
        menuActive: !this.state.menuActive,
        ...positions,
      })
    }
  }

  deselect = () => {
    this.setState({
      menuActive: false,
    })
  }

  handleContextSelect = (event, option) => {
    event.preventDefault()
    event.stopPropagation()

    const { previewRequest, request, confirmRemoveRequest, setRequestStatus, openPanelWithType } = this.props

    switch (option.action) {
      case "preview":
        previewRequest(request)
        this.deselect()
        break
      case "open":
        openPanelWithType(EDIT_WELL_CAMPAIGN, request)
        this.deselect()
        break
      case "setProcessed":
        setRequestStatus(request, "processed")
        this.deselect()
        break
      case "setUnProcessed":
        setRequestStatus(request, "unprocessed")
        this.deselect()
        break
      case "setResolved":
        break
      case "setBlocked":
        break
      case "remove":
        confirmRemoveRequest(request)
        this.deselect()
        break
      default:
    }
  }

  getStyle = (request) => ({
    backgroundColor: CU.getWellBookingStatusColor(request.wells.length > 0 ? request.wells[0].status : "conceptional").color,
  })

  handleClick = () => {
    const { onClick } = this.props

    if (onClick === true) {
      this.onClick()
    } else {
      this.toggleMenuActive()
    }
  }

  render() {
    const {
      request,
      request: { wells },
      id,
      status,
      previewmode,
    } = this.props
    const { yPos, arrowYPos } = this.state

    const style = this.getStyle(request)
    const key = 1

    const name = truncate(
      wells.map((w) => `${w.well.short_name || w.well.name || w.well.plno}`),
      20
    )

    return (
      <div
        ref={this.obj}
        key={`sidebar-requst-list-${id}`}
        className={`Request${status === "processed" ? " Processed" : ""}${previewmode ? " Preview" : ""}`}
        style={style}
        onClick={previewmode ? null : () => this.lookAtRequest()}
      >
        {this.state.menuActive && !previewmode ? (
          <ContextMenu
            id={`context-menu-requst-list-${key}`}
            options={this.contextOptions}
            active={this.state.menuActive}
            position={this.state.activeContextPos}
            deselect={this.deselect}
            yPos={arrowYPos}
            styles={{ left: "93%", top: yPos }}
            onSelect={(e, selected) => this.handleContextSelect(e, selected)}
          />
        ) : null}
        {/* {showStatus ? <div className='Request--Status'>New well</div> : null} */}
        <div className="Request--Container">
          <div className="Request--Name--Container">
            <div className="Request--Name FontStyle--Bold">{name}</div>
            <div className="Request--op FontStyle--Regular">{wells.length && wells[0].well ? wells[0].well.plno : ""}</div>
          </div>
          <div className="Request--Time-and-Icons--Container">
            <div className="Request--Icons--Container">
              {request.pressure === "hpht" ? IconContainer(HPHT, 11, 11) : null}
              {request.rig_type && request.rig_type.includes("jack-up") ? IconContainer(JackUpOnly, 11, 11) : null}
              {request.rig_type && request.rig_type.includes("semi-sub") ? IconContainer(Semisub, 11, 11) : null}
            </div>
            <div className="Request--Time--Container">
              <div className="Request--Time">{request.request_type !== "no_changes" ? this.getEstimate() : "\xa0"}</div>
              <div className="Request--Days">{request.request_type !== "no_changes" ? "DAYS" : "\xa0"}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Request.propTypes = {
  request: PropTypes.object,
  PVersion: PropTypes.object,
  id: PropTypes.number,
  onClick: PropTypes.func,
  openPanelWithType: PropTypes.func,
  editRequest: PropTypes.func,
  previewRequest: PropTypes.func,
  confirmRemoveRequest: PropTypes.func,
  setRequestStatus: PropTypes.func,
  status: PropTypes.string,
  readonly: PropTypes.bool,
  showStatus: PropTypes.bool,
  previewmode: PropTypes.bool,
  accessLevel: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    PVersion: state.calendar.PVersion,
    accessLevel: state.user.accessLevel,
    readonly: state.schedulerData.schedulerData.data.readonly,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Request)
