import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"
import { getReadableRigType } from "../../../lib/RigUtils"
import ConditionalField, { ConditionalFieldBlock } from "../../../components/common/ConditionalField"

const requiredFields = ["type", "pressure", "move_speed"]

class InputRigSpecs extends Component {
  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}
    obj[elem.target.name] = elem.target.value

    onUpdate(obj)
  }

  validateForm() {
    const { data } = this.props

    let validated = true
    const keys = Object.keys(data)
    validated = requiredFields.every((key) => keys.includes(key))

    return validated
  }

  componentDidMount() {
    const { index, onValidated, data } = this.props
    if (data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props
    if (data !== prevProps.data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }
  }

  render() {
    const {
      data,
      domain: {
        data: { activeRigTypes, units },
      },
    } = this.props

    return (
      <div className="PanelContent__Container PanelForm">
        <ConditionalField scope="rig" field="generation">
          <div className="PanelForm__InputPart PanelForm__InputPart--radio">
            <label className="PanelForm__inputheading">Generation</label>
            <label className="PanelForm__inputlabel">
              <input
                onChange={this.onFieldUpdate}
                value="single"
                name="generation"
                defaultChecked={data.generation && data.generation === "single"}
                type="radio"
              />
              <span className="check-indicator"></span>
              <span className="PanelForm__inputlabel-text">Single</span>
            </label>
            <label className="PanelForm__inputlabel">
              <input
                onChange={this.onFieldUpdate}
                value="dual"
                defaultChecked={data.generation && data.generation === "dual"}
                name="generation"
                type="radio"
              />
              <span className="check-indicator"></span>
              <span className="PanelForm__inputlabel-text">Dual</span>
            </label>
          </div>
        </ConditionalField>
        <div className="PanelForm__InputPart PanelForm__InputPart--radio">
          <label className="PanelForm__inputheading">Type</label>
          {activeRigTypes.map((e, k) => (
            <EditRigType key={e} onFieldUpdate={this.onFieldUpdate} type={e} selectedType={data.type} />
          ))}
          {/* <label className="PanelForm__inputheading">Rig type *</label>
          <label className="PanelForm__inputlabel">
            <input
              onChange={this.onFieldUpdate}
              value="jack-up"
              defaultChecked={data.type && data.type === "jack-up"}
              name="type"
              type="radio"
            />
            <span className="check-indicator"></span>
            <span className="PanelForm__inputlabel-text">Jack-up</span>
          </label>
          <label className="PanelForm__inputlabel">
            <input
              onChange={this.onFieldUpdate}
              value="semi-sub"
              defaultChecked={data.type && data.type === "semi-sub"}
              name="type"
              type="radio"
            />
            <span className="check-indicator"></span>
            <span className="PanelForm__inputlabel-text">Semi-sub</span>
          </label>
          <label className="PanelForm__inputlabel">
            <input
              onChange={this.onFieldUpdate}
              value="platform"
              defaultChecked={data.type && data.type === "platform"}
              name="type"
              type="radio"
            />
            <span className="check-indicator"></span>
            <span className="PanelForm__inputlabel-text">Platform</span>
          </label>
          <label className="PanelForm__inputlabel">
            <input
              onChange={this.onFieldUpdate}
              value="land_rig"
              defaultChecked={data.type && data.type === "land_rig"}
              name="type"
              type="radio"
            />
            <span className="check-indicator"></span>
            <span className="PanelForm__inputlabel-text">Land-rig</span>
          </label>
          <label className="PanelForm__inputlabel">
            <input
              onChange={this.onFieldUpdate}
              value="drillship"
              defaultChecked={data.type && data.type === "drillship"}
              name="type"
              type="radio"
            />
            <span className="check-indicator"></span>
            <span className="PanelForm__inputlabel-text">Drillship</span>
          </label> */}
        </div>
        <div className="PanelForm__InputPart PanelForm__InputPart--radio">
          <label className="PanelForm__inputheading">Pressure *</label>
          <label className="PanelForm__inputlabel">
            <input
              onChange={this.onFieldUpdate}
              value="5000"
              defaultChecked={data.pressure && data.pressure === "5000"}
              name="pressure"
              type="radio"
            />
            <span className="check-indicator"></span>
            <span className="PanelForm__inputlabel-text">5K</span>
          </label>
          <label className="PanelForm__inputlabel">
            <input
              onChange={this.onFieldUpdate}
              value="10000"
              defaultChecked={data.pressure && data.pressure === "10000"}
              name="pressure"
              type="radio"
            />
            <span className="check-indicator"></span>
            <span className="PanelForm__inputlabel-text">10K</span>
          </label>
          <label className="PanelForm__inputlabel">
            <input
              onChange={this.onFieldUpdate}
              value="15000"
              defaultChecked={data.pressure && data.pressure === "15000"}
              name="pressure"
              type="radio"
            />
            <span className="check-indicator"></span>
            <span className="PanelForm__inputlabel-text">15K</span>
          </label>
        </div>
        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputlabel">Rig move speed *</label>
          <input
            onChange={this.onFieldUpdate}
            className={`PanelForm__textinput ${data.move_speed ? "active" : ""}`}
            value={data.move_speed ? data.move_speed : ""}
            type="number"
            name="move_speed"
            placeholder=""
          />
          <span className="PanelForm__inputinfo">Enter in {units === "imperial" ? "mph" : "km/h"} and use . as comma</span>
        </div>
        <ConditionalFieldBlock scope="rig" fields={["ocean_depth_from", "ocean_depth_to"]}>
          <div className="PanelForm__InputPart PanelForm__InputPart--twofold">
            <label className="PanelForm__inputlabel">Water depth from-to in {units === "imperial" ? "feet" : "meters"}</label>
            <div className="PanelForm__flex">
              <input
                onChange={this.onFieldUpdate}
                className={`PanelForm__textinput ${data.ocean_depth_from ? "active" : ""}`}
                value={data.ocean_depth_from ? data.ocean_depth_from : ""}
                name="ocean_depth_from"
                type="number"
                placeholder="From"
              />
              <input
                onChange={this.onFieldUpdate}
                className={`PanelForm__textinput ${data.ocean_depth_to ? "active" : ""}`}
                value={data.ocean_depth_to ? data.ocean_depth_to : ""}
                name="ocean_depth_to"
                type="number"
                placeholder="To"
              />
            </div>
          </div>
        </ConditionalFieldBlock>
      </div>
    )
  }
}

const EditRigType = ({ type, selectedType, onFieldUpdate }) => {
  return (
    <label className="PanelForm__inputlabel">
      <input onChange={onFieldUpdate} value={type} defaultChecked={selectedType && selectedType === type} name="type" type="radio" />
      <span className="check-indicator"></span>
      <span className="PanelForm__inputlabel-text rig">{getReadableRigType(type)}</span>
    </label>
  )
}

InputRigSpecs.propTypes = {
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
  onValidated: PropTypes.func,
  domain: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    domain: state.application.domain,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputRigSpecs)
