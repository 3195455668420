import React, { Component } from "react"
import PropTypes from "prop-types"
import DayPickerInput from "react-day-picker/DayPickerInput"
import setYear from "date-fns/setYear"
import getYear from "date-fns/getYear"
import getMonth from "date-fns/getMonth"
import RoundedDropList from "../dropdown/RoundedDropList"
import VU from "../../lib/ValidateUtils"
import CU from "../../lib/CalendarUtils"

class DaypickerElement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  formatDate = (d) => `${d.getDate()} - ${d.getMonth() + 1} - ${d.getFullYear()}`

  parseDate = (str) => {
    const check = str.match(/\d+/g)
    const numbers = check ? check.map(Number) : check

    if (!numbers) return null

    const [day, month, year] = numbers

    let newDate = null

    if (year && year.toString().length === 4) {
      newDate = new Date(Date.UTC(year, month - 1, day, 3, 0, 0))
    }

    return VU.isDateObject(newDate) ? newDate : null
  }

  toggleOpen = () => {
    const { open } = this.state

    this.setState({
      open: !open,
    })
  }

  YearMonthForm = ({ date }) => {
    const { value } = this.props

    const fv = value || new Date()

    const startYear = 2018
    const thisYear = getYear(new Date())
    const selectedYear = getYear(fv)
    const thisMonth = CU.getMonthsInYear()[getMonth(date)].longer
    const numbeOfYears = thisYear - startYear + 10
    const years = [...Array(numbeOfYears).keys()]
    return (
      <div className="DayPicker-Caption">
        <span className={"ROW"}>
          <RoundedDropList
            defaultOpen={false}
            data={years}
            startYear={startYear}
            thisYear={thisYear}
            selectedYear={selectedYear}
            onChange={this.onYearChange}
          />
          {thisMonth}
        </span>
      </div>
    )
  }

  onChange = (e) => {
    const { onChange } = this.props
    onChange(e)
  }

  onYearChange = (e) => {
    const { year } = e.target.form
    const { onChange, value } = this.props

    const fv = value || new Date()
    const newDate = setYear(fv, year.value)

    onChange(newDate)
  }

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.value !== this.props.value) {
      return true
    }

    return false
  }

  render() {
    const { label, styles, value, format, startDate, align, hidePlaceholder } = this.props

    const classNames = {
      container: `DayPickerInput ${value ? "active" : ""} ${align}`,
      overlayWrapper: "DayPickerInput-OverlayWrapper",
      overlay: "DayPickerInput-Overlay",
    }

    const fv = value || ""

    return (
      <div className="PanelForm__InputPart" style={styles}>
        <label className={`PanelForm__inputlabel ${align}`}>{label}</label>
        <DayPickerInput
          onDayChange={this.onChange}
          value={fv}
          formatDate={this.formatDate}
          parseDate={this.parseDate}
          locale={"nb"}
          keepFocus={false}
          format={format}
          placeholder={hidePlaceholder ? "" : format}
          classNames={classNames}
          dayPickerProps={{
            format,
            showWeekNumbers: true,
            showNextYear: true,
            firstDayOfWeek: 1,
            month: fv,
            fixedWeeks: true,
            year: fv,
            initialMonth: startDate ? new Date(startDate) : new Date(),
            selectedDays: startDate ? [new Date(startDate)] : null,
            captionElement: (date) => this.YearMonthForm(date),
          }}
        />
      </div>
    )
  }
}

DaypickerElement.propTypes = {
  label: PropTypes.string,
  startDate: PropTypes.any,
  format: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  styles: PropTypes.object,
  value: PropTypes.any,
  hidePlaceholder: PropTypes.bool,
  align: PropTypes.string,
}

export default DaypickerElement
