import {
  FETCH_DRAFTS,
  FETCH_PREVIOUS_DRAFTS,
  UPDATE_DRAFTS_LIST,
  UPDATE_PREVIOUS_DRAFTS_LIST,
  SAVE_MISSING_INPUTTERS,
  FETCH_MASTERS,
  UPDATE_MASTERS_LIST,
  SAVE_DRAFT_META,
  ARCHIVE_DRAFT,
  CREATE_NEW_DRAFT,
  FETCH_MISSING_INPUTTERS,
} from "../types"

export function fetchDrafts() {
  return { type: FETCH_DRAFTS }
}
export function fetchPreviousDrafts() {
  return { type: FETCH_PREVIOUS_DRAFTS }
}
export function fetchMissingInputters() {
  return { type: FETCH_MISSING_INPUTTERS }
}

export function updateDraftsList(data) {
  return { type: UPDATE_DRAFTS_LIST, data }
}

export function updatePreviousDraftsList(data) {
  return { type: UPDATE_PREVIOUS_DRAFTS_LIST, data }
}

export function saveMissingInputters(data) {
  return { type: SAVE_MISSING_INPUTTERS, data }
}

export function saveSingleDraftMeta(data) {
  return { type: SAVE_DRAFT_META, data }
}

export function archiveDraft(data) {
  return { type: ARCHIVE_DRAFT, data }
}

export function createNewDraft(data) {
  return { type: CREATE_NEW_DRAFT, data }
}

export function fetchMasters(data) {
  return { type: FETCH_MASTERS, data }
}

export function updateMastersList(data) {
  return { type: UPDATE_MASTERS_LIST, data }
}
