import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import ApiServices, { getConfig } from "../../services/ApiService"

import RoundedDropDown from "../../components/dropdown/RoundedDropDown"

const rolesArr = [
  { name: "Creator", value: "Creator" },
  { name: "Inputter", value: "Inputter" },
  { name: "Full read", value: "FullRead" },
  { name: "Viewer", value: "Viewer" },
]

class PageHeader extends Component {
  constructor() {
    super()

    this.state = {
      roleListActive: false,
      isUserImitated: false,
    }
  }

  componentDidMount() {
    this.checkForImitadedUser()
  }

  async checkForImitadedUser() {
    const token = ApiServices.getCookie("orig_jwt")
    if (token) {
      this.setState({
        isUserImitated: true,
      })
    }
  }

  shouldComponentUpdate = (prevProps) => {
    const { user, location, routes, accessLevel, domain } = this.props

    if (user !== prevProps.user) {
      return true
    }

    if (location !== prevProps.location) {
      return true
    }

    if (routes !== prevProps.routes) {
      return true
    }

    if (accessLevel !== prevProps.accessLevel) {
      return true
    }

    if (domain !== prevProps.domain) {
      return true
    }

    return false
  }

  switchRole = async (role) => {
    const config = await getConfig()
    const token = ApiServices.getCookie("orig_jwt") || ApiServices.getCookie("jwt")
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    localStorage.setItem("user", JSON.stringify({ ...user, role }))
    window.location.href = `//${config.apiHost}/v1/auth/switchrole?role=${role}&token=${token}&redirectTo=${window.location.href}`
  }

  gotoLogout = async () => {
    const config = await getConfig()
    const loggedOutUrl = `${window.location.origin}/logout`
    window.location.href = `//${config.apiHost}/v1/auth/logout?redirectTo=${loggedOutUrl}`
    return
  }

  render() {
    const { user, location, routes, accessLevel, domain } = this.props

    if (!user) return null

    const schedulerLink = routes.previousPathname.indexOf("schedule") !== -1 ? routes.previousPathname : "/schedule"

    const timelineLink = routes.previousPathname.indexOf("timeline") !== -1 ? routes.previousPathname : "/timeline"

    const canImitateRole = accessLevel > 5 || user.original_role === "Creator" || this.state.isUserImitated

    return (
      <>
        <div className={"PageHeader__Wrapper"}>
          <div className="PageHeader__LogoWrapper">
            {domain && domain.data && domain.data.logo ? <img className="PageHeader__LogoImage" src={domain.data.logo} /> : null}
          </div>
          <div className="PageHeader__Navigation TopNavigation">
            <nav className="TopNavigation__Wrapper">
              <ul className="TopNavigation__List">
                {accessLevel >= 2 && (
                  <li className={`TopNavigation__ListItem ${location.pathname.includes("schedule") ? "active" : ""}`}>
                    <Link className="TopNavigation__Link" to={schedulerLink}>
                      Schedule
                    </Link>
                  </li>
                )}
                {accessLevel >= 2 && (
                  <li className={`TopNavigation__ListItem ${location.pathname.includes("timeline") ? "active" : ""}`}>
                    <Link className="TopNavigation__Link" to={timelineLink}>
                      Timeline
                    </Link>
                  </li>
                )}
                {accessLevel > 5 && (
                  <li className={`TopNavigation__ListItem ${location.pathname.includes("managecontent") ? "active" : ""}`}>
                    <Link className="TopNavigation__Link" to="/managecontent">
                      Manage content
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div className="PageHeader__UserProfile UserProfile">
            {canImitateRole && (
              <RoundedDropDown
                clearFunction={() => this.clearFilter("license")}
                label={user.role}
                renderoption={(key, val) => (
                  <div
                    onClick={() => this.switchRole(val.value)}
                    className="RoundedDropDown__Option RoundedDropDown__Option--asset"
                    key={key}
                  >
                    <span className="RoundedDropDown__Option-Value">{val.name}</span>
                  </div>
                )}
                data={rolesArr}
              />
            )}
            <div className="UserProfile__NameWrapper">
              <span className="UserProfile__Name">
                Hi{user.firstName ? ` ${user.firstName}` : ""}!
                <a className="UserProfile__logout" onClick={this.gotoLogout}>
                  Logout
                </a>
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
}

PageHeader.propTypes = {
  text: PropTypes.string,
  company: PropTypes.string,
  user: PropTypes.object,
  accessLevel: PropTypes.number,
  routes: PropTypes.object,
  domain: PropTypes.object,
  location: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    accessLevel: state.user.accessLevel,
    user: state.application.user,
    routes: state.routes,
    company: state.application.company,
    darkMode: state.calendar.darkMode,
    domain: state.application.domain,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageHeader))
