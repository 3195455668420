import { xor } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import Modal from "react-modal"
import ListItem from "./ListItem"

class ContentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItems: [],
      filter: "",
      showDeleteConfirmation: false,
      showFilter: typeof props.showFilter !== "undefined" ? props.showFilter : true,
    }
  }

  onSelect = (itemId) => {
    this.setState({
      selectedItems: xor(this.state.selectedItems, [itemId]),
    })
  }

  onDeleteClick = () => {
    this.toggleModal()
  }

  toggleModal = () => {
    this.setState({
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
    })
  }

  filter = (event) => {
    this.setState({ filter: event.target.value })
  }

  handleDeleteFormSubmit = (event) => {
    const { deleteItemsFunc } = this.props
    const { selectedItems } = this.state

    event.preventDefault()
    event.stopPropagation()

    deleteItemsFunc(selectedItems)
  }

  componentDidUpdate(prevProps) {
    if (this.state.selectedItems.length > 0 && prevProps.items !== this.props.items) {
      this.toggleModal()
      this.setState({
        filter: "",
        selectedItems: [],
      })
    }
  }

  render() {
    const {
      items,
      header,
      renderItemName,
      editItem,
      addItem,
      classNames,
      hideAddButton,
      customButtonTitle,
      customButtonAction,
      customButton2Title,
      customButton2Action,
      hideArchiveButton,
    } = this.props
    const { selectedItems, filter, showDeleteConfirmation, showFilter } = this.state

    if (!items) {
      return null
    }

    const itemList = filter
      ? items.filter((v) => {
          const n = v.name || ""
          const exist = n.replace(/\//g, "===").toLowerCase().indexOf(filter.replace(/\//g, "===").toLowerCase()) > -1
          return exist
        })
      : items

    return (
      <div className={`ContentList ${classNames}`}>
        <div className="ContentList__HeaderWrapper">
          <h1 className="ContentList__Header">{header}</h1>
          {hideArchiveButton !== true && (
            <button onClick={this.onDeleteClick} className={`ContentList__RemoveBtn${selectedItems.length > 0 ? " show" : ""}`}>
              Archive {selectedItems.length}
            </button>
          )}
          {customButtonTitle && customButtonAction && (
            <button
              onClick={() => customButtonAction(selectedItems)}
              className={`ContentList__RemoveBtn${selectedItems.length > 0 ? " show" : ""}`}
            >
              {customButtonTitle} {selectedItems.length}
            </button>
          )}
          {customButton2Title && customButton2Action && (
            <button
              onClick={() => customButton2Action(selectedItems)}
              className={`ContentList__RemoveBtn${selectedItems.length > 0 ? " show" : ""}`}
            >
              {customButton2Title} {selectedItems.length}
            </button>
          )}
          {hideAddButton ? null : (
            <button onClick={addItem} className={`ContentList__RemoveBtn${selectedItems.length === 0 ? " show" : ""}`}>
              Add+
            </button>
          )}
          {showFilter && <input onChange={this.filter} className={"ContentList__Search"} placeholder="Filter" />}
        </div>
        <div className="ContentList__Content">
          {itemList.map((b, k) => (
            <ListItem
              selected={selectedItems.includes(b.id)}
              onSelect={this.onSelect}
              renderItemName={renderItemName}
              key={`list-${k}`}
              editItem={editItem}
              content={b}
            />
          ))}
        </div>

        <Modal
          key={"delete-modal"}
          isOpen={showDeleteConfirmation}
          onRequestClose={this.toggleModal}
          className={"ReactModal__Content"}
          overlayClassName={"ReactModal__Overlay"}
          contentLabel="Rename"
        >
          <form className="ReactModal__Wrapper" onSubmit={this.handleDeleteFormSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                margin: "40px 0",
              }}
            >
              <svg
                width="96px"
                height="96px"
                viewBox="0 0 76 76"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs></defs>
                <g id="Modules-+-Flows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Overview-draft-and-master-schedule-module" transform="translate(-1885.000000, -2885.000000)">
                    <g id="fail" transform="translate(1885.000000, 2885.000000)">
                      <circle id="Oval-5" stroke="#1C2226" cx="38" cy="38" r="37.5"></circle>
                      <path
                        d="M36.6142322,42.3361642 L36.1947566,27 L39.8052434,27 L39.3857678,42.3361642 L36.6142322,42.3361642 Z M36,49 L36,44.8895966 L40,44.8895966 L40,49 L36,49 Z"
                        id="!"
                        fill="#1C2226"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div style={{ paddingTop: 0 }} className="ReactModal__Form">
              <h2 className="ReactModal__FormHeader">
                <strong>Archive {selectedItems.length} selected items?</strong>
              </h2>
            </div>
            <div className="ReactModal__Buttons">
              <button type="button" onClick={this.toggleModal} className="ReactModal__Button">
                Cancel
              </button>
              <button type="submit" className={"ReactModal__Button ReactModal__Button--primary"}>
                {"Archive"}
              </button>
            </div>
          </form>
        </Modal>
      </div>
    )
  }
}

ContentList.propTypes = {
  items: PropTypes.array,
  itemType: PropTypes.string,
  header: PropTypes.string,
  classNames: PropTypes.string,
  renderItemName: PropTypes.func,
  deleteItemsFunc: PropTypes.func,
  editItem: PropTypes.func,
  addItem: PropTypes.func,
  hideAddButton: PropTypes.bool,
  showFilter: PropTypes.bool,
  hideArchiveButton: PropTypes.bool,
  customButtonTitle: PropTypes.string,
  customButtonAction: PropTypes.func,
  customButton2Title: PropTypes.string,
  customButton2Action: PropTypes.func,
}

export default ContentList
