/* eslint-disable import/prefer-default-export */
import { combineEpics } from "redux-observable"

import * as schedulerData from "./schedulerData"
import * as calendar from "./calendar"
import * as application from "./application"
import * as managecontent from "./managecontent"
import * as requests from "./requests"
import * as observer from "./observer"
import * as filter from "./filter"
import * as user from "./user"
import * as overviewData from "./overviewData"
import * as timeline from "./timeline"

export const rootEpic = combineEpics(
  ...Object.values(schedulerData),
  ...Object.values(calendar),
  ...Object.values(application),
  ...Object.values(managecontent),
  ...Object.values(requests),
  ...Object.values(observer),
  ...Object.values(overviewData),
  ...Object.values(filter),
  ...Object.values(user),
  ...Object.values(timeline)
)
