import React from "react"

const Chevron = (additionalClass, ignore) => (
  <span className={`arrow-icon ${additionalClass} ${ignore ? "ignore-events" : ""}`}>
    <span className="left-bar"></span>
    <span className="right-bar"></span>
  </span>
)

export default Chevron
