import store from "store"
import { of } from "rxjs"

const LS = {
  saveProp: async (name, object) => {
    try {
      const serializeState = await JSON.stringify(object)
      store.set(name, serializeState)
    } catch (err) {
      console.log(err)
    }

    return of({})
  },
  loadProp: async (name) => {
    try {
      const ls = store.get(name)
      const serializedState = ls ? await JSON.parse(ls) : {}

      delete serializedState.suggestions
      return serializedState
    } catch (err) {
      console.log(err)
      return {}
    }
  },
}

export default LS
