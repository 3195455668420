import format from "date-fns/format"
import { cloneDeep } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import parseISO from "date-fns/parseISO"
import Modal from "react-modal"
import { Link, withRouter } from "react-router-dom"
import { DATE_FORMATE_NB } from "../../../dateConsts"
import { ActionCreators } from "../../../redux/actions"
import { MODAL_TYPE_DRAFT_ARCHIVE, MODAL_TYPE_DRAFT_DUPLICATE, MODAL_TYPE_DRAFT_RENAME } from "../../../redux/types"
import ContextMenu from "../../ContextMenu"
import Master from "../../icons/Master"

const contextOptions = [
  { name: "Rename", action: "rename" },
  { name: "Duplicate", action: "duplicate" },
  { name: "Archive", action: "archive" },
]

const mainDraftContextOptions = [{ name: "Duplicate", action: "duplicate" }]

Modal.setAppElement("#root")

class NextScheduleModule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeContextMenu: "",
      openModal: "",
      activeDraftNameChange: {},
      activeDraftArchive: {},
      activeDuplicateDraft: {},
      newList: [],
      draftArchived: false,
      draftNameSaved: false,
      draftDuplicated: false,
      isSavingRename: false,
      isDuplicating: false,
      isSavingArchive: false,
      showPreviousDrafts: false,
    }
  }

  componentDidMount() {
    const { fetchDrafts } = this.props
    fetchDrafts()
  }

  triggerContextMenu = (elem, id) => {
    elem.preventDefault()
    elem.stopPropagation()

    const newId = this.state.activeContextMenu === id ? "" : id
    this.setState({
      activeContextMenu: newId,
    })
  }

  deselect = () => {
    this.setState({
      activeContextMenu: "",
    })
  }

  handleContextSelect = (event, option, draft) => {
    event.preventDefault()
    event.stopPropagation()

    const { showModal } = this.props

    const draftCopy = cloneDeep(draft)

    let modalData = {}

    switch (option.action) {
      case "rename":
        modalData = {
          id: draft.id,
          name: draft.name,
          openModal: `rename-${draft.id}`,
          activeDraftNameChange: draftCopy,
          activeContextMenu: "",
        }
        showModal(MODAL_TYPE_DRAFT_RENAME, modalData)
        break
      case "duplicate":
        modalData = {
          id: draft.id,
          name: draft.name,
          openModal: `duplicate-${draft.id}`,
          activeDuplicateDraft: draftCopy,
          activeContextMenu: "",
        }
        showModal(MODAL_TYPE_DRAFT_DUPLICATE, modalData)
        break
      case "archive":
        modalData = {
          id: draft.id,
          name: draft.name,
          openModal: `rename-${draft.id}`,
          activeDraftArchive: draftCopy,
          activeContextMenu: "",
        }

        showModal(MODAL_TYPE_DRAFT_ARCHIVE, modalData)
        break
      default:
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchDrafts, fetchPreviousDrafts } = this.props
    const { showPreviousDrafts } = this.state
    let newList = []

    if (prevState.showPreviousDrafts !== showPreviousDrafts) {
      if (showPreviousDrafts) {
        fetchPreviousDrafts()
      } else {
        fetchDrafts()
      }
    }

    if (prevProps.draftsList !== this.props.draftsList) {
      if (prevProps.draftsList.length > 0) {
        newList = this.props.draftsList.filter((e) => !prevProps.draftsList.find((a) => e.id === a.id))
      }
    } else if (prevProps.previousDrafts !== this.props.previousDrafts) {
      if (prevProps.previousDrafts.length > 0) {
        newList = this.props.previousDrafts.filter((e) => !prevProps.previousDrafts.find((a) => e.id === a.id))
      }
    }

    if (newList.length) {
      this.setState({
        draftArchived: true,
        draftNameSaved: true,
        draftDuplicated: true,
        isSavingRename: false,
        isSavingArchive: false,
        isDuplicating: false,
        newList,
      })
    }
  }

  render() {
    const { draftsList, accessLevel, previousDrafts } = this.props
    const { showPreviousDrafts } = this.state

    if (!draftsList) return null

    const list = showPreviousDrafts ? previousDrafts : draftsList

    const drafts = draftsList.length ? draftsList.filter((d) => d.is_main_draft) : []
    const draftsShared = list.length ? list.filter((d) => !d.is_main_draft) : []

    return (
      <>
        <div className="Overview__Module NextScheduleModule">
          <h2 className="Overview__ModuleHeading">Upcoming schedule</h2>
          <div className="NextScheduleModule__list">
            {drafts.map((val, key) => (
              <Link
                key={key}
                className={`NextScheduleModule__listitem ${this.state.newList.includes(val) ? "NextScheduleModule__listitem--new" : ""}`}
                to={`/schedule/${val.id}`}
              >
                {val.is_main_draft ? (
                  <h4 className="NextScheduleModule__listitem-subheader">
                    {Master(
                      {
                        width: "20px",
                        margin: "0px 5px 0 0",
                        position: "relative",
                        top: "3px",
                      },
                      `master-icon-${val.id}`
                    )}{" "}
                    Next months draft
                  </h4>
                ) : null}
                {accessLevel > 3 && (
                  <span
                    onClick={(e) => this.triggerContextMenu(e, `context-menu-draftlist-${val.id}`)}
                    className="NextScheduleModule__listitem-contexttrigger"
                  >
                    &#8942;
                  </span>
                )}
                <span className="NextScheduleModule__listitem-header">{val.name}</span>
                <span className="NextScheduleModule__listitem-date">Created {format(parseISO(val.created_at), DATE_FORMATE_NB)}</span>
                {accessLevel > 3 && (
                  <ContextMenu
                    id={`context-menu-draftlist-${val.id}`}
                    options={mainDraftContextOptions}
                    active={this.state.activeContextMenu === `context-menu-draftlist-${val.id}`}
                    position={this.state.activeContextPos}
                    deselect={this.deselect}
                    onSelect={(e, selected) => this.handleContextSelect(e, selected, val)}
                  />
                )}
              </Link>
            ))}
          </div>

          <div>
            <h3 className="Overview__SubHeading">
              <span className={showPreviousDrafts ? "" : "bold"} onClick={() => this.setState({ showPreviousDrafts: false })}>
                Shared drafts
              </span>
              {accessLevel > 5 && (
                <span className={!showPreviousDrafts ? "" : "bold"} onClick={() => this.setState({ showPreviousDrafts: true })}>
                  {" "}
                  / Previous drafts
                </span>
              )}
            </h3>
          </div>
          <div className="NextScheduleModule__list">
            {draftsShared.map((val, key) => (
              <Link
                key={key}
                className={`NextScheduleModule__listitem ${this.state.newList.includes(val) ? "NextScheduleModule__listitem--new" : ""}`}
                to={`/schedule/${val.id}`}
              >
                {val.is_main_draft ? (
                  <h4 className="NextScheduleModule__listitem-subheader">
                    {Master(
                      {
                        width: "20px",
                        margin: "0px 5px 0 0",
                        position: "relative",
                        top: "3px",
                      },
                      `master-icon-${val.id}`
                    )}{" "}
                    Next months draft
                  </h4>
                ) : null}
                {accessLevel > 3 && (
                  <span
                    onClick={(e) => this.triggerContextMenu(e, `context-menu-draftlist-${val.id}`)}
                    className="NextScheduleModule__listitem-contexttrigger"
                  >
                    &#8942;
                  </span>
                )}
                <span className="NextScheduleModule__listitem-header">
                  {val.name}
                  {val.is_independent ? (
                    <span style={{ fontSize: 10, padding: 3, fontWeight: 100, border: "1px solid #dedede", borderRadius: 4 }}>
                      independent
                    </span>
                  ) : (
                    ""
                  )}
                </span>
                <span className="NextScheduleModule__listitem-date">Created {format(parseISO(val.created_at), DATE_FORMATE_NB)}</span>
                {accessLevel > 3 && (
                  <ContextMenu
                    id={`context-menu-draftlist-${val.id}`}
                    options={contextOptions}
                    active={this.state.activeContextMenu === `context-menu-draftlist-${val.id}`}
                    position={this.state.activeContextPos}
                    deselect={this.deselect}
                    onSelect={(e, selected) => this.handleContextSelect(e, selected, val)}
                  />
                )}
              </Link>
            ))}
            {draftsShared.length === 0 && (
              <p className="Overview__message">{`${
                showPreviousDrafts ? "That was the last draft" : "No other drafts shared with you."
              }`}</p>
            )}
          </div>
        </div>
      </>
    )
  }
}

NextScheduleModule.propTypes = {
  previousDrafts: PropTypes.array,
  draftsList: PropTypes.array,
  fetchDrafts: PropTypes.func,
  fetchPreviousDrafts: PropTypes.func,
  saveSingleDraftMeta: PropTypes.func,
  archiveDraft: PropTypes.func,
  duplicateDraft: PropTypes.func,
  createNewDraft: PropTypes.func,
  showModal: PropTypes.func,
  accessLevel: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    draftsList: state.overviewData.draftsList,
    previousDrafts: state.overviewData.previousDrafts,
    accessLevel: state.user.accessLevel,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NextScheduleModule))
