// import '@babel/polyfill';

const RequestUtils = {
  getEmptyWell: (priority) => ({ sequence_priority: priority, well: {} }),
  compare: (a, b) => {
    if (a.request.updated_at > b.request.updated_at) {
      return -1
    }
    if (a.request.updated_at < b.request.updated_at) {
      return 1
    }
    return 0
  },
  sortOnUpdate: (data) => {
    data.sort(RequestUtils.compare)
    return data
  },
}
export default RequestUtils
