import React, { useState } from "react"
import PropTypes from "prop-types"
import Chevron from "../icons/Chevron"
import CU from "../../lib/CalendarUtils"

const getString = (change) => {
  let well = ""
  let from = ""
  let to = ""
  let string = change.changes && change.changes[0] && "No information about change"

  if (change.well) {
    switch (change.type) {
      case "moved":
        well = CU.getNameForWellBooking(change.well, true)
        from = change.changes[0].from
        to = change.changes[0].to
        string = (
          <span>
            <span className={"FontStyle--Bold"}>{well}</span>
            {" is moved from "}
            <span className={"FontStyle--Bold"}>{from}</span>
            {" to "}
            <span className={"FontStyle--Bold"}>{to}</span>
          </span>
        )
        break
      case "removed":
        well = CU.getNameForWellBooking(change.well, true)
        string = (
          <span>
            <span className={"FontStyle--Bold"}>{well}</span>
            {" is removed"}
          </span>
        )
        break
      case "added":
        well = CU.getNameForWellBooking(change.well, true)
        string = (
          <span>
            <span className={"FontStyle--Bold"}>{well}</span>
            {" is added"}
          </span>
        )
        break
      default: {
        string = "No known change"
      }
    }
  }

  return string
}

const ListMajorChange = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const { change } = props

  const changeString = getString(change)

  return (
    <div className="timeline__changes-wrapper--list-major-change" onClick={() => setIsOpen(!isOpen)}>
      {changeString}
      {change.well.comment ? Chevron(isOpen ? "open" : "") : null}
      {isOpen && change.well.comment ? <div>{change.well.comment}</div> : null}
    </div>
  )
}

ListMajorChange.propTypes = {
  change: PropTypes.object,
  type: PropTypes.string,
}

export default ListMajorChange
