import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import Locked from "../../../../assets/images/svg/icons/locked.svg"
import LockedGrey from "../../../../assets/images/svg/icons/lockedGrey.svg"
import UserAccessComponent from "../../../UserAccessComponent"
import NewWellButton from "../../../components/buttons/NewWellButton"
import Chevron from "../../../components/icons/Chevron"
import IconContainer from "../../../components/icons/IconContainer"
import { ActionCreators } from "../../../redux/actions"

import MastersModule from "../../../components/Overview/MastersModule"
import NextScheduleModule from "../../../components/Overview/NextScheduleModule"
import StatusModule from "../../../components/Overview/StatusModule"

class SchedulerTopVersion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      activityIndicatorFinished: false,
    }

    this.toggleSelectList = this.toggleSelectList.bind(this)
  }

  componentDidUpdate = (prevProps) => {
    const oldPathName = prevProps.location.pathname
    const newPathName = this.props.location.pathname

    if (oldPathName !== newPathName) {
      this.toggleSelectList()
    }
  }

  toggleSelectList() {
    const { fetchDrafts, fetchMasters, draftsList, mastersList, accessLevel } = this.props

    if (!this.state.isOpen && !draftsList.length && accessLevel > 1) {
      fetchDrafts()
    }

    if (!this.state.isOpen && !mastersList.length) {
      fetchMasters()
    }

    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activityIndicator !== this.props.activityIndicator) {
      // If the activityindicator has been shown, and is now to be hidden
      if (Object.keys(prevProps.activityIndicator).length > 0) {
        setTimeout(() => {
          this.setState({
            activityIndicatorFinished: true,
          })
        }, 600)
        setTimeout(() => {
          this.setState({
            activityIndicatorFinished: false,
          })
        }, 2000)
      }
    }
  }

  render() {
    const { isOpen, activityIndicatorFinished } = this.state
    const {
      accessLevel,
      darkMode,
      requests,
      // activityIndicator,
      schedulerData: { data },
    } = this.props

    const currentSchedule = data || null

    let dropDownText = "Loading drafts..."
    let dropDownIcon = null

    if (currentSchedule) {
      dropDownText = currentSchedule.name
      if (currentSchedule.readonly) {
        dropDownIcon = IconContainer(darkMode ? LockedGrey : Locked, 9.25, 13.5)
      }
    }

    const shouldItRender = true

    return (
      <div className={`SchedulerTopVersion ${isOpen ? "open" : ""}`}>
        {accessLevel > 2 && <NewWellButton text="+ New well" openPanelWithType={this.props.openPanelWithType} />}
        {accessLevel === 3 && requests && requests.unprocessed && requests.unprocessed.length > 0 && (
          <div className={"SchedulerTopVersion__pending"}>
            {requests.unprocessed.length} {requests.unprocessed.length > 1 ? "wells" : "well"} pending approval
          </div>
        )}
        {<div className={`SchedulerTopVersion__activity-done-indicator${activityIndicatorFinished ? " active" : ""}`}>Done! 🙌🏼</div>}
        {shouldItRender ? (
          <a onClick={this.toggleSelectList} className="SchedulerTopVersion__selector">
            {dropDownIcon} {currentSchedule ? currentSchedule.name : dropDownText}{" "}
            {currentSchedule.is_independent ? (
              <span style={{ fontSize: 10, padding: 3, fontWeight: 100, border: "1px solid #dedede", borderRadius: 4 }}>independent</span>
            ) : (
              ""
            )}
            {Chevron(isOpen ? "open" : "")}
          </a>
        ) : null}

        <div className={"SchedulerTopVersion__list DraftSelectionList"}>
          <div className="SchedulerTopVersion__list__Content__Container">
            {accessLevel > 2 && <StatusModule />}
            {accessLevel > 1 ? <NextScheduleModule /> : null}
            <MastersModule />
          </div>
        </div>
      </div>
    )
  }
}

SchedulerTopVersion.propTypes = {
  fetchDrafts: PropTypes.func,
  fetchMasters: PropTypes.func,
  openPanelWithType: PropTypes.func,
  draftsList: PropTypes.array,
  mastersList: PropTypes.array,
  schedulerData: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  requests: PropTypes.object,
  shouldRender: PropTypes.bool,
  darkMode: PropTypes.bool,
  accessLevel: PropTypes.number,
  activityIndicator: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    draftsList: state.overviewData.draftsList,
    mastersList: state.overviewData.mastersList,
    user: state.user,
    schedulerData: state.schedulerData.schedulerData,
    accessLevel: state.user.accessLevel,
    activityIndicator: state.application.activityIndicator,
    darkMode: state.calendar.darkMode,
    requests: state.requestData.requests,
  }
}

export default UserAccessComponent(withRouter(connect(mapStateToProps, mapDispatchToProps)(SchedulerTopVersion)), 2, true)
