import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import getYear from "date-fns/getYear"
import IconContainer from "../../icons/IconContainer"

import RoundedDropList from "../../dropdown/RoundedDropList"
import { ActionCreators } from "../../../redux/actions"
import Master from "../../icons/Master"
import Page from "../../../../assets/images/svg/icons/summary.svg"
import { DATE_FORMATE_NB } from "../../../dateConsts"

class MastersModuleTimeline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      thisYear: getYear(new Date()),
      selectedYear: getYear(new Date()),
    }

    this.timer = null
  }

  componentDidMount() {
    const { fetchMasters } = this.props
    fetchMasters()
  }

  onYearChange = (e) => {
    const { fetchMasters } = this.props
    const { value } = e.target

    const year = parseInt(value, 0)

    this.setState({
      selectedYear: year,
    })

    fetchMasters(year)
  }

  getTimelineForMaster = (e, id, openSummary = false) => {
    e.stopPropagation()
    e.preventDefault()

    const { getTimelineData } = this.props
    getTimelineData(id, openSummary)
  }

  render() {
    const { mastersList } = this.props
    const { thisYear, selectedYear } = this.state
    const masters = mastersList || []

    const diff = thisYear - 2018
    const years = []
    for (let i = 0; i >= -diff; i -= 1) {
      years.push(i)
    }

    return (
      <div className="Overview__Module MastersModule">
        <div className="Overview__Module__Header__container">
          <h2 className="Overview__ModuleHeading">Masters</h2>
          <RoundedDropList
            defaultOpen={false}
            data={years}
            thisYear={thisYear}
            startYear={thisYear}
            selectedYear={selectedYear}
            onChange={this.onYearChange}
          />
        </div>
        <div className="MastersModule__list">
          {masters.map((val, key) => (
            <div key={key} className="MastersModule__listitem" onClick={(e) => this.getTimelineForMaster(e, val.id, false)}>
              {key === 0 ? (
                <h4 className="MastersModule__listitem-subheader">
                  {Master(
                    {
                      width: "20px",
                      margin: "0px 5px 0 0",
                      position: "relative",
                      top: "3px",
                    },
                    `master-icon-${val.id}`
                  )}{" "}
                  Latest master
                </h4>
              ) : null}
              <span className="MastersModule__listitem-header">{val.name}</span>
              <span className="MastersModule__listitem-date">Published {format(parseISO(val.master_from_date), DATE_FORMATE_NB)}</span>
              {val.decisionsummary ? (
                <span className="MastersModule__listitem-icon" onClick={(e) => this.getTimelineForMaster(e, val.id, true)}>
                  {IconContainer(Page, 13, 15)}
                </span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

MastersModuleTimeline.propTypes = {
  mastersList: PropTypes.array,
  fetchMasters: PropTypes.func,
  getTimelineData: PropTypes.func,
  showModal: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    mastersList: state.overviewData.mastersList,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MastersModuleTimeline)
