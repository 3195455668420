import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"

class PanelFrame extends Component {
  render() {
    const { modalFailedValidation } = this.props

    return <div className={`Panel${modalFailedValidation ? " hasError" : ""}`}>{this.props.children}</div>
  }
}

PanelFrame.propTypes = {
  children: PropTypes.object,
  modalFailedValidation: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    modalFailedValidation: state.application.modalFailedValidation,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelFrame)
