import {
  EDIT_WELL_CAMPAIGN,
  EDIT_WELL_CAMPAIGN_INFO,
  // CANCEL,
  // SUBMIT,
} from "../../../../panelConsts"

const config = {
  type: EDIT_WELL_CAMPAIGN,
  index: 0,
  options: {
    heading: "Edit wells",
    dynamicHeader: false,
    subHeading: null,
    largeModal: true,
    wideModal: true,
    hideMenu: true,
    limitHeight: true,
  },
  pages: [
    {
      type: EDIT_WELL_CAMPAIGN_INFO,
      name: "Well Info",
      options: {
        buttons: {},
      },
    },
  ],
}

export default config
