/* eslint-disable import/prefer-default-export */
import { ofType } from "redux-observable"
import { from, of } from "rxjs"
import { catchError, map, mergeMap, switchMap } from "rxjs/operators"
import Api from "../../services/ApiService"
import { closeModal } from "../actions/managecontent"
import { observerEmpty, observerError } from "../actions/observer"
import {
  fetchDrafts as fetchDraftsAction,
  saveMissingInputters,
  updateDraftsList,
  updateMastersList,
  updatePreviousDraftsList,
} from "../actions/overviewData"
import * as types from "../types"

/**
  ATTEMPT TO FETCH DATA
*/

export const fetchDrafts = (action$) =>
  action$.pipe(
    ofType(types.FETCH_DRAFTS),
    switchMap(() => {
      // TODO: FIX THIS.
      // let path;
      // If the requested URL = a single schedule, the draftsList is used for the topmenu
      // if (state$.value.router.location.pathname.includes('schedule')) {
      //   path = 'schedule/getMyDrafts?includeMasters=true';
      // } else {
      //   path = 'schedule/getMyDrafts';
      // }
      const path = "schedule/getMyDrafts"

      return from(Api.getDataFromApi(path)).pipe(catchError((err) => of(observerError(err.xhr.response))))
    }),
    // mergeMap(result => [closeModal(), updateDraftsList(result.data)]),
    mergeMap((result) => [updateDraftsList(result.data)])
  )
export const fetchOverviewDrafts = (action$) =>
  action$.pipe(
    ofType(types.FETCH_PREVIOUS_DRAFTS),
    switchMap(() => {
      const path = "schedule/getMyOutdatedDrafts"

      return from(Api.getDataFromApi(path)).pipe(catchError((err) => of(observerError(err.xhr.response))))
    }),
    mergeMap((result) => [updatePreviousDraftsList(result.data)])
  )

/**
  ATTEMPT TO FETCH DATA
*/

export const fetchMissingInputters = (action$) =>
  action$.pipe(
    ofType(types.FETCH_MISSING_INPUTTERS),
    switchMap(() => {
      const path = "schedule/getMissingInput"

      return from(Api.getDataFromApi(path)).pipe(catchError((err) => of(observerError(err.xhr.response))))
    }),
    map((result) => saveMissingInputters(result.data))
  )

/**
  LOGIN TO FETCH DATA COMPLETE
*/

export const draftsFetched = (action$) =>
  action$.pipe(
    ofType(types.UPDATE_DRAFTS_LIST),
    map(() => observerEmpty())
  )

export const fetchMasters = (action$) =>
  action$.pipe(
    ofType(types.FETCH_MASTERS),
    switchMap((actons) =>
      from(Api.getDataFromApi(actons.data ? `schedule/mastersList/${actons.data}` : "schedule/mastersList")).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    // mergeMap(result => [closeModal(), updateMastersList(result.data)]),
    mergeMap((result) => [updateMastersList(result.data)])
  )

export const saveSingleDraftMeta = (action$, state$) =>
  action$.pipe(
    ofType(types.SAVE_DRAFT_META),
    switchMap(() => {
      const updateMeta = state$.value.overviewData.updatedDraftMeta
      return from(Api.postDataToApi("schedule", updateMeta)).pipe(catchError((err) => of(observerError(err.xhr.response))))
    }),
    mergeMap(() => [closeModal(), fetchDraftsAction()])
  )

export const archiveDraft = (action$, state$) =>
  action$.pipe(
    ofType(types.ARCHIVE_DRAFT),
    switchMap(() => {
      const draft = state$.value.overviewData.archivedDraft
      return from(Api.postDataToApi(`schedule/archive/${draft.id}`, {})).pipe(catchError((err) => of(observerError(err.xhr.response))))
    }),
    mergeMap(() => [closeModal(), fetchDraftsAction()])
  )

export const createNewDraft = (action$, state$) =>
  action$.pipe(
    ofType(types.CREATE_NEW_DRAFT),
    switchMap(() => {
      const draft = state$.value.overviewData.newDraft
      return from(
        Api.postDataToApi(
          `schedule/createNewFromVersion/${draft.id}`,
          {},
          { name: draft.name, is_independent: draft.isIndependent ? "1" : "0" }
        )
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    }),
    mergeMap(() => [fetchDraftsAction(), closeModal()])
  )

// export const closeModalAfterSubmit = action$ => action$.pipe(
//   ofType(types.CREATE_NEW_DRAFT || types.SAVE_DRAFT_META),
//   delay(1000),
//   map(() => closeModal()),
// );

/**
  LOGIN TO FETCH DATA COMPLETE
*/

export const mastersFetched = (action$) =>
  action$.pipe(
    ofType(types.UPDATE_MASTERS_LIST),
    map(() => observerEmpty())
  )
