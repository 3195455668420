import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../../redux/actions"
import PanelFooter from "../../../../components/panel/PanelFooter"
import PanelHeader from "../../../../components/panel/PanelHeader"
import PanelMenu from "../../../../components/panel/PanelMenu"
import PH from "../../../../lib/PanelHelper"

class RemoveRigError extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pages: props.pages,
      postData: {},
    }
  }

  componentDidMount() {
    const { panelData } = this.props

    if (typeof panelData !== "undefined") {
      this.setState({
        postData: panelData,
      })
    }
  }

  getPage = (index, pages, postData) => PH.getPage(index, pages[index], null, null, postData)

  render() {
    const { pages, postData } = this.state
    const {
      panelOpen: { index },
    } = this.props
    return [
      <PanelHeader key={"PanelHeader"} />,
      <PanelMenu key={"PanelMenu"} />,
      <div key={"Panel__Page"} className="Panel__Page ">
        <div className="Panel__Page-Container PanelContent">{this.getPage(index, pages, postData)}</div>
        <PanelFooter index={index} data={pages[index]} onSubmit={(val) => this.onSubmit(val)} />
      </div>,
    ]
  }
}

RemoveRigError.propTypes = {
  panelOpen: PropTypes.object,
  panelData: PropTypes.object,
  pages: PropTypes.array,
  index: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    panelOpen: state.application.panelOpen,
    panelData: state.application.panelData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveRigError)
