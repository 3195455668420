/* eslint-disable import/prefer-default-export */
import { from, of } from "rxjs"

import { ofType } from "redux-observable"
import { mergeMap, map, tap, switchMap, catchError, delay } from "rxjs/operators"

import * as types from "../types"
import * as panelConsts from "../../panelConsts"

import Api from "../../services/ApiService"
import { updateRigData } from "../actions/schedulerData"
import { setViewFilter } from "../actions/filters"
import { openPanelWithType, closePanel } from "../actions/application"
import { setScrollObjectPosition } from "../actions/calendar"
import { observerError, observerEmpty } from "../actions/observer"
import {
  saveRequests,
  saveSuggestions,
  getRequests,
  clearRequestEditData,
  clearPreviewSelection,
  setPreviewSelection,
} from "../actions/requests"

export const fetchRequests = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_REQUESTS),
    switchMap(() => from(Api.getDataFromApi(`wellrequest/getForSchedule/${state$.value.schedulerData.schedulerData.data.id}`))),
    mergeMap((result) => {
      if (!result.error) {
        return [saveRequests(result.data)]
      }

      return [observerError(result, true)]
    })
  )

export const deleteRequest = (action$, state$) =>
  action$.pipe(
    ofType(types.REMOVE_REQUEST),
    switchMap(() =>
      from(Api.postDataToApi(`wellrequest/remove/${state$.value.requestData.requestToRemove.id}`)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    tap(() => "REMOVE_REQUEST DONE"),
    mergeMap(() => [getRequests(), closePanel()])
  )

export const editRequest = (action$) =>
  action$.pipe(
    ofType(types.EDIT_REQUEST),
    map(() => openPanelWithType(panelConsts.REGISTER_NEW_REQUEST))
  )

export const previewRequest = (action$) =>
  action$.pipe(
    ofType(types.PREVIEW_REQUEST),
    map(() => openPanelWithType(panelConsts.PANEL_PREVIEW_REQUEST))
  )

export const setRequestStatus = (action$, state$) =>
  action$.pipe(
    ofType(types.SET_REQUEST_STATUS),
    switchMap(() => {
      const { data } = state$.value.schedulerData.schedulerData
      const { requestData } = state$.value

      return from(
        Api.postDataToApi(
          `schedule/setRequestStatus/${data.versions[state$.value.calendar.PVersion.key].id}/${requestData.requestToSetStatus.id}`,
          { status: requestData.requestStatus }
        )
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    }),
    map(() => getRequests())
  )

export const areYouSureYouWantToRemoveRequest = (action$) =>
  action$.pipe(
    ofType(types.CONFIRM_REMOVE_REQUEST),
    map(() => openPanelWithType(panelConsts.SHOULD_YOU_REMOVE_REQUEST))
  )

export const getSuggestions = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_REQUEST_SUGGESTONS),
    switchMap(() => {
      const { data } = state$.value.schedulerData.schedulerData
      const { requestData } = state$.value

      return from(Api.getDataFromApi(`schedule/getSuggestionsForRequest/${requestData.requestToEdit.id}/${data.id}`)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    }),
    mergeMap((result) => [
      result && result.data && result.data.suggestions.length === 1 && result.data.suggestions[0].entries.length === 1
        ? setPreviewSelection({
            rigId: result.data.suggestions[0].rig_id,
            scheduleVersionId: result.data.suggestions[0].schedule_version_id,
            rigInstenceID: result.data.suggestions[0].id,
            existingRigBooking: result.data.exsistsInScheduleversion ? result.data.existingRigBooking : null,
            ...result.data.suggestions[0].entries[0],
          })
        : () => {},
      saveSuggestions(result.data),
      setViewFilter({
        suggestions: true,
      }),
    ])
  )

// scroll to request
export const scrollToFirstSuggestion = (action$, state$) =>
  action$.pipe(
    ofType(types.SAVE_REQUEST_SUGGESTONS),
    delay(100),
    map(() => {
      if (
        state$.value.requestData.suggestions.suggestions.length > 0 &&
        state$.value.requestData.suggestions.suggestions[0].entries.length > 0
      ) {
        return setScrollObjectPosition(0, state$.value.requestData.suggestions.suggestions[0].entries[0].name)
      }

      return observerEmpty()
    })
  )

export const addRequestToSchedule = (action$, state$) =>
  action$.pipe(
    ofType(types.ADD_REQUEST_TO_SCHEDULE),
    switchMap(() =>
      from(
        Api.postDataToApi(
          `schedule/addRequestToSchedule/${state$.value.schedulerData.schedulerData.data.id}/${state$.value.requestData.previewSelectionSuggestion.rigInstenceID}`,
          state$.value.requestData.requestToEdit
        )
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    ),
    mergeMap((result) => [clearRequestEditData(), clearPreviewSelection(), closePanel(), updateRigData(result), setViewFilter({})])
  )
