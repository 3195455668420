import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"

import { ActionCreators } from "../../../redux/actions"

class NowTo1YearIndicator extends PureComponent {
  getStyle = () => {
    const { dayWidth, timespan } = this.props

    if (!timespan) {
      return {}
    }
    const style = {}

    const estimate = 365
    const startDate = new Date()
    const diffDays = differenceInCalendarDays(startDate, timespan.from)

    style.width = estimate * dayWidth
    style.transform = `translate3d(${diffDays * dayWidth}px, 15px, 0)`

    return style
  }

  render() {
    const style = this.getStyle()

    return (
      <div style={style} className="NowTo1YearIndicator">
        <span>1 year</span>
      </div>
    )
  }
}

NowTo1YearIndicator.propTypes = {
  timespan: PropTypes.object,
  dayWidth: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    dayWidth: state.calendar.dayWidth,
    PVersion: state.calendar.PVersion,
    timespan: state.calendar.timespan,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NowTo1YearIndicator)
