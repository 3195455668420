// LOGIN
export const ATTEMT_USER_LOGIN = "ATTEMT_USER_LOGIN"
export const LOGIN = "LOGIN"

// APPLICATION
export const GET_DOMAIN_DATA = "application.GET_DOMAIN_DATA"
export const SET_DOMAIN_DATA = "application.SET_DOMAIN_DATA"
export const GOTO_URL = "GOTO_URL"
export const SCROLL_TO_LAST_POSITION = "SCROLL_TO_LAST_POSITION"
export const SCROLL_POSITION = "SCROLL_POSITION"
export const SCROLL_OBJECT_POSITION = "SCROLL_OBJECT_POSITION"
export const DELETE_SCROLL_OBJECT_POSITION = "DELETE_SCROLL_OBJECT_POSITION"
export const REGRET_CHANGE = "REGRET_CHANGE"
export const UPDATE_REGRET_DATA = "UPDATE_REGRET_DATA"
export const SET_ELEMENT_IN_BETWEEN = "SET_ELEMENT_IN_BETWEEN"
export const TOGGLE_ERROR_CONSOLE_OPEN = "application.TOGGLE_ERROR_CONSOLE_OPEN"

// BUILDER
export const SHAKE_BUILDER = "builder.SHAKE_BUILDER"

// VIEWS
export const ACTIVE_VIEW = "ACTIVE_VIEW"
export const LOAD_AND_SHOW_VIEW = "LOAD_AND_SHOW_VIEW"

// ROUTER
export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE"

// CALENDAR
export const SET_CALENDAR_TIMESPAN = "SET_CALENDAR_TIMESPAN"
export const POPULATE_CALENDAR = "POPULATE_CALENDAR"
export const UPDATE_CALENDAR = "UPDATE_CALENDAR"
export const OPEN_WELL_POPOVER = "OPEN_WELL_POPOVER"
export const CLOSE_WELL_POPOVER = "CLOSE_WELL_POPOVER"
export const SET_ZOOM_LEVEL = "SET_ZOOM_LEVEL"
export const SET_P_LEVEL = "SET_P_LEVEL"
export const SET_DAY_WIDTH = "SET_DAY_WIDTH"
export const SET_HIDDEN_RIGS = "SET_HIDDEN_RIGS"
export const SET_CALENDARS_VISIBLE_YEARS = "SET_CALENDARS_VISIBLE_YEARS"
export const SET_CALENDARS_VISIBLE_MONTH = "SET_CALENDARS_VISIBLE_MONTH"
export const TOGGLE_DARKMODE = "TOGGLE_DARKMODE"
export const SET_DROP_READY = "SET_DROP_READY"
export const SET_DRAG_ROW_ID = "SET_DRAG_ROW_ID"
export const SET_OVERRIDE_P_VALUE_FOR_WELL = "SET_OVERRIDE_P_VALUE_FOR_WELL"
export const SET_ADDITIONAL_SCOPE_WELLS = "SET_ADDITIONAL_SCOPE_WELLS"

// FILTERS
export const TOGGLE_VIEW_FILTER = "TOGGLE_VIEW_FILTER"
export const SET_VIEW_FILTER = "SET_VIEW_FILTER"
export const SET_VIEW_FILTER_TO_STORED_VERSION = "SET_VIEW_FILTER_TO_STORED_VERSION"

// TIME
export const DURATION_ONE_MONTH = "DURATION_ONE_MONTH"
export const DURATION_ONE_YEAR = "DURATION_ONE_YEAR"
export const DURATION_18_MONTHS = "DURATION_18_MONTHS"
export const DURATION_EXTENDED_VIEW = "DURATION_EXTENDED_VIEW"
export const DURATION_TWO_YEAR = "DURATION_TWO_YEAR"
export const DURATION_THREE_YEAR = "DURATION_THREE_YEAR"
export const DURATION_FOUR_YEAR = "DURATION_FOUR_YEAR"
export const DURATION_FIVE_YEAR = "DURATION_FIVE_YEAR"

// P VERSIONS (Default values, name can be overridden in 'app_settings' table)
export const P10 = {
  name: "P10",
  value: "p10",
  key: 0,
}
export const P50 = {
  name: "P50",
  value: "p50",
  key: 1,
}
export const P90 = {
  name: "P90",
  value: "p90",
  key: 2,
}

// WELL STATUS
export const CONCEPTIONAL = {
  name: "Conceptional",
  type: "conceptional",
  color: "#E6D8FE",
  borderColor: "#8E6FC3",
  darkmodeColor: "#A482E9",
}
export const PLAN_AND_SELECT = {
  name: "Plan & select",
  type: "plan_and_select",
  color: "#FFDBF5",
  borderColor: "#FF47C9",
  darkmodeColor: "#D957AB",
}
export const ENGINEERING_AND_DESIGN = {
  name: "Engineering & design",
  type: "engineering_and_design",
  color: "#FFECBC",
  borderColor: "#FFB600",
  darkmodeColor: "#E9A14D",
}
export const PLAN_FOR_EXECUTION = {
  name: "Plan for execution",
  type: "plan_for_execution",
  color: "#FDFDC4",
  borderColor: "#E6E600",
  darkmodeColor: "#F1D960",
}
export const EXECUTION = {
  name: "Execution",
  type: "execution",
  color: "#D1FFE6",
  borderColor: "#33E283",
  darkmodeColor: "#6DBDA6",
}
export const COMPLETED = {
  name: "Completed",
  type: "completed",
  color: "#EAEAEA",
  borderColor: "#D4D7C6",
  darkmodeColor: "#919495",
}
export const OCCUPIED = {
  name: "Occupied",
  type: "occupied",
  color: "#DAF0FF",
  borderColor: "#6995B3",
  darkmodeColor: "#A2D9FF",
}

export const WELL_STATUS = new Map()
WELL_STATUS.set(CONCEPTIONAL.type, {
  name: CONCEPTIONAL.name,
  color: CONCEPTIONAL.color,
  borderColor: CONCEPTIONAL.borderColor,
  darkmodeColor: CONCEPTIONAL.darkmodeColor,
})
WELL_STATUS.set(PLAN_AND_SELECT.type, {
  name: PLAN_AND_SELECT.name,
  color: PLAN_AND_SELECT.color,
  borderColor: PLAN_AND_SELECT.borderColor,
  darkmodeColor: PLAN_AND_SELECT.darkmodeColor,
})
WELL_STATUS.set(ENGINEERING_AND_DESIGN.type, {
  name: ENGINEERING_AND_DESIGN.name,
  color: ENGINEERING_AND_DESIGN.color,
  borderColor: ENGINEERING_AND_DESIGN.borderColor,
  darkmodeColor: ENGINEERING_AND_DESIGN.darkmodeColor,
})
WELL_STATUS.set(PLAN_FOR_EXECUTION.type, {
  name: PLAN_FOR_EXECUTION.name,
  color: PLAN_FOR_EXECUTION.color,
  borderColor: PLAN_FOR_EXECUTION.borderColor,
  darkmodeColor: PLAN_FOR_EXECUTION.darkmodeColor,
})
WELL_STATUS.set(EXECUTION.type, {
  name: EXECUTION.name,
  color: EXECUTION.color,
  borderColor: EXECUTION.borderColor,
  darkmodeColor: EXECUTION.darkmodeColor,
})
WELL_STATUS.set(COMPLETED.type, {
  name: COMPLETED.name,
  color: COMPLETED.color,
  borderColor: COMPLETED.borderColor,
  darkmodeColor: COMPLETED.darkmodeColor,
})
WELL_STATUS.set(OCCUPIED.type, {
  name: OCCUPIED.name,
  color: OCCUPIED.color,
  borderColor: OCCUPIED.borderColor,
  darkmodeColor: OCCUPIED.darkmodeColor,
})

// BOOKING TYPEA
export const WELL_SINGLE = "single_well"
export const WELL_SEQUENCE = "well_sequence"
export const WELLS = "wells"
export const RIG_MOVE = "rig_move"
export const BUFFER_TIME = "buffer_time"
export const FREE_TIME = "free_time"
export const SUGGESTION = "suggestion"
export const NO_CONTRACT = "no_contract"
export const CONTRACT_OPTION = "contract_option"

// REQUESTS
export const GET_REQUESTS = "GET_REQUESTS"
export const SAVE_REQUESTS = "SAVE_REQUESTS"
export const SAVE_NEW_REQUEST = "SAVE_NEW_REQUEST"
export const ACCEPT_REQUEST_PLACEMENT = "ACCEPT_REQUEST_PLACEMENT"
export const SET_REQUEST_PROCESSED = "SET_REQUEST_PROCESSED"
export const SET_REQUEST_RESOLVED = "SET_REQUEST_RESOLVED"
export const SET_REQUEST_BLOCKED = "SET_REQUEST_BLOCKED"
export const SET_REQUEST_STATUS = "SET_REQUEST_STATUS"
export const CONFIRM_REMOVE_REQUEST = "CONFIRM_REMOVE_REQUEST"
export const REMOVE_REQUEST = "REMOVE_REQUEST"
export const EDIT_REQUEST = "EDIT_REQUEST"
export const EDIT_REQUEST_COMPLETE = "EDIT_REQUEST_COMPLETE"
export const PREVIEW_REQUEST = "PREVIEW_REQUEST"
export const SET_PREVIEW_SELECTION = "SET_PREVIEW_SELECTION"
export const ADD_REQUEST_TO_SCHEDULE = "ADD_REQUEST_TO_SCHEDULE"
export const CLEAR_REQUEST_EDIT_DATA = "CLEAR_REQUEST_EDIT_DATA"
export const CLEAR_REQUEST_TO_REMOVE = "CLEAR_REQUEST_TO_REMOVE"
export const CLEAR_PREVIEW_SELECTION = "CLEAR_PREVIEW_SELECTION"

// OVERLAY
export const SHOW_OVERLAY = "SHOW_OVERLAY"
export const HIDE_OVERLAY = "HIDE_OVERLAY"

// MANAGE CONTENT
export const GET_BLOCK_LIST = "GET_BLOCK_LIST"
export const SAVE_BLOCK_LIST = "SAVE_BLOCK_LIST"
export const GET_USERLIST_LIST = "GET_USERLIST_LIST"
export const SAVE_USERLIST_LIST = "SAVE_USERLIST_LIST"
export const GET_WELL_LIST = "GET_WELL_LIST"
export const SAVE_WELL_LIST = "SAVE_WELL_LIST"
export const GET_APP_SETTINGS = "GET_APP_SETTINGS"
export const SAVE_APP_SETTINGS = "SAVE_APP_SETTINGS"
export const GET_RIG_LIST = "GET_RIG_LIST"
export const SAVE_RIG_LIST = "SAVE_RIG_LIST"
export const GET_LICENSE_LIST = "GET_LICENSE_LIST"
export const SAVE_LICENSE_LIST = "SAVE_LICENSE_LIST"
export const GET_ASSET_LIST = "GET_ASSET_LIST"
export const SAVE_ASSET_LIST = "SAVE_ASSET_LIST"
export const GET_AREA_LIST = "GET_AREA_LIST"
export const SAVE_AREA_LIST = "SAVE_AREA_LIST"
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST"
export const SAVE_COUNTRY_LIST = "SAVE_COUNTRY_LIST"
export const DELETE_ITEM_LIST = "DELETE_ITEM_LIST"
export const CLEAR_DELETE_ITEM_LIST = "CLEAR_DELETE_ITEM_LIST"
export const EDIT_SINGLE_ITEM = "EDIT_SINGLE_ITEM"
export const CLEAR_SINGLE_ITEM_EDIT = "CLEAR_SINGLE_ITEM_EDIT"
export const SAVE_SINGLE_ITEM_EDIT = "SAVE_SINGLE_ITEM_EDIT"
export const SAVE_USERLIST_LIST_EDIT = "SAVE_USERLIST_LIST_EDIT"
export const EDIT_USERLIST_LIST = "EDIT_USERLIST_LIST"
export const DELETE_USER_LISTS = "DELETE_USER_LISTS"
export const GET_MESSAGE_LIST = "GET_MESSAGE_LIST"
export const GET_MESSAGE_LIST_FOR_USER = "GET_MESSAGE_LIST_FOR_USER"
export const SAVE_MESSAGE_LIST = "SAVE_MESSAGE_LIST"
export const SAVE_MESSAGE_LIST_FOR_USER = "SAVE_MESSAGE_LIST_FOR_USER"
export const SAVE_MESSAGE = "SAVE_MESSAGE"
export const SET_MESSAGE_READ = "SET_MESSAGE_READ"
export const CU_GET_LIST = "CU_GET_LIST"
export const CU_SAVE_LIST = "CU_SAVE_LIST"
export const CU_DELETE_SINGLE_ITEM = "CU_DELETE_SINGLE_ITEM"
export const CU_DELETED_SINGLE_ITEM = "CU_DELETED_SINGLE_ITEM"
export const CU_UPDATE_ITEM = "CU_UPDATE_ITEM"
export const CU_GET_GROUP_LIST = "CU_GET_GROUP_LIST"
export const CU_SAVE_GROUP_LIST = "CU_SAVE_GROUP_LIST"
export const CU_UPDATED_ITEM = "CU_UPDATED_ITEM"
export const CU_ERROR = "CU_ERROR"

// API
export const FETCH_RIG_DATA = "FETCH_RIG_DATA"
export const FETCH_RIG_REQUESTS = "FETCH_RIG_REQUESTS"
export const FETCH_RIG_LIST = "FETCH_RIG_LIST"
export const UPDATE_RIG_DATA = "UPDATE_RIG_DATA"
export const RESET_RIG_DATA = "RESET_RIG_DATA"
export const UPDATE_BLOCK_DATA = "UPDATE_BLOCK_DATA"
export const UPDATE_RIG_LIST = "UPDATE_RIG_LIST"
export const UPDATE_ASSETS_DATA = "UPDATE_ASSETS_DATA"
export const UPDATE_LICENSE_DATA = "UPDATE_LICENSE_DATA"
export const UPDATE_RIG_DATA_EDIT = "UPDATE_RIG_DATA_EDIT"
export const UPDATE_RIG_REQUESTS = "UPDATE_RIG_REQUESTS"
export const FETCH_BLOCK_DATA = "FETCH_BLOCK_DATA"
export const FETCH_WELL_DATA = "FETCH_WELL_DATA"
export const FETCH_ASSETS_DATA = "FETCH_ASSETS_DATA"
export const FETCH_AREA_DATA = "FETCH_AREA_DATA"
export const UPDATE_AREA_DATA = "UPDATE_AREA_DATA"
export const FETCH_LICENSE_DATA = "FETCH_LICENSE_DATA"
export const CREATE_NEW_RIG = "CREATE_NEW_RIG"
export const SAVE_WELL_INSTANCE = "SAVE_WELL_INSTANCE"
export const UPDATE_RIG_PRIORITY = "UPDATE_RIG_PRIORITY"
export const SET_WELL_INSTANCE_TO_REMOVE = "SET_WELL_INSTANCE_TO_REMOVE"
export const REMOVE_WELL_INSTANCE = "REMOVE_WELL_INSTANCE"
export const GET_REQUEST_SUGGESTONS = "GET_REQUEST_SUGGESTONS"
export const SAVE_REQUEST_SUGGESTONS = "SAVE_REQUEST_SUGGESTONS"
export const UPDATE_WELL_DATA = "UPDATE_WELL_DATA"
export const FETCH_SINGLE_WELL_DATA = "FETCH_SINGLE_WELL_DATA"
export const UPDATE_SINGLE_WELL_DATA = "UPDATE_SINGLE_WELL_DATA"
export const CLEAR_SINGLE_WELL_DATA = "CLEAR_SINGLE_WELL_DATA"

export const CREATE_NEW_REQUEST = "CREATE_NEW_REQUEST"
export const API_ADD_BUFFER_TIME = "API_ADD_BUFFER_TIME"
export const SET_BUFFER_TIME_INSTANCE_TO_REMOVE = "SET_BUFFER_TIME_INSTANCE_TO_REMOVE"
export const API_REMOVE_BUFFER_TIME = "API_REMOVE_BUFFER_TIME"
export const API_REMOVE_RIG = "API_REMOVE_RIG"

export const NOTIFY_SHARES_SUCCESS = "NOTIFY_SHARES_SUCCESS"
export const GET_CURRENT_SCHEDULE_SHARE_INFORMATION = "GET_CURRENT_SCHEDULE_SHARE_INFORMATION"
export const GET_SHARE_GROUPS = "GET_SHARE_GROUPS"
export const SAVE_SHARE_GROUPS = "SAVE_SHARE_GROUPS"
export const SHARE_CURRENT_SCHEDULE = "SHARE_CURRENT_SCHEDULE"
export const SAVE_CURRENT_SHARES = "SAVE_CURRENT_SHARES"
export const REMOVE_CURRENT_SHARES = "REMOVE_CURRENT_SHARES"
export const SET_CURRENT_SCHEDULE_AS_MASTER = "SET_CURRENT_SCHEDULE_AS_MASTER"
export const ADD_EXISTING_RIG_TO_SCHEDULE = "ADD_EXISTING_RIG_TO_SCHEDULE"

// SOUND
export const PLAY_SOUND = "PLAY_SOUND"
export const STOP_SOUND = "STOP_SOUND"

// TRANSITIONS
export const PAGE_TRANSITION_COMPLETE = "PAGE_TRANSITION_COMPLETE"

// STATE
export const RESIZE = "RESIZE SITE"
export const SET_TOOLBAR_OPEN_STATE = "SET_TOOLBAR_OPEN_STATE"
export const TOGGLE_TOOLBAR_OPEN_STATE = "TOGGLE_TOOLBAR_OPEN_STATE"

// TIMELINE
export const SET_TIMELINE_DATA = "timeline.SET_TIMELINE_DATA"
export const GET_TIMELINE_DATA = "timeline.GET_TIMELINE_DATA"

// OBSERVER MESSAGES
export const OBSERVER_ERROR = "OBSERVER_ERROR"
export const OBSERVER_HIDE_ERROR = "OBSERVER_HIDE_ERROR"
export const OBSERVER_EMPTY = "OBSERVER_EMPTY"

export const SET_MODAL_FAILED_VALIDATION = "SET_MODAL_FAILED_VALIDATION"
export const UNSET_MODAL_FAILED_VALIDATION = "UNSET_MODAL_FAILED_VALIDATION"

export const SET_ACTIVITY_INDICATOR = "SET_ACTIVITY_INDICATOR"
export const UNSET_ACTIVITY_INDICATOR = "UNSET_ACTIVITY_INDICATOR"

// DRAG n' DROP
export const DROP_WELL = "DROP WELL type"
export const DROP_WELL_SEQUENCE = "DROP WELL_SEQUENCE type"

// PANELS
export const OPEN_PANEL_WITH_TYPE = "OPEN_PANEL_WITH_TYPE"
export const CLOSE_PANEL = "CLOSE_PANEL"
export const PANEL_NEW_REQUEST = "PANEL_NEW_REQUEST"
export const NEXT_STEP_IN_PANEL = "NEXT_STEP_IN_PANEL"
export const INDEXED_STEP_IN_PANEL = "INDEXED_STEP_IN_PANEL"

// EDIT
export const TOGGLE_DRAGGING = "TOGGLE_DRAGGING"
export const UPDATE_DRAG_POS = "UPDATE_DRAG_POS"
export const SET_TEMPORARY_WELL = "SET_TEMPORARY_WELL"
export const CLEAR_DRAG_ELEMENT = "DROP CLEAR_DRAG_ELEMENT"
export const DELETE_TEMPORARY_WELL = "DELETE_TEMPORARY_WELL"
export const UPDATE_TEMPORARY_WELL = "UPDATE_TEMPORARY_WELL"
export const UPDATE_SCHEDULE_BASED_ON_PRIORITY = "UPDATE_SCHEDULE_BASED_ON_PRIORITY"

export const FETCH_DRAFTS = "FETCH_DRAFTS"
export const FETCH_PREVIOUS_DRAFTS = "overviewdata.FETCH_PREVIOUS_DRAFTS"
export const UPDATE_DRAFTS_LIST = "UPDATE_DRAFTS_LIST"
export const UPDATE_PREVIOUS_DRAFTS_LIST = "overviewdata.UPDATE_PREVIOUS_DRAFTS_LIST"
export const SAVE_DRAFT_META = "SAVE_DRAFT_META"
export const ARCHIVE_DRAFT = "ARCHIVE_DRAFT"
export const CREATE_NEW_DRAFT = "CREATE_NEW_DRAFT"
export const FETCH_MISSING_INPUTTERS = "FETCH_MISSING_INPUTTERS"
export const SAVE_MISSING_INPUTTERS = "SAVE_MISSING_INPUTTERS"

export const FETCH_MASTERS = "FETCH_MASTERS"
export const UPDATE_MASTERS_LIST = "UPDATE_MASTERS_LIST"

export const FETCH_USERS_RIGHTS_AND_ROLES = "FETCH_USERS_RIGHTS_AND_ROLES"
export const SET_USERS_RIGHTS_AND_ROLES = "SET_USERS_RIGHTS_AND_ROLES"
export const SET_USER_DATA = "SET_USER_DATA"
export const FETCH_USER_DATA = "FETCH_USER_DATA"
export const SET_DROP_PRIORITY = "SET_DROP_PRIORITY"

// MODALS
export const MODAL_TYPE_FLASH_MESSAGE = "modal/MODAL_TYPE_FLASH_MESSAGE"
export const MODAL_TYPE_MASTER_DUPLICATE = "modal/MODAL_TYPE_MASTER_DUPLICATE"
export const MODAL_TYPE_DRAFT_RENAME = "modal/MODAL_TYPE_DRAFT_RENAME"
export const MODAL_TYPE_DRAFT_DUPLICATE = "modal/MODAL_TYPE_DRAFT_DUPLICATE"
export const MODAL_TYPE_DRAFT_ARCHIVE = "modal/MODAL_TYPE_DRAFT_ARCHIVE"

export const SHOW_MODAL = "modal/SHOW_MODAL"
export const CLOSE_MODAL = "modal/CLOSE_MODAL"

// error
export const THROW_ERROR = "THROW_ERROR"
export const HIDE_ERROR = "HIDE_ERROR"
export const UNKNOWN_STATUS = "UNKNOWN_STATUS"
