import { TOGGLE_VIEW_FILTER, SET_VIEW_FILTER, SET_VIEW_FILTER_TO_STORED_VERSION } from "../types"

export function toggleViewFilter(filter) {
  return { type: TOGGLE_VIEW_FILTER, filter }
}

export function setViewFilter(filter) {
  return { type: SET_VIEW_FILTER, filter }
}

export function setViewFilterToStoredVersion() {
  return { type: SET_VIEW_FILTER_TO_STORED_VERSION }
}
