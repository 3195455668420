/* eslint-disable import/prefer-default-export */
import { OBSERVER_ERROR, OBSERVER_HIDE_ERROR, OBSERVER_EMPTY } from "../types"

export function observerError(payload, error) {
  return { type: OBSERVER_ERROR, payload, error }
}
export function hideObserverError() {
  return { type: OBSERVER_HIDE_ERROR }
}
export function observerEmpty() {
  return { type: OBSERVER_EMPTY }
}
