/* eslint-disable import/prefer-default-export */
import { from, of } from "rxjs"
import { ofType } from "redux-observable"
import { map, switchMap, catchError } from "rxjs/operators"
import * as types from "../types"
import { observerEmpty, observerError } from "../actions/observer"
import LG from "../../lib/localeStorage"

export const saveFiltersObserver = (action$) =>
  action$.pipe(
    ofType(types.SET_VIEW_FILTER),
    switchMap((action) => from(LG.saveProp("filter", action.filter)).pipe(catchError((err) => of(observerError(err.xhr.response))))),
    map(() => observerEmpty())
  )
