import { addDays, format } from "date-fns"
import parseISO from "date-fns/parseISO"
import { cloneDeep } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { interval, Subject } from "rxjs"
import { throttle } from "rxjs/operators"

import SelectWellForCampaignUpdate from "../../../containers/panel/subPages/SelectWellForCampaignUpdate"
import { DATE_FORMATE_NB_SHORT } from "../../../dateConsts"
import { ActionCreators } from "../../../redux/actions"
import AddWellButton from "../../buttons/AddWellButton"
import DNDCSingleWellContainer from "../DNDCSingleWellContainer"

const width = 150

class DragnDropContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dragElm: {},
      mouseX: 0,
      editDetails: false,
      showExistingWellList: false,
      expanded: false,
      scrollLeft: 0,
    }

    this.calculatePosition = false
    this.containerRef = React.createRef()
    this.speed = 2
    this.dragDomElements = {}
    this.prevPos = 0
  }

  componentDidMount = () => {
    const { data } = this.props
    if (data.wells.length === 1) {
      this.setEditWellMode(data.wells[0])
    }

    this.parentPos = this.containerRef.current.getBoundingClientRect()
    this.onEnterFrame()

    this.dragHandler = new Subject().pipe(throttle(() => interval(50)))

    this.dragHandler.subscribe((val) => this.onDragOverHandler(val))
  }

  componentDidUpdate = (prevProps) => {
    const { data, wellLength } = this.props

    if (wellLength !== prevProps.wellLength) {
      this.setState({
        wells: data.wells,
        wellsLength: data.wells.length,
      })
      this.calculatePosition = true
      this.parentPos = this.containerRef.current.getBoundingClientRect()
      window.requestAnimationFrame(this.onEnterFrame)
    }
  }

  setDragElm = (id, index, element, offsetX) => {
    this.setState({
      dragElm: { id, index, element },
      offsetX,
    })
  }

  onDrop = () => {
    const { dragElm } = this.state
    const { updateWellsAfterDragAndDrop } = this.props
    const xPos = dragElm.index * (width + 5)
    dragElm.element.style.transform = `translate3d(${xPos}px, 0, 0)`

    this.setState({
      dragElm: {},
      mouseX: 0,
      offsetX: 0,
    })

    this.dragDomElements = {}

    updateWellsAfterDragAndDrop()
  }

  arrayMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1
      while (k > 0) {
        k -= 1
      }
    }

    if (newIndex < arr.length) {
      const newObj = arr.splice(oldIndex, 1)[0]

      if (newObj) {
        arr.splice(newIndex, 0, newObj)
      }
    }

    arr.forEach((e, k) => {
      e.sequence_priority = k
    })

    return arr
  }

  onDragOverHandler = (e) => {
    const { dragElm, offsetX, scrollLeft } = this.state
    const { data } = this.props

    const { clientX } = e.nativeEvent

    const x = clientX + scrollLeft

    const parentX = this.parentPos.left

    const xPos = Math.floor(Math.max(Math.min(parentX ? x - parentX - offsetX : x, this.parentPos.width), 0))

    if (this.prevPos !== xPos) {
      const newIndex = Math.floor(xPos / width)
      if (newIndex !== dragElm.index) {
        const newDragElm = cloneDeep(dragElm)
        newDragElm.index = newIndex

        this.setState({
          wells: this.arrayMove(data.wells, dragElm.index, newIndex),
          dragElm: newDragElm,
        })
      }

      if (!this.calculatePosition) {
        this.calculatePosition = true
        window.requestAnimationFrame(this.onEnterFrame)
      }

      dragElm.element.style.transform = `translate3d(${xPos}px, 0, 0)`
      this.prevPos = xPos
    }
  }

  onDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()

    this.dragHandler.next(e)
  }

  getTranslate3d = (el) => {
    const values = el.style.transform.split(/\w+\(|\);?/)
    if (!values[1] || !values[1].length) {
      return []
    }

    const numbers = values[1].split(/,\s?/g).map((val) => parseInt(val.replace("px", ""), 0))
    return numbers
  }

  onEnterFrame = () => {
    const { dragElm } = this.state
    const {
      data: { wells },
    } = this.props

    let well
    let xPos
    let numberCompleted = 0

    wells.forEach((val, key) => {
      if (!this.dragDomElements[val.id]) {
        this.dragDomElements[val.id] = {
          dom: document.getElementById(`dragme-${val.id}`),
          posX: undefined,
        }
      }

      well = this.dragDomElements[val.id].dom
      xPos = key * (width + 5)

      if (val.id !== dragElm.id) {
        if (this.dragDomElements[val.id].posX === undefined) {
          this.dragDomElements[val.id].posX = this.getTranslate3d(well)[0] !== undefined ? this.getTranslate3d(well)[0] : xPos
        }

        let prevXPos = Math.floor(Math.abs(this.dragDomElements[val.id].posX - xPos) < 0.005 ? xPos : this.dragDomElements[val.id].posX)

        if (prevXPos === xPos) numberCompleted += 1

        prevXPos += (xPos - prevXPos) / this.speed
        this.dragDomElements[val.id].posX = prevXPos

        well.style.transform = `translate3d(${prevXPos}px, 0, 0)`
      }
    })

    if (numberCompleted === wells.length) {
      this.calculatePosition = false
    } else {
      window.requestAnimationFrame(this.onEnterFrame)
    }
  }

  setEditCampaignMode = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { setMode } = this.props
    setMode({
      type: "campaign",
    })
  }

  setEditWellMode = (data) => {
    const { setMode, mode } = this.props
    if (mode.id === data.id) {
      setMode({
        type: "campaign",
      })
    } else {
      setMode({
        type: "edit",
        id: data.id,
        expanded: mode.expanded,
        data,
      })

      this.setState({
        expanded: true,
      })
    }
  }

  addExistingWell = () => {
    this.setState({
      showExistingWellList: true,
    })
  }

  addExistingWellToRigBooking = (wellId) => {
    const { addExistingWellToRigBooking } = this.props
    this.setState({
      showExistingWellList: false,
    })

    addExistingWellToRigBooking(wellId)
  }

  onScroll = (e) => {
    this.setState({
      scrollLeft: e.target.scrollLeft,
    })
  }

  render() {
    const { dragElm, rigMove, showExistingWellList } = this.state
    const { mode, handleInlineEstimateChange, addWell, data, duplicateWell, deleteWell, selectedBuilderPVersion, extractWell } = this.props

    const totalEstimate = data.wells
      .filter((w) => !w.to_be_removed)
      .reduce((accumulator, a) => accumulator + Number(a.estimateData[selectedBuilderPVersion].estimate), 0)

    const extendedWidth = data.wells.length * (width + 5)
    const markerWidth = 40 + data.wells.length * (width + 5)

    let entryStartDateObj
    let endDate
    let newEndDate
    let formatedSDate
    let formatedEDate
    let formatedNewEDate
    let estimate

    if (data.estimateData && data.estimateData[selectedBuilderPVersion].start_date) {
      ;({ estimate } = data.estimateData[selectedBuilderPVersion])
      entryStartDateObj = parseISO(data.estimateData[selectedBuilderPVersion].start_date)
      endDate = addDays(entryStartDateObj, data.estimateData[selectedBuilderPVersion].estimate)
      newEndDate = addDays(entryStartDateObj, totalEstimate)

      formatedSDate = entryStartDateObj ? format(entryStartDateObj, DATE_FORMATE_NB_SHORT) : null
      formatedEDate = endDate ? format(endDate, DATE_FORMATE_NB_SHORT) : null
      formatedNewEDate = format(newEndDate, DATE_FORMATE_NB_SHORT)
    }

    let wellEstimateAggregated = 0
    return (
      <>
        {showExistingWellList && (
          <div className="well-list">
            <div className={"well-list--wrapper"}>
              <SelectWellForCampaignUpdate addExistingWellToRigBooking={this.addExistingWellToRigBooking} wellsInCampaign={data.wells} />
            </div>
          </div>
        )}

        <div id="ddp" className={`Dragdrop__Container${mode.expanded ? " expanded" : ""}`} onScroll={this.onScroll}>
          <div className="Estimate-marker" style={{ width: markerWidth }}>
            <div className="Estimate-marker__datewrapper Estimate-marker__datewrapper--start-date">
              <div className="Estimate-marker__label">Start</div>
              <div className="Estimate-marker__date">{formatedSDate}</div>
            </div>
            {data.wells.length > 1 ? (
              <div className="Estimate-marker__estimate">
                <span
                  style={estimate && estimate !== totalEstimate ? { top: "-15px", opacity: 0.5 } : { top: 0, opacity: 0 }}
                  className="Estimate-marker__oldestimate"
                >
                  {estimate} days
                </span>
                {`${totalEstimate} days`}
              </div>
            ) : null}
            <div className="Estimate-marker__datewrapper Estimate-marker__datewrapper--end-date">
              <div className="Estimate-marker__label">End</div>
              <div className="Estimate-marker__date">
                {formatedNewEDate}
                <span
                  style={estimate && estimate !== totalEstimate ? { top: "28px", opacity: 0.5 } : { top: "15px", opacity: 0 }}
                  className="Estimate-marker__oldenddate"
                >
                  {formatedEDate}
                </span>
              </div>
            </div>
          </div>
          <div
            ref={this.containerRef}
            className="Dragdrop__wells-container"
            style={{ width: extendedWidth }}
            onDrop={(e) => this.onDrop(e)}
            onDragOver={(e) => this.onDragOver(e)}
          >
            {data.wells.map((val, key) => {
              const well = (
                <DNDCSingleWellContainer
                  selectedElm={dragElm}
                  index={key}
                  id={val.id}
                  key={val.id}
                  onClick={this.setEditWellMode}
                  data={val}
                  p10estimate={(val.estimateData.p10.estimate || "").toString()}
                  p50estimate={(val.estimateData.p50.estimate || "").toString()}
                  p90estimate={(val.estimateData.p90.estimate || "").toString()}
                  p10OriginalEstimate={(val.estimateData.p10.original_estimate || "").toString()}
                  p50OriginalEstimate={(val.estimateData.p50.original_estimate || "").toString()}
                  p90OriginalEstimate={(val.estimateData.p90.original_estimate || "").toString()}
                  actualDuration={(val.actual_duration || "").toString()}
                  status={val.status}
                  duplicateWell={duplicateWell}
                  deleteWell={deleteWell}
                  extractWell={extractWell}
                  selectedBuilderPVersion={selectedBuilderPVersion}
                  calculatedStartDate={entryStartDateObj}
                  rigMove={rigMove}
                  width={width}
                  wellEstimateAggregated={wellEstimateAggregated}
                  handleInlineEstimateChange={handleInlineEstimateChange}
                  selected={mode}
                  hasSelection={mode.type !== "campaign"}
                  dragEnabled={data.wells.length > 1}
                  extractEnabled={data.wells.length > 1 && val.id > 0}
                  inactive={!!(dragElm && val.id !== dragElm.id)}
                  setDragElm={this.setDragElm}
                />
              )
              wellEstimateAggregated += val.estimateData[selectedBuilderPVersion].estimate
              return well
            })}
          </div>
          <AddWellButton
            addWell={addWell}
            addExistingWell={this.addExistingWell}
            btnText={data.wells.length > 1 ? "Add a well to this campaign" : "Add a well to create campaign"}
          />
        </div>
      </>
    )
  }
}

DragnDropContainer.propTypes = {
  data: PropTypes.object,
  popOverData: PropTypes.object,
  mode: PropTypes.object,
  wellLength: PropTypes.number,
  selectedBuilderPVersion: PropTypes.string,
  setMode: PropTypes.func,
  addWell: PropTypes.func,
  duplicateWell: PropTypes.func,
  deleteWell: PropTypes.func,
  handleInlineEstimateChange: PropTypes.func,
  updateWellsAfterDragAndDrop: PropTypes.func,
  addExistingWellToRigBooking: PropTypes.func,
  extractWell: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    popOverData: state.calendar.popOverData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DragnDropContainer)
