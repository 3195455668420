import React, { PureComponent } from "react"
import Overview from "../../containers/Overview"

class OverviewPage extends PureComponent {
  render() {
    return <Overview />
  }
}

export default OverviewPage
