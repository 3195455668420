import PropTypes from "prop-types"
import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ZoomButton from "../../../components/Scheduler/Calendar/zoom/ZoomButton"
import PSelector from "../../../components/Scheduler/PSelector"
import { ActionCreators } from "../../../redux/actions"
import * as types from "../../../redux/types"

class SchedulerTopZoom extends PureComponent {
  render() {
    const { user, zoomName, domain } = this.props

    let zoomButtons = (
      <div className="SchedulerTop__Zoom--years_buttonsWrapper">
        <div key={"Zoom--heading"} className="SchedulerTop__Zoom--years_desc">
          years
        </div>
        <div key={"Zoom--wrapper"} className="SchedulerTop__Zoom--years_buttons">
          <ZoomButton key={types.DURATION_ONE_YEAR} duration={types.DURATION_ONE_YEAR} text={"1"} />
          <ZoomButton key={types.DURATION_TWO_YEAR} duration={types.DURATION_TWO_YEAR} text={"2"} />
          <ZoomButton key={types.DURATION_THREE_YEAR} duration={types.DURATION_THREE_YEAR} text={"3"} />
          <ZoomButton key={types.DURATION_FOUR_YEAR} duration={types.DURATION_FOUR_YEAR} text={"4"} />
          <ZoomButton key={types.DURATION_FIVE_YEAR} duration={types.DURATION_FIVE_YEAR} text={"5"} />
        </div>
      </div>
    )
    if (user.accessLevel < 2) {
      zoomButtons = (
        <div className="SchedulerTop__Zoom--years_buttonsWrapper">
          <div key={"Zoom--heading"} className="SchedulerTop__Zoom--years_desc">
            months
          </div>
          <div key={"Zoom--wrapper"} className="SchedulerTop__Zoom--years_buttons">
            <ZoomButton key={types.DURATION_ONE_MONTH} duration={types.DURATION_ONE_MONTH} text={"1"} />
            <ZoomButton key={types.DURATION_ONE_YEAR} duration={types.DURATION_ONE_YEAR} text={"12"} />
            <ZoomButton key={types.DURATION_18_MONTHS} duration={types.DURATION_18_MONTHS} text={"18"} />
          </div>
        </div>
      )
    }

    if (user.accessLevel > 1 && user.accessLevel < 2) {
      // Extended viewer
      zoomButtons = (
        <div className="SchedulerTop__Zoom--years_buttonsWrapper">
          <div key={"Zoom--heading"} className="SchedulerTop__Zoom--years_desc">
            months
          </div>
          <div key={"Zoom--wrapper"} className="SchedulerTop__Zoom--years_buttons">
            <ZoomButton key={types.DURATION_ONE_MONTH} duration={types.DURATION_ONE_MONTH} text={"1"} />
            <ZoomButton key={types.DURATION_18_MONTHS} duration={types.DURATION_18_MONTHS} text={"18"} />
            <ZoomButton
              key={types.DURATION_EXTENDED_VIEW}
              duration={types.DURATION_EXTENDED_VIEW}
              text={domain && domain.data ? String(domain.data.extendedViewerMonths) : ""}
            />
          </div>
        </div>
      )
    }

    return (
      <div className="SchedulerTop__Zoom">
        <div className="SchedulerTop__Zoom__Selector__Container">
          <PSelector></PSelector>
          <div className={`SchedulerTop__Zoom--years${zoomName !== "DURATION_ONE_MONTH" ? " active" : ""}`}>{zoomButtons}</div>
        </div>
        {user.role === "Viewer" ? null : (
          <div className={`SchedulerTop__Zoom--month_button${zoomName === "DURATION_ONE_MONTH" ? " active" : ""}`}>
            <ZoomButton key={types.DURATION_ONE_MONTH} duration={types.DURATION_ONE_MONTH} text={"month"} />
          </div>
        )}
      </div>
    )
  }
}

SchedulerTopZoom.propTypes = {
  user: PropTypes.object,
  zoomName: PropTypes.string,
  domain: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    user: state.user,
    zoomName: state.calendar.zoomName,
    domain: state.application.domain,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerTopZoom)
