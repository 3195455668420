import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"

import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../../redux/actions"
import PanelFooter from "../../../../components/panel/PanelFooter"
import PH from "../../../../lib/PanelHelper"

class RemoveRequest extends Component {
  constructor(props) {
    super(props)

    const { requestToEdit } = props

    this.state = {
      pages: props.pages,
      options: props.options,
      postData: requestToEdit || {},
      validated: {},
    }
  }

  componentDidMount() {
    const {
      panelOpen: { index },
      panelData,
    } = this.props
    const { pages, validated } = this.state

    if (typeof panelData !== "undefined") {
      this.setState({
        postData: panelData,
      })
    }

    // TODO: if object has prod that it does not need to be validated
    if (!validated[index]) {
      if (pages[index].options.validated) {
        this.setSubPageValidated(index, pages[index].options.validated)
      } else {
        this.setSubPageValidated(index, false)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      panelOpen: { index },
    } = this.props
    const { pages, validated } = this.state

    // TODO: if object has prod that it does not need to be validated
    if (index !== prevProps.panelOpen.index) {
      if (!validated[index]) {
        if (pages[index].options.validated) {
          this.setSubPageValidated(index, pages[index].options.validated)
        }
      }
    }
  }

  onSubmit = () => {
    const { removeRequest } = this.props
    removeRequest()
  }

  setSubPageValidated = (index, isValidated) => {
    const validated = cloneDeep(this.state.validated)

    validated[index] = isValidated

    this.setState({
      validated,
    })
  }

  getPage = (index, pages, postData) =>
    PH.getPage(
      index,
      pages[index],
      (val) => this.onUpdate(val),
      (i, val) => this.setSubPageValidated(i, val),
      postData
    )

  render() {
    const { pages, validated } = this.state
    const {
      panelOpen: { index },
      requestToRemove,
    } = this.props
    return [
      <div key={"Panel__Page"} className="Panel__Page ">
        <div className="Panel__Page-Container PanelContent">{this.getPage(index, pages, requestToRemove)}</div>
        <PanelFooter index={index} data={pages[index]} validated={validated[index]} onSubmit={(val) => this.onSubmit(val)} />
      </div>,
    ]
  }
}

RemoveRequest.propTypes = {
  panelOpen: PropTypes.object,
  requests: PropTypes.object,
  options: PropTypes.object,
  requestToRemove: PropTypes.object,
  requestToEdit: PropTypes.object,
  panelData: PropTypes.object,
  removeRequest: PropTypes.func,
  pages: PropTypes.array,
  index: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    panelOpen: state.application.panelOpen,
    requestToRemove: state.requestData.requestToRemove,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveRequest)
