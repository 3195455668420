import React, { Component } from "react"
import PropTypes from "prop-types"
import Select from "react-select"

class PanelInputSelection extends Component {
  render() {
    const { options, value, onChange, placeholder, optionLabel, isClearable, isDisabled, isMulti, classes } = this.props

    const active = value ? "active" : ""
    const clearable = !!isClearable
    const disabled = !!isDisabled
    const multi = !!isMulti

    return (
      <Select
        key={value || Math.random()}
        options={options}
        closeMenuOnSelect={true}
        isClearable={clearable}
        placeholder={placeholder}
        isDisabled={disabled}
        isMulti={multi}
        className={`Select__Dropdown ${active} ${classes || ""}`}
        value={value}
        getOptionLabel={(option) => option[optionLabel || "name"]}
        classNamePrefix="Select__Dropdown__Elm"
        onChange={onChange}
      />
    )
  }
}

PanelInputSelection.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  optionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  classes: PropTypes.string,
}

export default PanelInputSelection
