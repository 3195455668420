import { Rigs } from "../../types/rigs"
import Drillship from "../../../assets/images/svg/icons/Drillship.svg"
import JackUp from "../../../assets/images/svg/icons/Jack-up-only.svg"
import Semisub from "../../../assets/images/svg/icons/Semisub.svg"
import Platform from "../../../assets/images/svg/icons/Platform.svg"
import Landrig from "../../../assets/images/svg/icons/Landrig.svg"
import IconContainer from "./IconContainer"

type Props = {
  rigtype: Rigs
  height?: number
  width?: number
}

export const RigTypeIcon = ({ rigtype, height = 14, width = 14 }: Props) => {
  switch (rigtype) {
    case "drillship":
      return IconContainer(Drillship, width, height)
    case "jack-up":
      return IconContainer(JackUp, width, height)
    case "semi-sub":
      return IconContainer(Semisub, width, height)
    case "platform":
      return IconContainer(Platform, width, height)
    case "land_rig":
      return IconContainer(Landrig, width, height)
    default:
      return IconContainer(JackUp, width, height)
  }
}

export default RigTypeIcon
