import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

import ContentList from "../Bits/ContentList"

class EditAreas extends Component {
  componentDidMount() {
    const { getAreaList } = this.props
    getAreaList()
  }

  handleDeleteFormSubmit = (selectedItems) => {
    const { deleteItems } = this.props
    deleteItems("area", selectedItems)
  }

  editItem = (item) => {
    const { editSingleItem } = this.props
    editSingleItem("area", item)
  }

  addItem = () => {
    const { editSingleItem } = this.props
    editSingleItem("area", { name: "" })
  }

  render() {
    const { areas } = this.props

    return (
      <div className="ManageContent__Content">
        <ContentList
          addItem={this.addItem}
          editItem={this.editItem}
          deleteItemsFunc={this.handleDeleteFormSubmit}
          header={"Edit areas"}
          items={areas}
        />
      </div>
    )
  }
}

EditAreas.propTypes = {
  editSingleItem: PropTypes.func,
  getAreaList: PropTypes.func,
  areas: PropTypes.array,
  deleteItems: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    areas: state.managecontent.areas,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAreas)
