import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../../redux/actions"

class ZoomButton extends PureComponent {
  updateZoom() {
    const { duration, setZoomLevel } = this.props
    setZoomLevel(duration)
  }

  render() {
    const { text, duration, zoomName } = this.props

    const style = duration === zoomName ? "active" : ""

    return (
      <div className={`Zoom--Button ${style}`} onClick={() => this.updateZoom()}>
        {text}
      </div>
    )
  }
}

ZoomButton.propTypes = {
  setZoomLevel: PropTypes.func,
  zoomName: PropTypes.string,
  duration: PropTypes.string,
  text: PropTypes.string,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    zoomName: state.calendar.zoomName,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoomButton)
