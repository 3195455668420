import { cloneDeep } from "lodash"
import createReducer from "../../lib/createReducer"
import * as types from "../types"
import PH from "../../lib/PanelHelper"

export const initialState = {
  resize: 0,
  toolbarOpen: false,
  size: null,
  user: null,
  panelOpen: null,
  panelData: null,
  errorMessage: null,
  modalFailedValidation: false,
  activityIndicator: {},
  shakeIncrement: 0,
  errorConsoleOpen: false,
  domain: {},
}

export const application = createReducer(initialState, {
  // RESIZE
  [types.GET_DOMAIN_DATA](state, actions) {
    const ns = Object.assign({}, state)
    const domain = cloneDeep(ns.domain)
    ns.domain = domain
    return ns
  },
  [types.SET_DOMAIN_DATA](state, actions) {
    const ns = Object.assign({}, state)
    const domain = cloneDeep(ns.domain)
    domain.data = actions.data.data
    ns.domain = domain
    return ns
  },
  [types.SET_USER_DATA](state, actions) {
    const ns = Object.assign({}, state)
    ns.user = actions.user
    return ns
  },
  [types.RESIZE](state, actions) {
    const ns = Object.assign({}, state)
    ns.resize += 1
    ns.size = actions.size

    return ns
  },

  // TOOLBAR
  [types.SET_TOOLBAR_OPEN_STATE](state, actions) {
    const ns = Object.assign({}, state)
    ns.toolbarOpen = actions.open
    return ns
  },
  [types.TOGGLE_TOOLBAR_OPEN_STATE](state) {
    const ns = Object.assign({}, state)
    ns.toolbarOpen = !ns.toolbarOpen
    return ns
  },
  [types.OPEN_PANEL_WITH_TYPE](state, actions) {
    const ns = Object.assign({}, state)

    ns.panelOpen = PH.getPanelConfig(actions.panel)
    const pd = cloneDeep(actions.data)
    ns.panelData = pd

    return ns
  },
  [types.NEXT_STEP_IN_PANEL](state) {
    const ns = Object.assign({}, state)
    const panelOpen = cloneDeep(ns.panelOpen)

    const nextId = panelOpen.index + 1

    if (nextId < panelOpen.pages.length) {
      panelOpen.index = nextId
    } else {
      panelOpen.index = 0
    }

    ns.panelOpen = panelOpen

    return ns
  },
  [types.INDEXED_STEP_IN_PANEL](state, actions) {
    const ns = Object.assign({}, state)
    const panelOpen = cloneDeep(ns.panelOpen)

    panelOpen.index = actions.index
    ns.panelOpen = panelOpen

    return ns
  },
  [types.CLOSE_PANEL](state) {
    const ns = Object.assign({}, state)
    ns.panelOpen = null
    return ns
  },
  [types.THROW_ERROR](state, actions) {
    const ns = Object.assign({}, state)
    ns.errorMessage = actions.error
    return ns
  },
  [types.HIDE_ERROR](state) {
    const ns = Object.assign({}, state)
    ns.errorMessage = null
    return ns
  },
  [types.SET_ACTIVITY_INDICATOR](state, actions) {
    const ns = Object.assign({}, state)
    const newObject = cloneDeep(ns.activityIndicator)
    newObject[actions.activity] = true
    ns.activityIndicator = newObject
    return ns
  },
  [types.UNSET_ACTIVITY_INDICATOR](state, actions) {
    const ns = Object.assign({}, state)
    const newObject = cloneDeep(ns.activityIndicator)
    delete newObject[actions.activity]
    ns.activityIndicator = newObject
    return ns
  },
  [types.SET_MODAL_FAILED_VALIDATION](state) {
    const ns = Object.assign({}, state)
    ns.modalFailedValidation = true
    return ns
  },
  [types.UNSET_MODAL_FAILED_VALIDATION](state) {
    const ns = Object.assign({}, state)
    ns.modalFailedValidation = false
    return ns
  },
  [types.SHAKE_BUILDER](state) {
    const ns = Object.assign({}, state)
    ns.shakeIncrement += 1
    return ns
  },
  [types.TOGGLE_ERROR_CONSOLE_OPEN](state, actions) {
    const ns = Object.assign({}, state)
    ns.errorConsoleOpen = actions.open // ? actions.open : !ns.errorConsoleOpen;
    return ns
  },
})
