import React from "react"
import Right from "../../assets/images/svg/icons/Right.svg"
import RigtypeJackupIcon from "../../assets/images/svg/icons/Jack-up-only.svg"
import RigtypeSemisubIcon from "../../assets/images/svg/icons/Semisub.svg"
import PressureIcon from "../../assets/images/svg/icons/HPHT.svg"
import ContractIcon from "../../assets/images/svg/icons/locked.svg"
import PlatformIcon from "../../assets/images/svg/icons/Platform.svg"

const IconUtils = {
  getRigIcon: (type) => {
    switch (type) {
      case "jack-up":
        return RigtypeJackupIcon
      case "semi-sub":
        return RigtypeSemisubIcon
      case "platform":
        return PlatformIcon
      case "hpht":
        return PressureIcon
      case "contract":
        return ContractIcon
      default:
        return Right
    }
  },
  getDropDownIcon: (type) => {
    switch (type) {
      case "active":
        return (
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs></defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Button/Dropdown/Active" transform="translate(-10.000000, -79.000000)" fill="#1C2226">
                <g id="Item-2-Copy" transform="translate(10.000000, 76.000000)">
                  <g id="Icons/Check/black" transform="translate(0.000000, 3.000000)">
                    <path
                      d="M15.4278468,5.03142357 L14.7948263,5.66486596 C15.0504692,6.39488701 15.1884352,7.18212483 15.1884352,8.00182607 C15.1884352,11.9623627 11.9624651,15.1884146 8.00202891,15.1884146 C4.03753487,15.1884146 0.811564798,11.9623627 0.811564798,8.00182607 C0.811564798,4.03723148 4.03753487,0.811585381 8.00202891,0.811585381 C9.6576211,0.811585381 11.1833629,1.37523143 12.3966523,2.3207284 L12.9769211,1.74450278 C11.6094344,0.652920439 9.88080142,0 8.00202891,0 C3.59117423,0 0,3.59085952 0,8.00182607 C0,12.4127926 3.59117423,16 8.00202891,16 C12.4128836,16 16,12.4127926 16,8.00182607 C16,6.95488093 15.7971088,5.94892085 15.4278468,5.03142357"
                      id="Fill-1"
                    ></path>
                    <path
                      d="M4.72372652,5.78968254 C4.64337151,5.71428571 4.53240507,5.67460317 4.42186379,5.67460317 C4.3155741,5.67460317 4.20503282,5.71428571 4.12467781,5.78968254 C3.95844073,5.94444444 3.95844073,6.1984127 4.12467781,6.3531746 L8.03146175,10 L15.1018521,3.40039683 L15.8756411,2.67857143 C16.041453,2.52380952 16.041453,2.27380952 15.8756411,2.11904762 C15.7906093,2.03968254 15.6843196,2 15.5742035,2 C15.4679138,2 15.3573725,2.03968254 15.2761672,2.11904762 L14.5831584,2.76190476 L13.9798581,3.32936508 L8.03146175,8.87698413 L4.72372652,5.78968254 Z"
                      id="Fill-4"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        )
      case "inactive":
        return (
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs></defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Button/Dropdown/Active" transform="translate(-10.000000, -114.000000)" fill="#B2C2CC">
                <g id="Item-3-Copy" transform="translate(10.000000, 111.000000)">
                  <g id="Icons/Check/Uncheck-Button-white" transform="translate(0.000000, 3.000000)">
                    <path
                      d="M8,0.811605965 C4.03611647,0.811605965 0.811605965,4.03611647 0.811605965,8 C0.811605965,11.9634777 4.03611647,15.188394 8,15.188394 C11.9638835,15.188394 15.188394,11.9634777 15.188394,8 C15.188394,4.03611647 11.9638835,0.811605965 8,0.811605965 M8,16 C3.58892158,16 0,12.4110784 0,8 C0,3.58892158 3.58892158,0 8,0 C12.4110784,0 16,3.58892158 16,8 C16,12.4110784 12.4110784,16 8,16"
                      id="Fill-1-Copy"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        )
      default:
        return null
    }
  },
}
export default IconUtils
