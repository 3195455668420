import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"

class PanelHeaderButton extends PureComponent {
  render() {
    const { handleClick, value } = this.props

    return (
      <button className={"Panel__Header-Button"} onClick={() => handleClick()}>
        {value}
      </button>
    )
  }
}

PanelHeaderButton.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    panelOpen: state.application.panelOpen,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelHeaderButton)
