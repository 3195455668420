import createReducer from "../../lib/createReducer"
import * as types from "../types"

export const initialState = {
  blocks: [],
  userlists: [],
  wells: [],
  licenses: [],
  assets: [],
  countries: [],
  rigs: [],
  areas: [],
  messages: { active: [], inactive: [] },
  modal: null,
  deleteItemType: "",
  deleteItems: [],
  editItemType: "",
  editItem: null,
  editUserListItem: null,
  updatedUserslistItem: null,
  updatedEditItem: null,
  settings: [],
  cognitousers: { users: [], groups: [] },
}

export const managecontent = createReducer(initialState, {
  [types.GET_BLOCK_LIST](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.GET_USERLIST_LIST](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SAVE_BLOCK_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.blocks = actions.blocks
    return ns
  },
  [types.SAVE_USERLIST_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.userlists = actions.userlists
    return ns
  },
  [types.GET_WELL_LIST](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SAVE_WELL_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.wells = actions.wells
    return ns
  },
  [types.GET_RIG_LIST](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SAVE_RIG_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.rigs = actions.rigs
    return ns
  },
  [types.GET_APP_SETTINGS](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SAVE_APP_SETTINGS](state, actions) {
    const ns = Object.assign({}, state)
    ns.settings = actions.settings
    return ns
  },
  [types.GET_LICENSE_LIST](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SAVE_LICENSE_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.licenses = actions.licenses
    return ns
  },
  [types.GET_ASSET_LIST](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SAVE_ASSET_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.assets = actions.assets
    return ns
  },
  [types.GET_AREA_LIST](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SAVE_AREA_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.areas = actions.assets
    return ns
  },
  [types.GET_COUNTRY_LIST](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SAVE_COUNTRY_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.countries = actions.countries
    return ns
  },
  [types.DELETE_ITEM_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.deleteItemType = actions.itemType
    ns.deleteItems = actions.items
    return ns
  },
  [types.DELETE_USER_LISTS](state, actions) {
    const ns = Object.assign({}, state)
    ns.deleteItems = actions.items
    return ns
  },
  [types.EDIT_SINGLE_ITEM](state, actions) {
    const ns = Object.assign({}, state)
    ns.editItemType = actions.itemType
    ns.editItem = actions.item
    return ns
  },
  [types.EDIT_USERLIST_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.editUserListItem = actions.item
    ns.editItemType = "userlist"
    return ns
  },
  [types.CLEAR_DELETE_ITEM_LIST](state) {
    const ns = Object.assign({}, state)
    ns.deleteItemType = ""
    ns.deleteItems = []
    return ns
  },
  [types.CLEAR_SINGLE_ITEM_EDIT](state) {
    const ns = Object.assign({}, state)
    ns.editItemType = ""
    ns.editItem = null
    ns.editUserListItem = null
    return ns
  },
  [types.SAVE_SINGLE_ITEM_EDIT](state, actions) {
    const ns = Object.assign({}, state)
    ns.updatedEditItem = actions.item
    return ns
  },
  [types.SAVE_USERLIST_LIST_EDIT](state, actions) {
    const ns = Object.assign({}, state)
    ns.updatedUserslistItem = actions.item
    return ns
  },
  [types.GET_MESSAGE_LIST](state) {
    return state
  },
  [types.GET_MESSAGE_LIST_FOR_USER](state) {
    return state
  },
  [types.SAVE_MESSAGE_LIST](state, actions) {
    const ns = Object.assign({}, state)
    ns.messages = actions.messages
    return ns
  },
  [types.SAVE_MESSAGE_LIST_FOR_USER](state, actions) {
    const ns = Object.assign({}, state)
    ns.modal = actions.messages
    ns.modal.type = types.MODAL_TYPE_FLASH_MESSAGE
    return ns
  },
  [types.SAVE_MESSAGE](state) {
    return state
  },
  [types.SET_MESSAGE_READ](state) {
    return state
  },
  [types.SHOW_MODAL](state, actions) {
    const ns = Object.assign({}, state)
    ns.modal = {}
    ns.modal.data = actions.data
    ns.modal.type = actions.modalType
    return ns
  },
  [types.CLOSE_MODAL](state) {
    const ns = Object.assign({}, state)
    ns.modal = {}
    return ns
  },

  // Cognito user management
  [types.CU_SAVE_GROUP_LIST](state, action) {
    const ns = Object.assign({}, state)
    ns.cognitousers = {
      ...ns.cognitousers,
      err: null,
      groups: [],
    }
    ns.cognitousers.groups = action.payload
    return ns
  },
  [types.CU_SAVE_LIST](state, action) {
    const ns = Object.assign({}, state)
    ns.cognitousers = {
      ...ns.cognitousers,
      err: null,
      users: [],
    }
    ns.cognitousers.users = action.payload.map((u) => ({
      id: u.username,
      err: null,
      ...u,
    }))
    return ns
  },
  [types.CU_DELETED_SINGLE_ITEM](state, action) {
    const ns = Object.assign({}, state)
    ns.cognitousers = {
      ...ns.cognitousers,
      users: state.cognitousers.users.filter((u) => u.id !== action.payload),
      err: null,
    }
    return ns
  },
  [types.CU_ERROR](state, action) {
    const ns = Object.assign({}, state)
    ns.cognitousers = {
      ...ns.cognitousers,
      err: action.payload.message,
    }
    return ns
  },
  [types.CU_UPDATED_ITEM](state, action) {
    const ns = Object.assign({}, state)
    ns.cognitousers = {
      ...ns.cognitousers,
      users: state.cognitousers.users.map((u) => (u.id === action.payload.sub ? action.payload : u)),
      err: null,
    }
    return ns
  },
})
