import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

import ContentList from "../Bits/ContentList"

class EditLicenses extends Component {
  componentDidMount() {
    const { getLicenseList } = this.props
    getLicenseList()
  }

  handleDeleteFormSubmit = (selectedItems) => {
    const { deleteItems } = this.props
    deleteItems("license", selectedItems)
  }

  editItem = (item) => {
    const { editSingleItem } = this.props
    editSingleItem("license", item)
  }

  addItem = () => {
    const { editSingleItem } = this.props
    editSingleItem("license", { name: "" })
  }

  render() {
    const { licenses } = this.props

    return (
      <div className="ManageContent__Content">
        <ContentList
          addItem={this.addItem}
          editItem={this.editItem}
          deleteItemsFunc={this.handleDeleteFormSubmit}
          header={"Edit licenses"}
          items={licenses}
        />
      </div>
    )
  }
}

EditLicenses.propTypes = {
  editSingleItem: PropTypes.func,
  getLicenseList: PropTypes.func,
  deleteItems: PropTypes.func,
  licenses: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    licenses: state.managecontent.licenses,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLicenses)
