import React, { PureComponent } from "react"
import StatusModule from "../../components/Overview/StatusModule"
import NextScheduleModule from "../../components/Overview/NextScheduleModule"
import MastersModule from "../../components/Overview/MastersModule"

export class Overview extends PureComponent {
  render() {
    return (
      <div className="Overview">
        <StatusModule />
        <NextScheduleModule />
        <MastersModule />
      </div>
    )
  }
}

export default Overview
