import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import store from "store"
import { EDIT_RIG, REGISTER_NEW_RIG } from "../../../panelConsts"
import { ActionCreators } from "../../../redux/actions"
import Rig from "../../../components/Scheduler/SchedulerRigList/Rig"
import DarkModeSwitch from "../../../components/Scheduler/Calendar/DarkModeSwitch"
import RigPopover from "../../RigPopover"

class SchedulerRigList extends Component {
  constructor(props) {
    super(props)

    this.popoverRef = React.createRef()

    this.state = {
      showPopOver: false,
      selectedRig: null,
      popOvertop: 84,
      rigs: [],
    }

    this.scrollContainer = React.createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.PVersion.key !== this.props.PVersion.key) {
      return true
    }
    if (nextProps.rigData !== this.props.rigData) {
      return true
    }
    if (nextProps.darkMode !== this.props.darkMode) {
      return true
    }
    if (nextProps.scrollTop !== this.props.scrollTop) {
      return true
    }
    if (nextState.selectedRig !== this.state.selectedRig) {
      return true
    }
    if (nextProps.accessLevel !== this.props.accessLevel) {
      return true
    }
    if (nextProps.rigData.id !== this.props.rigData.id) {
      return true
    }

    return false
  }

  handleClickOutside = (event) => {
    if (this.popoverRef && this.popoverRef.current && !this.popoverRef.current.contains(event.target)) {
      this.closePopOver()
    }
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside)
  }

  componentDidUpdate = (prevProps) => {
    const { scrollTop } = this.props

    if (prevProps.scrollTop !== scrollTop) {
      this.scrollContainer.current.scrollTop = this.props.scrollTop
    }
  }

  closePopOver = () => {
    this.setState({
      showPopOver: false,
      selectedRig: null,
    })
  }

  openPopOver = (selectedRig, rigRef) => {
    this.setState({
      showPopOver: true,
      selectedRig,
      popOvertop: rigRef.current.offsetTop,
    })
  }

  editRig = (selectedRig) => {
    const { openPanelWithType } = this.props
    this.closePopOver()
    openPanelWithType(EDIT_RIG, selectedRig)
  }

  addRig = () => {
    const { openPanelWithType } = this.props
    openPanelWithType(REGISTER_NEW_RIG)
  }

  render() {
    const { rigData, readonly, accessLevel, syncScroll, darkMode, PVersion } = this.props
    const { selectedRig, showPopOver } = this.state
    const rigPopover = selectedRig ? (
      <RigPopover selectedRig={selectedRig} showPopOver={showPopOver} closePopOver={this.closePopOver} editRig={this.editRig} />
    ) : null

    const rigs = rigData.rigs.map((val, key) => (
      <Rig
        isRigSelected={this.state.selectedRig && val.rigData.id === this.state.selectedRig.id}
        onSelectRig={this.openPopOver}
        darkMode={darkMode}
        key={`${PVersion.key}-${val.id}-${key}`}
        {...val}
        id={val.id}
      />
    ))

    return (
      <div className="Scheduler--Overview__Rig-List-Container">
        <div className="space"></div>
        <DarkModeSwitch />
        <div ref={this.scrollContainer} className="Scheduler--Overview__Rig-List" onScroll={syncScroll}>
          {rigs}
          {accessLevel > 3 && (
            <div className="Scheduler--Overview__AddRig">
              <button onClick={readonly ? null : this.addRig} className={`Scheduler__Overview__AddRigButton ${readonly ? "readonly" : ""}`}>
                +
              </button>
            </div>
          )}
        </div>
        {rigPopover}
      </div>
    )
  }
}

SchedulerRigList.propTypes = {
  rigData: PropTypes.object,
  PVersion: PropTypes.object,
  openPanelWithType: PropTypes.func,
  syncScroll: PropTypes.func,
  readonly: PropTypes.bool,
  darkMode: PropTypes.bool,
  accessLevel: PropTypes.number,
  scrollTop: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    rigData: state.schedulerData.schedulerData.data,
    readonly: state.schedulerData.schedulerData.data ? state.schedulerData.schedulerData.data.readonly : true,
    PVersion: state.calendar.PVersion,
    darkMode: state.calendar.darkMode,
    accessLevel: state.user.accessLevel,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerRigList)
