import { cloneDeep } from "lodash"
import createReducer from "../../lib/createReducer"
import * as types from "../types"

export const initialState = {
  requests: {},
  requestToSetStatus: {},
  requestStatus: "",
  requestToEdit: null,
  requestToRemove: null,
  suggestions: {},
  previewSelectionSuggestion: {},
}

export const requestData = createReducer(initialState, {
  [types.GET_REQUESTS](state) {
    return state
  },
  [types.SAVE_REQUESTS](state, actions) {
    const ns = Object.assign({}, state)
    ns.requests = actions.data
    return ns
  },
  [types.EDIT_REQUEST](state, actions) {
    const ns = Object.assign({}, state)
    ns.requestToEdit = actions.request
    return ns
  },
  [types.EDIT_REQUEST_COMPLETE](state) {
    const ns = Object.assign({}, state)
    ns.requestToEdit = null
    return ns
  },
  [types.PREVIEW_REQUEST](state, actions) {
    const ns = Object.assign({}, state)
    ns.requestToEdit = actions.request
    return ns
  },
  [types.ACCEPT_REQUEST_PLACEMENT](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SET_REQUEST_STATUS](state, actions) {
    const ns = Object.assign({}, state)
    ns.requestToSetStatus = actions.request
    ns.requestStatus = actions.status
    return ns
  },
  [types.SET_REQUEST_RESOLVED](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SET_REQUEST_BLOCKED](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.CONFIRM_REMOVE_REQUEST](state, actions) {
    const ns = Object.assign({}, state)
    ns.requestToRemove = actions.request
    return ns
  },
  [types.REMOVE_REQUEST](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.GET_REQUEST_SUGGESTONS](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.SAVE_REQUEST_SUGGESTONS](state, actions) {
    const ns = Object.assign({}, state)
    ns.suggestions = actions.data
    return ns
  },
  [types.SET_PREVIEW_SELECTION](state, actions) {
    const ns = Object.assign({}, state)
    ns.previewSelectionSuggestion = actions.data
    return ns
  },
  [types.ADD_REQUEST_TO_SCHEDULE](state, actions) {
    const ns = Object.assign({}, state)

    const edit = cloneDeep(ns.requestToEdit)
    edit.start_date = actions.data.preview_start_date
    edit.p10 = actions.data.p10
    edit.p90 = actions.data.p90
    edit.priority = ns.previewSelectionSuggestion.priority
    ns.requestToEdit = edit

    return ns
  },
  [types.CLEAR_PREVIEW_SELECTION](state) {
    const ns = Object.assign({}, state)
    ns.suggestions = {}
    ns.previewSelectionSuggestion = {}
    return ns
  },
  [types.CLEAR_REQUEST_TO_REMOVE](state) {
    const ns = Object.assign({}, state)
    ns.requestToRemove = null
    return ns
  },
  [types.CLEAR_REQUEST_EDIT_DATA](state) {
    const ns = Object.assign({}, state)
    ns.requestToEdit = null
    return ns
  },
})
