import createReducer from "../../lib/createReducer"
import * as types from "../types"
import CU from "../../lib/CalendarUtils"

export const initialState = {
  header: "Master Draft",
  keyEvents: [],
  changes: [],
  summary: {
    month: "February",
    desc: "Decision Summary",
    icon: "page",
  },
  dateInfo: {},
  schedule: {},
  openSummary: false,
}

export const timeline = createReducer(initialState, {
  [types.GET_TIMELINE_DATA](state, actions) {
    const ns = Object.assign({}, state)
    ns.openSummary = actions.openSummary
    return ns
  },
  [types.SET_TIMELINE_DATA](state, actions) {
    const ns = Object.assign({}, state)
    const { changes, dateInfo, keyEvents, schedule, decisionSummary } = actions.data

    ns.changes = changes
    ns.dateInfo = dateInfo
    ns.dateInfo.dates = CU.getCalendarBasedOnTimeframe(dateInfo)
    ns.keyEvents = keyEvents
    ns.schedule = schedule
    ns.decisionSummary = decisionSummary

    return ns
  },
})
