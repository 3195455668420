import PropTypes from "prop-types"
import React, { Component } from "react"

import BuilderUtils from "../../../lib/BuilderUtils"
import ConditionalField, { ConditionalFieldBlock } from "../../common/ConditionalField"
import WellStatusName from "../../common/WellStatusName"

class EditDetailsPlanAndSelect extends Component {
  onFieldUpdate = (elem) => {
    const { onFieldUpdate, data } = this.props
    let { value } = elem.target
    const { checked } = elem.target
    if (elem.target.type === "checkbox") {
      value = !!checked
    }
    if (elem.target.type === "radio") {
      value = value === "true"
    }
    onFieldUpdate(elem.target.name, value, data.id)
  }

  render() {
    const { data } = this.props
    return (
      <ConditionalFieldBlock scope="well" fields={BuilderUtils.fieldsPrStatus("plan_and_select")}>
        <div id="plan_and_select" className="Details-edit__section">
          <h3>
            <WellStatusName statusKey={"plan_and_select"} />
          </h3>
          <div className="Details-edit__wrapper">
            <div className="Details-edit__part">
              <ConditionalField scope="well" field="lli_ordered">
                <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                  <label className="radio-input__mainlabel">
                    LLI ordered
                    <span className="radio-input__sublabel">(Long Lead Items)</span>
                  </label>
                  <div className="radio-input__optionwrapper">
                    <label className={`radio-input__option${data.well.lli_ordered ? " selected" : ""}`}>
                      <span className="radio-input__label">Yes</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.lli_ordered"
                        value={true}
                      />
                    </label>
                    <label className={`radio-input__option${data.well.lli_ordered ? "" : " selected"}`}>
                      <span className="radio-input__label">No</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.lli_ordered"
                        value={false}
                      />
                    </label>
                  </div>
                </div>
              </ConditionalField>
              <ConditionalField scope="well" field="cpt_ordered">
                <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                  <label className="radio-input__mainlabel">
                    CPT ordered
                    <span className="radio-input__sublabel">(Cone Penetration Test)</span>
                  </label>
                  <div className="radio-input__optionwrapper">
                    <label className={`radio-input__option${data.well.cpt_ordered ? " selected" : ""}`}>
                      <span className="radio-input__label">Yes</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.cpt_ordered"
                        value={true}
                      />
                    </label>
                    <label className={`radio-input__option${data.well.cpt_ordered === false ? " selected" : ""}`}>
                      <span className="radio-input__label">No</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.cpt_ordered"
                        value={false}
                      />
                    </label>
                    <label className={`radio-input__option${data.well.cpt_ordered === null ? " selected" : ""}`}>
                      <span className="radio-input__label">N/A</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.cpt_ordered"
                        value={"null"}
                      />
                    </label>
                  </div>
                </div>
              </ConditionalField>
              <ConditionalField scope="well" field="cpt_conducted">
                <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                  <label className="radio-input__mainlabel">
                    CPT conducted
                    <span className="radio-input__sublabel">(Cone Penetration Test)</span>
                  </label>
                  <div className="radio-input__optionwrapper">
                    <label className={`radio-input__option${data.well.cpt_conducted ? " selected" : ""}`}>
                      <span className="radio-input__label">Yes</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.cpt_conducted"
                        value={true}
                      />
                    </label>
                    <label className={`radio-input__option${data.well.cpt_conducted === false ? " selected" : ""}`}>
                      <span className="radio-input__label">No</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.cpt_conducted"
                        value={false}
                      />
                    </label>
                    <label className={`radio-input__option${data.well.cpt_conducted === null ? " selected" : ""}`}>
                      <span className="radio-input__label">N/A</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.cpt_conducted"
                        value={"null"}
                      />
                    </label>
                  </div>
                </div>
              </ConditionalField>
              <ConditionalField scope="well" field="site_survey_ordered">
                <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                  <label className="radio-input__mainlabel">Site survey ordered</label>
                  <div className="radio-input__optionwrapper">
                    <label className={`radio-input__option${data.well.site_survey_ordered ? " selected" : ""}`}>
                      <span className="radio-input__label">Yes</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.site_survey_ordered"
                        value={true}
                      />
                    </label>
                    <label className={`radio-input__option${data.well.site_survey_ordered ? "" : " selected"}`}>
                      <span className="radio-input__label">No</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.site_survey_ordered"
                        value={false}
                      />
                    </label>
                  </div>
                </div>
              </ConditionalField>
              <ConditionalField scope="well" field="site_survey_conducted">
                <div className={"Details-edit__radiowrapper radio-input radio-input--horizontal"}>
                  <label className="radio-input__mainlabel">Site survey conducted</label>
                  <div className="radio-input__optionwrapper">
                    <label className={`radio-input__option${data.well.site_survey_conducted ? " selected" : ""}`}>
                      <span className="radio-input__label">Yes</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.site_survey_conducted"
                        value={true}
                      />
                    </label>
                    <label className={`radio-input__option${data.well.site_survey_conducted ? "" : " selected"}`}>
                      <span className="radio-input__label">No</span>
                      <span className="check-indicator"></span>
                      <input
                        onChange={this.onFieldUpdate}
                        className="radio-input__radio"
                        type="radio"
                        name="well.site_survey_conducted"
                        value={false}
                      />
                    </label>
                  </div>
                </div>
              </ConditionalField>
            </div>
          </div>
        </div>
      </ConditionalFieldBlock>
    )
  }
}

EditDetailsPlanAndSelect.propTypes = {
  data: PropTypes.object,
  onFieldUpdate: PropTypes.func,
}

export default EditDetailsPlanAndSelect
