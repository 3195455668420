import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../../redux/actions"
import PanelFooter from "../../../../components/panel/PanelFooter"
import PanelHeader from "../../../../components/panel/PanelHeader"
import PanelMenu from "../../../../components/panel/PanelMenu"
import PH from "../../../../lib/PanelHelper"
import PanelHeaderButton from "../../../../components/buttons/PanelHeaderButton"
import { CONNECT_EXISTING_RIG } from "../../../../panelConsts"

class RegisterNewRig extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pages: props.pages,
      options: props.options,
      postData: {},
      validated: {},
    }
  }

  onUpdate = (obj, type) => {
    const { postData } = this.state

    const nPostData = cloneDeep(postData)

    Object.entries(obj).forEach(([key, val]) => {
      if (type === "array") {
        if (typeof nPostData[key] === "undefined") {
          nPostData[key] = []
        }
        if (nPostData[key].includes(val)) {
          const index = nPostData[key].indexOf(val)
          if (index !== -1) nPostData[key].splice(index, 1)
        } else {
          nPostData[key].push(val)
        }
      } else {
        nPostData[key] = val
      }
    })

    this.setState({
      postData: nPostData,
    })
  }

  onSubmit = () => {
    const { createNewRig, scheduleId } = this.props
    createNewRig({
      ...this.state.postData,
      scheduleId,
    })
  }

  setSubPageValidated = (index, isValidated) => {
    const validated = cloneDeep(this.state.validated)

    validated[index] = isValidated

    this.setState({
      validated,
    })
  }

  componentDidMount() {
    const {
      panelOpen: { index },
      panelData,
    } = this.props
    const { pages, validated } = this.state

    if (typeof panelData !== "undefined") {
      this.setState({
        postData: panelData,
      })
    }

    // TODO: if object has prod that it does not need to be validated
    if (!validated[index]) {
      if (pages[index].options.validated) {
        this.setSubPageValidated(index, pages[index].options.validated)
      } else {
        this.setSubPageValidated(index, false)
      }
    }
  }

  getPage = (index, pages, postData) =>
    PH.getPage(
      index,
      pages[index],
      (val, type) => this.onUpdate(val, type),
      (i, val) => this.setSubPageValidated(i, val),
      postData
    )

  deleteRig = () => {
    const { openPanelWithType } = this.props
    openPanelWithType(CONNECT_EXISTING_RIG)
  }

  render() {
    const { pages, validated, postData } = this.state
    const {
      panelOpen: { index },
    } = this.props

    const headerButtons = [<PanelHeaderButton key={"Delete-rig-button"} value={"Add existing »"} handleClick={this.deleteRig} />]

    return [
      <PanelHeader key={"PanelHeader"} buttons={headerButtons} />,
      <PanelMenu key={"PanelMenu"} />,
      <div key={"Panel__Page"} className="Panel__Page ">
        <div className="Panel__Page-Container PanelContent">{this.getPage(index, pages, postData)}</div>
        <PanelFooter index={index} data={pages[index]} validated={validated[index]} onSubmit={(val) => this.onSubmit(val)} />
      </div>,
    ]
  }
}

RegisterNewRig.propTypes = {
  panelOpen: PropTypes.object,
  panelData: PropTypes.object,
  createNewRig: PropTypes.func,
  options: PropTypes.object,
  pages: PropTypes.array,
  index: PropTypes.number,
  scheduleId: PropTypes.number,
  openPanelWithType: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    panelOpen: state.application.panelOpen,
    panelData: state.application.panelData,
    scheduleId: state.schedulerData.schedulerData.data.id,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterNewRig)
