import format from "date-fns/format"
import getYear from "date-fns/getYear"
import parseISO from "date-fns/parseISO"
import { cloneDeep } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { DATE_FORMATE_NB } from "../../../dateConsts"
import { ActionCreators } from "../../../redux/actions"
import { MODAL_TYPE_MASTER_DUPLICATE } from "../../../redux/types"
import ContextMenu from "../../ContextMenu"
import RoundedDropList from "../../dropdown/RoundedDropList"
import Master from "../../icons/Master"

const contextOptions = [{ name: "Duplicate", action: "duplicate" }]

class MastersModule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeContextMenu: "",
      activeDuplicateDraft: {},
      draftDuplicated: false,
      isDuplicating: false,
      thisYear: getYear(new Date()),
      selectedYear: getYear(new Date()),
    }

    this.timer = null
  }

  componentDidMount() {
    const { fetchMasters } = this.props
    fetchMasters()
  }

  triggerContextMenu = (elem, id) => {
    elem.preventDefault()
    elem.stopPropagation()

    const newId = this.state.activeContextMenu === id ? "" : id
    this.setState({
      activeContextMenu: newId,
    })
  }

  deselect = () => {
    this.setState({
      activeContextMenu: "",
    })
  }

  onYearChange = (e) => {
    const { fetchMasters } = this.props
    const { value } = e.target

    const year = parseInt(value, 0)

    this.setState({
      selectedYear: year,
    })

    fetchMasters(year)
  }

  handleContextSelect = (event, option, draft) => {
    event.preventDefault()
    event.stopPropagation()

    const { showModal } = this.props
    const activeDuplicateDraft = cloneDeep(draft)

    const modalData = {
      id: draft.id,
      name: draft.name,
      openModal: `duplicate-${draft.id}`,
      activeDuplicateDraft,
      activeContextMenu: "",
    }

    showModal(MODAL_TYPE_MASTER_DUPLICATE, modalData)
  }

  render() {
    const { mastersList, accessLevel } = this.props
    const { thisYear, selectedYear } = this.state
    const masters = mastersList || []

    const diff = thisYear - 2018
    const years = []
    for (let i = 0; i >= -diff; i -= 1) {
      years.push(i)
    }

    return (
      <div className="Overview__Module MastersModule">
        <div className="Overview__Module__Header__container">
          <h2 className="Overview__ModuleHeading">Masters</h2>
          <RoundedDropList
            defaultOpen={false}
            data={years}
            thisYear={thisYear}
            startYear={thisYear}
            selectedYear={selectedYear}
            onChange={this.onYearChange}
          />
        </div>
        <div className="MastersModule__list">
          {masters.map((val, key) => (
            <Link key={key} className="MastersModule__listitem" to={`/schedule/${val.id}`}>
              {key === 0 ? (
                <h4 className="MastersModule__listitem-subheader">
                  {Master(
                    {
                      width: "20px",
                      margin: "0px 5px 0 0",
                      position: "relative",
                      top: "3px",
                    },
                    `master-icon-${val.id}`
                  )}{" "}
                  Latest master
                </h4>
              ) : null}
              {accessLevel > 3 && (
                <span
                  onClick={(e) => this.triggerContextMenu(e, `context-menu-masterslist-${key}`)}
                  className="MastersModule__listitem-contexttrigger"
                >
                  &#8942;
                </span>
              )}
              <span className="MastersModule__listitem-header">{val.name}</span>
              <span className="MastersModule__listitem-date">Published {format(parseISO(val.master_from_date), DATE_FORMATE_NB)}</span>
              {accessLevel > 3 && (
                <ContextMenu
                  id={`context-menu-masterslist-${key}`}
                  options={contextOptions}
                  active={this.state.activeContextMenu === `context-menu-masterslist-${key}`}
                  deselect={this.deselect}
                  onSelect={(e, selected) => this.handleContextSelect(e, selected, val)}
                />
              )}
            </Link>
          ))}
        </div>
      </div>
    )
  }
}

MastersModule.propTypes = {
  mastersList: PropTypes.array,
  draftsList: PropTypes.array,
  fetchMasters: PropTypes.func,
  createNewDraft: PropTypes.func,
  showModal: PropTypes.func,
  accessLevel: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    mastersList: state.overviewData.mastersList,
    draftsList: state.overviewData.draftsList,
    accessLevel: state.user.accessLevel,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MastersModule))
