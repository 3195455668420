import {
  REGISTER_NEW_RIG,
  INPUT_RIG_INFO,
  INPUT_RIG_SPECS,
  // SELECT_RIGS_P_VERSION,
  CANCEL,
  NEXT,
  SUBMIT,
} from "../../../../panelConsts"

const config = {
  type: REGISTER_NEW_RIG,
  index: 0,
  options: {
    heading: "Add new rig",
    subHeading: null,
    largeModal: true,
  },
  pages: [
    {
      type: INPUT_RIG_INFO,
      name: "Rig Info",
      options: {
        buttons: {
          left: CANCEL,
          right: NEXT,
        },
      },
    },
    {
      type: INPUT_RIG_SPECS,
      name: "Specification",
      options: {
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
    // {
    //   type: SELECT_RIGS_P_VERSION,
    //   name: 'Select versions',
    //   options: {
    //     buttons: {
    //       left: CANCEL,
    //       right: SUBMIT,
    //     },
    //   },
    // },
  ],
}

export default config
