import { SHOULD_YOU_REMOVE_REQUEST, INPUT_REMOVE_REQUEST, NO, SUBMIT } from "../../../../panelConsts"

const config = {
  type: SHOULD_YOU_REMOVE_REQUEST,
  index: 0,
  options: {
    heading: "",
    subHeading: "Remove Request",
    whiteModal: true,
    hideMenu: true,
  },
  pages: [
    {
      type: INPUT_REMOVE_REQUEST,
      name: "Remove Request",
      options: {
        validated: true,
        hideMenu: true,
        hideInMenu: true,
        buttons: {
          left: NO,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
