import dateFnsFormat from "date-fns/format"
import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { find } from "lodash"
import DayPickerElement from "../../../components/form/DayPickerElement"
import { DATE_FORMATE_NB } from "../../../dateConsts"

import PanelInputSelection from "../../../components/panel/PanelInputSelection"
import { ActionCreators } from "../../../redux/actions"

const requiredFields = ["start_date", "estimate", "status"]

const wellStatus = [
  { value: "conceptional", label: "Conceptional" },
  { value: "plan_and_select", label: "Plan & select" },
  { value: "engineering_and_design", label: "Engineering & design" },
  { value: "plan_for_execution", label: "Plan for execution" },
  { value: "execution", label: "Execution" },
  { value: "completed", label: "Completed" },
  { value: "occupied", label: "Occupied" },
]

class InputWellInstanceInfo extends Component {
  isNumeric = (n) => Number.isFinite(n) && +n === n /* ignore */

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const obj = {}

    let { value } = elem.target
    if (elem.target.name === "estimate") {
      const parsed = parseInt(elem.target.value, 0)
      value = this.isNumeric(parsed) ? parsed : ""
    }

    obj[elem.target.name] = value

    onUpdate(obj)
  }

  validateForm() {
    const { data } = this.props

    let validated = true
    const keys = Object.keys(data)
    validated = requiredFields.every((key) => keys.includes(key))

    return validated
  }

  handleDayChange = (day) => {
    const { onUpdate } = this.props

    const obj = {}
    obj.start_date = day

    onUpdate(obj)
  }

  handleStatusChange = (status) => {
    const { onUpdate } = this.props

    const obj = {}
    obj.status = status.value
    onUpdate(obj)
  }

  componentDidMount() {
    const { index, onValidated, data } = this.props
    if (data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }
  }

  componentDidUpdate(prevProps) {
    const { index, onValidated, data } = this.props
    if (data !== prevProps.data) {
      const validated = this.validateForm()
      onValidated(index, validated)
    }
  }

  formatDate = (date, format, locale) => dateFnsFormat(date, format, { locale })

  render() {
    const {
      data: { status, well, estimate, start_date: startDate },
    } = this.props

    return (
      <div className="PanelContent__Container PanelForm">
        <div className="PanelForm__InputPart">
          <DayPickerElement
            label="Start date"
            onChange={this.handleDayChange}
            format={DATE_FORMATE_NB}
            startDate={startDate}
            value={startDate ? dateFnsFormat(parseISO(startDate), DATE_FORMATE_NB) : ""}
          />

          <span className="PanelForm__inputinfo">Start date for this P version of this well</span>
        </div>
        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputlabel">Estimate *</label>
          <input
            onChange={this.onFieldUpdate}
            className={`PanelForm__textinput ${estimate ? "active" : ""}`}
            value={estimate || ""}
            type="text"
            name="estimate"
            placeholder=""
          />
          <span className="PanelForm__inputinfo">Estimate for this P version of this well</span>
        </div>

        <div className="PanelForm__InputPart PanelForm__InputPart--select">
          <label className="PanelForm__inputheading">Select status</label>
          <PanelInputSelection
            value={find(wellStatus, { value: status })}
            options={wellStatus}
            placeholder="Select status"
            optionLabel={"label"}
            onChange={(retStatus) => this.handleStatusChange(retStatus)}
          />
        </div>

        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputlabel">Legal well name</label>
          <input
            onChange={this.onFieldUpdate}
            className={`PanelForm__textinput ${well.name ? "active" : ""}`}
            value={well.name ? well.name : ""}
            type="text"
            name="well.name"
            placeholder=""
          />
        </div>

        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputlabel">Common well name</label>
          <input
            onChange={this.onFieldUpdate}
            className={`PanelForm__textinput ${well.short_name ? "active" : ""}`}
            value={well.short_name ? well.short_name : ""}
            type="text"
            name="well.short_name"
            placeholder=""
          />
        </div>

        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputlabel">PL NO</label>
          <input
            onChange={this.onFieldUpdate}
            className={`PanelForm__textinput ${well.plno ? "active" : ""}`}
            value={well.plno ? well.plno : ""}
            type="text"
            name="well.plno"
            placeholder=""
          />
        </div>

        <div className="PanelForm__InputPart">
          <label className="PanelForm__inputlabel">Comment</label>
          <textarea
            onChange={this.onFieldUpdate}
            value={well.comment ? well.comment : ""}
            className="PanelForm__textarea"
            name="well.comment"
            type="text"
            placeholder="Comment"
          />
        </div>
      </div>
    )
  }
}

InputWellInstanceInfo.propTypes = {
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
  onValidated: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InputWellInstanceInfo)
