import React, { Component } from "react"
import SchedulerTopVersion from "./SchedulerTopVersion"
import SchedulerTopFilter from "./SchedulerTopFilter"
import SchedulerTopZoom from "./SchedulerTopZoom"
import SchedulerTopDate from "./SchedulerTopDate"

export class SchedulerTop extends Component {
  render() {
    return (
      <div className="Scheduler--Top">
        <SchedulerTopVersion />
        <div className="SchedulerTop_Filters">
          <SchedulerTopZoom />
          <SchedulerTopDate />
          <SchedulerTopFilter />
        </div>
      </div>
    )
  }
}

export default SchedulerTop
