import parseISO from "date-fns/parseISO"
import { cloneDeep } from "lodash"
import store from "store"
import CU from "../../lib/CalendarUtils"
import createReducer from "../../lib/createReducer"
import { OBJECT_SCROLL_POS, SCROLL_POS } from "../../scrollConsts"
import * as types from "../types"

export const initialState = {
  timespan: null,
  calendar: new Map(),
  PVersion: types.P50,
  zoomLevel: CU.getZoomLevel(types.DURATION_FIVE_YEAR),
  zoomName: types.DURATION_FIVE_YEAR,
  dayWidth: null,
  visibleYears: [],
  visibleMonth: [],
  hiddenRigs: [],
  popOver: null,
  popOverData: null,
  darkMode: false,
  scrollToLastPosition: 0,
  scrollPosition: {
    type: SCROLL_POS,
    value: 0,
    object: null,
  },
}

export const calendar = createReducer(initialState, {
  [types.SET_CALENDAR_TIMESPAN](state, actions) {
    const ns = Object.assign({}, state)
    ns.timespan = actions.timespan
    return ns
  },
  [types.POPULATE_CALENDAR](state, actions) {
    const ns = Object.assign({}, state)
    ns.calendar = actions.calendar
    return ns
  },
  [types.UPDATE_CALENDAR](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.CLOSE_WELL_POPOVER](state) {
    const ns = Object.assign({}, state)
    ns.popOver = null
    ns.popOverData = null
    return ns
  },
  [types.OPEN_WELL_POPOVER](state, actions) {
    const ns = Object.assign({}, state)
    const newData = cloneDeep(actions.data)

    newData.calculatedStartDate = newData.calculatedStartDate ? parseISO(newData.calculatedStartDate) : newData.calculatedStartDate
    newData.startDate = newData.startDate ? parseISO(newData.startDate) : null

    ns.popOver = actions.popover
    ns.popOverData = newData

    return ns
  },
  [types.TOGGLE_DARKMODE](state) {
    const ns = Object.assign({}, state)
    ns.darkMode = !ns.darkMode
    return ns
  },
  [types.SET_ZOOM_LEVEL](state, actions) {
    const ns = Object.assign({}, state)
    ns.zoomLevel = CU.getZoomLevel(actions.zoomLevel)
    ns.zoomName = actions.zoomLevel
    return ns
  },
  [types.SET_P_LEVEL](state, actions) {
    const ns = Object.assign({}, state)
    ns.PVersion = actions.p
    store.set("RIG_PVersion", actions.p)
    return ns
  },
  [types.SET_CALENDARS_VISIBLE_YEARS](state, actions) {
    const ns = Object.assign({}, state)
    const index = ns.visibleYears.indexOf(actions.year)
    if (actions.isVisible && index === -1) {
      ns.visibleYears.push(actions.year)
    } else if (index !== -1) {
      ns.visibleYears.splice(index, 1)
    }
    return ns
  },
  [types.SET_CALENDARS_VISIBLE_MONTH](state, actions) {
    const ns = Object.assign({}, state)
    ns.visibleMonth = actions.month
    return ns
  },
  [types.SET_DAY_WIDTH](state, actions) {
    const ns = Object.assign({}, state)
    ns.dayWidth = actions.dayWidth
    return ns
  },
  [types.SET_HIDDEN_RIGS](state, actions) {
    const ns = Object.assign({}, state)
    ns.hiddenRigs = actions.hiddenRigs
    return ns
  },
  [types.SCROLL_TO_LAST_POSITION](state) {
    const ns = Object.assign({}, state)
    let pos = ns.scrollToLastPosition
    pos += 1
    ns.scrollToLastPosition = pos
    return ns
  },
  [types.SCROLL_POSITION](state, actions) {
    const ns = Object.assign({}, state)

    const scrollPosition = cloneDeep(ns.scrollPosition)
    scrollPosition.type = SCROLL_POS
    scrollPosition.value = actions.value
    ns.scrollPosition = scrollPosition
    return ns
  },
  [types.SCROLL_OBJECT_POSITION](state, actions) {
    const ns = Object.assign({}, state)
    const scrollPosition = cloneDeep(ns.scrollPosition)
    scrollPosition.type = OBJECT_SCROLL_POS
    scrollPosition.value = actions.value
    scrollPosition.object = actions.object.toString()
    ns.scrollPosition = scrollPosition
    return ns
  },
  [types.DELETE_SCROLL_OBJECT_POSITION](state) {
    const ns = Object.assign({}, state)
    const scrollPosition = cloneDeep(ns.scrollPosition)
    scrollPosition.object = null
    ns.scrollPosition = scrollPosition
    return ns
  },
})
