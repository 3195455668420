// import '@babel/polyfill';
import validate from "validate.js"

const emailConstraints = {
  from: {
    email: true,
  },
}

const ValidateUtils = {
  isDateObject: (d) => d instanceof Date && !Number.isNaN(d),
  isEmail: (d) => {
    const check = validate({ from: d }, emailConstraints)
    return !check
  },
  isNumber: (d) => validate.isNumber(d),
  isEquivalent: (a, b) => {
    if (!a || !b) return false

    const aProps = Object.getOwnPropertyNames(a)
    const bProps = Object.getOwnPropertyNames(b)

    if (aProps.length !== bProps.length) {
      return false
    }

    for (let i = 0; i < aProps.length; i += 1) {
      const propName = aProps[i]
      if (a[propName] !== b[propName]) {
        return false
      }
    }

    return true
  },
}
export default ValidateUtils
