import { includes } from "lodash"
import { store } from "../../client/index"
import VALUE_HIDDEN from "../appSettingConsts"
import { WELL_STATUS } from "../redux/types"

const BuilderUtils = {
  globalVariables: () => [
    "well.cpt_ordered",
    "well.cpt_conducted",
    "well.afc",
    "well.afd",
    "well.site_survey_ordered",
    "well.site_survey_conducted",
  ],

  isPropertyGlobal: (key) => {
    const globalVariables = BuilderUtils.globalVariables()
    return includes(globalVariables, key)
  },

  getStatusArrayWithCompletion: (wellInstance) => {
    if (!Object.keys(wellInstance).length) return []
    const ret = Array.from(WELL_STATUS)
      .filter((e) => e[0] !== "completed" && e[0] !== "occupied" && e[0] !== "execution")
      .map((e) => ({
        name: e[1].name,
        type: e[0],
      }))

    ret.unshift({
      name: "Basic information",
      type: "basic_information",
    })
    return ret
  },

  requiredFieldsPrStatusCheck: (wellType) => {
    const state = store.getState()

    let fields = []
    if (
      state &&
      state.application &&
      state.application.domain &&
      state.application.domain.data &&
      state.application.domain.data.fields &&
      state.application.domain.data.fields.well
    ) {
      fields = state.application.domain.data.fields.well
    }

    return [
      {
        basic_information: ["short_name", "well_type", "block_id", "license_id", "contact_person"],
      },
      {
        conceptional: [],
      },
      {
        plan_and_select: (wellType === "exploration" ? ["decision_to_drill"] : ["dg3"]).filter((f) => fields[f] !== false),
      },
      {
        engineering_and_design: [],
      },
      {
        plan_for_execution: ["afc", "afd"].filter((f) => fields[f] !== false),
      },
      {
        execution: [],
      },
      {
        completed: [],
      },
      {
        occupied: ["has_contract_with"],
      },
    ]
  },

  getBasicInformationErrorMessage: (value) => {
    switch (value) {
      case "short_name":
        return 'Common well name is missing on the "basic information page"'
      case "well_type":
        return 'Well type on the "basic information page"'
      case "block_id":
        return 'Block id is missing on the "basic information page"'
      case "license_id":
        return 'License id is missing on the "basic information page"'
      default:
        return 'There is something missing on the "basic information page"'
    }
  },
  getConceptionalInformationErrorMessage: (value) => {
    switch (value) {
      default:
        return 'there is something missing on the "basic information page"'
    }
  },
  getPlanAndSelectInformationErrorMessage: (value) => {
    switch (value) {
      case "decision_to_drill":
        return 'You need to set decision to drill under "conceptional"'
      case "dg3":
        return 'You need to set dg3 under "conceptional"'
      default:
        return "There is something missing under pland and select"
    }
  },
  getEngenieeringAndDesignInformationErrorMessage: (value) => {
    switch (value) {
      default:
        return 'There is something missing on the "plan and select page"'
    }
  },
  getPlanForExecutionInformationErrorMessage: (value) => {
    switch (value) {
      case "afc":
        return 'You need to set afc under "engineering and design"'
      case "afd":
        return 'You need to set afd under "engineering and design"'
      default:
        return "There is something missing under pland for execution"
    }
  },
  getExecutionInformationErrorMessage: (value) => {
    switch (value) {
      default:
        return 'There is something missing on "plan for execution"'
    }
  },
  getCompletedInformationErrorMessage: (value) => {
    switch (value) {
      default:
        return 'There is something missing on "execution"'
    }
  },
  getOccupiedInformationErrorMessage: (value) => {
    switch (value) {
      default:
        return 'There is something missing on "completed"'
    }
  },

  getErrorMessageBasegOnMissingValue: (status, missingValue) => {
    // console.log('status', status);
    // console.log('missingValue', missingValue);

    switch (status) {
      case "basic_information":
        return BuilderUtils.getBasicInformationErrorMessage(missingValue)
      case "conceptional":
        return BuilderUtils.getConceptionalInformationErrorMessage(missingValue)
      case "plan_and_select":
        return BuilderUtils.getPlanAndSelectInformationErrorMessage(missingValue)
      case "engineering_and_design":
        return BuilderUtils.getEngenieeringAndDesignInformationErrorMessage(missingValue)
      case "plan_for_execution":
        return BuilderUtils.getPlanForExecutionInformationErrorMessage(missingValue)
      case "execution":
        return BuilderUtils.getExecutionInformationErrorMessage(missingValue)
      case "completed":
        return BuilderUtils.getCompletedInformationErrorMessage(missingValue)
      case "occupied":
        return BuilderUtils.getOccupiedInformationErrorMessage(missingValue)
      default:
        return "Make shure all required fields are set"
    }
  },

  checkUntilIndex: (wellWrapper, index, domain) => {
    const check = BuilderUtils.requiredFieldsPrStatusCheck(wellWrapper.well_type)
    const { well, estimateData } = wellWrapper

    const returnObject = {
      well: wellWrapper.well.name,
      valid: true,
      messages: [],
    }

    const p10 = domain.data && domain.data.scenarios && domain.data.scenarios.p10 ? domain.data.scenarios.p10 : "P10"
    const p50 = domain.data && domain.data.scenarios && domain.data.scenarios.p50 ? domain.data.scenarios.p50 : "P50"
    const p90 = domain.data && domain.data.scenarios && domain.data.scenarios.p90 ? domain.data.scenarios.p90 : "P90"

    if (estimateData.p10.estimate === 0 || estimateData.p50.estimate === 0 || (estimateData.p90.estimate === 0 && p90 !== VALUE_HIDDEN)) {
      const pMessage = `${p10}, ${p50} ${p90 !== VALUE_HIDDEN ? `& ${p90}` : ""} must be set`

      returnObject.valid = false
      returnObject.messages.push(pMessage)
    }

    // check all chec points
    for (let i = 0; i <= index; i += 1) {
      const arr = Object.values(check[i], (val) => val)[0]
      for (let j = 0; j < arr.length; j += 1) {
        if (!wellWrapper[arr[j]] && !well[arr[j]]) {
          returnObject.valid = false

          const key = Object.keys(check[i])[0]
          const value = check[i][key][j]

          // console.log(`${key}: ${value} : j-${j} i-${i}`)

          const message = BuilderUtils.getErrorMessageBasegOnMissingValue(key, value)

          returnObject.messages.push(message)
        }
      }
    }

    return returnObject
  },

  fieldsPrStatus: (status) => {
    switch (status) {
      case "conceptional":
        return [
          "dg1",
          "dg2",
          "dg3",
          "site_survey_ordered",
          "site_survey_conducted",
          "lli_ordered",
          "cpt_ordered",
          "cpt_conducted",
          "work_order_issues",
          "decision_to_drill",
        ]
      case "plan_and_select":
        return ["lli_ordered", "cpt_ordered", "cpt_conducted", "site_survey_ordered", "site_survey_conducted"]
      case "engineering_and_design":
      case "plan_for_execution":
        return ["afc", "afd"]
      default:
        return []
    }
  },
}

export default BuilderUtils
