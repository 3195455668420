import React, { Component } from "react"
import PropTypes from "prop-types"

export class GradientBackground extends Component {
  constructor(props) {
    super(props)
    const { gradientWidth } = props

    this.gradient = React.createRef()

    this.state = {
      style: {
        background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(236,238,240,1) ${gradientWidth}%, rgba(236,238,240,0) ${gradientWidth}%)`,
      },
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.gradientWidth !== this.props.gradientWidth) {
      const { gradientWidth } = this.props
      this.setState({
        style: {
          background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(236,238,240,1) ${gradientWidth}%, rgba(236,238,240,0) ${gradientWidth}%)`,
        },
      })
    }
  }

  render() {
    return <div ref={this.gradient} className="Gradient_Background" style={this.state.style}></div>
  }
}

GradientBackground.propTypes = {
  gradientWidth: PropTypes.number,
}

export default GradientBackground
