import { CONFIRM_SET_AS_MASTER, CONFIRM_SET_AS_MASTER_SUBPAGE, WRITE_DESICION_SUMMARY, CANCEL, SUBMIT, NEXT } from "../../../../panelConsts"

const config = {
  type: CONFIRM_SET_AS_MASTER,
  index: 0,
  options: {
    heading: "name",
    dynamicHeader: true,
    subHeading: "Confirm set as master",
    hideMenu: true,
    whiteModal: true,
  },
  pages: [
    {
      type: CONFIRM_SET_AS_MASTER_SUBPAGE,
      name: null,
      options: {
        buttons: {
          left: CANCEL,
          right: NEXT,
        },
      },
    },
    {
      type: WRITE_DESICION_SUMMARY,
      name: null,
      options: {
        buttons: {
          left: CANCEL,
          right: SUBMIT,
        },
      },
    },
  ],
}

export default config
