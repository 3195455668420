import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import classNames from "classnames"
import Select from "react-select"
import { find } from "lodash"
import { ActionCreators } from "../../../redux/actions"

class EditCognitoUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editItem: null,
    }
  }

  componentDidMount() {
    this.setState({
      editItem: this.props.editItem,
    })
  }

  handleInputChange = (event) => {
    const editItem = Object.assign({}, this.state.editItem)
    editItem[event.target.name] = event.target.value
    this.setState({ editItem })
  }

  closeModal = () => {
    const { clearSingleItemEdit } = this.props
    clearSingleItemEdit()
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleEditFormSubmit = (event) => {
    const { updateCognitoUser } = this.props
    const { editItem } = this.state

    event.preventDefault()
    event.stopPropagation()

    if (editItem.groups.length > 0) {
      updateCognitoUser(editItem)
    }
  }

  handleSelectChange = (value) => {
    const editItem = Object.assign({}, this.state.editItem)
    editItem.groups = [value.name]
    this.setState({ editItem })
  }

  render() {
    const {
      editItem: { name },
      groups,
      err,
    } = this.props
    const { editItem } = this.state
    if (!editItem) {
      return null
    }

    // Is invalid?
    const isValidPhone = /\+\d+/.test(editItem.phoneNumber)
    const isValidEmail = /[^@]+@[^\.]+\..+/.test(editItem.email)
    const isValidGroup = editItem.groups.length > 0

    const gr = groups.map((g) => ({ name: g }))
    return (
      <form className="ReactModal__Wrapper" onSubmit={(event) => this.handleEditFormSubmit(event)}>
        <div className="ReactModal__Form ModalForm">
          <h2 className="ReactModal__FormHeader">
            Edit <strong>{name}</strong>
          </h2>
          <span>{err}</span>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Email</label>
            <input
              type="email"
              onKeyDown={this.handleEnterPress}
              value={editItem.email || ""}
              name={"email"}
              onChange={this.handleInputChange}
              className={classNames({ invalid: !isValidEmail }, "ModalForm__Input")}
              placeholder="user@domain.com"
              required
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Mobile Phone (+4712345678)</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editItem.phoneNumber || ""}
              name={"phoneNumber"}
              onChange={this.handleInputChange}
              className={classNames({ invalid: !isValidPhone }, "ModalForm__Input")}
              placeholder="+47 123 45 678"
              required
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Given Name</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editItem.givenName || ""}
              name={"givenName"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Given Name"
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Family Name</label>
            <input
              onKeyDown={this.handleEnterPress}
              value={editItem.familyName || ""}
              name={"familyName"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Family Name"
            />
          </div>
          <div className="ModalForm__Part">
            <label className="ModalForm__Label">Access level</label>
            <Select
              options={gr}
              closeMenuOnSelect={true}
              isClearable={false}
              placeholder="Select access level"
              className={classNames({ invalid: !isValidGroup }, "White__Dropdown")}
              getOptionLabel={(option) => option.name}
              value={find(gr, { name: editItem.groups[0] })}
              classNamePrefix="Select__Dropdown__Elm"
              onChange={this.handleSelectChange}
              required
            />
          </div>
        </div>
        <div className="ReactModal__Buttons">
          <button type="button" onClick={this.closeModal} className="ReactModal__Button">
            Cancel
          </button>
          <button type="submit" className={"ReactModal__Button ReactModal__Button--primary"}>
            {"Save"}
          </button>
        </div>
      </form>
    )
  }
}

EditCognitoUser.propTypes = {
  err: PropTypes.string,
  editItem: PropTypes.object,
  groups: PropTypes.array,
  updateCognitoUser: PropTypes.func,
  clearSingleItemEdit: PropTypes.func,
  handleEditFormSubmit: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editItem: state.managecontent.editItem,
    groups: state.managecontent.cognitousers.groups,
    err: state.managecontent.cognitousers.err,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCognitoUser)
