import React, { Component } from "react"
import PropTypes from "prop-types"
import { cloneDeep } from "lodash"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../../redux/actions"
import PanelFooter from "../../../../components/panel/PanelFooter"
import PanelHeader from "../../../../components/panel/PanelHeader"
import PH from "../../../../lib/PanelHelper"
import PanelMenu from "../../../../components/panel/PanelMenu"

class ConfirmShareSchedule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pages: props.pages,
      options: props.options,
      postData: {
        users: {},
        comment: "",
      },
      comment: "",
      removeData: {},
    }
  }

  componentDidMount = () => {
    const { getCurrentScheduleShareInformation, getShareGroups } = this.props
    getCurrentScheduleShareInformation()
    getShareGroups()
  }

  onSubmit = () => {
    const { postData, removeData } = this.state
    const {
      shareCurrentSchedule,
      removeSharedToUsers,
      panelOpen: { index },
    } = this.props

    if (index === 0 && Object.keys(postData.users).length > 0) {
      shareCurrentSchedule(postData)
    } else if (index === 1 && removeData.length > 0) {
      removeSharedToUsers(removeData)
    }
  }

  onUpdate = (type, data) => {
    switch (type) {
      case "add":
        this.setState({
          postData: data,
        })
        break
      case "remove":
        this.setState({
          removeData: data,
        })
        break
      default:
        break
    }
  }

  setSubPageValidated = (index, isValidated) => {
    const validated = cloneDeep(this.state.validated)

    validated[index] = isValidated

    this.setState({
      validated,
    })
  }

  getPage = (index, pages, postData) =>
    PH.getPage(
      index,
      pages[index],
      (type, list) => this.onUpdate(type, list),
      (i, val) => this.setSubPageValidated(i, val),
      postData
    )

  render() {
    const { pages, postData } = this.state
    const {
      panelOpen: { index },
    } = this.props

    return (
      <>
        <PanelHeader key={"PanelHeader"} classes={"white narrow"} />
        <PanelMenu key={"PanelMenu"} classes={"black narrow"} clickable={true} activateOnlyCurrent={true} />
        <div key={"Panel__Page"} className="Panel__Page Panel-Share">
          <div className="Panel__Page-Container PanelContent">{this.getPage(index, pages, postData)}</div>
          <PanelFooter index={index} data={pages[index]} validated={true} onSubmit={(val) => this.onSubmit(val)} />
        </div>
      </>
    )
  }
}

ConfirmShareSchedule.propTypes = {
  panelOpen: PropTypes.object,
  panelData: PropTypes.object,
  options: PropTypes.object,
  pages: PropTypes.array,
  index: PropTypes.number,
  shareCurrentSchedule: PropTypes.func,
  removeSharedToUsers: PropTypes.func,
  getCurrentScheduleShareInformation: PropTypes.func,
  getShareGroups: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    panelOpen: state.application.panelOpen,
    panelData: state.application.panelData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmShareSchedule)
