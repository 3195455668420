import { matchPath } from "react-router"
import createReducer from "../../lib/createReducer"
import * as types from "../types"

export const initialState = {
  previousPathname: "/",
  pathname: "/",
  search: "",
  hash: "",
}

export const routes = createReducer(initialState, {
  [types.LOCATION_CHANGE](state, actions) {
    const ns = Object.assign({}, state)

    const match = matchPath(actions.payload.pathname, {
      path: "/schedule/:id",
      exact: true,
      strict: false,
    })

    ns.previousPathname = ns.pathname
    ns.pathname = actions.payload.location.pathname
    ns.search = actions.payload.location.search
    ns.hash = actions.payload.location.hash
    ns.params = match ? match.params : {}

    return ns
  },
})
