import React, { Component } from "react"
import PropTypes from "prop-types"
import RoundCloseButton from "../buttons/RoundCloseButton"
import PushDropDown from "../dropdown/PushDropDown"

const filterOpts = [
  {
    title: "New",
    filter: {
      key: "request_type",
      val: "new_well",
    },
  },
  {
    title: "Updates",
    filter: {
      key: "request_type",
      val: "well_update",
    },
  },
]

class NewRequestMenu extends Component {
  constructor() {
    super()

    this.state = {
      show: "",
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        show: "show",
      })
    }, 40)
  }

  render() {
    const { processed, unprocessed, accessLevel } = this.props

    const { show } = this.state
    const emptyMessage = accessLevel > 5 ? "No more requests to process 😊 👏" : "You have no unprocessed requests 😊"

    return (
      <div className={`Requests__Panel__Container ${show}`}>
        <div className={"Requests__Panel__Container__Menu"}>
          <RoundCloseButton />
        </div>
        <div className="Requests__Panel">
          <PushDropDown
            filter={filterOpts}
            defaultOpen={true}
            emptyMessage={emptyMessage}
            showCounter={true}
            label="Unprocessed"
            status="unprocessed"
            data={unprocessed}
          />
          {accessLevel >= 5 ? <PushDropDown label="Processed" status="processed" data={processed} /> : null}
        </div>
      </div>
    )
  }
}

NewRequestMenu.propTypes = {
  processed: PropTypes.array,
  unprocessed: PropTypes.array,
  accessLevel: PropTypes.number,
}

export default NewRequestMenu
