import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import WellPopover from "../../../../containers/WellPopover"
import { ActionCreators } from "../../../../redux/actions"
import Row from "./PlotterRow"

class Plotter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scheduleId: null,
      rigs: null,
      rigsElements: null,
    }

    this.scrollContainer = React.createRef()
  }

  componentDidMount = () => {
    if (this.props.schedulerDataEdit && !this.state.rigs) {
      const {
        updateScrollToLastScrollPos,
        PVersion,
        schedulerDataEdit: { id },
      } = this.props
      const snapshot = this.getRigs()

      this.setState({
        rigs: snapshot,
        rigsElements: snapshot.map((val) => <Row key={`${id}-${PVersion.key}-${val.id}`} info={val}></Row>),
      })

      updateScrollToLastScrollPos()
    }
  }

  shouldComponentUpdate = (prevProps, prevState) => {
    const { PVersion, schedulerDataEdit, popOver, scrollTop } = this.props
    const { rigsElements, scrollToPos } = this.state

    if (scrollToPos !== prevState.scrollToPos) return true
    if (rigsElements !== prevState.rigsElements) return true
    if (popOver !== prevState.popOver) return true

    if (schedulerDataEdit !== prevProps.schedulerDataEdit) return true
    if (PVersion !== prevProps.PVersion) return true
    if (scrollTop !== prevProps.scrollTop) return true
    if (scrollTop !== prevProps.scrollTop) return true

    return false
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { scrollTop } = this.props
    if (snapshot) {
      if (snapshot !== this.state.rigs) {
        const {
          PVersion,
          schedulerDataEdit: { id },
        } = this.props

        this.setState({
          scheduleId: id,
          rigs: snapshot,
          rigsElements: snapshot.map((val) => (
            <Row key={`${id}-${PVersion.key}-${val.id}-${val.revision}-${Math.random() * 1000}`} info={val} rigData={val.rigData} />
          )),
        })
      }
    } else if (scrollTop !== prevProps.scrollTop) {
      this.scrollContainer.current.scrollTop = scrollTop
    }
  }

  getSnapshotBeforeUpdate = (prevProps) => {
    const { schedulerDataEdit, PVersion } = this.props

    const id = schedulerDataEdit ? schedulerDataEdit.id : null

    if (id && !prevProps.schedulerDataEdit) {
      return this.getRigs()
    }

    if (id && prevProps.schedulerDataEdit && id !== prevProps.schedulerDataEdit.id) {
      return this.getRigs()
    }

    if (schedulerDataEdit !== prevProps.schedulerDataEdit) {
      return this.getRigs()
    }

    if (PVersion !== prevProps.PVersion) {
      return this.getRigs()
    }
    return null
  }

  getRigs = () => {
    const { schedulerDataEdit } = this.props
    return schedulerDataEdit.rigs
  }

  componentWillUnmount() {
    this.setState({
      rigs: null,
      rigsElements: null,
    })
  }

  getStyle = () => ({ width: "calc(100vw - 200px)", opacity: "0.9" })

  render() {
    const { rigsElements } = this.state
    const { syncScroll, popOver } = this.props

    if (!rigsElements) {
      return (
        <div className="Preloader--Container" style={this.getStyle()}>
          <div className="Preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )
    }

    return (
      <div ref={this.scrollContainer} id="Harry-Plotter" className="Plotter" onScroll={syncScroll}>
        {rigsElements}
        <div className={"Row Plotter--Row"}></div>
        <WellPopover hasPopover={!!popOver} />
      </div>
    )
  }
}

Plotter.propTypes = {
  updateIndex: PropTypes.number,
  PVersion: PropTypes.object,
  schedulerDataEdit: PropTypes.object,
  popOver: PropTypes.string,
  zoomLevel: PropTypes.number,
  updateRigDataIndex: PropTypes.number,
  updateScrollToLastScrollPos: PropTypes.func,
  scrollTop: PropTypes.number,
  syncScroll: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    updateRigDataIndex: state.schedulerData.updateRigDataIndex,
    schedulerDataEdit: state.schedulerData.schedulerDataEdit.data,
    PVersion: state.calendar.PVersion,
    popOver: state.calendar.popOver,
    zoomLevel: state.calendar.zoomLevel,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plotter)
