import React, { Component } from "react"
import PSelectorButton from "./PSelectorButton"

import { P10, P50, P90 } from "../../redux/types"

class PSelector extends Component {
  render() {
    return (
      <div className="PSelector">
        <PSelectorButton PValue={P10} />
        <PSelectorButton PValue={P50} />
        <PSelectorButton PValue={P90} />
      </div>
    )
  }
}

PSelector.propTypes = {}

export default PSelector
