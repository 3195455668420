import React from "react"
import PropTypes from "prop-types"
import ChangeMajor from "./ChangeMajor"
import ChangeMinor from "./ChangeMinor"

const Change = (props) => {
  const {
    data: { data },
  } = props

  return (
    <div className="timeline__calendar-change" onClick={() => props.handleClick(data)}>
      {data.major ? <ChangeMajor data={data.major} /> : null}
      {data.minor ? <ChangeMinor data={data.minor} /> : null}
    </div>
  )
}

Change.propTypes = {
  data: PropTypes.object,
  week: PropTypes.number,
  major: PropTypes.array,
  minor: PropTypes.array,
  handleClick: PropTypes.func,
}

export default Change
