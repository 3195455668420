import { PANEL_PREVIEW_REQUEST, INPUT_PREVIEW_REQUEST, CANCEL, ACCEPT_AND_ADD } from "../../../../panelConsts"

const config = {
  type: PANEL_PREVIEW_REQUEST,
  index: 0,
  options: {
    heading: "Preview request",
    largeModal: false,
    free: true,
  },
  pages: [
    {
      type: INPUT_PREVIEW_REQUEST,
      name: "New well",
      options: {
        hideMenu: true,
        hideInMenu: true,
        buttons: {
          left: CANCEL,
          right: ACCEPT_AND_ADD,
        },
      },
    },
  ],
}

export default config
