import Loader from "./Loader"
import RootAppElm from "./RootApp"
import SchedulerElm from "./Scheduler/Scheduler"
import TimelineElm from "./Timeline"
import OverviewElm from "./Overview/Overview"
import ManageContentElm from "./ManageContent/ManageContent"
import NoMatchElm from "./NoMatch"
import ErrorElm from "./Error/Error"
import LogoutElm from "./Logout"

export const RootApp = Loader(RootAppElm)
export const Scheduler = Loader(SchedulerElm)
export const Timeline = Loader(TimelineElm)
export const Overview = Loader(OverviewElm)
export const ManageContent = Loader(ManageContentElm)
export const NoMatch = Loader(NoMatchElm)
export const Error = Loader(ErrorElm)
export const Logout = Loader(LogoutElm)
