import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import IconContainer from "../icons/IconContainer"
import HPHT from "../../../assets/images/svg/icons/HPHT.svg"

import CU from "../../lib/CalendarUtils"

class ExistingWellPreview extends Component {
  getEstimate = () => {
    const { data } = this.props

    return data.wells[0].estimate
  }

  getStyle = (data) => ({
    backgroundColor: CU.getWellBookingStatusColor(data.wells[0].status).color,
  })

  render() {
    const { data } = this.props

    if (!data.wells.length) {
      return null
    }

    const style = this.getStyle(data)

    const name = data.wells[0].well.name || data.wells[0].well.plno || "no name"

    return (
      <div ref={this.obj} className={"Request Preview Request--existing"} style={style}>
        <div className="Request--Container">
          <div className="Request--Name--Container">
            <div className="Request--Name FontStyle--Bold">{name}</div>
            <div className="Request--op FontStyle--Regular">{data.wells[0].well.plno || ""}</div>
          </div>
          <div className="Request--Time-and-Icons--Container">
            <div className="Request--Icons--Container">{data.wells[0].well.pressure === "hpht" ? IconContainer(HPHT, 11, 11) : null}</div>
            <div className="Request--Time--Container">
              <div className="Request--Time">{this.getEstimate()}</div>
              <div className="Request--Days">DAYS</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ExistingWellPreview.propTypes = {
  data: PropTypes.object,
  PVersion: PropTypes.object,
  readonly: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    PVersion: state.calendar.PVersion,
    readonly: state.schedulerData.schedulerData.data.readonly,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExistingWellPreview)
