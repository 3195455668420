import React, { Component } from "react"
import PropTypes from "prop-types"

class WellSelection extends Component {
  render() {
    const { well, onSelect, selected } = this.props

    return (
      <div onClick={() => onSelect(well)} className={`PanelForm__SelectWell WellSelection${selected() ? " WellSelection--selected" : ""}`}>
        <div className="WellSelection__name">{well.name ? well.name : well.plno}</div>
      </div>
    )
  }
}

WellSelection.propTypes = {
  well: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.func,
}

export default WellSelection
