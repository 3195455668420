import React, { Component } from "react"
import PropTypes from "prop-types"

class MagicDragButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      moveObject: null,
      offset: {
        x: 0,
        y: 0,
      },
      bounds: {
        left: 0,
        top: 0,
        right: 500,
        bottom: 500,
      },
    }

    this.magic = React.createRef()
  }

  componentDidMount() {
    const elm = document.getElementsByClassName(this.props.objectToMove)

    this.setState({
      moveObject: elm[0],
    })
  }

  onScreenDown = (e) => {
    e.preventDefault()
    const { moveObject } = this.state

    if (!moveObject) return

    const bbox = moveObject.getBoundingClientRect()

    this.setState({
      offset: {
        x: e.nativeEvent.offsetX,
        y: e.nativeEvent.offsetY,
      },
      bounds: {
        left: 0,
        top: 0,
        right: window.innerWidth - bbox.width,
        bottom: window.innerHeight - bbox.height,
      },
    })

    // handle touch
    document.addEventListener("touchmove", this.touchHandler, true)
    document.addEventListener("touchend", this.onScreenUp, true)
    document.addEventListener("touchcancel", this.onScreenUp, true)

    // handle mouse
    document.addEventListener("mousemove", this.onMouseEvent, true)
    document.addEventListener("mouseup", this.onScreenUp, true)
  }

  onScreenUp = (e) => {
    e.preventDefault()
    document.removeEventListener("touchmove", this.touchHandler, true)
    document.removeEventListener("touchend", this.touchHandler, true)
    document.removeEventListener("touchcancel", this.touchHandler, true)

    // handle mouse
    document.removeEventListener("mousemove", this.onMouseEvent, true)
    document.removeEventListener("mouseup", this.onMouseEvent, true)
  }

  onMouseEvent = (e) => {
    const { moveObject, offset, bounds } = this.state

    const xPos = Math.max(bounds.left, Math.min(e.clientX - offset.x, bounds.right))
    const yPos = Math.max(bounds.top, Math.min(e.clientY - offset.y, bounds.bottom))

    moveObject.style.color = "blue"
    moveObject.style.backgroundColor = "yellow"
    moveObject.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`

    e.preventDefault()
  }

  touchHandler = (e) => {
    const touch = e.changedTouches[0]

    const simulatedEvent = document.createEvent("MouseEvent")
    simulatedEvent.initMouseEvent(
      {
        touchstart: "mousedown",
        touchmove: "mousemove",
        touchend: "mouseup",
      }[e.type],
      true,
      true,
      window,
      1,
      touch.screenX,
      touch.screenY,
      touch.clientX,
      touch.clientY,
      false,
      false,
      false,
      false,
      0,
      null
    )

    touch.target.dispatchEvent(simulatedEvent)
    e.preventDefault()
  }

  componentDidUpdate = () => {}

  render() {
    return (
      <div ref={this.magic} className="MagicDragElement" onMouseDown={this.onScreenDown} onTouchStart={this.onScreenDown}>
        {this.props.children}
      </div>
    )
  }
}

MagicDragButton.propTypes = {
  children: PropTypes.any,
  objectToMove: PropTypes.string,
}

export default MagicDragButton
