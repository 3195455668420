import axios from "axios"
import { matchPath } from "react-router"

export let getConfig = async () => {
  // FIXME: How to handle the /rig prefix?

  const timestamp = Math.floor(Date.now() / 1000)
  try {
    const urlReq = await fetch(`/${window.location.hostname}_env-conf.json?timestamp=${timestamp}`)
    const config = await urlReq.json()
    return config
  } catch (error) {
    const urlReq = await fetch(`/env-conf.json?timestamp=${timestamp}`)
    const config = await urlReq.json()
    return config
  }
}

export const API_URL = localStorage.getItem("config") ? "//" + JSON.parse(localStorage.getItem("config")).apiHost + "/" : ""
export const baseUrl = API_URL

const ApiServices = {
  attemptLogin: () => Promise.resolve(true),

  getApiUrl: () => baseUrl,

  formatUrl: (url, pathname, overridenPValueWells, additionalScopeWells) => {
    const match = matchPath(pathname, {
      path: "/schedule/:id",
      exact: true,
      strict: false,
    })

    let formated = match && match.params.id ? `${url}/get?id=${match.params.id}` : `${url}/current`

    if (overridenPValueWells && Object.keys(overridenPValueWells).length > 0) {
      const wells = Object.keys(overridenPValueWells)
        .map((k) => `${k}|${overridenPValueWells[k]}`)
        .join(",")
      formated += `&overridePValues=${wells}`
    }
    if (additionalScopeWells && additionalScopeWells.length > 0) {
      const wells = additionalScopeWells.join(",")
      formated += `&additionalScopeWells=${wells}`
    }

    return formated
  },

  deleteDataFromApi: async (url, version = "v1") => {
    try {
      const token = ApiServices.getCookie("jwt")
      return await axios
        .delete(baseUrl + version + "/" + url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "x-access-token": token,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
          mode: "cors",
          method: "DELETE",
        })
        .then((res) => {
          if (!res.status || res.status === 200) {
            return res.data
          }

          throw res
        })
    } catch (error) {
      return error
    }
  },

  getSettingsDummy: async () => {
    const result = await {
      p10: "P10",
      p50: "P50",
      p90: "P90",
    }

    return result
  },

  getDataFromApi: async (url, version = "v1") => {
    try {
      const token = ApiServices.getCookie("jwt")
      return await axios
        .get(baseUrl + version + "/" + url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "x-access-token": token,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
          mode: "cors",
          method: "GET",
        })
        .then((res) => {
          if (!res.status || res.status === 200) {
            return res.data
          }

          throw res
        })
    } catch (error) {
      // If unauthorized (401), log us out.
      if (error.response.status === 401) {
        const logoutUri = "/logout"
        if (document.location.pathname !== logoutUri) {
          const config = await getConfig()
          const loggedOutUrl = `${window.location.origin}/logout`
          window.location.href = `//${config.apiHost}/v1/auth/logout?redirectTo=${loggedOutUrl}`
        }
      }

      return { error, code: error.response.status }
    }
  },

  putDataToApi: async (url, data, queryParams) => {
    try {
      let query = ""
      if (typeof queryParams !== "undefined") {
        const esc = encodeURIComponent
        query = Object.keys(queryParams)
          .map((k) => `${esc(k)}=${esc(queryParams[k])}`)
          .join("&")
      }

      const token = ApiServices.getCookie("jwt")
      // const response = await fetch(`${baseUrl + url}?${query}`, {
      const version = "v1"
      return await axios(`${baseUrl + version + "/" + url}?${query}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "x-access-token": token,
        },
        mode: "cors",
        // credentials: 'include',
        data: JSON.stringify(data),
      }).then((res) => {
        if (!res.status || res.status === 200) {
          return res.data
        }

        throw res
      })
    } catch (error) {
      return error
    }
  },
  postDataToApi: async (url, data, queryParams) => {
    const version = "v1"
    try {
      let query = ""
      if (typeof queryParams !== "undefined") {
        const esc = encodeURIComponent
        query = Object.keys(queryParams)
          .map((k) => `${esc(k)}=${esc(queryParams[k])}`)
          .join("&")
      }

      const token = ApiServices.getCookie("jwt")
      // const response = await fetch(`${baseUrl + url}?${query}`, {
      return await axios(`${baseUrl + version + "/" + url}?${query}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "x-access-token": token,
        },
        mode: "cors",
        // credentials: 'include',
        data: JSON.stringify(data),
      }).then((res) => {
        if (!res.status || res.status === 200) {
          return res.data
        }

        throw res
      })
    } catch (error) {
      return error
    }
  },

  getCookie: (cname) => {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  },

  deleteCookie: (cname) => {
    if (ApiServices.getCookie(cname)) {
      document.cookie = cname + "=" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT"
    }
  },
}

export default ApiServices
