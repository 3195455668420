import createReducer from "../../lib/createReducer"
import * as types from "../types"

export const initialState = {
  error: null,
}

export const observer = createReducer(initialState, {
  [types.OBSERVER_ERROR](state, actions) {
    const ns = Object.assign({}, state)
    ns.error = actions.payload
    return ns
  },
  [types.OBSERVER_HIDE_ERROR](state) {
    const ns = Object.assign({}, state)
    ns.error = null
    return ns
  },
  [types.OBSERVER_EMPTY](state) {
    const ns = Object.assign({}, state)
    return ns
  },
})
