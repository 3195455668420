import React, { Component } from "react"
import PropTypes from "prop-types"
import format from "date-fns/format"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import CU from "../../../lib/CalendarUtils"
import { DATE_FORMATE_NB_SHORT } from "../../../dateConsts"
import { ActionCreators } from "../../../redux/actions"

const nextRevisionDate = CU.getNextRevisionDate()

class StatusModule extends Component {
  render() {
    const days = differenceInCalendarDays(nextRevisionDate, new Date())
    const date = format(nextRevisionDate, DATE_FORMATE_NB_SHORT)

    return (
      <div className="Overview__Module StatusModule">
        <h2 className="Overview__ModuleHeading">Status</h2>
        <div className="StatusModule__part StatusModule__part--due">
          Next schedule due in <strong>{days} days</strong> - {date}.
        </div>
      </div>
    )
  }
}

StatusModule.propTypes = {
  missingInputters: PropTypes.array,
  fetchMissingInputters: PropTypes.func,
  accessLevel: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    missingInputters: state.overviewData.missingInputters,
    accessLevel: state.user.accessLevel,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusModule)
