import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import isAfter from "date-fns/isAfter"
import { find } from "lodash"
import { ActionCreators } from "../../redux/actions"
import CU from "../../lib/CalendarUtils"
import VU from "../../lib/ValidateUtils"

let lastPos = 0

class DnD extends Component {
  constructor() {
    super()

    this.state = {
      modalOpen: null,
    }

    this.img = null
    this.currentDrag = null
    this.clickInterval = null
    this.mouseDown = false
    this.mouseMove = false
  }

  componentDidMount = () => {
    this.img = new Image()
    this.img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="

    document.addEventListener("mousedown", this.handleMouseDown)
  }

  handleMouseDown = (e) => {
    const {
      accessLevel,
      schedulerData: {
        schedulerData: { data },
      },
    } = this.props

    // Check if acceslevel is high enough
    if (accessLevel < 10 || !data || data.readonly) {
      return
    }

    document.removeEventListener("mousedown", this.handleMouseDown)
    document.addEventListener("mouseup", this.handleMouseUp)

    this.mouseDown = true
    clearInterval(this.clickInterval)

    this.clickInterval = setInterval(() => {
      if (this.mouseDown) {
        clearInterval(this.clickInterval)
        this.handleDragStart(e)
      }
    }, 200)
  }

  handleDragStart = (e) => {
    const { panelOpen } = this.props
    const elm = e.target

    if (elm.hasAttribute("draggable") && this.mouseDown && !panelOpen) {
      document.addEventListener("mousemove", this.onDragoveMove)
      this.currentDrag = e
      this.onDragStart()
    }
  }

  handleMouseUp = (e) => {
    document.addEventListener("mousedown", this.handleMouseDown)
    document.removeEventListener("mouseup", this.handleMouseUp)
    document.removeEventListener("mousemove", this.onDragoveMove)

    clearInterval(this.clickInterval)

    if (e.target.classList.contains("Plotter--Row") && this.mouseMove) {
      this.saveDragUpdate(e)
    } else {
      this.deleteCurrentDrag()
    }

    this.mouseDown = false
    this.mouseMove = false
  }

  deleteCurrentDrag = () => {
    const { resetRigdataEdit } = this.props

    if (this.currentDrag) {
      this.currentDrag.target.style.opacity = 1
      this.currentDrag.target.style.pointerEvents = "all"
      this.currentDrag = null

      resetRigdataEdit()
    }
  }

  onDragoveMove = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (!this.props.moveObject || Object.keys(this.props.moveObject).length === 0) return

    this.mouseMove = true

    window.requestAnimationFrame(() => this.updateOnMouseMove(e))
  }

  updateOnMouseMove = (e) => {
    const {
      updateDragPos,
      timespan: { from },
      dayWidth,
      setDropInfo,
      dropInfo,
    } = this.props

    const xPos = CU.getPos(e)

    if (lastPos !== xPos) {
      lastPos = xPos

      updateDragPos(xPos)

      const nd = CU.getNewDate(from, xPos, dayWidth)
      let priority = null

      try {
        priority = this.getPriority(nd)
      } catch (err) {
        priority = null
      }

      if (priority && !VU.isEquivalent(dropInfo, priority)) {
        setDropInfo(priority)
      }
    }
  }

  getPriority = (nd) => {
    const { dragRowID, PVersion, moveObject } = this.props
    const rigs = this.getRigs()

    const rigLine = find(rigs, (val) => val.rig_id === dragRowID)

    const { entries } = rigLine || []
    let priority = 0

    let val

    for (let i = 0; i < entries.length; i += 1) {
      val = entries[i]
      if (val.booking_type === "wells") {
        const date = new Date(val.estimateData[PVersion.value].start_date)

        if (isAfter(nd, date)) {
          ;({ priority } = val)
          if (priority < moveObject.priority) {
            priority = Math.max(0, priority + 10)
          }
        }
      }
    }

    const returnObj = {
      priority,
      id: this.props.moveObject.id, // rig booking id
      rig_instance_id: rigLine.id,
      start_date: nd,
    }

    return returnObj
  }

  getRigs = () => {
    const { schedulerDataEdit } = this.props
    return schedulerDataEdit.rigs
  }

  saveDragUpdate = () => {
    if (!this.currentDrag) return
    const { dropInfo, updateScheduleBasedOnWellPriority } = this.props

    if (dropInfo && dropInfo.priority >= 0) {
      updateScheduleBasedOnWellPriority()
    }

    this.deleteCurrentDrag()
  }

  onDragStart = () => {
    const objectProps = JSON.parse(this.currentDrag.target.dataset.props)

    const { setTemporaryWell } = this.props

    const { PVersion, id, rigId, estimate, pressure, rigMove, rigType, bounds, hasContractWith, startDate, priority } = objectProps

    const {
      timespan: { from },
    } = this.props

    const dom = this.currentDrag.target

    const data = {
      PVersion,
      id,
      rigId,
      from,
      estimate,
      pressure,
      rigMove,
      rigType,
      hasContractWith,
      bounds,
      startDate,
      priority,
    }

    setTemporaryWell(dom, data)

    this.currentDrag.target.style.opacity = 0.2
    this.currentDrag.target.style.pointerEvents = "none"
  }

  render() {
    return null
  }
}

DnD.propTypes = {
  accessLevel: PropTypes.number,
  dayWidth: PropTypes.number,
  schedulerData: PropTypes.object,
  schedulerDataEdit: PropTypes.object,
  dropInfo: PropTypes.object,
  updateDragPos: PropTypes.func,
  resetRigdataEdit: PropTypes.func,
  updateTemporaryWell: PropTypes.func,
  setElementInBetween: PropTypes.func,
  updateScheduleBasedOnWellPriority: PropTypes.func,
  setTemporaryWell: PropTypes.func,
  setDropInfo: PropTypes.func,
  timespan: PropTypes.object,
  dragRowID: PropTypes.number,
  moveObject: PropTypes.object,
  PVersion: PropTypes.object,
  panelOpen: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    accessLevel: state.user.accessLevel,
    schedulerData: state.schedulerData,
    schedulerDataEdit: state.schedulerData.schedulerDataEdit.data,

    dropInfo: state.schedulerData.dropInfo,
    timespan: state.calendar.timespan,
    moveObject: state.schedulerData.moveObject,

    dragRowID: state.schedulerData.dragRowID,
    PVersion: state.calendar.PVersion,
    dayWidth: state.calendar.dayWidth,
    panelData: state.application.panelData,
    panelOpen: state.application.panelOpen,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DnD)
