import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { format } from "date-fns"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class WriteSummarySub extends PureComponent {
  closeModal = (e) => {
    const { closePanel } = this.props
    e.stopPropagation()
    e.preventDefault()

    closePanel()
  }

  onFieldUpdate = (elem) => {
    const { onUpdate } = this.props

    const { value } = elem.target
    const obj = {}
    obj[elem.target.name] = value

    onUpdate(obj)
  }

  render() {
    const { data, schedulerData } = this.props

    return (
      <div className="PanelContent__Container write-desicion-summary">
        <h1>{"Write summary for new master"}</h1>
        <div>
          <div className="FontStyle--Bold">{`${schedulerData.data.name} as Master`}</div>
          <div>{format(new Date(), "dd.MM.yyyy' 'HH:mm")}</div>
        </div>
        <div>
          <textarea name="summary" value={data && data.summary} onChange={this.onFieldUpdate} />
        </div>
      </div>
    )
  }
}

WriteSummarySub.propTypes = {
  closePanel: PropTypes.func,
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  schedulerData: PropTypes.object,
  user: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    timeline: state.timeline,
    schedulerData: state.schedulerData.schedulerData,
    user: state.application.user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WriteSummarySub)
