import React from "react"
import FlashMessage from "../modals/FlashMessage"
import DuplicateMaster from "../modals/DuplicateMaster"
import RenameDraft from "../modals/RenameDraft"
import ArchiveDraft from "../modals/ArchiveDraft"
import DuplicateDraft from "../modals/DuplicateDraft"
import * as types from "../redux/types"

const ModalUtils = {
  getModal: (data) => {
    if (!data || !data.type) return null

    switch (data.type) {
      case types.MODAL_TYPE_FLASH_MESSAGE:
        return data.valid[0] ? ModalUtils.createFlashMessageModal(data.valid[0]) : null
      case types.MODAL_TYPE_MASTER_DUPLICATE:
        return ModalUtils.createDuplicateMasterModal(data)
      case types.MODAL_TYPE_DRAFT_RENAME:
        return ModalUtils.createRenameDraftModal(data)
      case types.MODAL_TYPE_DRAFT_DUPLICATE:
        return ModalUtils.createDuplicateDraftModal(data)
      case types.MODAL_TYPE_DRAFT_ARCHIVE:
        return ModalUtils.createArchiveDraftModal(data)
      default:
        return null
    }
  },

  createFlashMessageModal: (data) => <FlashMessage data={data} />,

  createDuplicateMasterModal: (data) => <DuplicateMaster data={data} />,

  createRenameDraftModal: (data) => <RenameDraft data={data} />,

  createDuplicateDraftModal: (data) => <DuplicateDraft data={data} />,

  createArchiveDraftModal: (data) => <ArchiveDraft data={data} />,
}
export default ModalUtils
