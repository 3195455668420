import React from "react"
import { useSelector } from "react-redux"

type WellFields =
  | "lwi_candidate"
  | "pressure"
  | "ocean_depth"
  | "environmental_constraints"
  | "start_up_constraints"
  | "well_test"
  | "subsea_well"
  | "work_order_issues"
  | "decision_to_drill"
  | "lli_ordered"
  | "cpt_ordered"
  | "cpt_conducted"
  | "site_survey_ordered"
  | "site_survey_conducted"
  | "afc"
  | "afd"
  | "dg1"
  | "dg2"
  | "dg3"

type RigFields = "ocean_depth_from" | "ocean_depth_to" | "npdid" | "generation"

type WellSettings = {
  [K in WellFields]: boolean
}

type RigSettings = {
  [K in RigFields]: boolean
}

type Scope = "well" | "rig"

type FieldSettings = {
  well: WellSettings
  rig: RigSettings
}

type Props = {
  scope: Scope
  field: WellFields | RigFields
  children: any
}

const ConditionalField = ({ scope, field, children }: Props) => {
  const activeFields: FieldSettings = useSelector((state: any) => state.application.domain.data.fields)

  // @ts-ignore
  if (activeFields && activeFields[scope] && typeof activeFields[scope][field] !== "undefined" && activeFields[scope][field] === false)
    return null

  return children
}

type BlockProps = {
  scope: Scope
  fields: WellFields[]
  children: any
  emptyState?: React.ReactNode
}

const ConditionalFieldBlock = ({ scope, fields, children, emptyState }: BlockProps) => {
  const activeFields: FieldSettings = useSelector((state: any) => state.application.domain.data.fields)

  // @ts-ignore
  if (activeFields && activeFields[scope] && fields.length > 0 && fields.every((f) => activeFields[scope][f] === false))
    return typeof emptyState !== "undefined" ? emptyState : null

  return children
}

export { ConditionalFieldBlock }
export default ConditionalField
