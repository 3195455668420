import { Rigs } from "../types/rigs"

export const getReadableRigType = (rigtype: Rigs) => {
  switch (rigtype) {
    case "drillship":
      return "drillship"
    case "jack-up":
      return "jack-up"
    case "land_rig":
      return "landrig"
    case "platform":
      return "platform"
    case "semi-sub":
      return "semi-sub"
  }
}
