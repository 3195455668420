import createReducer from "../../lib/createReducer"
import LG from "../../lib/localeStorage"
import * as types from "../types"

export const initialState = async () => ({
  filter: {},
})

export const filters = createReducer(initialState, {
  [types.TOGGLE_VIEW_FILTER](state, actions) {
    const ns = Object.assign({}, state)
    ns.filter = ns.filter !== actions.filter ? actions.filter : null
    return ns
  },
  [types.SET_VIEW_FILTER](state, actions) {
    const ns = Object.assign({}, state)
    ns.filter = actions.filter
    return ns
  },
  [types.SET_VIEW_FILTER_TO_STORED_VERSION](state) {
    const ns = Object.assign({}, state)
    try {
      LG.loadProp("filter").then((res) => {
        ns.filter = res
        return ns
      })
    } catch (err) {
      return ns
    }

    return ns
  },
})
