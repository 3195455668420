/* eslint-disable import/prefer-default-export */
import parseISO from "date-fns/parseISO"
import { ofType } from "redux-observable"
import { empty, from, of, timer } from "rxjs"
import { catchError, debounce, distinctUntilChanged, map, mergeMap, switchMap, tap } from "rxjs/operators"
import store from "store"

import Api from "../../services/ApiService"
import {
  fetchAreaData,
  fetchAssetsData,
  fetchLicenseData,
  fetchRigData as fetchRigDataAction,
  getCurrentScheduleShareInformation,
  notifySharesSuccess,
  resetRigdataEdit,
  saveCurrentShares,
  saveShareGroups,
  setDropReady,
  updateAreaData,
  updateAssetsData,
  updateBlockData,
  updateLicensesData,
  updateRegretData,
  updateRigData,
  updateRigDataEdit,
  updateRigList,
  updateSingleWellInstance,
  updateWellData,
} from "../actions/schedulerData"
import * as types from "../types"

import { closePanel, hideAcitivityIndicator, showAcitivityIndicator } from "../actions/application"
import { setCalendarTimespan, setPVersion } from "../actions/calendar"
import { observerEmpty, observerError } from "../actions/observer"
import { fetchDrafts } from "../actions/overviewData"
import { getRequests } from "../actions/requests"

/**
  ATTEMPT TO FETCH DATA
*/

export const saveWellInstance = (action$, state$) =>
  action$.pipe(
    ofType(types.SAVE_WELL_INSTANCE),
    switchMap(() =>
      from(Api.postDataToApi("schedule/updateWellInstance", state$.value.schedulerData.wellInstance)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    // tap(res => console.log(res)),
    mergeMap(() => [
      hideAcitivityIndicator("save_well_instance"),
      fetchRigDataAction(),
      // closePanel(),
    ])
  )

/**
  ATTEMPT TO FETCH DATA
*/

export const setRigPriority = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_RIG_PRIORITY),
    switchMap(() =>
      from(Api.postDataToApi("schedule/setRigPriority", state$.value.schedulerData.rigPriorityArray)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    // tap(res => console.log(res)),
    mergeMap(() => [
      fetchRigDataAction(),
      // closePanel(),
    ])
  )

/**
  ATTEMPT TO FETCH DATA
*/

export const removeWellInstance = (action$, state$) =>
  action$.pipe(
    ofType(types.REMOVE_WELL_INSTANCE),
    switchMap(() =>
      from(Api.postDataToApi("schedule/removeWellInstance", state$.value.schedulerData.wellInstanceToRemove)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    mergeMap(() => [fetchRigDataAction(), closePanel()])
  )

export const addExistingRigToSchedule = (action$, state$) =>
  action$.pipe(
    ofType(types.ADD_EXISTING_RIG_TO_SCHEDULE),
    switchMap(() =>
      from(
        Api.postDataToApi(
          `schedule/addExistingRigToSchedule/${state$.value.schedulerData.schedulerData.data.id}`,
          state$.value.schedulerData.existingRigToAdd
        )
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    ),
    mergeMap(() => [fetchRigDataAction(), closePanel()])
  )

/**
  SET SCHEDULE AS MASTER
*/

export const showActivityOnMasterSet = (action$) =>
  action$.pipe(
    ofType(types.SET_CURRENT_SCHEDULE_AS_MASTER),
    mergeMap(() => [closePanel(), showAcitivityIndicator("set_new_master")])
  )

export const setCurrentScheduleAsMaster = (action$, state$) =>
  action$.pipe(
    ofType(types.SET_CURRENT_SCHEDULE_AS_MASTER),
    switchMap((actions) =>
      from(
        Api.postDataToApi(`schedule/setMaster/${state$.value.schedulerData.schedulerData.data.id}`, {
          decisionSummary: actions.data.summary,
          month: actions.data.month,
          year: actions.data.year,
        })
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    ),
    mergeMap(() => [hideAcitivityIndicator("set_new_master"), fetchRigDataAction(), fetchDrafts()])
  )
/**
 */

export const shareCurrentSchedule = (action$, state$) =>
  action$.pipe(
    ofType(types.SHARE_CURRENT_SCHEDULE),
    switchMap(() =>
      from(
        Api.postDataToApi(`sharing/shareDraft/${state$.value.schedulerData.schedulerData.data.id}`, state$.value.schedulerData.shareTo)
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    ),
    mergeMap((result) => {
      if (result.data === true) {
        return [getCurrentScheduleShareInformation(), notifySharesSuccess(result.data)]
      }

      return [notifySharesSuccess(result.data)]
    })
  )

export const removeCurrentScheduleShares = (action$, state$) =>
  action$.pipe(
    ofType(types.REMOVE_CURRENT_SHARES),
    switchMap(() =>
      from(
        Api.postDataToApi(
          `sharing/unShareDraft/${state$.value.schedulerData.schedulerData.data.id}`,
          state$.value.schedulerData.currentRemoveShareList
        )
      ).pipe(catchError((err) => of(observerError(err.xhr.response))))
    ),
    mergeMap((result) => {
      if (result.data === true) {
        return [getCurrentScheduleShareInformation(), notifySharesSuccess(result.data)]
      }

      return [notifySharesSuccess(result.data)]
    })
  )
/**
 */

export const getSharerGroupsObserver = (action$) =>
  action$.pipe(
    ofType(types.GET_SHARE_GROUPS),
    switchMap(() => from(Api.getDataFromApi("sharing/getLists/")).pipe(catchError((err) => of(observerError(err.xhr.response))))),
    mergeMap((result) => [saveShareGroups(result.data)])
  )
/**
 */

export const getCurrentScheduleShareInformationObserver = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_CURRENT_SCHEDULE_SHARE_INFORMATION),
    switchMap(() =>
      from(Api.getDataFromApi(`sharing/sharesForDraft/${state$.value.schedulerData.schedulerData.data.id}`)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    mergeMap((result) => [saveCurrentShares(result)])
  )

/**
  ATTEMPT TO FETCH DATA
*/

export const addBufferTime = (action$, state$) =>
  action$.pipe(
    ofType(types.API_ADD_BUFFER_TIME),
    switchMap(() =>
      from(Api.postDataToApi("schedule/addBufferTime", state$.value.schedulerData.bufferTime)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    mergeMap(() => [fetchRigDataAction(), closePanel()])
  )
/**
  ATTEMPT TO FETCH DATA
*/

export const removeBufferTime = (action$, state$) =>
  action$.pipe(
    ofType(types.API_REMOVE_BUFFER_TIME),
    switchMap(() =>
      from(Api.postDataToApi("schedule/removeBufferTime", state$.value.schedulerData.removeBufferTime)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    mergeMap(() => [fetchRigDataAction(), closePanel()])
  )

/**
  ATTEMPT TO FETCH DATA
*/

export const createNewRig = (action$, state$) =>
  action$.pipe(
    ofType(types.CREATE_NEW_RIG),
    switchMap(() =>
      from(Api.postDataToApi("rig/create", state$.value.schedulerData.newRigData)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    mergeMap(() =>
      // TODO: if response is error, do not update images and do not update imagerefresh
      [fetchRigDataAction(), closePanel()]
    )
  )

/**
  ATTEMPT TO CREATE NEW REQUEST
*/

export const createNewRequest = (action$, state$) =>
  action$.pipe(
    ofType(types.CREATE_NEW_REQUEST),
    switchMap(() =>
      from(Api.postDataToApi("wellrequest/create", state$.value.schedulerData.newRequestData)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    mergeMap(() =>
      // TODO: if response is error, do not update images and do not update imagerefresh
      [
        () => {
          console.log("OBSERVERS CREATE_NEW_REQUEST FINISHED")
        },
        fetchRigDataAction(),
        closePanel(),
      ]
    )
  )

export const removeRig = (action$, state$) =>
  action$.pipe(
    ofType(types.API_REMOVE_RIG),
    switchMap(() =>
      from(Api.postDataToApi("rig/removeInstances", state$.value.schedulerData.removeRigInstanceIds)).pipe(
        catchError((err) => of(observerError(err.xhr.response)))
      )
    ),
    mergeMap(() =>
      // TODO: if response is error, do not update images and do not update imagerefresh
      [fetchRigDataAction(), closePanel()]
    )
  )

/**
  ATTEMPT TO FETCH DATA
*/

export const showActivity = (action$) =>
  action$.pipe(
    ofType(types.FETCH_RIG_DATA),
    mergeMap(() => [showAcitivityIndicator("fetch_rig_data")])
  )

export const updateRigDataShowActivity = (action$) =>
  action$.pipe(
    ofType(types.UPDATE_RIG_DATA_EDIT),
    mergeMap(() => [showAcitivityIndicator("update_rig_data")])
  )

export const saveWellInstanceShowActivity = (action$) =>
  action$.pipe(
    ofType(types.SAVE_WELL_INSTANCE),
    mergeMap(() => [showAcitivityIndicator("save_well_instance")])
  )

export const fetchRigData = (action$, state$) =>
  action$.pipe(
    ofType(types.FETCH_RIG_DATA),
    switchMap(() =>
      from(
        Api.getDataFromApi(
          Api.formatUrl(
            "schedule",
            state$.value.router.location.pathname,
            state$.value.schedulerData.overridenPValueWells,
            state$.value.schedulerData.additionalScopeWells
          )
        )
      )
    ),
    mergeMap((result) => {
      if (!result.error || !result.code) {
        const isoFrom = parseISO(result.data.start_date)
        const isoTo = parseISO(result.data.end_date)

        const actions = [
          updateRigData(result),
          fetchAssetsData(),
          fetchLicenseData(),
          fetchAreaData(),
          setCalendarTimespan({
            from: isoFrom,
            to: isoTo,
          }),
          hideAcitivityIndicator("fetch_rig_data"),
        ]

        const pVersion = store.get("RIG_PVersion")
        const lastVersion = store.get("RIG_schedulerDataVersion")

        if (
          lastVersion &&
          pVersion &&
          parseInt(lastVersion.id, 0) === parseInt(result.data.id, 0) &&
          parseInt(lastVersion.revision, 0) === parseInt(result.data.revision, 0)
        ) {
          if (state$.value.calendar.PVersion.key !== pVersion.key) {
            actions.push(setPVersion(pVersion))
          }
        }

        return actions
      }

      return [hideAcitivityIndicator("fetch_rig_data"), showAcitivityIndicator("no_access")]
    })
  )

/**
  ATTEMPT TO FETCH_RIG_LIST
*/
export const fetchRigList = (action$) =>
  action$.pipe(
    ofType(types.FETCH_RIG_LIST),
    switchMap(() => from(Api.getDataFromApi("rig/getList"))),
    map((result) => updateRigList(result))
  )

export const fetchBlockData = (action$) =>
  action$.pipe(
    ofType(types.FETCH_BLOCK_DATA),
    switchMap(() => from(Api.getDataFromApi("block/list"))),
    map((result) => updateBlockData(result))
  )

export const fetchAreas = (action$) =>
  action$.pipe(
    ofType(types.FETCH_AREA_DATA),
    switchMap(() => from(Api.getDataFromApi("area/list"))),
    map((result) => updateAreaData(result.data))
  )

export const fetchWellData = (action$) =>
  action$.pipe(
    ofType(types.FETCH_WELL_DATA),
    switchMap(() => from(Api.getDataFromApi("well/list"))),
    map((result) => updateWellData(result))
  )
export const fetchSingleWellData = (action$, state$) =>
  action$.pipe(
    ofType(types.FETCH_SINGLE_WELL_DATA),
    switchMap(() => from(Api.getDataFromApi(`well/getWellForEdit/${state$.value.schedulerData.singleIdWellToFetch}`))),
    map((result) => updateSingleWellInstance(result.data))
  )

export const fetchAssetsDataObserver = (action$) =>
  action$.pipe(
    ofType(types.FETCH_ASSETS_DATA),
    switchMap(() => from(Api.getDataFromApi("asset/list"))),
    map((result) => updateAssetsData(result))
  )

export const fetchLicensesData = (action$) =>
  action$.pipe(
    ofType(types.FETCH_LICENSE_DATA),
    switchMap(() => from(Api.getDataFromApi("license/list")).pipe(catchError((err) => of(observerError(err.xhr.response))))),
    map((result) => updateLicensesData(result))
  )

/**
  EDIT RIG LIST
*/

export const editWellUpdated = (action$) =>
  action$.pipe(
    ofType(types.UPDATE_TEMPORARY_WELL),
    map(() => updateRigDataEdit())
  )

/**
  UPDATE SERVER
// */
export const rigDataEditSaved = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_RIG_DATA_EDIT),
    switchMap(() => from(Api.postDataToApi("schedule", state$.value.schedulerData.schedulerDataEdit.data))),
    mergeMap((result) => {
      if (!result.error) {
        return [
          updateRegretData(state$.value.schedulerData.schedulerHistoryObject),
          updateRigData(result),
          hideAcitivityIndicator("update_rig_data"),
        ]
      }

      return [resetRigdataEdit(), observerError(result, true)]
    })
  )

/**

*/
export const regretChange = (action$, state$) =>
  action$.pipe(
    ofType(types.REGRET_CHANGE),
    switchMap(() => from(Api.postDataToApi("schedule", state$.value.schedulerData.schedulerDataEdit.data))),
    mergeMap((result) => {
      if (!result.error) {
        return [updateRigData(result)]
      }

      return [resetRigdataEdit(), observerError(result, true)]
    })
  )

export const setInbetween = (action$, state$) =>
  action$.pipe(
    ofType(types.SET_ELEMENT_IN_BETWEEN),
    switchMap(() => from(Api.postDataToApi("schedule/moveWellBefore", state$.value.schedulerData.sendInBetween))),
    mergeMap((result) => {
      if (!result.error) {
        return [updateRegretData(state$.value.schedulerData.schedulerHistoryObject), updateRigData(result)]
      }

      return [observerError(result, true)]
    })
  )

export const rigDataFetched = (action$) =>
  action$.pipe(
    ofType(types.UPDATE_RIG_DATA),
    map(() => getRequests())
  )

export const updateScheduleBasedOnWellPriorityObserver = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_SCHEDULE_BASED_ON_PRIORITY),
    // tap(() => console.log(`Lets update on dropInfo: ${state$.value.schedulerData.dropInfo}`)),
    switchMap(() => from(Api.postDataToApi("schedule/updateRigbooking", state$.value.schedulerData.dropInfo))),
    mergeMap((result) => {
      if (!result.error) {
        return [fetchRigDataAction(), hideAcitivityIndicator("update_rig_data")]
      }

      return [resetRigdataEdit(), showAcitivityIndicator("update_rig_data"), observerError(result, true)]
    })
  )

export const clearSelections = (action$) =>
  action$.pipe(
    ofType(types.CLEAR_PREVIEW_SELECTION),
    tap(() => {
      const elms = document.getElementsByClassName("element--allready--in--schedule")

      // TODO check
      for (let i = 0; i < elms.length; i += 1) {
        elms[i].classList.remove("element--allready--in--schedule")
      }

      // for (const value of elms) {
      //   value.classList.remove('element--allready--in--schedule');
      // }
    }),
    map(() => observerEmpty())
  )

/**


*/
export const checkDragIfInBetween = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_DRAG_POS),
    distinctUntilChanged(),
    map(() => ({
      okDiff: state$.value.schedulerData.xPosDiff <= 2,
      dropReady: state$.value.schedulerData.dropReady,
    })),
    map((check) => {
      if (check.dropReady === true && check.okDiff === false) {
        return false
      }
      return true
    }),
    debounce((res) => {
      if (res) return timer(1000)
      return empty()
    }),
    distinctUntilChanged(),
    map((res) => setDropReady(res))
  )
